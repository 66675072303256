import { Container } from '@krakentech/coral';

import Alert from '../Alert';
import { useGlobalMessage } from '../Providers/GlobalMessage';

const GlobalMessage = () => {
  const {
    globalMessage: { message, messageSeverity = 'info' },
  } = useGlobalMessage();

  return message ? (
    <Container marginTop="lg" marginX="auto" maxWidth="lg" paddingX="md">
      <Alert severity={messageSeverity}>{message}</Alert>
    </Container>
  ) : null;
};

export default GlobalMessage;
