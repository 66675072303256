import { Stack } from '@krakentech/coral';

import PaymentScheduleCard from '@/components/billingAndPayment/PaymentScheduleCard';
import { Body1Skeleton, H1Skeleton } from '@/components/Skeletons';

const PaymentCardLoader = ({ title }: { title: string }) => (
  <PaymentScheduleCard title={title} isLoading>
    <Stack direction="vertical" alignItems="center">
      <H1Skeleton width={160} />
      <Body1Skeleton width={160} />
    </Stack>
  </PaymentScheduleCard>
);

export default PaymentCardLoader;
