import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';

import { useAuth } from '@/components/Providers/Auth';
import { useAnalytics } from '@/hooks/utils/useAnalytics';
import { trackPageViewForGoogle } from '@/lib/gtag';

// TODO: do something with this once we're confident we have a working set up. Right now this only houses GA and some Analytics functions and so it seems a bit silly to live in this state whilst the Adobe Analytics tag has to be elsewhere.
// The GA stuff could be either moved to the _document.page.tsx file, or we could move both GA and Adobe into a file that's linked there (and then silence the Next warnings about beforeInteractive being used outside the Head). This component can't be referenced there because it would be run before router is available and honestly just cause a load of havoc and mess.
const Analytics = () => {
  const router = useRouter();
  const {
    trackPageView,
    adobe: { enabled: adobeAnalyticsEnabled, ready: adobeAnalyticsReady },
    google: { enabled: googleAnalyticsEnabled },
  } = useAnalytics();
  const { hasLoaded } = useAuth();

  const trackPageViewForAdobe = useCallback(() => {
    if (adobeAnalyticsEnabled && window._satellite) {
      const delayedTrack = setTimeout(() => {
        // Tracks every page view including steppers
        window._satellite.track('page_step_changed');
      }, 3000); // Delay by 3 seconds which allows enough time for the window.digitalData to be up to date prior to tracking

      // Cleanup function to clear the timeout
      return () => {
        clearTimeout(delayedTrack);
      };
    }
  }, [adobeAnalyticsEnabled]);

  /**
   * Handle route / hash changes in the browser
   * and log those in Google/Adobe Analytics.
   */
  useEffect(() => {
    const handleRouteChange = () => {
      trackPageView();
      trackPageViewForAdobe();

      if (googleAnalyticsEnabled) {
        trackPageViewForGoogle({
          url: router.asPath,
        });
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('hashChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('hashChangeComplete', handleRouteChange);
    };
  }, [
    router.events,
    trackPageView,
    trackPageViewForAdobe,
    googleAnalyticsEnabled,
    router.asPath,
  ]);

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      typeof document !== 'undefined' &&
      hasLoaded
    ) {
      trackPageView();
    }
  }, [adobeAnalyticsEnabled, adobeAnalyticsReady, hasLoaded, trackPageView]);

  // Temporary solution to disable GA for Adobe clients, to see how much GTag is borking Adobe. The plan is to use GA for our purposes so this shouldn't stick around for long.
  if (googleAnalyticsEnabled) {
    return (
      <>
        <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GTAG_ID}`}
        />
        <Script
          id="ga-data-layer-inline"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}

            // Set initial consent values to 'denied'
            gtag('consent', 'default', {
              'ad_storage': 'denied',
              'analytics_storage': 'denied',
              'ad_user_data': 'denied',
              'ad_personalization': 'denied',
            });

            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GA_TRACKING_ID}');
          `,
          }}
        />
      </>
    );
  } else {
    return null;
  }
};

export default Analytics;
