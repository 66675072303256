// Function to convert the reading that the user enters in a form into a value that can be sent to the Kraken API
export const makeMeterReadingKrakenFriendly = (valueCubicMetres: string) => {
  return parseFloat(valueCubicMetres);
};

export const makeMeterReadingCorrectLength = (
  valueCubicMetres: string | number,
  numberOfMeterDigits: number
) => {
  const valueCubicMetresAsString = valueCubicMetres.toString();

  if (valueCubicMetresAsString.length < numberOfMeterDigits) {
    const zeros = '0'.repeat(
      numberOfMeterDigits - valueCubicMetresAsString.length
    );
    return `${zeros}${valueCubicMetresAsString}`;
  } else {
    return valueCubicMetresAsString;
  }
};
