import { IS_STW } from '@/utils/environment';

export const PASSWORD_MIN_LENGTH_LONG = 15;
export const PASSWORD_MIN_LENGTH_SHORT = 8;

export const MIN_CHARACTER_LENGTH = IS_STW
  ? PASSWORD_MIN_LENGTH_LONG
  : PASSWORD_MIN_LENGTH_SHORT;

export const passwordTooShortErrorMessage = `Please enter a password that is at least ${PASSWORD_MIN_LENGTH_SHORT} characters`;
export const stwPasswordTooShortErrorMessage = `This password is too short. Please enter at least ${PASSWORD_MIN_LENGTH_LONG} characters`;

export const MIN_CHARACTER_LENGTH_ERROR_MESSAGE = IS_STW
  ? stwPasswordTooShortErrorMessage
  : passwordTooShortErrorMessage;

// Leep, PWL and Nautilus password validation error messages
export const passwordTooCommonErrorMessage =
  'Please enter a password that is less common';
export const passwordReusedTooRecentlyErrorMessage =
  'Please enter a password that is different to your last 3 passwords';
export const passwordTooSimilarToEmailErrorMessage =
  'Please enter a password that does not include your email';
export const passwordIncludesAccountNumberErrorMessage =
  'Please enter a password that does not include your account number';

// STW password validation error messages
export const stwUppercaseCharacterErrorMessage =
  'This password is invalid. Please include at least 1 upper case character';
export const stwLowercaseCharacterErrorMessage =
  'This password is invalid. Please include at least 1 lower case character';
export const stwSpecialCharacterErrorMessage =
  'This password is invalid. Please include at least 1 special character (e.g. £ # %)';
export const stwNumericCharacterErrorMessage =
  'This password is invalid. Please include at least 1 numeric number (e.g. 1, 3, 6)';
export const stwCommonPasswordErrorMessage =
  'This password is invalid. Please enter a password that is less common';
export const stwPasswordReusedTooRecentlyErrorMessage =
  'This password is invalid. Please enter a password that is different to your last 3 passwords';
export const stwPasswordTooSimilarToEmailErrorMessage =
  'This password is invalid. Please enter a password that does not include your email';
export const stwPasswordIncludesAccountNumberErrorMessage =
  'This password is invalid. Please enter a password that does not include your account number';

export const defaultPasswordErrorMessage =
  'We were unable to update your password. Please try again later';

export const ERROR_MESSAGES: { [key: string]: string } = {
  ...(IS_STW
    ? {
        'This password does not contain the required amount of uppercase characters. It must contain at least 1 uppercase characters.':
          stwUppercaseCharacterErrorMessage,
        'This password does not contain the required amount of lowercase characters. It must contain at least 1 lowercase characters.':
          stwLowercaseCharacterErrorMessage,
        'This password does not contain the required amount of special characters. It must contain at least 1 characters.':
          stwSpecialCharacterErrorMessage,
        'This password does not contain the required amount of numeric characters. It must contain at least 1 numeric characters.':
          stwNumericCharacterErrorMessage,
        'This password is too common.': stwCommonPasswordErrorMessage,
        'Password reused too recently.':
          stwPasswordReusedTooRecentlyErrorMessage,
        'New password matches current password.':
          stwPasswordReusedTooRecentlyErrorMessage,
        'The password is too similar to the email.':
          stwPasswordTooSimilarToEmailErrorMessage,
        'New password must not contain account number.':
          stwPasswordIncludesAccountNumberErrorMessage,
      }
    : {
        'This password is too common.': passwordTooCommonErrorMessage,
        'Password reused too recently.': passwordReusedTooRecentlyErrorMessage,
        'New password matches current password.':
          passwordReusedTooRecentlyErrorMessage,
        'The password is too similar to the email.':
          passwordTooSimilarToEmailErrorMessage,
        'New password must not contain account number.':
          passwordIncludesAccountNumberErrorMessage,
      }),
  default: defaultPasswordErrorMessage,
};
