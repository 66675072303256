import { useState } from 'react';
import { Grid } from '@krakentech/coral';
import { IconWaterDrop } from '@krakentech/icons';

import { ActionCard } from '@/components';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';
import { useLatestReading } from '@/hooks/usage/useLatestReading';
import { INTERNAL_PATHS } from '@/utils/urls';

const SubmitNewReadingCard = () => {
  const [displayError, setDisplayError] = useState(false);
  const { accountNumber } = useAccountNumberContext();

  const {
    data,
    isLoading: isLatestReadingLoading,
    isError: isLatestReadingError,
  } = useLatestReading({ accountNumber });

  const isMetered = data?.properties[0]?.activeWaterMeters.length;

  // It's possible for this env var to be empty (e.g. for Leep, there are no unmetered accounts so there's no need for this variable)
  const meterInstallationLink =
    process.env.NEXT_PUBLIC_METER_INSTALLATION_LINK || '';

  const getCardHref = () => {
    if (isMetered) {
      return INTERNAL_PATHS.SUBMIT_METER_READING.createPath({
        meterId: data.properties[0].activeWaterMeters[0].id,
      });
    } else if (meterInstallationLink !== null) {
      return meterInstallationLink;
    } else {
      // We shouldn't ever actually get to this else block, as we'll have this env var for clients with unmetered accounts, meanwhile Leep customers are all metered. But Typescript gonna Typescript, innit
      setDisplayError(true);
      return '';
    }
  };

  const actionCardTitle = isMetered
    ? 'Enter a reading'
    : 'Switch to a water meter';

  return (
    <Grid.Item>
      <ActionCard
        href={getCardHref()}
        title={actionCardTitle}
        titleComponent="p"
        icon={<IconWaterDrop size={32} />}
        isLoading={isLatestReadingLoading}
        isError={isLatestReadingError || displayError}
        openInNewTab={!!meterInstallationLink && !isMetered}
      />
    </Grid.Item>
  );
};

export default SubmitNewReadingCard;
