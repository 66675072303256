import { StepConfig } from '@krakentech/coral';

import {
  AccountUserName,
  BlackholeUser,
  USER_REGISTRATION_JOURNEY_KEY_OPTIONS,
} from '@/context/UserRegistrationContext';

import { INTERNAL_PATHS } from './urls';

export const getUserIdFromBlackholeUserObject = (
  blackholeAccountUsers: Array<BlackholeUser>,
  currentFullName: AccountUserName
): string => {
  // This way of grabbing the user ID from the object using the givenName as a key is a bit shite but it works for now until we find a nicer way.
  return (
    blackholeAccountUsers.find(
      (accountUserName: BlackholeUser) =>
        currentFullName.givenName === accountUserName.givenName
    )?.id || ''
  );
};

// Stepper utils
export const hasMultipleMatchingBlackholeUsers = (
  blackholeAccountUsers: Array<BlackholeUser>
) => blackholeAccountUsers.length > 1;

export const getStepperPaths = ({
  shouldDisplayAboutYouStep,
  paths,
  journeyKey,
}: {
  shouldDisplayAboutYouStep: boolean;
  paths: Array<Record<string, string>>;
  journeyKey: USER_REGISTRATION_JOURNEY_KEY_OPTIONS;
}) => {
  if (shouldDisplayAboutYouStep) {
    return paths.filter(
      (path) => path.name !== INTERNAL_PATHS[`${journeyKey}_SUCCESS`].name
    );
  } else {
    return paths.filter(
      (path) =>
        path.name !== INTERNAL_PATHS[`${journeyKey}_SUCCESS`].name &&
        path.name !== INTERNAL_PATHS[`${journeyKey}_ABOUT_YOU`].name
    );
  }
};

export const getCurrentStep = (
  paths: Array<Record<string, string>>,
  asPath: string
) => {
  const activeStepIndex = paths.findIndex((page) => asPath === page.path);

  if (activeStepIndex !== -1) {
    return activeStepIndex;
  }

  return 0;
};

export const getStepConfigs = (
  finalStepConfig: StepConfig,
  shouldDisplayAboutYouStep: boolean
) => {
  const defaultStepConfigs = [
    { title: 'Your account', 'aria-label': 'Step - Your account' },
    { title: 'About you', 'aria-label': 'Step - About you' },
    { title: 'Security', 'aria-label': 'Step - Security' },
    finalStepConfig,
  ];

  return shouldDisplayAboutYouStep
    ? defaultStepConfigs
    : defaultStepConfigs.filter((config) => config.title !== 'About you');
};

// Journey Key Config
export const userRegistrationJourneyConfig = {
  [USER_REGISTRATION_JOURNEY_KEY_OPTIONS.FORGOTTEN_EMAIL]: {
    blockForRegisteredEmails: false,
    showDetailedNotEnoughInfoAlert: false,
    registerForm: {
      headerText: 'Register your email',
      alertMessage:
        "It looks like you haven't registered with an email yet. You'll need to do this to get access to your account online.",
    },
  },
  [USER_REGISTRATION_JOURNEY_KEY_OPTIONS.REGISTER_FOR_ONLINE_ACCOUNT]: {
    blockForRegisteredEmails: true,
    showDetailedNotEnoughInfoAlert: true,
    registerForm: {
      headerText: 'Register your account',
      alertMessage: '',
    },
  },
};
