import styled, { css } from 'styled-components';

export const StyledBoxWithGreyBorder = styled.div<{
  darkBackground: boolean;
  fullWidth: boolean;
}>`
  ${({ theme, darkBackground, fullWidth }) => css`
    width: ${fullWidth ? '100%' : 'auto'};
    padding: ${theme.spacing.smMd};
    border: ${theme.borderWidth} solid ${theme.color.surface.light};
    border-radius: ${theme.borderRadius};
    background: ${darkBackground
      ? theme.color.base.main
      : theme.color.base.dark};
  `}
`;
