import { PropsWithChildren } from 'react';

import { StyledBoxWithGreyBorder } from './index.styled';

const BoxWithGreyBorder = ({
  testId,
  darkBackground = false,
  fullWidth = false,
  children,
}: PropsWithChildren<{
  testId?: string;
  fullWidth?: boolean;
  darkBackground?: boolean;
}>) => (
  <StyledBoxWithGreyBorder
    data-testid={testId}
    darkBackground={darkBackground}
    fullWidth={fullWidth}
  >
    {children}
  </StyledBoxWithGreyBorder>
);

export default BoxWithGreyBorder;
