import { useKrakenQuery } from '@/components/GraphQLClient';
import { useAuth } from '@/components/Providers/Auth';

import { viewerEmailSuccessSchema } from '../validations';

import ViewerEmail from './ViewerEmail.graphql';

export const useViewerEmail = () => {
  const { isAuthenticated } = useAuth();

  return useKrakenQuery({
    queryKey: ['viewerEmail'],
    query: ViewerEmail,
    select: (data: any) => data.viewer.email,
    validateFn(response) {
      return viewerEmailSuccessSchema.validate(response);
    },
    enabled: Boolean(isAuthenticated),
  });
};
