import { useKrakenQuery } from '@/components/GraphQLClient';
import { useAuth } from '@/components/Providers/Auth';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';

import { ledgersSuccessSchema } from '../validations';

import Ledgers from './Ledgers.graphql';

export const useLedgers = () => {
  const { accountNumber } = useAccountNumberContext();
  const { isAuthenticated } = useAuth();

  return useKrakenQuery({
    queryKey: ['ledgers'],
    query: Ledgers,
    select: (data: any) => data.account.ledgers,
    variables: { accountNumber },
    validateFn(response) {
      return ledgersSuccessSchema.validate(response);
    },
    enabled: Boolean(isAuthenticated && accountNumber),
  });
};
