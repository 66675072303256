export const TRANSACTION_TYPES: Record<string, string> = {
  CREDIT: 'Credit',
  CHARGE: 'Charge',
  PAYMENT: 'Payment',
  REFUND: 'Refund',
};
export const TRANSACTION_TYPE_NAMES: Record<string, string> = {
  CREDIT: 'Credit',
  CHARGE: 'Water charge',
  PAYMENT: 'Payment',
  REFUND: 'Refund',
};
