import styled, { css } from 'styled-components';

export const StyledMeterReadingFieldContainer = styled.div<{
  normalSizedInputText: boolean;
}>`
  ${({ theme, normalSizedInputText }) => css`
    input {
      text-align: center;
      font-size: ${theme.typography.h1.baseStyles.fontSize};

      ${!normalSizedInputText &&
      css`
        letter-spacing: ${theme.spacing.xxs};
      `}

      @media (min-width: ${theme.breakpoints.md}px) {
        ${!normalSizedInputText &&
        css`
          font-size: ${theme.typography.h1.md.fontSize};
        `}
      }
    }

    label {
      display: none;
    }

    span {
      display: none;
    }
  `}
`;
