import { useEffect } from 'react';
import { Card, Typography, Stack, Link } from '@krakentech/coral';
import { Formik, Form, Field, useFormikContext } from 'formik';

import {
  Alert,
  Body2Skeleton,
  EstimatedPaymentsCard,
  FormContainer,
  FormSubmitButton,
  FullWidthButtonSkeleton,
  H4Skeleton,
  PageError,
  PaymentDayField,
  RadioCard,
  RadioCardSkeleton,
  TextFieldSkeleton,
} from '@/components';
import { DIRECT_DEBIT_SCHEDULE_CHOICES } from '@/consts/paymentFrequency';
import { PAYMENT_FREQUENCY_FIELD_OPTIONS } from '@/consts/paymentFrequency';
import { OptionalDate } from '@/context/DirectDebitContext/index.types';
import { useBalance } from '@/hooks/accounts/useBalance';
import { useProposedDirectDebitPaymentSchedule } from '@/hooks/billsAndPayments/useProposedDirectDebitPaymentSchedule';
import { useHasActiveWaterMeter } from '@/hooks/usage/useHasActiveWaterMeter';
import { PaymentFrequencyFormValues } from '@/types/directDebit';

type PaymentFrequencyFormContentProps = {
  setFirstPossibleVariableSchedulePaymentDate: (date: OptionalDate) => void;
  setHasActiveWaterMeter: (hasActiveWaterMeter: boolean) => void;
  submitButtonLabel?: string;
  isChangePaymentScheduleForm?: boolean;
  isMutationError?: boolean;
  isMutationLoading?: boolean;
};

type PaymentFrequencyFormProps = PaymentFrequencyFormContentProps & {
  handleSubmit: (values: PaymentFrequencyFormValues) => void;
  initialPaymentFrequency: PAYMENT_FREQUENCY_FIELD_OPTIONS | undefined;
  initialPaymentDay: { label: string; value: number } | undefined;
  submitButtonLabel?: string;
  isChangePaymentScheduleForm?: boolean;
  isLoading?: boolean;
  isMutationError?: boolean;
  isMutationLoading?: boolean;
};

const PaymentFrequencyFormContent = ({
  setFirstPossibleVariableSchedulePaymentDate,
  setHasActiveWaterMeter,
  submitButtonLabel,
  isChangePaymentScheduleForm,
  isMutationError,
  isMutationLoading = false,
}: PaymentFrequencyFormContentProps) => {
  const { values, dirty } = useFormikContext<PaymentFrequencyFormValues>();
  const {
    isError: isErrorProposedPaymentSchedule,
    refetch,
    data,
  } = useProposedDirectDebitPaymentSchedule({
    scheduleType:
      typeof values.paymentFrequency !== 'undefined' &&
      values.paymentFrequency === PAYMENT_FREQUENCY_FIELD_OPTIONS.VARIABLE
        ? DIRECT_DEBIT_SCHEDULE_CHOICES.PAY_ON_RECEIPT_OF_BILL
        : DIRECT_DEBIT_SCHEDULE_CHOICES.MONTHLY,
  });
  const {
    data: activeWaterMeterCount,
    isError: isErrorActiveWaterMeter,
    isLoading: isLoadingActiveWaterMeter,
  } = useHasActiveWaterMeter();
  const {
    data: balance,
    isLoading: isLoadingBalance,
    isError: isErrorBalance,
  } = useBalance();

  // Block the user from setting up a fixed payment schedule if they're unmeasured and aren't in debit, as the mutation would error in this case
  const blockFixedPaymentSchedule = !activeWaterMeterCount && balance >= 0;

  // Display the estimated payments card if the user is on a variable schedule or if they are on a monthly schedule and have selected a payment day. Or in other words, only display the estimated payments card if the above form fields have been filled in
  const displayEstimatedPaymentsCard =
    values.paymentFrequency === PAYMENT_FREQUENCY_FIELD_OPTIONS.VARIABLE ||
    (values.paymentFrequency === PAYMENT_FREQUENCY_FIELD_OPTIONS.MONTHLY &&
      values.paymentDay?.value);

  const hasNoChanges = isChangePaymentScheduleForm && !dirty;
  // Disable the form submit button if the user is on a variable schedule and the proposed payment schedule is in error, or if the estimated payments card is not displayed. If the esimated payments card is not displayed, it means the user has not filled in the form fields
  const disableFormSubmitButton =
    (isErrorProposedPaymentSchedule &&
      values.paymentFrequency !== PAYMENT_FREQUENCY_FIELD_OPTIONS.VARIABLE) ||
    !displayEstimatedPaymentsCard ||
    (isChangePaymentScheduleForm && blockFixedPaymentSchedule) ||
    hasNoChanges;

  // Save the firstPossiblePaymentDate to context if the user is on a variable schedule, so we can send it over the mutation later
  useEffect(() => {
    if (values.paymentFrequency === PAYMENT_FREQUENCY_FIELD_OPTIONS.VARIABLE) {
      setFirstPossibleVariableSchedulePaymentDate(
        data?.firstPossibleCollectionDate
      );
    }
  }, [
    values.paymentFrequency,
    data,
    setFirstPossibleVariableSchedulePaymentDate,
  ]);

  useEffect(() => {
    setHasActiveWaterMeter(!!activeWaterMeterCount);
  });

  if (isLoadingBalance || isLoadingActiveWaterMeter) {
    return <LoadingState />;
  }

  if (isErrorActiveWaterMeter || isErrorBalance) {
    return <PageError />;
  }

  return (
    <Stack gap="md" md={{ gap: 'lg' }} direction="vertical">
      <Card>
        <Stack direction="vertical" gap="md">
          <Typography variant="h2">How often do you want to pay?</Typography>

          <Field
            name="paymentFrequency"
            label="When you get your bill"
            explanation={`Every ${
              activeWaterMeterCount
                ? process.env.NEXT_PUBLIC_MEASURED_BILLING_PERIOD
                : process.env.NEXT_PUBLIC_UNMEASURED_BILLING_PERIOD
            } months, we'll collect any balance owed in full a few days after your bill has been issued`}
            component={RadioCard}
            value={PAYMENT_FREQUENCY_FIELD_OPTIONS.VARIABLE}
            dataTestId="variableScheduleField"
            onChange={refetch}
          />
          <Field
            name="paymentFrequency"
            label="Monthly"
            explanation={
              blockFixedPaymentSchedule
                ? "You can't set up monthly payments right now. Please come back after you receive your next bill"
                : 'Pay into your account with monthly regular payments'
            }
            component={RadioCard}
            value={PAYMENT_FREQUENCY_FIELD_OPTIONS.MONTHLY}
            dataTestId="monthlyScheduleField"
            onChange={refetch}
            disabled={blockFixedPaymentSchedule}
          />
        </Stack>
      </Card>

      {values.paymentFrequency === PAYMENT_FREQUENCY_FIELD_OPTIONS.MONTHLY && (
        <Card>
          <Stack direction="vertical" gap="md">
            <Typography variant="h2">Choose a payment date</Typography>

            <PaymentDayField />
          </Stack>
        </Card>
      )}

      {displayEstimatedPaymentsCard && <EstimatedPaymentsCard />}

      {isChangePaymentScheduleForm && (
        <Alert severity={blockFixedPaymentSchedule ? 'warning' : 'info'}>
          {blockFixedPaymentSchedule ? (
            <Typography>
              You can&apos;t set up monthly payments right now. Please come back
              after you receive your next bill.
            </Typography>
          ) : (
            <Typography>
              <strong>Please note:</strong> Direct Debit changes may take up to
              14 days to process, so any payments due within this period might
              still be collected.
            </Typography>
          )}
        </Alert>
      )}

      {isMutationError && (
        <Alert severity="error">
          <Typography>
            Sorry, there seems to be a problem and we can&apos;t process your
            request right now. Please try again later or{' '}
            <Link href={process.env.NEXT_PUBLIC_HELP_LINK} target="_blank">
              contact us
            </Link>{' '}
            if the issue persists.
          </Typography>
        </Alert>
      )}

      <FormSubmitButton
        loading={isMutationLoading}
        disabled={disableFormSubmitButton}
      >
        {submitButtonLabel}
      </FormSubmitButton>
    </Stack>
  );
};

const PaymentFrequencyForm = ({
  setHasActiveWaterMeter,
  setFirstPossibleVariableSchedulePaymentDate,
  handleSubmit,
  initialPaymentFrequency,
  initialPaymentDay,
  submitButtonLabel = 'Add bank details',
  isChangePaymentScheduleForm,
  isLoading = false,
  isMutationError,
  isMutationLoading,
}: PaymentFrequencyFormProps) => {
  if (isLoading) return <LoadingState />;

  return (
    <FormContainer>
      <Formik
        initialValues={{
          paymentFrequency: initialPaymentFrequency,
          paymentDay: initialPaymentDay,
        }}
        onSubmit={handleSubmit}
      >
        <Form>
          <PaymentFrequencyFormContent
            submitButtonLabel={submitButtonLabel}
            setFirstPossibleVariableSchedulePaymentDate={
              setFirstPossibleVariableSchedulePaymentDate
            }
            setHasActiveWaterMeter={setHasActiveWaterMeter}
            isChangePaymentScheduleForm={isChangePaymentScheduleForm}
            isMutationError={isMutationError}
            isMutationLoading={isMutationLoading}
          />
        </Form>
      </Formik>
    </FormContainer>
  );
};

const LoadingState = () => (
  <Stack gap="md" md={{ gap: 'lg' }} direction="vertical">
    <Card>
      <Stack direction="vertical" gap="md">
        <H4Skeleton />
        <RadioCardSkeleton />
        <RadioCardSkeleton />
      </Stack>
    </Card>

    <Card>
      <Stack direction="vertical" gap="md">
        <H4Skeleton />
        <Stack direction="vertical" gap="sm">
          <TextFieldSkeleton />

          <Body2Skeleton />
        </Stack>
      </Stack>
    </Card>

    <FullWidthButtonSkeleton />
  </Stack>
);

export default PaymentFrequencyForm;
