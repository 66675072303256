import { sub, getYear, isFuture, isExists } from 'date-fns';

export const combineDateValues = (
  year: string | undefined,
  month: string | undefined,
  day: string | undefined
) => {
  if (!year || !month || !day) {
    return;
  }

  const combinedDate = new Date(`${year}-${month}-${day}`);

  return combinedDate || undefined;
};

// Check that the year isn't anymore than 120 years in the past (this is what we do on OEGB)
export const yearTooEarly = (year: number) => {
  const earliestValidYear = getYear(sub(new Date(), { years: 120 }));

  return year < earliestValidYear;
};

// Check that the date isn't in the future (this is what we do on OEGB). If the full date is entered, and this can create a valid date object, we check that the whole date is not in the future. So tomorrow would return falsy. If only the year is available, or if the other fields are invalid so they don't create a valid date, just check the year
export const dateTooLate = (
  year: string | undefined,
  month: string | undefined,
  day: string | undefined
) => {
  const date = combineDateValues(year, month, day);

  return date ? isFuture(date) : isFuture(new Date(`${year}-01-01`));
};

const numberIsInRange = (
  numToCheck: number,
  lowestLimit: number,
  highestLimit: number
) => numToCheck >= lowestLimit && numToCheck <= highestLimit;

export const validateYear = (dateFields: {
  year?: string;
  month?: string;
  day?: string;
}): boolean => {
  const { year: year, month: month, day: day } = dateFields;

  if (!year) {
    return false;
  }

  return (
    year.length === 4 &&
    !yearTooEarly(parseInt(year)) &&
    !dateTooLate(year, month, day)
  );
};

export const validateMonth = (month: string | undefined): boolean => {
  if (!month || month.length > 2) {
    return false;
  }

  return numberIsInRange(parseInt(month), 1, 12);
};

export const validateDay = (dayFields: {
  year?: string;
  month?: string;
  day?: string;
}): boolean => {
  const { year: year, month: month, day: day } = dayFields;

  if (!day || day.length > 2 || day === '' || isNaN(parseInt(day))) {
    return false;
  }

  // Check if the date actually exists or not. E.g. 28/02/99 -> true and 29/02/00 -> true but 29/02/99 -> false. We only do this if the month exists.
  const dateDoesNotExist =
    month &&
    !isExists(
      // If the year exists and if it has a length of 4, use it in this function. This is so a day error doesn't pop up whilst the user is typing. If the year is missing, we can still invalidate most invalid dates (e.g. April 31st). We use the year 2000 if it's missing, as it's a leap year
      year && year.length === 4 ? parseInt(year) : 2000,
      parseInt(month) - 1,
      parseInt(day)
    );

  return numberIsInRange(parseInt(day), 1, 31) && !dateDoesNotExist;
};

export const fieldIsEmpty = (value?: string) =>
  value === '' || value === undefined;

export const dateIsEmpty = (year?: string, month?: string, day?: string) =>
  fieldIsEmpty(year) && fieldIsEmpty(month) && fieldIsEmpty(day);
