import { useMutation, useQuery, useInfiniteQuery } from '@tanstack/react-query';
import { GraphQLClient } from 'graphql-request';

import { useAuth } from '@/components/Providers/Auth';
import { API_ENDPOINT } from '@/utils/environment';
import { getErrorDescriptionWithType } from '@/utils/errors';
import { isKrakenErrorResponse } from '@/utils/request/types-response';

import type {
  ResponseWithEdges,
  UseKrakenInfiniteQueryArgs,
  UseKrakenMutationArgs,
  UseKrakenQueryArgs,
} from './types';

const client = new GraphQLClient(API_ENDPOINT);

const handleKrakenErrors = ({
  errorResponse,
  onRefreshExpired,
  onAccessTokenExpired,
  onAuthHeaderNotProvided,
}: {
  errorResponse: unknown;
  onRefreshExpired: () => void;
  onAccessTokenExpired: () => void;
  onAuthHeaderNotProvided: () => void;
}): void => {
  if (!isKrakenErrorResponse(errorResponse)) {
    return;
  }
  const [firstError] = errorResponse.response.errors;
  const { errorType } = getErrorDescriptionWithType(firstError);

  if (errorType === 'REFRESH_TOKEN_EXPIRED') {
    onRefreshExpired();
    return;
  }

  if (errorType === 'ACCESS_TOKEN_EXPIRED') {
    onAccessTokenExpired();
    return;
  }

  if (errorType === 'AUTH_HEADER_NOT_PROVIDED') {
    onAuthHeaderNotProvided();
    return;
  }

  return;
};

export function useKrakenQuery<D, E, TSelectData = D>({
  query,
  queryKey,
  validateFn,
  requestHeaders,
  silentErrors,
  enabled = true,
  variables,
  select,
  ...options
}: UseKrakenQueryArgs<D, E, TSelectData>) {
  const {
    accessToken,
    masqueradeToken,
    hasLoaded,
    clearAccessTokens,
    expireSession,
  } = useAuth();

  /**
   * Token to use as Authorization value.
   */
  const authToken = masqueradeToken ?? accessToken;

  return useQuery<D, E, TSelectData>({
    ...options,
    queryKey: [...queryKey, authToken].filter(Boolean),
    queryFn: async () => {
      const headers = {
        ...(authToken && { Authorization: authToken }),
        ...requestHeaders,
      };

      let silentError = null;

      const response = await client
        .request(query, variables, headers)
        .catch((e) => {
          handleKrakenErrors({
            errorResponse: e,
            onRefreshExpired: () => expireSession(),
            onAccessTokenExpired: () => clearAccessTokens(),
            onAuthHeaderNotProvided: () => clearAccessTokens(),
          });
          if (silentErrors) {
            silentError = e;
            return;
          }

          throw e;
        });

      if (silentError) {
        return silentError;
      }

      return await validateFn(response);
    },
    enabled: enabled && hasLoaded,
    select,
  });
}

export function useKrakenInfiniteQuery<D, E>({
  query,
  validateFn,
  requestHeaders,
  silentErrors,
  enabled = true,
  variables,
  ...options
}: UseKrakenInfiniteQueryArgs<D, E>) {
  const {
    accessToken,
    expireSession,
    clearAccessTokens,
    hasLoaded,
    masqueradeToken,
  } = useAuth();

  /**
   * Token to use as Authorization value.
   */
  const authToken = masqueradeToken ?? accessToken;

  return useInfiniteQuery<ResponseWithEdges<D>, E>({
    ...options,
    queryKey: [options.queryKey, authToken],
    queryFn: async ({ pageParam = '0' }) => {
      const headers = {
        ...(authToken && { Authorization: authToken }),
        ...requestHeaders,
      };

      let silentError = null;

      const response = await client
        .request(query, { ...variables, cursor: pageParam }, headers)
        .catch((e) => {
          handleKrakenErrors({
            errorResponse: e,
            onRefreshExpired: () => expireSession(),
            onAccessTokenExpired: () => clearAccessTokens(),
            onAuthHeaderNotProvided: () => clearAccessTokens(),
          });
          if (silentErrors) {
            silentError = e;
            return;
          }

          throw e;
        });

      if (silentError) {
        return silentError;
      }

      return await validateFn(response);
    },
    getNextPageParam: (lastPage) => {
      // Conditional required as endCursor still returns when hasNextPage is falsy
      return lastPage.pageInfo.hasNextPage
        ? lastPage.pageInfo.endCursor
        : undefined;
    },
    enabled: hasLoaded && enabled,
  });
}

export function useKrakenMutation<D, E, V>({
  mutation,
  validateFn,
  requestHeaders,
  silentErrors,
  ...options
}: UseKrakenMutationArgs<D, E, V>) {
  const { accessToken, masqueradeToken, clearAccessTokens, expireSession } =
    useAuth();

  /**
   * Token to use as Authorization value.
   */
  const authToken = masqueradeToken ?? accessToken;

  return useMutation({
    ...options,
    mutationFn: async (variables: V) => {
      const headers = {
        ...(authToken && { Authorization: authToken }),
        ...requestHeaders,
      };
      const requestVariables = variables ? variables : undefined;

      const operation = mutation(variables);

      let silentError = null;

      const response = await client
        .request(operation, requestVariables, headers)
        .catch((e) => {
          handleKrakenErrors({
            errorResponse: e,
            onRefreshExpired: () => expireSession(),
            onAccessTokenExpired: () => clearAccessTokens(),
            onAuthHeaderNotProvided: () => clearAccessTokens(),
          });
          if (silentErrors) {
            silentError = e;
            return;
          }
          throw e;
        });

      if (silentError) {
        return silentError;
      }

      return await validateFn(response);
    },
    retry(_, errorResponse) {
      if (!isKrakenErrorResponse(errorResponse)) {
        return false;
      }
      const [firstError] = errorResponse.response.errors;
      const { errorType } = getErrorDescriptionWithType(firstError);

      if (errorType === 'ACCESS_TOKEN_EXPIRED') {
        clearAccessTokens();
        return true;
      }
      return false;
    },
  });
}

export default client;
