import { useKrakenMutation } from '@/components/GraphQLClient';
import { useAuth } from '@/components/Providers/Auth';

import { forceReauthenticationSchema } from '../validations';

import ForceReauthentication from './ForceReauthentication.graphql';

export const useForceReauthentication = ({
  displayError,
}: {
  displayError: () => void;
}) => {
  const { logout } = useAuth();

  return useKrakenMutation({
    mutation: (_) => ForceReauthentication,
    validateFn(response) {
      return forceReauthenticationSchema.validate(response);
    },
    onSuccess({ forceReauthentication: { tokensInvalidated } }) {
      if (tokensInvalidated) {
        logout();
      } else {
        // If tokensInvalidated comes back as false then something has gone wrong on the back end, therefore they're still effectively logged in. We need to treat this scenario as an error because, well, that's what it is
        displayError();
      }
    },
    onError() {
      displayError();
    },
  });
};
