/**
 *  Replace either 6 consecutive digits or 6 digits separated by spaces every 2 digits with the same 6 digits separated by hyphens every 2 digits, i.e.
 *
 *      123456 -> 12-34-56
 *
 *      12 34 56 -> 12-34-56
 */
export const addHyphensIntoSortCode = (sortCode?: string) => {
  // Copilot wrote this for me, I don't really know what it's doing tbh :D
  return sortCode
    ? sortCode.replace(
        // (\d{2})(\d{2})(\d{2}) matches 6 consecutive digits
        // (\d{2}) (\d{2}) (\d{2}) matches 6 digits separated by spaces every 2 digits
        /^(\d{2})(\d{2})(\d{2})$|(\d{2}) (\d{2}) (\d{2})$/,
        '$1$4-$2$5-$3$6'
      )
    : '';
};
