import { useCoralTheme } from '@krakentech/coral';

const HouseActive = () => {
  const { color } = useCoralTheme();

  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.9937 10.6824C23.9937 11.4335 23.3678 12.0177 22.6584 12.0177H21.3231L21.3648 18.6942C21.3648 18.8194 21.3648 18.9446 21.3648 19.028V19.6957C21.3648 20.6554 20.6137 21.3648 19.6957 21.3648H19.028C18.9446 21.3648 18.9029 21.3648 18.8611 21.3648C18.8194 21.3648 18.736 21.3648 18.6942 21.3648H16.3574C15.3977 21.3648 14.6883 20.6554 14.6883 19.6957V16.0236C14.6883 15.3142 14.0624 14.6883 13.353 14.6883H10.6824C9.9313 14.6883 9.34711 15.3142 9.34711 16.0236V19.6957C9.34711 20.6554 8.596 21.3648 7.67798 21.3648H5.34121C5.25775 21.3648 5.21602 21.3648 5.13257 21.3648C5.09084 21.3648 5.04911 21.3648 5.00738 21.3648H4.33973C3.37998 21.3648 2.6706 20.6554 2.6706 19.6957V15.0221C2.6706 15.0221 2.6706 14.9804 2.6706 14.9387V12.0177H1.3353C0.584194 12.0177 0 11.4335 0 10.6824C0 10.3069 0.125185 9.97303 0.417282 9.68094L11.0997 0.333825C11.3918 0.0417282 11.7256 0 12.0177 0C12.3098 0 12.6436 0.0834563 12.894 0.292097L23.5347 9.68094C23.8685 9.97303 24.0354 10.3069 23.9937 10.6824Z"
        fill={color.tertiary.main}
      />
    </svg>
  );
};

export default HouseActive;
