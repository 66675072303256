import {
  createContext,
  useContext,
  useMemo,
  useState,
  type ReactNode,
} from 'react';

import { TGlobalMessageContext } from './types';

const GlobalMessageContext = createContext<TGlobalMessageContext | null>(null);

type GlobalMessage = TGlobalMessageContext['globalMessage']['message'];
type GlobalMessageSeverity =
  TGlobalMessageContext['globalMessage']['messageSeverity'];

export function GlobalMessageProvider({ children }: { children: ReactNode }) {
  const [message, setMessage] = useState<GlobalMessage>(null);
  const [messageSeverity, setMessageSeverity] =
    useState<GlobalMessageSeverity>('info');

  const setGlobalMessage = ({
    message,
    severity,
  }: {
    message: GlobalMessage;
    severity?: GlobalMessageSeverity;
  }) => {
    setMessage(message);
    setMessageSeverity(severity);
  };

  const globalMessage = useMemo(
    () => ({
      message,
      messageSeverity,
    }),
    [message, messageSeverity]
  );

  const value = {
    globalMessage,
    setGlobalMessage,
  };

  return (
    <GlobalMessageContext.Provider value={value}>
      {children}
    </GlobalMessageContext.Provider>
  );
}

export function useGlobalMessage() {
  const globalContext = useContext(GlobalMessageContext);

  if (globalContext === null) {
    throw new Error(
      'useGlobalMessage must be used within an GlobalMessageProvider'
    );
  }

  return globalContext;
}
