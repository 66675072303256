import { ReactNode, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Loader } from '@krakentech/coral';

import { useScopedTokenContext, useAccountNumberContext } from '@/context';
import { INTERNAL_PATHS } from '@/utils/urls';

const NonEmailSignUpRedirectLayout = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { push, query } = useRouter();
  const { scopedToken } = useScopedTokenContext();

  const { setAccountNumber } = useAccountNumberContext();

  const { accountNumber, token } = query as {
    accountNumber: string;
    token: string;
  };

  useEffect(() => {
    if (!scopedToken && accountNumber && token) {
      setAccountNumber(accountNumber);
      push(
        `${INTERNAL_PATHS.SCOPED_TOKEN.createPath({
          token,
        })}?redirect=${INTERNAL_PATHS.SIGN_UP.CHECK_USER_DETAILS.path}`
      );
    }
  }, [scopedToken, push, accountNumber, token, setAccountNumber]);

  if (!scopedToken) {
    return <Loader type="linear" variant="indeterminate" />;
  }

  return <>{children}</>;
};

export default NonEmailSignUpRedirectLayout;
