import {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState,
  type ReactNode,
} from 'react';
import { useRouter } from 'next/router';
import { useMutation } from '@tanstack/react-query';
import { GraphQLClient } from 'graphql-request';

import { sessionAccountNumberKey } from '@/context/AccountNumberContext/withFetch/sessionKey';
import ObtainKrakenToken from '@/hooks/auth/useObtainKrakenToken/ObtainKrakenToken.graphql';
import { setUserIdForGoogle } from '@/lib/gtag';
import {
  ObtainKrakenTokenResponse,
  validateResponseSchema,
} from '@/utils/request/ObtainKrakenToken/validateResponseSchema';
import { INTERNAL_PATHS } from '@/utils/urls';

import { useGlobalMessage } from '../GlobalMessage';

import type { AuthContext as TAuthContext, LoginTokens } from './types';

const LOCAL_STORAGE_REFRESH_TOKEN_KEY = 'refreshToken';
const LOCAL_STORAGE_MASQUERADE_TOKEN_KEY = 'masqueradeToken';

const AuthContext = createContext<TAuthContext | null>(null);

/**
 * The `AuthProvider` wraps the application. It provides children components with `login`, `logout` & `removal of auth tokens` handlers.
 * It also provides the access token and a boolean for whether or not a user `isAuthenticated`.
 */
export function AuthProvider({
  client,
  children,
}: {
  client: GraphQLClient;
  children: ReactNode;
}) {
  const { setGlobalMessage } = useGlobalMessage();
  const [accessToken, setAccessToken] =
    useState<TAuthContext['accessToken']>(null);
  const [hasLoaded, setHasLoaded] = useState(false);
  const router = useRouter();

  /**
   * This token will be used to represent a masqueraded user token.
   */
  const masqueradeToken =
    typeof window !== 'undefined'
      ? window.localStorage.getItem(LOCAL_STORAGE_MASQUERADE_TOKEN_KEY) ?? null
      : null;

  /*
   * Set the accessToken to a null value & remove the masqueradeToken from the LocalStorage
   */
  const clearAccessTokens = useCallback(() => {
    setHasLoaded(false);
    setAccessToken(null);

    // Masquerade token expiry
    if (masqueradeToken) {
      // Remove masquerade token from local storage
      if (typeof window !== 'undefined') {
        localStorage.removeItem(LOCAL_STORAGE_MASQUERADE_TOKEN_KEY);
      }

      setGlobalMessage({
        message: 'Your session has expired, please log in again to continue.',
        severity: 'info',
      });

      router.replace(INTERNAL_PATHS.LOGIN.path);
    }
  }, [masqueradeToken, router, setGlobalMessage]);

  /**
   * When a user logs in, we save their access token in memory and their refresh token in `LocalStorage`
   */
  const login: TAuthContext['login'] = useCallback(
    ({ accessToken, masqueradeToken, refreshToken }) => {
      // When a support user requests to masquerade, we save the masquerade token in `LocalStorage`
      if (masqueradeToken) {
        window.localStorage.setItem(
          LOCAL_STORAGE_MASQUERADE_TOKEN_KEY,
          masqueradeToken
        );
      }

      accessToken && setAccessToken(accessToken);

      // Add the refresh token to local storage
      if (refreshToken) {
        window.localStorage.setItem(
          LOCAL_STORAGE_REFRESH_TOKEN_KEY,
          refreshToken
        );
      }
      // Clear the global message in the provider
      setGlobalMessage({ message: null });
    },
    [setGlobalMessage]
  );

  /**
   * When a user logs out, we remove both their refresh token and their access token then redirect them to the login page.
   */
  const logout = useCallback(() => {
    // Set access token state to null
    clearAccessTokens();

    // Remove refresh token from local storage
    if (typeof window !== 'undefined') {
      window.localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
    }

    // Remove account number from session storage
    window.sessionStorage.removeItem(sessionAccountNumberKey);

    // Reset userId for Google analytics
    setUserIdForGoogle(null);

    router.replace(INTERNAL_PATHS.LOGIN.path);
  }, [clearAccessTokens, router]);

  /**
   * When a refresh token has expired, we can use this function to log them out and and display a message telling them why.
   */
  const expireSession = useCallback(() => {
    logout();

    setGlobalMessage({
      message: 'Your session has expired, please log in again to continue.',
      severity: 'info',
    });
  }, [logout, setGlobalMessage]);

  /*
   * If client-side, attempt to retrieve the refresh token from local storage
   */
  const refreshToken =
    typeof window !== 'undefined'
      ? window.localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY) ?? null
      : null;

  /*
   * Retrieve the kraken token from the mutation endpoint using the refresh token
   *  Upon successful retrieval, log the user in by storing the values in local storage and context
   */
  const refreshAccessToken = useCallback(
    async (
      refreshToken: LoginTokens['refreshToken']
    ): Promise<ObtainKrakenTokenResponse | null> => {
      try {
        const response = await client.request(ObtainKrakenToken, {
          input: { refreshToken },
        });
        return await validateResponseSchema(response);
      } catch (e) {
        return null;
      }
    },
    [client]
  );

  const { mutate: refreshAccessTokenMutation } = useMutation({
    mutationFn: refreshAccessToken,
    onSuccess: (response: ObtainKrakenTokenResponse | null) => {
      if (response !== null) {
        const {
          obtainKrakenToken: { token: accessToken, refreshToken },
        } = response;
        login({ accessToken, refreshToken });
      } else {
        expireSession();
      }
    },
    onError: () => {
      expireSession();
    },
    onSettled: () => {
      setHasLoaded(true);
    },
  });

  /**
   * When the application loads for the first time, we won't have an access token because we store that in memory.
   * If we have logged in before, we should have a refresh token in localStorage.
   * We check if that is the case and use the refresh token to get a new access token with obtainKrakenToken.
   *
   */
  useEffect(() => {
    if (!accessToken && refreshToken) {
      refreshAccessTokenMutation(refreshToken);
    } else if (!accessToken && !refreshToken) {
      // With no access token and no refresh token, we finish loading because there is nothing left to do.
      setHasLoaded(true);
    }
  }, [accessToken, refreshAccessTokenMutation, refreshToken]);

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        masqueradeToken,
        hasLoaded,
        // Previously isAuthenticated was being set in a useEffect to prevent a potential hydration issue, whereby there would be mismatches between client state and server state. However, the useEffect was causing us to be redirected back to the login page (without a log out) whenever the page was manually refreshed. So the solution to prevent the potential mismatch has been removed for now, however it is possible we could start to see this issue in the future.
        isAuthenticated:
          Boolean(accessToken && refreshToken) || Boolean(masqueradeToken),
        login,
        logout,
        expireSession,
        clearAccessTokens,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const authContext = useContext(AuthContext);

  if (authContext === null) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return authContext;
}
