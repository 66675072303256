import { AccountUserName } from '@/context/UserRegistrationContext';

/**
 * The Formik/Coral select expects a string or a number as a
 * 'value'. Converts the array of name objects returned from the
 * `BlackholeEmailAccount` query with the shape:
 * { givenName: string, familyName: string }
 */
export const generateUserNameSelectOptions = (
  usersNames: AccountUserName[]
) => {
  return usersNames.map((user) => ({
    value: `${user.givenName}_${user.familyName}`,
    label: `${user.givenName} ${user.familyName}`,
  }));
};

/**
 * The Formik/Coral select expects a string or a number as a 'value'.
 * Converts an underscored separated string to an object with the shape:
 * { givenName: string, familyName: string }
 */
export const fullNameToObject = (fullName: string) => {
  const names = fullName.split('_');
  return {
    givenName: names[0],
    familyName: names[1],
  };
};
