import { Card, Typography, Stack } from '@krakentech/coral';
import { FormikTextField } from '@krakentech/coral-formik';

import {
  DateField,
  MutedText,
  TitleField,
  HomeOwnershipStatusField,
  EmploymentStatusField,
} from '@/components';

const PersonalDetailsForm = ({ readOnly }: { readOnly?: boolean }) => {
  return (
    <Card>
      <fieldset disabled={readOnly} aria-disabled={readOnly}>
        <Stack direction="vertical" gap="smMd">
          <Typography variant="h2">Your personal details</Typography>
          <Stack direction="vertical" gap="sm">
            <TitleField disabled={readOnly} />
            <Stack direction="vertical" gap="xs">
              <Typography variant="h3">Name</Typography>
              <MutedText variant="body1">
                This will be displayed on your account and any messages or bills
                we send
              </MutedText>
            </Stack>
            <FormikTextField
              name="givenName"
              label="First name"
              disabled={readOnly}
            />
            <FormikTextField
              name="middleName"
              label="Middle name (optional)"
              disabled={readOnly}
            />
            <FormikTextField
              name="familyName"
              label="Last name"
              disabled={readOnly}
            />
          </Stack>
          <DateField
            title="Date of birth"
            subtitle="This helps us secure your account & may also be used for tracing and collection purposes if payments are not maintained"
            fieldArrayName="dateOfBirth"
            helperText="For example: 01 01 1970"
            disabled={readOnly}
          />
          <Stack direction="vertical" gap="sm">
            {/* TODO: Replace with FormTextFieldWithDescription when available */}
            <Typography variant="h3">Pronouns</Typography>
            <Stack direction="vertical" gap="xs">
              <FormikTextField
                name="pronouns"
                label="Pronouns (optional)"
                disabled={readOnly}
              />
              <MutedText variant="caption">
                Examples include he/him, she/her, they/them or ze/hir
              </MutedText>
            </Stack>
          </Stack>
          <Stack direction="vertical" gap="sm">
            <Typography variant="h3">Personal status</Typography>
            <HomeOwnershipStatusField disabled={readOnly} />
            <EmploymentStatusField disabled={readOnly} />
          </Stack>
        </Stack>
      </fieldset>
    </Card>
  );
};

export default PersonalDetailsForm;
