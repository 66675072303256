import styled, { css } from 'styled-components';

export const StyledTransaction = styled.strong<{ isPositive: boolean }>`
  ${({ theme, isPositive }) => css`
    color: ${isPositive ? theme.color.action.success : theme.color.text.main};
  `}
`;

export const StyledMobilePaymentDate = styled.strong`
  ${({ theme }) => css`
    color: ${theme.color.text.dark};
  `}
`;
