/* eslint-disable turbo/no-undeclared-env-vars */
// We can't use the environment variables from @utils/environment.ts here as they aren't available at build time. But, if we get the environment variables from the process.env object, they will be available at build time. Thank you, Next.js! Bad Turborepo, no cookie.

const appConfig: Record<string, string> = {
  appName: 'Water Consumer Site',
  appDescription: 'Consumer site for all things watery',
  apiEndpointDev: process.env.NEXT_PUBLIC_API_ENDPOINT,
  apiEndpointPreview: process.env.NEXT_PUBLIC_API_ENDPOINT,
  apiEndpointProd: process.env.NEXT_PUBLIC_API_ENDPOINT,
  apiEndpointTest: 'http://localhost:3000/api/graphql',
  localhost: 'localhost:3000',
};

export default appConfig;
