export enum DIRECT_DEBIT_INSTRUCTION_STATUS {
  ACTIVE = 'ACTIVE',
  PROVISIONAL = 'PROVISIONAL',
  FAILED = 'FAILED',
  ACCOUNT_CLOSED = 'ACCOUNT_CLOSED',
}

export enum PAYMENT_PLAN_BILLING_CONFIG {
  FIRST_MORE = 'FIRST_MORE',
  LAST_MORE = 'LAST_MORE',
  LAST_LESS = 'LAST_LESS',
}
