import styled, { css } from 'styled-components';

export const DesktopAndTabletOnlyDiv = styled.div`
  ${({ theme }) => css`
    display: none;

    @media (min-width: ${theme.breakpoints.md}px) {
      display: block;
    }
  `}
`;

export const MobileOnlyDiv = styled.div<{ fullWidth?: boolean }>`
  ${({ theme, fullWidth }) => css`
    display: block;
    width: ${fullWidth ? '100%' : 'auto'};

    @media (min-width: ${theme.breakpoints.md}px) {
      display: none;
    }
  `}
`;

export const RelativelyPositionedDiv = styled.div`
  position: relative;
`;
