import { Container, Typography, Stack } from '@krakentech/coral';

import {
  DesktopAndTabletOnlyDiv,
  MobileOnlyDiv,
  RelativelyPositionedDiv,
} from '@/styles/Utils.styled';

import { BackButton } from './components';
import { ChildPageLayoutProps } from './index.types';

const ChildPageLayout = ({
  pageHeading,
  backButtonHref,
  previousPageName,
  children,
}: ChildPageLayoutProps) => (
  <Container
    component="section"
    marginX="auto"
    maxWidth="lg"
    marginY="md"
    paddingX="md"
    md={{
      marginY: 'lg',
      paddingX: 'lg',
    }}
  >
    <Stack
      direction="vertical"
      gap="md"
      md={{
        gap: 'lg',
      }}
    >
      <RelativelyPositionedDiv>
        <Stack justifyContent="flex-start" alignItems="center">
          <BackButton
            previousPageName={previousPageName}
            backButtonHref={backButtonHref}
          />

          <DesktopAndTabletOnlyDiv>
            <Typography variant="h1">{pageHeading}</Typography>
          </DesktopAndTabletOnlyDiv>
          <MobileOnlyDiv>
            <Typography variant="h1">{pageHeading}</Typography>
          </MobileOnlyDiv>
        </Stack>
      </RelativelyPositionedDiv>

      <div>{children}</div>
    </Stack>
  </Container>
);

export default ChildPageLayout;
