import { PropsWithChildren } from 'react';
import {
  Container,
  Stack,
  Typography,
  useCoralBreakpoints,
} from '@krakentech/coral';

import { H3Skeleton } from '@/components/Skeletons';

import { StyledPaymentScheduleCard } from './index.styled';

const PaymentScheduleCard = ({
  title,
  isLoading = false,
  children,
}: PropsWithChildren<{
  title: string;
  isLoading?: boolean;
}>) => {
  const { isMaxMd } = useCoralBreakpoints();

  return (
    <StyledPaymentScheduleCard>
      <Container component="div" padding="md" theme="dark">
        <Stack justifyContent="center">
          {isLoading ? (
            <H3Skeleton width={isMaxMd ? 160 : undefined} />
          ) : (
            <Typography variant="h3" textAlign="center" color="primary">
              {title}
            </Typography>
          )}
        </Stack>
      </Container>
      <Container component="div" padding="md" theme="none">
        <Stack
          justifyContent="center"
          direction="vertical"
          alignItems="center"
          gap="sm"
        >
          {children}
        </Stack>
      </Container>
    </StyledPaymentScheduleCard>
  );
};

export default PaymentScheduleCard;
