import { Tooltip } from '@krakentech/coral';

import { PAYMENT_FREQUENCY } from '@/consts/paymentFrequency';
import { useHasActiveWaterMeter } from '@/hooks/usage/useHasActiveWaterMeter';
import { getPaymentFrequency } from '@/utils/paymentSchedule';

import { PaymentFrequencyTooltipProps } from './index.types';

const PaymentFrequencyTooltip = ({
  isVariablePaymentAmount,
  paymentFrequency,
  paymentFrequencyMultiplier,
}: PaymentFrequencyTooltipProps) => {
  const {
    isLoading,
    isError,
    data: activeWaterMeterCount,
  } = useHasActiveWaterMeter();

  if (isLoading || isError) {
    return null;
  }

  const getPaymentFrequencyAndMultiplier = () => {
    return `${
      paymentFrequencyMultiplier > 1
        ? `${paymentFrequencyMultiplier} ${
            paymentFrequency === PAYMENT_FREQUENCY.MONTHLY ? 'months' : 'weeks'
          }`
        : paymentFrequency === PAYMENT_FREQUENCY.MONTHLY
          ? 'month'
          : 'week'
    }`;
  };

  const getTooltipText = () => {
    if (activeWaterMeterCount) {
      if (isVariablePaymentAmount) {
        return `We send you a bill every ${process.env.NEXT_PUBLIC_MEASURED_BILLING_PERIOD} months, based on how much water you've used. We'll collect any balance owed in full after your bill has been issued`;
      } else {
        return `We send you a bill every ${
          process.env.NEXT_PUBLIC_MEASURED_BILLING_PERIOD
        } months, based on how much water you've used. You're paying into your acount every ${getPaymentFrequency(
          paymentFrequency,
          paymentFrequencyMultiplier
        )} to cover your usage`;
      }
    } else {
      if (isVariablePaymentAmount) {
        return `We send you a bill every ${process.env.NEXT_PUBLIC_UNMEASURED_BILLING_PERIOD} months. We'll collect any balance owed in full after your bill has been issued`;
      } else {
        if (
          paymentFrequency === PAYMENT_FREQUENCY.MONTHLY ||
          paymentFrequency === PAYMENT_FREQUENCY.WEEKLY
        ) {
          return `We send you a bill every ${
            process.env.NEXT_PUBLIC_UNMEASURED_BILLING_PERIOD
          } months. You spread the cost of your bill by paying into your account every ${getPaymentFrequencyAndMultiplier()}`;
        } else {
          return ``;
        }
      }
    }
  };

  return (
    <Tooltip
      title={getTooltipText()}
      theme="dark"
      data-attributes={{ 'data-testid': 'paymentFrequencyTooltip' }}
    />
  );
};

export default PaymentFrequencyTooltip;
