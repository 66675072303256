
import { useKrakenQuery } from '@/components/GraphQLClient';
import { useAuth } from '@/components/Providers/Auth';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';

import { currentActivePaymentScheduleSchema } from '../validations';

import CurrentActivePaymentSchedule from './CurrentActivePaymentSchedule.graphql';

export const useCurrentActivePaymentSchedule = () => {
  const { isAuthenticated } = useAuth();

  const { accountNumber } = useAccountNumberContext();

  return useKrakenQuery({
    queryKey: ['currentActivePaymentSchedule'],
    query: CurrentActivePaymentSchedule,
    select: (data: any) => data.account.paymentSchedules.edges[0],
    variables: { accountNumber },
    validateFn(response) {
      return currentActivePaymentScheduleSchema.validate(response);
    },
    enabled: Boolean(isAuthenticated && accountNumber),
    cacheTime: 0,
  });
};
