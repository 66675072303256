import { useCoralTheme } from '@krakentech/coral';

const EmailSent = () => {
  const {
    color: {
      primary: { main: primaryMain },
    },
  } = useCoralTheme();

  return (
    <svg
      width="220"
      height="147"
      viewBox="0 0 220 147"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M170.764 86.019L170.531 87.2879C168.545 97.9904 161.218 106.57 151.282 109.296C150.003 109.649 148.698 109.899 147.379 110.044C137.852 111.105 127.697 106.677 121.349 98.6876C117.616 93.9896 114.945 88.0164 109.918 85.008C103.369 81.0886 95.4573 83.6513 88.6385 86.2767C81.8136 88.9084 73.8953 91.4459 67.3594 87.4952C63.2683 85.0268 60.6288 80.4039 59.2462 75.6683C55.5698 63.1378 59.8055 49.3198 68.1575 40.2754C76.5096 31.2307 88.4187 26.5388 100.491 25.0377C117.396 22.9399 135.584 26.9974 149.56 38.1776C163.537 49.3575 172.58 68.0682 170.764 86.019Z"
        fill="url(#paint0_linear_1_105)"
      />
      <path
        d="M58.2389 73.226C60.9904 67.3977 58.496 60.4423 52.6678 57.6908C46.8395 54.9396 39.884 57.4339 37.1326 63.2622C34.3814 69.0905 36.8757 76.0457 42.704 78.7972C48.5322 81.5486 55.4874 79.0543 58.2389 73.226Z"
        fill={primaryMain}
      />
      <path
        d="M57.6869 71.4687C57.6962 71.8733 58.1291 72.1258 58.4858 71.9344L63.0268 69.498C63.4101 69.2924 63.4074 68.7417 63.0219 68.5398L58.3709 66.1034C58.005 65.9118 57.5676 66.1831 57.5769 66.596L57.6869 71.4687Z"
        fill={primaryMain}
      />
      <path
        d="M43.559 68.5471C43.5554 68.7526 43.5113 68.9553 43.4292 69.1437C43.3472 69.3321 43.2288 69.5025 43.0808 69.6451C42.9329 69.7877 42.7583 69.8997 42.567 69.9748C42.3757 70.0498 42.1714 70.0864 41.966 70.0825C41.7605 70.0788 41.5578 70.0348 41.3694 69.9527C41.181 69.8707 41.0106 69.7523 40.868 69.6043C40.7254 69.4563 40.6134 69.2817 40.5383 69.0904C40.4633 68.8991 40.4267 68.6949 40.4306 68.4894C40.4343 68.284 40.4784 68.0813 40.5605 67.8929C40.6425 67.7045 40.7609 67.5341 40.9089 67.3915C41.0568 67.2489 41.2314 67.1369 41.4227 67.0618C41.614 66.9868 41.8182 66.9502 42.0237 66.9541C42.4384 66.962 42.8331 67.1342 43.121 67.4329C43.4089 67.7316 43.5664 68.1323 43.559 68.5471ZM48.9935 68.7776C48.9055 69.6364 48.1348 70.2614 47.276 70.1734C46.4173 70.0854 45.7923 69.3147 45.8803 68.4562C45.9683 67.5975 46.739 66.9724 47.5977 67.0604C48.4565 67.1513 49.0815 67.9189 48.9935 68.7776ZM54.1031 68.8717C54.0151 69.7305 53.2444 70.3555 52.3857 70.2675C51.5269 70.1795 50.9019 69.4088 50.9899 68.5501C51.0779 67.6913 51.8486 67.0663 52.7074 67.1543C52.9118 67.1753 53.1101 67.2365 53.2909 67.3342C53.4717 67.4319 53.6315 67.5643 53.7611 67.7238C53.8907 67.8833 53.9876 68.0668 54.0463 68.2637C54.105 68.4607 54.1243 68.6673 54.1031 68.8717Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.4176 69.8891V114.085C72.4176 119.338 76.6722 123.592 81.9251 123.592H148.473C153.725 123.592 157.98 119.338 157.98 114.085V69.8891"
        fill={primaryMain}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.4176 66.7028C74.1774 66.7028 75.604 68.1294 75.604 69.8891V114.085C75.604 117.578 78.4321 120.406 81.9251 120.406H148.473C151.966 120.406 154.794 117.578 154.794 114.085V69.8891C154.794 68.1294 156.221 66.7028 157.98 66.7028C159.74 66.7028 161.167 68.1294 161.167 69.8891V114.085C161.167 121.097 155.485 126.779 148.473 126.779H81.9251C74.9123 126.779 69.2313 121.097 69.2313 114.085V69.8891C69.2313 68.1294 70.6579 66.7028 72.4176 66.7028Z"
        fill={primaryMain}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.6299 62.0024L109.878 39.6362C111.451 38.5787 113.304 38.0139 115.199 38.0139C117.095 38.0139 118.948 38.5787 120.521 39.6362L153.769 62.0024C159.369 65.7722 159.369 74.0104 153.769 77.78L125.845 96.5668C119.409 100.898 110.995 100.898 104.558 96.5668L76.6346 77.78C71.0299 74.0104 71.0299 65.7673 76.6299 62.0024Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111.656 42.28C111.656 42.2802 111.655 42.2804 111.655 42.2807L78.4084 64.6462L78.4077 64.6466C74.6874 67.1479 74.684 72.6282 78.4128 75.1361L106.337 93.9231C111.698 97.5303 118.706 97.5304 124.067 93.9232L151.989 75.1368C151.99 75.1366 151.99 75.1365 151.99 75.1363C155.713 72.6295 155.713 67.1524 151.989 64.6456L118.744 42.2807C118.743 42.2804 118.743 42.2802 118.743 42.28C117.695 41.5761 116.461 41.2002 115.199 41.2002C113.937 41.2002 112.704 41.5761 111.656 42.28ZM108.1 36.9917C110.199 35.581 112.67 34.8275 115.199 34.8275C117.728 34.8275 120.2 35.581 122.299 36.9917L155.548 59.3592C163.024 64.3918 163.024 75.3908 155.548 80.4233L127.624 99.2104C127.624 99.2104 127.624 99.2104 127.624 99.2104C120.112 104.265 110.291 104.265 102.78 99.2105M102.78 99.2105L74.8563 80.424C74.8562 80.4239 74.856 80.4238 74.8559 80.4237C67.3761 75.3926 67.3726 64.3874 74.8514 59.3586C74.8516 59.3584 74.8519 59.3583 74.8521 59.3581L108.1 36.9917"
        fill={primaryMain}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.1366 64.3566C96.1366 62.7092 97.4721 61.3737 99.1195 61.3737H131.283C132.93 61.3737 134.266 62.7092 134.266 64.3566C134.266 66.0041 132.93 67.3396 131.283 67.3396H99.1195C97.4721 67.3396 96.1366 66.0041 96.1366 64.3566ZM99.6747 77.1154C99.6747 75.4679 101.01 74.1324 102.658 74.1324H127.877C129.525 74.1324 130.86 75.4679 130.86 77.1154C130.86 78.7628 129.525 80.0983 127.877 80.0983H102.658C101.01 80.0983 99.6747 78.7628 99.6747 77.1154Z"
        fill={primaryMain}
      />
      <path
        d="M175.581 105.02L167.329 113.272C167.028 113.573 167.028 114.06 167.329 114.36C167.629 114.661 168.116 114.661 168.417 114.36L176.669 106.108C176.97 105.807 176.97 105.32 176.669 105.02C176.369 104.719 175.882 104.719 175.581 105.02Z"
        fill="#E1E4E5"
      />
      <path
        d="M174.773 110.928L172.393 113.308C172.093 113.609 172.093 114.096 172.393 114.397C172.694 114.697 173.181 114.697 173.482 114.397L175.862 112.017C176.162 111.716 176.162 111.229 175.862 110.928C175.561 110.628 175.074 110.628 174.773 110.928Z"
        fill="#E1E4E5"
      />
      <path
        d="M128.667 29.7396L136.92 21.4871C137.22 21.1866 137.22 20.6994 136.92 20.3989C136.619 20.0984 136.132 20.0984 135.832 20.3989L127.579 28.6514C127.279 28.9519 127.279 29.4391 127.579 29.7396C127.88 30.0401 128.367 30.0401 128.667 29.7396Z"
        fill="#E1E4E5"
      />
      <path
        d="M129.475 23.8309L131.855 21.451C132.156 21.1505 132.156 20.6632 131.855 20.3627C131.555 20.0622 131.067 20.0622 130.767 20.3627L128.387 22.7427C128.086 23.0432 128.086 23.5304 128.387 23.8309C128.687 24.1314 129.175 24.1314 129.475 23.8309Z"
        fill="#E1E4E5"
      />
      <path
        d="M61.3506 97.9348L54.0552 105.23C53.7895 105.496 53.7895 105.927 54.0552 106.192C54.3209 106.458 54.7516 106.458 55.0173 106.192L62.3127 98.8968C62.5783 98.6312 62.5783 98.2004 62.3127 97.9348C62.047 97.6691 61.6163 97.6691 61.3506 97.9348Z"
        fill="#E1E4E5"
      />
      <path
        d="M60.6363 103.158L58.5324 105.262C58.2668 105.528 58.2668 105.959 58.5324 106.224C58.7981 106.49 59.2288 106.49 59.4945 106.224L61.5984 104.12C61.8641 103.855 61.8641 103.424 61.5984 103.158C61.3327 102.893 60.902 102.893 60.6363 103.158Z"
        fill="#E1E4E5"
      />
      <path
        d="M173.594 57.1431L180.411 50.3261C180.66 50.0779 180.66 49.6755 180.411 49.4273L180.411 49.4271C180.163 49.1789 179.761 49.1789 179.512 49.4271L172.695 56.2441C172.447 56.4923 172.447 56.8947 172.695 57.1429L172.696 57.1431C172.944 57.3913 173.346 57.3913 173.594 57.1431Z"
        fill="#E1E4E5"
      />
      <path
        d="M174.262 52.2622L176.228 50.296C176.476 50.0478 176.476 49.6454 176.228 49.3972L176.228 49.397C175.979 49.1488 175.577 49.1488 175.329 49.397L173.363 51.3632C173.114 51.6114 173.114 52.0138 173.363 52.262L173.363 52.2622C173.611 52.5104 174.013 52.5104 174.262 52.2622Z"
        fill="#E1E4E5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M182.316 86.0442C182.582 85.7905 183.001 85.7905 183.267 86.0442L186.115 88.7561C186.251 88.8862 186.329 89.0667 186.329 89.2555C186.329 89.4442 186.251 89.6247 186.115 89.7549L183.267 92.4668C183.001 92.7205 182.582 92.7205 182.316 92.4668L179.468 89.7549C179.332 89.6247 179.254 89.4442 179.254 89.2555C179.254 89.0667 179.332 88.8862 179.468 88.756L182.316 86.0442ZM180.944 89.2555L182.792 91.0149L184.639 89.2555L182.792 87.4961L180.944 89.2555Z"
        fill="#E1E4E5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.9501 88.9117C50.8234 88.9117 49.9132 89.822 49.9132 90.9456C49.9132 92.066 50.8205 92.9795 51.944 92.9795C53.0706 92.9795 53.9808 92.0691 53.9808 90.9456C53.9808 89.82 53.0745 88.9117 51.9501 88.9117ZM48.5573 90.9456C48.5573 89.0718 50.0759 87.5557 51.9501 87.5557C53.8259 87.5557 55.3367 89.0737 55.3367 90.9456C55.3367 92.8194 53.8181 94.3354 51.944 94.3354C50.0671 94.3354 48.5573 92.8103 48.5573 90.9456Z"
        fill="#E1E4E5"
      />
      <path
        d="M155.788 22.8578C151.996 26.0306 152.22 32.5495 152.22 32.5495C152.22 32.5495 158.593 33.9201 162.384 30.7438C166.176 27.5709 165.954 21.0555 165.954 21.0555C165.954 21.0555 159.581 19.6849 155.788 22.8578Z"
        fill="url(#paint1_linear_1_105)"
      />
      <path
        d="M88.5596 34.4454C88.1912 35.8203 89.0444 37.2436 90.4652 37.6243C91.8861 38.0051 93.3366 37.1991 93.705 35.8241C94.0734 34.4491 93.2203 33.0259 91.7994 32.6451C90.3785 32.2644 88.928 33.0704 88.5596 34.4454Z"
        fill="#E1E4E5"
      />
      <path
        d="M187.061 69.9413C186.42 72.3347 183.96 73.755 181.566 73.1137C179.173 72.4724 177.753 70.0123 178.394 67.6189C179.035 65.2255 181.495 63.8051 183.889 64.4464C186.282 65.0877 187.702 67.5479 187.061 69.9413Z"
        fill={primaryMain}
      />
      <path
        d="M149.221 43.3773C148.982 44.2676 148.198 44.831 147.47 44.6359C146.742 44.4407 146.344 43.5608 146.583 42.6705C146.822 41.7802 147.605 41.2168 148.334 41.4119C149.062 41.6071 149.459 42.487 149.221 43.3773Z"
        fill="#E1E4E5"
      />
      <path
        d="M66.5625 38.3365H66.6177C66.9443 42.6766 70.3873 42.7433 70.3873 42.7433C70.3873 42.7433 66.5908 42.813 66.5908 47.8278C66.5908 42.813 62.7941 42.7433 62.7941 42.7433C62.7941 42.7433 66.2356 42.6766 66.5625 38.3365ZM166.632 36.6464H166.679C166.959 40.1185 169.91 40.172 169.91 40.172C169.91 40.172 166.656 40.2277 166.656 44.2396C166.656 40.2277 163.402 40.172 163.402 40.172C163.402 40.172 166.352 40.1185 166.632 36.6464Z"
        fill="#E1E4E5"
      />
      <path
        d="M82.9736 111.664L76.4864 118.151C76.2502 118.388 76.2501 118.771 76.4864 119.007C76.7226 119.243 77.1056 119.243 77.3419 119.007L83.829 112.519C84.0653 112.283 84.0654 111.9 83.8291 111.664C83.5929 111.428 83.2099 111.428 82.9736 111.664Z"
        fill="white"
      />
      <path
        d="M88.2822 111.664L81.795 118.151C81.5588 118.388 81.5587 118.771 81.795 119.007C82.0312 119.243 82.4142 119.243 82.6505 119.007L89.1376 112.519C89.3739 112.283 89.3739 111.9 89.1377 111.664C88.9015 111.428 88.5185 111.428 88.2822 111.664Z"
        fill="white"
      />
      <path
        d="M93.9223 111.664L87.4351 118.151C87.1988 118.388 87.1988 118.771 87.435 119.007C87.6712 119.243 88.0543 119.243 88.2905 119.007L94.7777 112.519C95.014 112.283 95.014 111.9 94.7778 111.664C94.5416 111.428 94.1585 111.428 93.9223 111.664Z"
        fill="white"
      />
      <path
        d="M143.976 120.348C146.522 120.351 150.186 119.688 153.188 116.795C156.189 113.902 154.502 112.104 151.522 111.778C148.543 111.452 144.969 112.811 142.656 115.721C140.342 118.63 141.886 120.345 143.976 120.348Z"
        fill="url(#paint2_linear_1_105)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_105"
          x1="112.043"
          y1="-23.5383"
          x2="114.045"
          y2="203.432"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#EEEEEE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1_105"
          x1="142.676"
          y1="35.6786"
          x2="183.822"
          y2="13.8397"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#EEEEEE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1_105"
          x1="157.434"
          y1="121.3"
          x2="134.27"
          y2="108.221"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#EEEEEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default EmailSent;
