import styled, { css } from 'styled-components';

export const StyledPageLayout = styled.main`
  ${({ theme }) => css`
    min-height: 100vh;
    /* These are technically the same, but use both for cross browser compatibility */
    overflow-wrap: break-word;
    word-wrap: break-word;
    background: ${theme.color.base.main};

    ul {
      margin: 0;
    }
  `}
`;
