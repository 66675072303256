import { CoralTypography } from '@krakentech/coral';

export const kfuTypography: Partial<CoralTypography> = {
  homepageTitle: {
    baseStyles: {
      fontSize: '2.8rem',
      fontWeight: 600,
      lineHeight: '3.4rem',
      letterSpacing: 'normal',
    },
    md: {
      fontSize: '4rem',
      fontWeight: 600,
      lineHeight: '4rem',
    },
  },
  h1: {
    baseStyles: {
      fontSize: '2.2rem',
      fontWeight: 600,
      lineHeight: '3.4rem',
      letterSpacing: 'normal',
    },
    md: {
      fontSize: '2.8rem',
      fontWeight: 600,
      lineHeight: '4rem',
    },
  },
  h2: {
    baseStyles: {
      fontSize: '1.8rem',
      fontWeight: 600,
      lineHeight: '2.4rem',
      letterSpacing: 'normal',
    },
    md: {
      fontSize: '2.2rem',
      fontWeight: 600,
      lineHeight: '3.4rem',
    },
  },
  h3: {
    baseStyles: {
      fontSize: '1.6rem',
      fontWeight: 600,
      lineHeight: '2.4rem',
      letterSpacing: 'normal',
    },
    md: {
      fontSize: '1.8rem',
      fontWeight: 600,
      lineHeight: '2.4rem',
    },
  },
  h4: {
    baseStyles: {
      fontSize: '1.4rem',
      fontWeight: 600,
      lineHeight: '2.2rem',
      letterSpacing: 'normal',
    },
    md: {
      fontSize: '1.6rem',
      fontWeight: 600,
      lineHeight: '2.4rem',
    },
  },
  body1: {
    baseStyles: {
      fontSize: '1.6rem',
      fontWeight: 300,
      lineHeight: '2.6rem',
    },
    md: {
      fontSize: '1.8rem',
      fontWeight: 300,
      lineHeight: '2.75rem',
    },
  },
  body2: {
    baseStyles: {
      fontSize: '1.4rem',
      fontWeight: 300,
      lineHeight: '2.4rem',
    },
    md: {
      fontSize: '1.6rem',
      fontWeight: 300,
      lineHeight: '2.6rem',
    },
  },
  caption: {
    baseStyles: {
      fontSize: '1.2rem',
      fontWeight: 300,
      lineHeight: '1.85rem',
    },
  },
  button: {
    baseStyles: {
      fontSize: '1.6rem',
      fontWeight: 600,
      lineHeight: 1.75,
    },
  },
  link: {
    fontWeight: 600,
  },
};
