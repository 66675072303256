import { ReactNode } from 'react';
import { Stack, Typography } from '@krakentech/coral';

import { UnorderedListProps } from './index.types';

const UnorderedList = ({ listItems, gap = 'sm' }: UnorderedListProps) => {
  return (
    <Stack direction="vertical" component="ul" gap={gap}>
      {listItems.map((item: ReactNode, index) => (
        <Typography component="li" key={`${item} - ${index}`}>
          {item}
        </Typography>
      ))}
    </Stack>
  );
};

export default UnorderedList;
