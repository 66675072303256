import { useKrakenQuery } from '@/components/GraphQLClient';
import { useAuth } from '@/components/Providers/Auth';
import { DIRECT_DEBIT_SCHEDULE_CHOICES } from '@/consts/paymentFrequency';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';

import { proposedDirectDebitPaymentScheduleSchema } from '../validations';

import ProposedDirectDebitPaymentSchedule from './ProposedDirectDebitPaymentSchedule.graphql';

export const useProposedDirectDebitPaymentSchedule = ({
  scheduleType,
}: {
  scheduleType: DIRECT_DEBIT_SCHEDULE_CHOICES;
}) => {
  const { accountNumber } = useAccountNumberContext();

  const { isAuthenticated } = useAuth();

  return useKrakenQuery({
    queryKey: ['proposedDirectDebitPaymentSchedule', scheduleType],
    query: ProposedDirectDebitPaymentSchedule,
    select: (data: any) => data.proposedDirectDebitPaymentSchedule,
    variables: { accountNumber, scheduleType },
    validateFn(response) {
      return proposedDirectDebitPaymentScheduleSchema.validate(response);
    },
    enabled: Boolean(isAuthenticated && accountNumber && scheduleType),
  });
};
