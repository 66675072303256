import { Typography } from '@krakentech/coral';

import { Alert, ErrorLink } from '@/components';

const IdentifyAndVerifyGenericErrorAlert = () => (
  <Alert severity="error">
    <Typography>
      Sorry, you can&apos;t complete this online right now - please{' '}
      <ErrorLink href={process.env.NEXT_PUBLIC_HELP_LINK || ''}>
        get in touch
      </ErrorLink>{' '}
      and our team will help you.
    </Typography>
  </Alert>
);

export default IdentifyAndVerifyGenericErrorAlert;
