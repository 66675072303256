export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  Date: any;
  DateTime: any;
  Decimal: any;
  Email: any;
  GenericScalar: any;
  JSONString: any;
};

export type ApiCallType = {
  __typename?: 'APICallType';
  /** Storage for the API client to submit any contextual information. */
  context?: Maybe<Scalars['JSONString']>;
  /** The request's correlation id. */
  correlationId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Input data for the API call if any. */
  inputData?: Maybe<Scalars['JSONString']>;
  /** Free field for the API caller to categorise their own operation name. This field can be used to filter entries on the UI. */
  operationName: Scalars['String'];
  /** The response from the API call if any. */
  response?: Maybe<Scalars['JSONString']>;
};

/** An enumeration. */
export enum ApiExceptionCategories {
  Account = 'ACCOUNT',
  Unknown = 'UNKNOWN',
}

/** Paginator of API exceptions. */
export type ApiExceptionConnectionTypeConnection = {
  __typename?: 'APIExceptionConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ApiExceptionConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `APIExceptionConnectionType` and its cursor. */
export type ApiExceptionConnectionTypeEdge = {
  __typename?: 'APIExceptionConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ApiExceptionType>;
};

export type ApiExceptionEventType = {
  __typename?: 'APIExceptionEventType';
  /** The category of the event. */
  category: Scalars['String'];
  /** A JSON context to be provided with the event, if any. */
  context?: Maybe<Scalars['JSONString']>;
  createdAt: Scalars['DateTime'];
  /** A description of the event. */
  description?: Maybe<Scalars['String']>;
  /** The type of the event. */
  eventType: Scalars['String'];
  id: Scalars['ID'];
};

export type ApiExceptionNoteType = {
  __typename?: 'APIExceptionNoteType';
  /** The content of the API Exception note. */
  body: Scalars['String'];
  /** Timestamp of when the API Exception note was created. */
  createdAt: Scalars['DateTime'];
  /** The ID of the API Exception note. */
  id: Scalars['ID'];
};

/** An enumeration. */
export enum ApiExceptionPriority {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
}

export type ApiExceptionQueryInput = {
  /** The account number to filter for. */
  accountNumber?: InputMaybe<Scalars['ID']>;
  /** The category to filter for. */
  category?: InputMaybe<ApiExceptionCategories>;
  /** The channel to filter for. */
  channel?: InputMaybe<Scalars['String']>;
  /** The customer contact to filter for. */
  customerContact?: InputMaybe<Scalars['String']>;
  /** The external identifier to filter for. */
  externalIdentifier?: InputMaybe<Scalars['String']>;
  /** The priority to filter for. */
  priority?: InputMaybe<ApiExceptionPriority>;
  /** The resolution status to filter for. */
  resolutionStatus?: InputMaybe<ApiExceptionResolutionStatus>;
  /** The resolution type to filter for. */
  resolutionType?: InputMaybe<ApiExceptionResolutionType>;
  /** The supply point identifier to filter for. */
  supplyPointIdentifier?: InputMaybe<Scalars['ID']>;
  /** Tags to filter for. */
  tags?: InputMaybe<Array<InputMaybe<ApiExceptionTags>>>;
  /** The user ID to filter for. */
  userId?: InputMaybe<Scalars['ID']>;
};

/** An enumeration. */
export enum ApiExceptionResolutionStatus {
  Assigned = 'ASSIGNED',
  Cancelled = 'CANCELLED',
  InProgress = 'IN_PROGRESS',
  Resolved = 'RESOLVED',
  Unassigned = 'UNASSIGNED',
  Unsuccessful = 'UNSUCCESSFUL',
  WaitingOnThirdParty = 'WAITING_ON_THIRD_PARTY',
}

/** An enumeration. */
export enum ApiExceptionResolutionType {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL',
  Unassigned = 'UNASSIGNED',
}

/** An enumeration. */
export enum ApiExceptionTags {
  MoveIn = 'MOVE_IN',
  MoveOut = 'MOVE_OUT',
  ProductUpdate = 'PRODUCT_UPDATE',
}

export type ApiExceptionType = {
  __typename?: 'APIExceptionType';
  /** The account number provided to the exception. */
  accountNumber?: Maybe<Scalars['String']>;
  /** The API calls associated with this exception if any. */
  apiCalls?: Maybe<Array<Maybe<ApiCallType>>>;
  /** The user assigned to handle this exception if any. */
  assignedUser?: Maybe<AssignedUserType>;
  /** Category associated with this exception. */
  category?: Maybe<ApiExceptionCategories>;
  /** Free field for the API caller to categorise a channel. This could be (but not limited to) the client's team that calleded the API, the name of the 'flow' the call belongs to, etc. */
  channel: Scalars['String'];
  /** Storage for the API client to submit any contextual information. */
  context?: Maybe<Scalars['JSONString']>;
  createdAt: Scalars['DateTime'];
  /** The customer contact provided to the exception. */
  customerContact?: Maybe<Scalars['String']>;
  /** The events associated with this exception if any. */
  events?: Maybe<Array<Maybe<ApiExceptionEventType>>>;
  /** External identifier submitted by the API client to track this exception on their end. */
  externalIdentifier: Scalars['String'];
  id: Scalars['ID'];
  /** The key date associated with the exception, if available. */
  keyDate?: Maybe<Scalars['Date']>;
  /** Notes associated with this exception if any. */
  notes?: Maybe<Array<Maybe<ApiExceptionNoteType>>>;
  /** The operations team assigned to this exception if any. */
  operationsTeam?: Maybe<OperationsTeamType>;
  /** The current priority for the API exception. */
  priority: ApiExceptionPriority;
  /** The current resolution status for the API exception. */
  resolutionStatus: ApiExceptionResolutionStatus;
  /** The current resolution type for the API exception. */
  resolutionType: ApiExceptionResolutionType;
  /** The supply point identifier provided to the exception. */
  supplyPointIdentifier?: Maybe<Scalars['String']>;
  /** Tags associated with this exception if any. */
  tags?: Maybe<Array<Maybe<ApiExceptionTags>>>;
  /** The user id provided to the exception. */
  userId?: Maybe<Scalars['Int']>;
};

/**
 * Interface for all lifecycle journey processes that
 * inherit from AbstractSupplyPointProcess.
 */
export type AbstractSupplyPointProcessInterface = {
  /** The ID or the primary key of the lifecycle process. */
  id?: Maybe<Scalars['ID']>;
  /** The status of the process. */
  status?: Maybe<LeaveSupplierProcessStatus>;
  /** The supply points associated with the process. */
  supplyPoints: SupplyPointConnectionTypeConnection;
};

/**
 * Interface for all lifecycle journey processes that
 * inherit from AbstractSupplyPointProcess.
 */
export type AbstractSupplyPointProcessInterfaceSupplyPointsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-8223: Unauthorized.
 * - KT-CT-8201: Received an invalid quoteId.
 * - KT-CT-8224: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type AcceptGoodsQuote = {
  __typename?: 'AcceptGoodsQuote';
  /** Goods purchase created. */
  goodsPurchase?: Maybe<GoodsPurchase>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type AcceptGoodsQuoteInput = {
  /** The account number. */
  accountNumber: Scalars['String'];
  /** A JSON object containing client parameters to store on the quote. */
  clientParams?: InputMaybe<Scalars['JSONString']>;
  /** A JSON object containing market parameters to store on the purchase. */
  marketParams?: InputMaybe<Scalars['JSONString']>;
  /** ID of the accepted quote. */
  quoteId: Scalars['Int'];
};

/** Properties relating to the accessibility of features. */
export type AccessibilityInterface = {
  /** Whether the element is hidden from view. */
  accessibilityHidden?: Maybe<Scalars['Boolean']>;
  /** Accessible description of the element. */
  accessibilityLabel?: Maybe<Scalars['String']>;
};

export type AccountApplicationConnectionTypeConnection = {
  __typename?: 'AccountApplicationConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AccountApplicationConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `AccountApplicationConnectionType` and its cursor. */
export type AccountApplicationConnectionTypeEdge = {
  __typename?: 'AccountApplicationConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AccountApplicationType>;
};

/** An enumeration. */
export enum AccountApplicationStatus {
  /** Current */
  Current = 'CURRENT',
  /** Failed */
  Failed = 'FAILED',
  /** Historic */
  Historic = 'HISTORIC',
  /** Withdrawn */
  Withdrawn = 'WITHDRAWN',
}

export type AccountApplicationType = {
  __typename?: 'AccountApplicationType';
  /** Last day of the cooling off period. Barring changes or objections, the account will be gained on the next business day after this date. This value will only be returned for current applications. */
  coolingOffEndDate?: Maybe<Scalars['Date']>;
  /** Date at which this account decided to switch to us. */
  dateOfSale?: Maybe<Scalars['Date']>;
  /** Whether this account application represents a migration into the current system or a regular gain. */
  isMigrated?: Maybe<Scalars['Boolean']>;
  /** The source system for a migrated account. This could be the previous supplier or the previous account management system. */
  migrationSource?: Maybe<Scalars['String']>;
  salesChannel: Scalars['String'];
  /** The sales subchannel used when signing up. This could for example be a price comparison site. */
  salesSubchannel?: Maybe<Scalars['String']>;
  status: AccountApplicationStatus;
};

export type AccountBalanceTransferType = {
  __typename?: 'AccountBalanceTransferType';
  /** Debit details. */
  accountCharge?: Maybe<AccountChargeType>;
  /** Credit details. */
  accountCredit?: Maybe<AccountCreditType>;
  /** Balance transfer ID. */
  id?: Maybe<Scalars['ID']>;
  /** The reason for the balance transfer. */
  reason?: Maybe<Scalars['String']>;
};

export type AccountBillingAddressInput = {
  /** The account number of the account to update. */
  accountNumber?: InputMaybe<Scalars['String']>;
  /** Billing address details. */
  billingAddress?: InputMaybe<BillingAddressDetailsInput>;
};

/** An enumeration. */
export enum AccountBillingOptionsPeriodLength {
  /** Monthly */
  Monthly = 'MONTHLY',
  /** Quarterly */
  Quarterly = 'QUARTERLY',
}

export type AccountCampaignType = {
  __typename?: 'AccountCampaignType';
  /** The date on which the associated campaign itself concludes. */
  campaignExpiryDate?: Maybe<Scalars['Date']>;
  /** The date on which the account's participation in the campaign ends. */
  expiryDate?: Maybe<Scalars['Date']>;
  /** The name of the campaign. */
  name?: Maybe<Scalars['String']>;
  /** The slug of the campaign. */
  slug?: Maybe<Scalars['String']>;
  /** The date that the account's link to the campaign started. */
  startDate?: Maybe<Scalars['Date']>;
};

export type AccountChargeMetadataType = {
  __typename?: 'AccountChargeMetadataType';
  /** A JSON object containing unstructured data about the account charge. */
  data?: Maybe<Scalars['JSONString']>;
  /** The date and time the metadata was last updated. */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AccountChargeType = {
  __typename?: 'AccountChargeType';
  /** The climate change levy amount of the charge. */
  cclAmount?: Maybe<Scalars['BigInt']>;
  /** The display note for the charge. */
  displayNote?: Maybe<Scalars['String']>;
  /** The gross amount of the charge. */
  grossAmount?: Maybe<Scalars['BigInt']>;
  /** The ID of the account charge. */
  id?: Maybe<Scalars['ID']>;
  metadata?: Maybe<AccountChargeMetadataType>;
  /** The net amount of the charge. */
  netAmount?: Maybe<Scalars['BigInt']>;
  /** The note for the charge. */
  note?: Maybe<Scalars['String']>;
  /** The reason for the charge. */
  reason?: Maybe<Scalars['String']>;
  /** The sales tax amount of the charge. */
  salesTaxAmount?: Maybe<Scalars['BigInt']>;
};

export type AccountCreditMetadataType = {
  __typename?: 'AccountCreditMetadataType';
  data: Scalars['JSONString'];
  updatedAt: Scalars['DateTime'];
};

/**
 *
 *     Please note: these labels are exposed in the API documentation.
 *
 */
export enum AccountCreditReasonType {
  /** External referral credit */
  ExternalReferralCredit = 'EXTERNAL_REFERRAL_CREDIT',
}

export type AccountCreditType = {
  __typename?: 'AccountCreditType';
  grossAmount: Scalars['BigInt'];
  id: Scalars['ID'];
  metadata?: Maybe<AccountCreditMetadataType>;
  netAmount: Scalars['BigInt'];
  note: Scalars['String'];
  reason: Scalars['String'];
  salesTaxAmount: Scalars['BigInt'];
};

export type AccountEvent = EmailEventType | PrintEventType;

export type AccountEventConnectionTypeConnection = {
  __typename?: 'AccountEventConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AccountEventConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `AccountEventConnectionType` and its cursor. */
export type AccountEventConnectionTypeEdge = {
  __typename?: 'AccountEventConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AccountEvent>;
};

/** An enumeration. */
export enum AccountEventType {
  /** The email messages that were received by the account. */
  EmailReceived = 'EMAIL_RECEIVED',
  /** The email messages that were sent by the account. */
  EmailSent = 'EMAIL_SENT',
  /** The marketing email messages that were sent by the account. */
  MarketingEmailSent = 'MARKETING_EMAIL_SENT',
  /** The print messages that were cancelled. */
  PrintCancelled = 'PRINT_CANCELLED',
  /** The print messages that failed to be delivered. */
  PrintFailed = 'PRINT_FAILED',
  /** The print messages that were returned to sender. */
  PrintReturned = 'PRINT_RETURNED',
  /** The print messages that were sent to the print partner by the account. */
  PrintSent = 'PRINT_SENT',
  /** The print messages that were sent by the print partner. */
  PrintSucceeded = 'PRINT_SUCCEEDED',
}

export type AccountFileAttachment = FileAttachment &
  Node & {
    __typename?: 'AccountFileAttachment';
    category: Scalars['String'];
    fetchUrl?: Maybe<Scalars['String']>;
    filename: Scalars['String'];
    /** The ID of the object */
    id: Scalars['ID'];
    /** Is the file ready for use / downloadable? */
    isReady?: Maybe<Scalars['Boolean']>;
    /** Is the file uploaded to S3? */
    isUploaded?: Maybe<Scalars['Boolean']>;
    sizeInBytes?: Maybe<Scalars['Int']>;
  };

export type AccountFileAttachmentConnectionTypeConnection = {
  __typename?: 'AccountFileAttachmentConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AccountFileAttachmentConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `AccountFileAttachmentConnectionType` and its cursor. */
export type AccountFileAttachmentConnectionTypeEdge = {
  __typename?: 'AccountFileAttachmentConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AccountFileAttachment>;
};

export type AccountInterface = {
  /** The type of account. */
  accountType?: Maybe<AccountTypeChoices>;
  /** List of active hardship agreements for the user when is_in_hardship is True. */
  activeHardshipAgreements?: Maybe<Array<Maybe<HardshipAgreementType>>>;
  /** The referral schemes currently active for this account. */
  activeReferralSchemes?: Maybe<ReferralSchemeTypes>;
  /**
   *
   * The billing address of this account, stored in the new
   * libaddressinput-based format.
   *
   * Note that `name` and `organization` are very unlikely to be
   * supplied here; the `billing_name` field on the account
   * itself is generally used for that purpose instead.
   *
   */
  address?: Maybe<RichAddressType>;
  /** Fetch annual statements for the account. */
  annualStatements?: Maybe<AnnualStatementConnectionTypeConnection>;
  /** Applications by this account to become our customer. More recent applications will be listed first. */
  applications?: Maybe<AccountApplicationConnectionTypeConnection>;
  /** Assistance agreements for account. */
  assistanceAgreements?: Maybe<Array<Maybe<AssistanceAgreementType>>>;
  /** The current account balance. */
  balance: Scalars['Int'];
  /** Fetch a specific issued bill (invoice/statement) for the account. */
  bill?: Maybe<BillInterface>;
  /** The billing address of the account. */
  billingAddress?: Maybe<Scalars['String']>;
  billingAddressLine1?: Maybe<Scalars['String']>;
  billingAddressLine2?: Maybe<Scalars['String']>;
  billingAddressLine3?: Maybe<Scalars['String']>;
  billingAddressLine4?: Maybe<Scalars['String']>;
  billingAddressLine5?: Maybe<Scalars['String']>;
  billingAddressPostcode?: Maybe<Scalars['String']>;
  billingCountryCode?: Maybe<Scalars['String']>;
  billingDeliveryPointIdentifier?: Maybe<Scalars['String']>;
  /** The billing email of the account. */
  billingEmail?: Maybe<Scalars['String']>;
  /** The billing name of the account. */
  billingName?: Maybe<Scalars['String']>;
  /** Information about the account's billing cycle. */
  billingOptions?: Maybe<BillingOptionsType>;
  /** The billing sub name of the account. */
  billingSubName?: Maybe<Scalars['String']>;
  /** Fetch issued bills (invoices/statements) for the account. */
  bills?: Maybe<BillConnectionTypeConnection>;
  /** The brand of the account. */
  brand?: Maybe<Scalars['String']>;
  /** Business info related to a business account. */
  business?: Maybe<BusinessType>;
  /**
   * The company type of a business account.
   * @deprecated The 'businessType' field is deprecated.
   *
   * Use `business.businessType` instead
   *
   * - Marked as deprecated on 2022-03-09.
   * - Will be removed on 2024-01-01.
   */
  businessType?: Maybe<BusinessTypeOptions>;
  /** The campaigns associated with an account. */
  campaigns?: Maybe<Array<Maybe<AccountCampaignType>>>;
  /** Whether the account can request a credit refund. */
  canRequestRefund?: Maybe<Scalars['Boolean']>;
  /** The method the account has specified they prefer we contact them */
  commsDeliveryPreference?: Maybe<CommsDeliveryPreference>;
  /**
   * @deprecated The 'communicationDeliveryPreference' field is deprecated.
   *
   * Use `commsDeliveryPreference` instead
   *
   * - Marked as deprecated on 2022-05-27.
   * - Will be removed on 2024-01-01.
   */
  communicationDeliveryPreference?: Maybe<Scalars['String']>;
  /** Contribution agreements for account. */
  contributionAgreements?: Maybe<Array<Maybe<ContributionAgreementType>>>;
  /** The datetime that the account was originally created. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Debt collection proceedings for account. */
  debtCollectionProceedings?: Maybe<Array<Maybe<DebtCollectionProceedingType>>>;
  /** The direct debit instructions of the account */
  directDebitInstructions?: Maybe<DirectDebitInstructionConnectionTypeConnection>;
  /** The document accessibility preference of the account. */
  documentAccessibility?: Maybe<DocumentAccessibilityChoices>;
  /** The account events that were recorded for the account. */
  events?: Maybe<AccountEventConnectionTypeConnection>;
  /** Files attached to this account. Only available if categories defined in settings. */
  fileAttachments?: Maybe<Array<Maybe<AccountFileAttachment>>>;
  /** True if there is an active Hardship Agreement for this account. False otherwise. */
  isInHardship?: Maybe<Scalars['Boolean']>;
  /** Ledgers provide the foundation of bookkeeping functionality. Similar to a bank account, they allow us to keep track of financial activity on a particular customer account. */
  ledgers?: Maybe<Array<Maybe<LedgerType>>>;
  /** The maximum amount a customer is allowed to request as a refund and the reason why that's the maximum amount. */
  maximumRefund?: Maybe<MaximumRefundType>;
  /** Metadata associated with the account. */
  metadata?: Maybe<Array<Maybe<Metadata>>>;
  /** Notes for the account. */
  notes?: Maybe<Array<Maybe<AccountNoteType>>>;
  /** A code that uniquely identifies the account. */
  number?: Maybe<Scalars['String']>;
  /** The current account overdue balance. */
  overdueBalance?: Maybe<Scalars['Int']>;
  /** Files attached to this account. */
  paginatedFileAttachments?: Maybe<AccountFileAttachmentConnectionTypeConnection>;
  /** Paginated payment forecasts for an account. Starts from today's date (inclusive). The interface supports `last` but does not guarantee 'lastness'. */
  paginatedPaymentForecast?: Maybe<PaymentForecastConnectionTypeConnection>;
  /**
   * A list displaying the payment forecast for an account. The list starts from today's date (inclusive).
   * @deprecated The 'paymentForecast' field is deprecated.
   *
   * Please use 'paginatedPaymentForecast' instead.
   *
   * - Marked as deprecated on 2024-01-03.
   * - Will be removed on 2025-01-01.
   */
  paymentForecast?: Maybe<Array<Maybe<PaymentForecastType>>>;
  /** The schedules that describe how we would expect to take payments for an account on a given month. */
  paymentSchedules?: Maybe<PaymentScheduleConnectionTypeConnection>;
  /** The payments made into an account from a payment instruction. */
  payments?: Maybe<AccountPaymentConnectionTypeConnection>;
  /** The portfolio this account is linked to. */
  portfolio?: Maybe<PortfolioType>;
  /** The language that the account preferred for communications. */
  preferredLanguageForComms?: Maybe<Scalars['String']>;
  provisionalTransactions?: Maybe<ProvisionalTransactionConnectionTypeConnection>;
  /** Account references linked to this account. */
  references?: Maybe<Array<Maybe<AccountReferenceType>>>;
  /** The referrals created by this account. */
  referrals?: Maybe<ReferralConnectionTypeConnection>;
  /** Number of referrals created by this account. */
  referralsCreated?: Maybe<Scalars['Int']>;
  /** The repayments that have been requested for this account. */
  repayments?: Maybe<AccountRepaymentConnectionTypeConnection>;
  /** Details about the eligibility status for requesting a refund. */
  requestRefundEligibility?: Maybe<RequestRefundEligibilityType>;
  /** The rewards applied to this account. */
  rewards?: Maybe<Array<Maybe<RewardType>>>;
  /** List of billing address lines. */
  splitBillingAddress?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The current status of the account. */
  status?: Maybe<AccountStatus>;
  /** Fetch transactions that have taken place on the account. */
  transactions?: Maybe<TransactionConnectionTypeConnection>;
  /** Unique reference number from a 3rd party enrolment. */
  urn?: Maybe<Scalars['String']>;
};

export type AccountInterfaceAnnualStatementsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type AccountInterfaceApplicationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type AccountInterfaceBalanceArgs = {
  includeAllLedgers?: InputMaybe<Scalars['Boolean']>;
};

export type AccountInterfaceBillArgs = {
  billType?: InputMaybe<BillTypeEnum>;
  id: Scalars['ID'];
};

export type AccountInterfaceBillsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  fromDate?: InputMaybe<Scalars['Date']>;
  includeBillsWithoutPDF?: InputMaybe<Scalars['Boolean']>;
  includeHeldStatements?: InputMaybe<Scalars['Boolean']>;
  includeHistoricStatements?: InputMaybe<Scalars['Boolean']>;
  includeOpenStatements?: InputMaybe<Scalars['Boolean']>;
  issuedFromDate?: InputMaybe<Scalars['Date']>;
  issuedToDate?: InputMaybe<Scalars['Date']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  onlyCurrentEmail?: InputMaybe<Scalars['Boolean']>;
  orderBy?: InputMaybe<BillsOrderBy>;
  toDate?: InputMaybe<Scalars['Date']>;
};

export type AccountInterfaceDirectDebitInstructionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<InputMaybe<DirectDebitInstructionStatus>>>;
};

export type AccountInterfaceEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  eventTypes?: InputMaybe<Array<InputMaybe<AccountEventType>>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type AccountInterfaceLedgersArgs = {
  ledgerId?: InputMaybe<Scalars['ID']>;
  ledgerNumber?: InputMaybe<Scalars['String']>;
};

export type AccountInterfacePaginatedFileAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type AccountInterfacePaginatedPaymentForecastArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  dateTo?: InputMaybe<Scalars['Date']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  ledgerId?: InputMaybe<Scalars['Int']>;
};

export type AccountInterfacePaymentForecastArgs = {
  dateTo: Scalars['Date'];
  ledgerId?: InputMaybe<Scalars['Int']>;
};

export type AccountInterfacePaymentSchedulesArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  activeOnDate?: InputMaybe<Scalars['Date']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  includeDormant?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  ledgerId?: InputMaybe<Scalars['ID']>;
  ledgerType?: InputMaybe<Scalars['Int']>;
  reason?: InputMaybe<PaymentScheduleReasonOptions>;
};

export type AccountInterfacePaymentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  includePromises?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  ledgerId?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<PaymentReasonOptions>;
  status?: InputMaybe<AccountPaymentStatusOptions>;
};

export type AccountInterfaceProvisionalTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type AccountInterfaceReferralsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type AccountInterfaceRepaymentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<InputMaybe<AccountRepaymentStatusOptions>>>;
};

export type AccountInterfaceTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  fromDate?: InputMaybe<Scalars['Date']>;
  includeAllLedgers?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  ledgerId?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TransactionsOrderBy>;
  toDate?: InputMaybe<Scalars['Date']>;
  transactionTypes?: InputMaybe<Array<InputMaybe<TransactionTypeFilter>>>;
  transactionTypesExcluded?: InputMaybe<
    Array<InputMaybe<TransactionTypeFilter>>
  >;
};

export type AccountLedgerInput = {
  /** The account number. */
  accountNumber: Scalars['ID'];
  /** The ledger id for the account. */
  ledgerId: Scalars['ID'];
};

export type AccountNoteType = {
  __typename?: 'AccountNoteType';
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  isPinned: Scalars['Boolean'];
};

export type AccountPaymentConnectionTypeConnection = {
  __typename?: 'AccountPaymentConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AccountPaymentConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `AccountPaymentConnectionType` and its cursor. */
export type AccountPaymentConnectionTypeEdge = {
  __typename?: 'AccountPaymentConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AccountPaymentType>;
};

/** An enumeration. */
export enum AccountPaymentStatusOptions {
  /** The payment has been approved by the merchant and added to your Kraken account. */
  Cleared = 'CLEARED',
  /** This payment was deleted. From this point it cannot be altered. */
  Deleted = 'DELETED',
  /** The payment failed permanently. */
  Failed = 'FAILED',
  /** The payment has been made successfully and applied to the Kraken balance. */
  Fulfilled = 'FULFILLED',
  /** Payments made in a previous system and then imported into Kraken. */
  Historic = 'HISTORIC',
  /** The payment has been submitted. From this point it cannot be altered. */
  Pending = 'PENDING',
  /** A payment promise has been created, but it has not left the customers bank account. */
  Promised = 'PROMISED',
  /** The payment promise has been broken. */
  PromiseBroken = 'PROMISE_BROKEN',
  /** The initial state of a payment in Kraken. It should be scheduled with a payment vendor in the future. */
  Requested = 'REQUESTED',
  /** The payment has been scheduled for collection and the customer has been notified. While a payment is scheduled, it can still be deleted. */
  Scheduled = 'SCHEDULED',
  /** Third Party payments are those recorded for financial purposes in a different system but should be added to statements. */
  ThirdParty = 'THIRD_PARTY',
}

/** An enumeration. */
export enum AccountPaymentTransactionTypeChoices {
  Agency = 'AGENCY',
  AllpayCard = 'ALLPAY_CARD',
  AllpayCash = 'ALLPAY_CASH',
  AllpayCheque = 'ALLPAY_CHEQUE',
  AustraliaPost = 'AUSTRALIA_POST',
  BacsDeposit = 'BACS_DEPOSIT',
  Bpay = 'BPAY',
  Bpoint = 'BPOINT',
  BristolPound = 'BRISTOL_POUND',
  Btre = 'BTRE',
  Cash = 'CASH',
  Centrepay = 'CENTREPAY',
  Cheque = 'CHEQUE',
  CreditCard = 'CREDIT_CARD',
  DcaCollection = 'DCA_COLLECTION',
  DdFinalCollection = 'DD_FINAL_COLLECTION',
  DdFirstCollection = 'DD_FIRST_COLLECTION',
  DdRegularCollection = 'DD_REGULAR_COLLECTION',
  DdRePresentation = 'DD_RE_PRESENTATION',
  DebitCard = 'DEBIT_CARD',
  Dwp = 'DWP',
  EapaVoucher = 'EAPA_VOUCHER',
  Eft = 'EFT',
  ErroneousPayment = 'ERRONEOUS_PAYMENT',
  FailedRepaymentReversal = 'FAILED_REPAYMENT_REVERSAL',
  FuelDirect = 'FUEL_DIRECT',
  Heeas = 'HEEAS',
  Ivr = 'IVR',
  Konbini = 'KONBINI',
  PagopaNotice = 'PAGOPA_NOTICE',
  PaymentFee = 'PAYMENT_FEE',
  PaypointCard = 'PAYPOINT_CARD',
  PaypointCash = 'PAYPOINT_CASH',
  PaypointCheque = 'PAYPOINT_CHEQUE',
  Payzone = 'PAYZONE',
  PostOfficeCard = 'POST_OFFICE_CARD',
  PostOfficeCash = 'POST_OFFICE_CASH',
  PostOfficeCheque = 'POST_OFFICE_CHEQUE',
  PostOfficeSavingsStamps = 'POST_OFFICE_SAVINGS_STAMPS',
  PrepaidCard = 'PREPAID_CARD',
  PrepayCard = 'PREPAY_CARD',
  PrepayKey = 'PREPAY_KEY',
  PrepaySmart = 'PREPAY_SMART',
  PrepayToken = 'PREPAY_TOKEN',
  TransferFromSap = 'TRANSFER_FROM_SAP',
  Unknown = 'UNKNOWN',
  Urgs = 'URGS',
}

export type AccountPaymentType = {
  __typename?: 'AccountPaymentType';
  /** Amount of payment in pence */
  amount: Scalars['BigInt'];
  id: Scalars['ID'];
  /** The date this payment is scheduled to be debited */
  paymentDate: Scalars['Date'];
  reference: Scalars['String'];
  /** The current status of the payment */
  status?: Maybe<AccountPaymentStatusOptions>;
  /** Surcharge amount generated by this payment. */
  surchargeAmount?: Maybe<Scalars['Int']>;
  /** The transaction type of the payment. */
  transactionType?: Maybe<AccountPaymentTransactionTypeChoices>;
};

/** The input type for the account reference. */
export type AccountReferenceInput = {
  /** The account number. */
  accountNumber: Scalars['String'];
  /** The namespace for the reference. */
  namespace: Scalars['String'];
  /** The reference value. */
  value: Scalars['String'];
};

export type AccountReferenceType = {
  __typename?: 'AccountReferenceType';
  account: AccountType;
  createdAt: Scalars['DateTime'];
  namespace: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export type AccountReminder = {
  __typename?: 'AccountReminder';
  /** Reminder content. */
  content?: Maybe<Scalars['String']>;
  /** When the reminder is due. */
  dueAt?: Maybe<Scalars['DateTime']>;
  /** The reminder type. */
  reminderType?: Maybe<AccountReminderTypes>;
};

/** Contains reminder type choices for all territories. */
export enum AccountReminderTypes {
  AccountCoolOff = 'ACCOUNT_COOL_OFF',
  AccountMigrationSyncXoserveMeterMismatch = 'ACCOUNT_MIGRATION_SYNC_XOSERVE_METER_MISMATCH',
  ActivatePaymentCancellationStopped = 'ACTIVATE_PAYMENT_CANCELLATION_STOPPED',
  AdHoc = 'AD_HOC',
  AgreementRevoked = 'AGREEMENT_REVOKED',
  AmperageChange = 'AMPERAGE_CHANGE',
  AssignedToCreditTeam = 'ASSIGNED_TO_CREDIT_TEAM',
  AusEmbeddedWaterEstimationRequiredForSkippedReading = 'AUS_EMBEDDED_WATER_ESTIMATION_REQUIRED_FOR_SKIPPED_READING',
  AusEmbeddedWaterReadingFailure = 'AUS_EMBEDDED_WATER_READING_FAILURE',
  AusIndustryCustomerOwnReadingNotSent = 'AUS_INDUSTRY_CUSTOMER_OWN_READING_NOT_SENT',
  AusVicSharedfuseNotification = 'AUS_VIC_SHAREDFUSE_NOTIFICATION',
  AutomatedBillingDisabled = 'AUTOMATED_BILLING_DISABLED',
  Billing = 'BILLING',
  BillingAmendedIntervalDataReceivedCausingOvercharge = 'BILLING_AMENDED_INTERVAL_DATA_RECEIVED_CAUSING_OVERCHARGE',
  BillingAmendedMeterReadBasic = 'BILLING_AMENDED_METER_READ_BASIC',
  BillingAmendedMeterReadCesElecBasic = 'BILLING_AMENDED_METER_READ_CES_ELEC_BASIC',
  BillingAmendedMeterReadCesElecInterval = 'BILLING_AMENDED_METER_READ_CES_ELEC_INTERVAL',
  BillingAmendedMeterReadGas = 'BILLING_AMENDED_METER_READ_GAS',
  BillingAmendedMeterReadInterval = 'BILLING_AMENDED_METER_READ_INTERVAL',
  BillingAmendedMeterReadIntervalLegacy = 'BILLING_AMENDED_METER_READ_INTERVAL_LEGACY',
  BillingAmendedMeterReadPreMigration = 'BILLING_AMENDED_METER_READ_PRE_MIGRATION',
  BillingBackdatedConcessionReceived = 'BILLING_BACKDATED_CONCESSION_RECEIVED',
  BillingBackdatedUmsRecordReceived = 'BILLING_BACKDATED_UMS_RECORD_RECEIVED',
  BillingCustomerSelfReadReceived = 'BILLING_CUSTOMER_SELF_READ_RECEIVED',
  BillingMissingRead = 'BILLING_MISSING_READ',
  BillingQuotedNtcDoesNotAlign = 'BILLING_QUOTED_NTC_DOES_NOT_ALIGN',
  BillingRegisterReplacedOrRemoved = 'BILLING_REGISTER_REPLACED_OR_REMOVED',
  CancelMoveOutUnableToReinstateFutureAgreements = 'CANCEL_MOVE_OUT_UNABLE_TO_REINSTATE_FUTURE_AGREEMENTS',
  ChurnPrevention = 'CHURN_PREVENTION',
  CommsToBePrinted = 'COMMS_TO_BE_PRINTED',
  ContractCommsNotDelivered = 'CONTRACT_COMMS_NOT_DELIVERED',
  CosGain = 'COS_GAIN',
  CosGainMigrationEcoesMeterMismatch = 'COS_GAIN_MIGRATION_ECOES_METER_MISMATCH',
  CosGainRelRetrievalFailure = 'COS_GAIN_REL_RETRIEVAL_FAILURE',
  CosLoss = 'COS_LOSS',
  CustomerDetailsChange = 'CUSTOMER_DETAILS_CHANGE',
  CustomerReportedSmartMeterIssues = 'CUSTOMER_REPORTED_SMART_METER_ISSUES',
  D0010CreationFailure = 'D0010_CREATION_FAILURE',
  D0052CreationFailure = 'D0052_CREATION_FAILURE',
  D0205CreationFailure = 'D0205_CREATION_FAILURE',
  DeuMasterDataSynchronisation = 'DEU_MASTER_DATA_SYNCHRONISATION',
  DeuMeterReadings = 'DEU_METER_READINGS',
  DeuSwichtingProcessFailed = 'DEU_SWICHTING_PROCESS_FAILED',
  DisconnectionMandatoryNoticeEmailFailure = 'DISCONNECTION_MANDATORY_NOTICE_EMAIL_FAILURE',
  DisconnectionMandatoryNoticeSmsFailure = 'DISCONNECTION_MANDATORY_NOTICE_SMS_FAILURE',
  DunningBestEndeavoursCall = 'DUNNING_BEST_ENDEAVOURS_CALL',
  DunningDisconnectionDeEnergisationAssessment = 'DUNNING_DISCONNECTION_DE_ENERGISATION_ASSESSMENT',
  DunningDisconnectionMandatoryNoticeEmailFailure = 'DUNNING_DISCONNECTION_MANDATORY_NOTICE_EMAIL_FAILURE',
  DunningDisconnectionOutboundReminderCall = 'DUNNING_DISCONNECTION_OUTBOUND_REMINDER_CALL',
  DunningMandatoryNoticeEmailFailure = 'DUNNING_MANDATORY_NOTICE_EMAIL_FAILURE',
  DunningOutboundReminderCall = 'DUNNING_OUTBOUND_REMINDER_CALL',
  DunningReminder = 'DUNNING_REMINDER',
  DunningReminderCall = 'DUNNING_REMINDER_CALL',
  DunningReminderDisconnectionApplication = 'DUNNING_REMINDER_DISCONNECTION_APPLICATION',
  DunningReminderFuturePayment = 'DUNNING_REMINDER_FUTURE_PAYMENT',
  DunningReminderPaymentMade = 'DUNNING_REMINDER_PAYMENT_MADE',
  DunningVacantConsumptionDeEnergisationAssessment = 'DUNNING_VACANT_CONSUMPTION_DE_ENERGISATION_ASSESSMENT',
  DuplicateCardFingerprinTs = 'DUPLICATE_CARD_FINGERPRINTs',
  EmbeddedNetworkAllChildLifeSupportRecordsAreDeregistered = 'EMBEDDED_NETWORK_ALL_CHILD_LIFE_SUPPORT_RECORDS_ARE_DEREGISTERED',
  EmbeddedNetworkExceptionDistributorOwnedParentLifeSupportRecord = 'EMBEDDED_NETWORK_EXCEPTION_DISTRIBUTOR_OWNED_PARENT_LIFE_SUPPORT_RECORD',
  EmbeddedNetworkExceptionParentMeterPointLostOrLosing = 'EMBEDDED_NETWORK_EXCEPTION_PARENT_METER_POINT_LOST_OR_LOSING',
  EmbeddedNetworkExceptionParentMeterPointReceivedDangerousLifeSupportNotification = 'EMBEDDED_NETWORK_EXCEPTION_PARENT_METER_POINT_RECEIVED_DANGEROUS_LIFE_SUPPORT_NOTIFICATION',
  EmbeddedNetworkExceptionParentMeterPointReceivedLifeSupportNotification = 'EMBEDDED_NETWORK_EXCEPTION_PARENT_METER_POINT_RECEIVED_LIFE_SUPPORT_NOTIFICATION',
  FieldworksAccountCreatedWithNoEmailAddress = 'FIELDWORKS_ACCOUNT_CREATED_WITH_NO_EMAIL_ADDRESS',
  FieldworksAllocateNmiMarketParticipantsNotSet = 'FIELDWORKS_ALLOCATE_NMI_MARKET_PARTICIPANTS_NOT_SET',
  FieldworksAssignMeteringCoordinatorStepFailed = 'FIELDWORKS_ASSIGN_METERING_COORDINATOR_STEP_FAILED',
  FieldworksAssignMeteringProviderStepFailed = 'FIELDWORKS_ASSIGN_METERING_PROVIDER_STEP_FAILED',
  FieldworksBulkDeploymentJourneyAutoCancelled = 'FIELDWORKS_BULK_DEPLOYMENT_JOURNEY_AUTO_CANCELLED',
  FieldworksChangeRetailerFailed = 'FIELDWORKS_CHANGE_RETAILER_FAILED',
  FieldworksContactSoRecipientToUpdateTheSoDetails = 'FIELDWORKS_CONTACT_SO_RECIPIENT_TO_UPDATE_THE_SO_DETAILS',
  FieldworksExpectedMeterPointNotCreated = 'FIELDWORKS_EXPECTED_METER_POINT_NOT_CREATED',
  FieldworksExpectedMeterReadNotReceived = 'FIELDWORKS_EXPECTED_METER_READ_NOT_RECEIVED',
  FieldworksExpectedNtcsNotReceived = 'FIELDWORKS_EXPECTED_NTCS_NOT_RECEIVED',
  FieldworksInvestigateWhetherJourneyNeedsContinuation = 'FIELDWORKS_INVESTIGATE_WHETHER_JOURNEY_NEEDS_CONTINUATION',
  FieldworksJourneyAttachments = 'FIELDWORKS_JOURNEY_ATTACHMENTS',
  FieldworksJourneyCancelledDueToLifeSupportRegistration = 'FIELDWORKS_JOURNEY_CANCELLED_DUE_TO_LIFE_SUPPORT_REGISTRATION',
  FieldworksMaintainRegisterBillableOverrideForUnsolicitedMeterChanges = 'FIELDWORKS_MAINTAIN_REGISTER_BILLABLE_OVERRIDE_FOR_UNSOLICITED_METER_CHANGES',
  FieldworksManuallyCompleteCustomerMoveIn = 'FIELDWORKS_MANUALLY_COMPLETE_CUSTOMER_MOVE_IN',
  FieldworksMeterpointIsNotActive = 'FIELDWORKS_METERPOINT_IS_NOT_ACTIVE',
  FieldworksMeterFaultJourneyAutoCancelled = 'FIELDWORKS_METER_FAULT_JOURNEY_AUTO_CANCELLED',
  FieldworksMeterFaultNcomCommsFailed = 'FIELDWORKS_METER_FAULT_NCOM_COMMS_FAILED',
  FieldworksMeterFaultReceived = 'FIELDWORKS_METER_FAULT_RECEIVED',
  FieldworksMirnDiscoveryFailed = 'FIELDWORKS_MIRN_DISCOVERY_FAILED',
  FieldworksMoveOutCancelledWhileSupplyAbolishmentInProgress = 'FIELDWORKS_MOVE_OUT_CANCELLED_WHILE_SUPPLY_ABOLISHMENT_IN_PROGRESS',
  FieldworksMultipleOpenJourneysForMeterPoint = 'FIELDWORKS_MULTIPLE_OPEN_JOURNEYS_FOR_METER_POINT',
  FieldworksNewConnectionCompletionReview = 'FIELDWORKS_NEW_CONNECTION_COMPLETION_REVIEW',
  FieldworksNewConnectionDataNotValid = 'FIELDWORKS_NEW_CONNECTION_DATA_NOT_VALID',
  FieldworksObtainCustomerApprovalBeforeProgressing = 'FIELDWORKS_OBTAIN_CUSTOMER_APPROVAL_BEFORE_PROGRESSING',
  FieldworksObtainSupplyAbolishmentApproval = 'FIELDWORKS_OBTAIN_SUPPLY_ABOLISHMENT_APPROVAL',
  FieldworksServiceOrderAttachments = 'FIELDWORKS_SERVICE_ORDER_ATTACHMENTS',
  FieldworksServiceOrderCompleted = 'FIELDWORKS_SERVICE_ORDER_COMPLETED',
  FieldworksServiceOrderFailed = 'FIELDWORKS_SERVICE_ORDER_FAILED',
  FieldworksServiceOrderPartiallyCompleted = 'FIELDWORKS_SERVICE_ORDER_PARTIALLY_COMPLETED',
  FieldworksServiceOrderStatusUpdated = 'FIELDWORKS_SERVICE_ORDER_STATUS_UPDATED',
  FieldworksServiceOrderUnableToAccessWithCustomerConsultation = 'FIELDWORKS_SERVICE_ORDER_UNABLE_TO_ACCESS_WITH_CUSTOMER_CONSULTATION',
  FieldworksSupplyPeriodDoesNotExistForMeterpoint = 'FIELDWORKS_SUPPLY_PERIOD_DOES_NOT_EXIST_FOR_METERPOINT',
  FieldworksUploadAttachmentToJemenaPortal = 'FIELDWORKS_UPLOAD_ATTACHMENT_TO_JEMENA_PORTAL',
  FinalBillingUnableToBill = 'FINAL_BILLING_UNABLE_TO_BILL',
  FlowFileError = 'FLOW_FILE_ERROR',
  FraActivationProcessAwaitingSwitchInConfirmation = 'FRA_ACTIVATION_PROCESS_AWAITING_SWITCH_IN_CONFIRMATION',
  FraActivationProcessAwaitingSwitchReadings = 'FRA_ACTIVATION_PROCESS_AWAITING_SWITCH_READINGS',
  FraActivationProcessCommanderSouscription = 'FRA_ACTIVATION_PROCESS_COMMANDER_SOUSCRIPTION',
  FraActivationProcessGasProviderChangeRequest = 'FRA_ACTIVATION_PROCESS_GAS_PROVIDER_CHANGE_REQUEST',
  FraActivationProcessInceptionChecks = 'FRA_ACTIVATION_PROCESS_INCEPTION_CHECKS',
  FraActivationProcessPrmEligibility = 'FRA_ACTIVATION_PROCESS_PRM_ELIGIBILITY',
  FraActivationProcessScheduleSiteworks = 'FRA_ACTIVATION_PROCESS_SCHEDULE_SITEWORKS',
  FraElecActivationProcessWrongSwitchReadingsForProviderCalendarTemporalClasses = 'FRA_ELEC_ACTIVATION_PROCESS_WRONG_SWITCH_READINGS_FOR_PROVIDER_CALENDAR_TEMPORAL_CLASSES',
  FraEnergyChequeUnknown = 'FRA_ENERGY_CHEQUE_UNKNOWN',
  FraFsl = 'FRA_FSL',
  FraTerminationRequestErrored = 'FRA_TERMINATION_REQUEST_ERRORED',
  GasExceptionCustomerDetailsNotificationFailed = 'GAS_EXCEPTION_CUSTOMER_DETAILS_NOTIFICATION_FAILED',
  GasExceptionCustomerTransferCancelled = 'GAS_EXCEPTION_CUSTOMER_TRANSFER_CANCELLED',
  GasExceptionCustomerTransferRejected = 'GAS_EXCEPTION_CUSTOMER_TRANSFER_REJECTED',
  GasExceptionLifeSupportNotificationFailed = 'GAS_EXCEPTION_LIFE_SUPPORT_NOTIFICATION_FAILED',
  GasExceptionMeterDataVerifyRequestFailed = 'GAS_EXCEPTION_METER_DATA_VERIFY_REQUEST_FAILED',
  GasExceptionMeterDataVerifyResponseNoChangeWithExplanation = 'GAS_EXCEPTION_METER_DATA_VERIFY_RESPONSE_NO_CHANGE_WITH_EXPLANATION',
  GasExceptionMeterDataVerifyResponseOverdue = 'GAS_EXCEPTION_METER_DATA_VERIFY_RESPONSE_OVERDUE',
  GasExceptionMeterDataVerifyResponseWithoutRevisedRead = 'GAS_EXCEPTION_METER_DATA_VERIFY_RESPONSE_WITHOUT_REVISED_READ',
  GasExceptionSiteAccessDetailsNotificationFailed = 'GAS_EXCEPTION_SITE_ACCESS_DETAILS_NOTIFICATION_FAILED',
  GasExceptionSiteAccessDetailsReceivedForNonExistentMeter = 'GAS_EXCEPTION_SITE_ACCESS_DETAILS_RECEIVED_FOR_NON_EXISTENT_METER',
  GasExceptionSiteAddressDetailsNotificationFailed = 'GAS_EXCEPTION_SITE_ADDRESS_DETAILS_NOTIFICATION_FAILED',
  GasManualServiceOrderRequiredForEnrolment = 'GAS_MANUAL_SERVICE_ORDER_REQUIRED_FOR_ENROLMENT',
  GasNotificationChangeOfSiteAddress = 'GAS_NOTIFICATION_CHANGE_OF_SITE_ADDRESS',
  GasReadingRemovedInstalledReceived = 'GAS_READING_REMOVED_INSTALLED_RECEIVED',
  GasUnknownCustomerClassificationCodeReceived = 'GAS_UNKNOWN_CUSTOMER_CLASSIFICATION_CODE_RECEIVED',
  HardshipGraduationAssessment = 'HARDSHIP_GRADUATION_ASSESSMENT',
  HardshipNoPaymentPlan = 'HARDSHIP_NO_PAYMENT_PLAN',
  HardshipRemovalAssessment = 'HARDSHIP_REMOVAL_ASSESSMENT',
  HeldStatement = 'HELD_STATEMENT',
  IndustryChangeOfSupplierDoubleGain = 'INDUSTRY_CHANGE_OF_SUPPLIER_DOUBLE_GAIN',
  IndustryCustomerTransferDelayed = 'INDUSTRY_CUSTOMER_TRANSFER_DELAYED',
  IndustryExceptionChangeOfSupplierCancelled = 'INDUSTRY_EXCEPTION_CHANGE_OF_SUPPLIER_CANCELLED',
  IndustryExceptionChangeOfSupplierConflictingPeriod = 'INDUSTRY_EXCEPTION_CHANGE_OF_SUPPLIER_CONFLICTING_PERIOD',
  IndustryExceptionChangeOfSupplierGainCompletionOverdue = 'INDUSTRY_EXCEPTION_CHANGE_OF_SUPPLIER_GAIN_COMPLETION_OVERDUE',
  IndustryExceptionChangeOfSupplierObjected = 'INDUSTRY_EXCEPTION_CHANGE_OF_SUPPLIER_OBJECTED',
  IndustryExceptionChangeOfSupplierRejected = 'INDUSTRY_EXCEPTION_CHANGE_OF_SUPPLIER_REJECTED',
  IndustryExceptionChangeRequestCancellationFailed = 'INDUSTRY_EXCEPTION_CHANGE_REQUEST_CANCELLATION_FAILED',
  IndustryExceptionChangeRequestCancelled = 'INDUSTRY_EXCEPTION_CHANGE_REQUEST_CANCELLED',
  IndustryExceptionChangeRequestCompleted = 'INDUSTRY_EXCEPTION_CHANGE_REQUEST_COMPLETED',
  IndustryExceptionChangeRequestObjected = 'INDUSTRY_EXCEPTION_CHANGE_REQUEST_OBJECTED',
  IndustryExceptionChangeRequestObjectionMissingAck = 'INDUSTRY_EXCEPTION_CHANGE_REQUEST_OBJECTION_MISSING_ACK',
  IndustryExceptionChangeRequestObjectionRejected = 'INDUSTRY_EXCEPTION_CHANGE_REQUEST_OBJECTION_REJECTED',
  IndustryExceptionChangeRequestObjectionWithdrawalRejected = 'INDUSTRY_EXCEPTION_CHANGE_REQUEST_OBJECTION_WITHDRAWAL_REJECTED',
  IndustryExceptionChangeRequestRejected = 'INDUSTRY_EXCEPTION_CHANGE_REQUEST_REJECTED',
  IndustryExceptionChangeRequestWithdrawalRejected = 'INDUSTRY_EXCEPTION_CHANGE_REQUEST_WITHDRAWAL_REJECTED',
  IndustryExceptionCustomerDetailsNotificationInvalidBillingAddress = 'INDUSTRY_EXCEPTION_CUSTOMER_DETAILS_NOTIFICATION_INVALID_BILLING_ADDRESS',
  IndustryExceptionCustomerDetailsNotificationMissingBusinessAcceptance = 'INDUSTRY_EXCEPTION_CUSTOMER_DETAILS_NOTIFICATION_MISSING_BUSINESS_ACCEPTANCE',
  IndustryExceptionCustomerDetailsNotificationMissingMandatoryFields = 'INDUSTRY_EXCEPTION_CUSTOMER_DETAILS_NOTIFICATION_MISSING_MANDATORY_FIELDS',
  IndustryExceptionCustomerDetailsNotificationRejected = 'INDUSTRY_EXCEPTION_CUSTOMER_DETAILS_NOTIFICATION_REJECTED',
  IndustryExceptionCustomerDetailsRequestSpecialReason = 'INDUSTRY_EXCEPTION_CUSTOMER_DETAILS_REQUEST_SPECIAL_REASON',
  IndustryExceptionHouseMoveEnrolmentCannotCalculateMoveInReading = 'INDUSTRY_EXCEPTION_HOUSE_MOVE_ENROLMENT_CANNOT_CALCULATE_MOVE_IN_READING',
  IndustryExceptionHouseMoveEnrolmentServiceOrderAlreadyInProgress = 'INDUSTRY_EXCEPTION_HOUSE_MOVE_ENROLMENT_SERVICE_ORDER_ALREADY_IN_PROGRESS',
  IndustryExceptionHouseMoveEnrolmentUnableToCopyLastMeterReading = 'INDUSTRY_EXCEPTION_HOUSE_MOVE_ENROLMENT_UNABLE_TO_COPY_LAST_METER_READING',
  IndustryExceptionLifeSupportContactUserRemovedFromAccount = 'INDUSTRY_EXCEPTION_LIFE_SUPPORT_CONTACT_USER_REMOVED_FROM_ACCOUNT',
  IndustryExceptionLifeSupportMultipleAccountsMatches = 'INDUSTRY_EXCEPTION_LIFE_SUPPORT_MULTIPLE_ACCOUNTS_MATCHES',
  IndustryExceptionLifeSupportMultipleLifeSupportContactMatches = 'INDUSTRY_EXCEPTION_LIFE_SUPPORT_MULTIPLE_LIFE_SUPPORT_CONTACT_MATCHES',
  IndustryExceptionLifeSupportNotificationContainsUnexpectedData = 'INDUSTRY_EXCEPTION_LIFE_SUPPORT_NOTIFICATION_CONTAINS_UNEXPECTED_DATA',
  IndustryExceptionLifeSupportNotificationFailedToSend = 'INDUSTRY_EXCEPTION_LIFE_SUPPORT_NOTIFICATION_FAILED_TO_SEND',
  IndustryExceptionLifeSupportNotificationInvalidContactMethod = 'INDUSTRY_EXCEPTION_LIFE_SUPPORT_NOTIFICATION_INVALID_CONTACT_METHOD',
  IndustryExceptionLifeSupportNotificationInvalidPhone = 'INDUSTRY_EXCEPTION_LIFE_SUPPORT_NOTIFICATION_INVALID_PHONE',
  IndustryExceptionLifeSupportNotificationMissingBusinessAcceptance = 'INDUSTRY_EXCEPTION_LIFE_SUPPORT_NOTIFICATION_MISSING_BUSINESS_ACCEPTANCE',
  IndustryExceptionLifeSupportNotificationReceivedFromNonRegistrationOwner = 'INDUSTRY_EXCEPTION_LIFE_SUPPORT_NOTIFICATION_RECEIVED_FROM_NON_REGISTRATION_OWNER',
  IndustryExceptionLifeSupportNotificationRejected = 'INDUSTRY_EXCEPTION_LIFE_SUPPORT_NOTIFICATION_REJECTED',
  IndustryExceptionLifeSupportNotificationUnknownContact = 'INDUSTRY_EXCEPTION_LIFE_SUPPORT_NOTIFICATION_UNKNOWN_CONTACT',
  IndustryExceptionLifeSupportRequestMissingBusinessAcceptance = 'INDUSTRY_EXCEPTION_LIFE_SUPPORT_REQUEST_MISSING_BUSINESS_ACCEPTANCE',
  IndustryExceptionLifeSupportRequestMissingLifeSupportNotification = 'INDUSTRY_EXCEPTION_LIFE_SUPPORT_REQUEST_MISSING_LIFE_SUPPORT_NOTIFICATION',
  IndustryExceptionLifeSupportRequestRejected = 'INDUSTRY_EXCEPTION_LIFE_SUPPORT_REQUEST_REJECTED',
  IndustryExceptionMeterPointEnrolmentIncompleteCouldNotSendServiceOrder = 'INDUSTRY_EXCEPTION_METER_POINT_ENROLMENT_INCOMPLETE_COULD_NOT_SEND_SERVICE_ORDER',
  IndustryExceptionNextScheduledReadDateTooFarInFuture = 'INDUSTRY_EXCEPTION_NEXT_SCHEDULED_READ_DATE_TOO_FAR_IN_FUTURE',
  IndustryExceptionRelinkingOccurredDuringSdrSync = 'INDUSTRY_EXCEPTION_RELINKING_OCCURRED_DURING_SDR_SYNC',
  IndustryExceptionSiteAccessDetailsNotificationMissingBusinessAcceptance = 'INDUSTRY_EXCEPTION_SITE_ACCESS_DETAILS_NOTIFICATION_MISSING_BUSINESS_ACCEPTANCE',
  IndustryExceptionSiteAccessDetailsNotificationRejected = 'INDUSTRY_EXCEPTION_SITE_ACCESS_DETAILS_NOTIFICATION_REJECTED',
  IndustryExceptionStandingDataPropertiesAddressFailedToUpdate = 'INDUSTRY_EXCEPTION_STANDING_DATA_PROPERTIES_ADDRESS_FAILED_TO_UPDATE',
  IndustryExceptionUnableToEnrolMeterPointIncompleteCouldNotSendChangeRequest = 'INDUSTRY_EXCEPTION_UNABLE_TO_ENROL_METER_POINT_INCOMPLETE_COULD_NOT_SEND_CHANGE_REQUEST',
  IndustryExceptionUnableToEnrolMeterPointInvalidNmiMeterStatus = 'INDUSTRY_EXCEPTION_UNABLE_TO_ENROL_METER_POINT_INVALID_NMI_METER_STATUS',
  IndustryExceptionUnableToEnrolMeterPointNextScheduledReadDatePast = 'INDUSTRY_EXCEPTION_UNABLE_TO_ENROL_METER_POINT_NEXT_SCHEDULED_READ_DATE_PAST',
  IndustryExceptionUnableToProcessRolr = 'INDUSTRY_EXCEPTION_UNABLE_TO_PROCESS_ROLR',
  IndustryLifeSupportCancelDeregistrationFailed = 'INDUSTRY_LIFE_SUPPORT_CANCEL_DEREGISTRATION_FAILED',
  IndustryLifeSupportManualBestEndeavourRequired = 'INDUSTRY_LIFE_SUPPORT_MANUAL_BEST_ENDEAVOUR_REQUIRED',
  IndustryLifeSupportRegistrationFollowUpRequired = 'INDUSTRY_LIFE_SUPPORT_REGISTRATION_FOLLOW_UP_REQUIRED',
  IndustryLifeSupportReviewAfterCancelledMoveOutForNextAccount = 'INDUSTRY_LIFE_SUPPORT_REVIEW_AFTER_CANCELLED_MOVE_OUT_FOR_NEXT_ACCOUNT',
  IndustryLifeSupportReviewAttemptedCancelledMoveOutForNextAccount = 'INDUSTRY_LIFE_SUPPORT_REVIEW_ATTEMPTED_CANCELLED_MOVE_OUT_FOR_NEXT_ACCOUNT',
  IndustryLifeSupportReviewDeregistration = 'INDUSTRY_LIFE_SUPPORT_REVIEW_DEREGISTRATION',
  IndustryLifeSupportReviewPostDeregistration = 'INDUSTRY_LIFE_SUPPORT_REVIEW_POST_DEREGISTRATION',
  IndustryLifeSupportReviewPostDeregistrationComms = 'INDUSTRY_LIFE_SUPPORT_REVIEW_POST_DEREGISTRATION_COMMS',
  IndustryManualActionRequired = 'INDUSTRY_MANUAL_ACTION_REQUIRED',
  IndustryManualCustomerDetailsNotificationRequired = 'INDUSTRY_MANUAL_CUSTOMER_DETAILS_NOTIFICATION_REQUIRED',
  IndustryManualLifeSupportNotificationRequired = 'INDUSTRY_MANUAL_LIFE_SUPPORT_NOTIFICATION_REQUIRED',
  IndustryMeterPointMissingCustomerClassification = 'INDUSTRY_METER_POINT_MISSING_CUSTOMER_CLASSIFICATION',
  IndustryReadingsNotSent = 'INDUSTRY_READINGS_NOT_SENT',
  IndustrySendLifeSupportDeRegistrationForm = 'INDUSTRY_SEND_LIFE_SUPPORT_DE_REGISTRATION_FORM',
  IndustryUnableToCreateRecord = 'INDUSTRY_UNABLE_TO_CREATE_RECORD',
  IndustryVicDroManualLifeSupportExtensionRequest = 'INDUSTRY_VIC_DRO_MANUAL_LIFE_SUPPORT_EXTENSION_REQUEST',
  IndustryWarningMessageReceived = 'INDUSTRY_WARNING_MESSAGE_RECEIVED',
  JpnCallForDunningCampaign = 'JPN_CALL_FOR_DUNNING_CAMPAIGN',
  JpnConfirmationOfRelocation = 'JPN_CONFIRMATION_OF_RELOCATION',
  JpnConfirmPaymentForDunningCampaign = 'JPN_CONFIRM_PAYMENT_FOR_DUNNING_CAMPAIGN',
  JpnFinalReadingOutsideAgreement = 'JPN_FINAL_READING_OUTSIDE_AGREEMENT',
  JpnReadingsNotReceived = 'JPN_READINGS_NOT_RECEIVED',
  JpnShortTermMoveInRejection = 'JPN_SHORT_TERM_MOVE_IN_REJECTION',
  JpnSupplyDetailsUpdateConfirmation = 'JPN_SUPPLY_DETAILS_UPDATE_CONFIRMATION',
  JpnSupplyPointFailsCanSupplyCheck = 'JPN_SUPPLY_POINT_FAILS_CAN_SUPPLY_CHECK',
  Kmt = 'KMT',
  MailFailed = 'MAIL_FAILED',
  MailReturned = 'MAIL_RETURNED',
  MandatoryCommsNotDelivered = 'MANDATORY_COMMS_NOT_DELIVERED',
  MarketSupplyExceptionAgreementFailedToCreate = 'MARKET_SUPPLY_EXCEPTION_AGREEMENT_FAILED_TO_CREATE',
  MarketSupplyExceptionAgreementFailedToReverseTermination = 'MARKET_SUPPLY_EXCEPTION_AGREEMENT_FAILED_TO_REVERSE_TERMINATION',
  MarketSupplyExceptionAgreementFailedToTerminate = 'MARKET_SUPPLY_EXCEPTION_AGREEMENT_FAILED_TO_TERMINATE',
  MarketSupplyExceptionAgreementFailedToUpdate = 'MARKET_SUPPLY_EXCEPTION_AGREEMENT_FAILED_TO_UPDATE',
  MarketSupplyExceptionMissingAccountQuotedProduct = 'MARKET_SUPPLY_EXCEPTION_MISSING_ACCOUNT_QUOTED_PRODUCT',
  MeterExchangeOneWayNotification = 'METER_EXCHANGE_ONE_WAY_NOTIFICATION',
  MeterFaultAndIssueOneWayNotificationAccepted = 'METER_FAULT_AND_ISSUE_ONE_WAY_NOTIFICATION_ACCEPTED',
  MeterFaultAndIssueOneWayNotificationRejected = 'METER_FAULT_AND_ISSUE_ONE_WAY_NOTIFICATION_REJECTED',
  MoveIn = 'MOVE_IN',
  MoveInCesLifeSupportRequired = 'MOVE_IN_CES_LIFE_SUPPORT_REQUIRED',
  MoveInCommsNotDelivered = 'MOVE_IN_COMMS_NOT_DELIVERED',
  MoveInDefaultPaymentScheduleFailed = 'MOVE_IN_DEFAULT_PAYMENT_SCHEDULE_FAILED',
  MoveInMoveOutManualProcess = 'MOVE_IN_MOVE_OUT_MANUAL_PROCESS',
  MoveOut = 'MOVE_OUT',
  NetworkTariffOneWayNotification = 'NETWORK_TARIFF_ONE_WAY_NOTIFICATION',
  NewPsrAddedToPrepayAccount = 'NEW_PSR_ADDED_TO_PREPAY_ACCOUNT',
  NonEnergyPaymentCallReminder = 'NON_ENERGY_PAYMENT_CALL_REMINDER',
  NoticeOfMeteringWorksOneWayNotification = 'NOTICE_OF_METERING_WORKS_ONE_WAY_NOTIFICATION',
  NzBillingQuery = 'NZ_BILLING_QUERY',
  NzDunning = 'NZ_DUNNING',
  NzMeteringQuery = 'NZ_METERING_QUERY',
  NzSolarMeterChange = 'NZ_SOLAR_METER_CHANGE',
  NzSwitchingWithdrawal = 'NZ_SWITCHING_WITHDRAWAL',
  NzVacantProperty = 'NZ_VACANT_PROPERTY',
  ObsoleteBillingAmendedStandingDataRegisterChanged = 'OBSOLETE_BILLING_AMENDED_STANDING_DATA_REGISTER_CHANGED',
  ObsoleteBillingAmendedStandingDataRegisterDeleted = 'OBSOLETE_BILLING_AMENDED_STANDING_DATA_REGISTER_DELETED',
  PaymentsFailedRepayment = 'PAYMENTS_FAILED_REPAYMENT',
  PaymentInstructionFailed = 'PAYMENT_INSTRUCTION_FAILED',
  PaymentPlan = 'PAYMENT_PLAN',
  PaymentPlanHardshipCompletion = 'PAYMENT_PLAN_HARDSHIP_COMPLETION',
  PaymentPlanHardshipCompletionWorkflowCancelled = 'PAYMENT_PLAN_HARDSHIP_COMPLETION_WORKFLOW_CANCELLED',
  PaymentPlanMissedInstalment = 'PAYMENT_PLAN_MISSED_INSTALMENT',
  PlannedInterruption = 'PLANNED_INTERRUPTION',
  PlannedInterruptionMedicalDependency = 'PLANNED_INTERRUPTION_MEDICAL_DEPENDENCY',
  PlannedInterruptionNotificationSentToLifeSupportCustomer = 'PLANNED_INTERRUPTION_NOTIFICATION_SENT_TO_LIFE_SUPPORT_CUSTOMER',
  PlannedInterruptionOneWayNotification = 'PLANNED_INTERRUPTION_ONE_WAY_NOTIFICATION',
  PlannedInterruptionOneWayNotificationRejected = 'PLANNED_INTERRUPTION_ONE_WAY_NOTIFICATION_REJECTED',
  PostHardshipCancellation = 'POST_HARDSHIP_CANCELLATION',
  PropertyAddressNeedsUpdate = 'PROPERTY_ADDRESS_NEEDS_UPDATE',
  PsrImportCouldNotIdentifyAccountUser = 'PSR_IMPORT_COULD_NOT_IDENTIFY_ACCOUNT_USER',
  SaConcessionStatementClosed = 'SA_CONCESSION_STATEMENT_CLOSED',
  ScheduledBillingAddressUpdate = 'SCHEDULED_BILLING_ADDRESS_UPDATE',
  ScheduledOccupierPackSend = 'SCHEDULED_OCCUPIER_PACK_SEND',
  ServiceOrderAcknowledgementOverdue = 'SERVICE_ORDER_ACKNOWLEDGEMENT_OVERDUE',
  ServiceOrderCancellationFailed = 'SERVICE_ORDER_CANCELLATION_FAILED',
  ServiceOrderCancellationRequestRejected = 'SERVICE_ORDER_CANCELLATION_REQUEST_REJECTED',
  ServiceOrderFailed = 'SERVICE_ORDER_FAILED',
  ServiceOrderInitialResponseOverdue = 'SERVICE_ORDER_INITIAL_RESPONSE_OVERDUE',
  ServiceOrderNotCompleted = 'SERVICE_ORDER_NOT_COMPLETED',
  ServiceOrderOtherJobEnquiryCode = 'SERVICE_ORDER_OTHER_JOB_ENQUIRY_CODE',
  ServiceOrderPartiallyCompleted = 'SERVICE_ORDER_PARTIALLY_COMPLETED',
  ServiceOrderRequestRejected = 'SERVICE_ORDER_REQUEST_REJECTED',
  ServiceOrderUnableToCharge = 'SERVICE_ORDER_UNABLE_TO_CHARGE',
  ServiceOrderUnsolicitedReceived = 'SERVICE_ORDER_UNSOLICITED_RECEIVED',
  ServiceOrderUnsolicitedReceivedDeenergisedMeterPoint = 'SERVICE_ORDER_UNSOLICITED_RECEIVED_DEENERGISED_METER_POINT',
  SmartflexDeviceIntegrationPostponed = 'SMARTFLEX_DEVICE_INTEGRATION_POSTPONED',
  SmartChangeOfTenancyInProgress = 'SMART_CHANGE_OF_TENANCY_IN_PROGRESS',
  SmartChangeOfTenancyRequestFailed = 'SMART_CHANGE_OF_TENANCY_REQUEST_FAILED',
  SmartMeterModeChangeUnsupported = 'SMART_METER_MODE_CHANGE_UNSUPPORTED',
  SmartPrepayAddDebtFailure = 'SMART_PREPAY_ADD_DEBT_FAILURE',
  SmartPrepayNeedToAdjustDebt = 'SMART_PREPAY_NEED_TO_ADJUST_DEBT',
  SmartPrepayTopUpFailure = 'SMART_PREPAY_TOP_UP_FAILURE',
  SmartPrepayTopUpMultipleActiveProcesses = 'SMART_PREPAY_TOP_UP_MULTIPLE_ACTIVE_PROCESSES',
  SmartPrepayTopUpUtrn = 'SMART_PREPAY_TOP_UP_UTRN',
  Smets2DataRequestFollowup = 'SMETS2_DATA_REQUEST_FOLLOWUP',
  Smets2HealthCheck = 'SMETS2_HEALTH_CHECK',
  Smets2IhdHealthCheck = 'SMETS2_IHD_HEALTH_CHECK',
  SolarExportCreditsExcess = 'SOLAR_EXPORT_CREDITS_EXCESS',
  SolrFinalBillReminder = 'SOLR_FINAL_BILL_REMINDER',
  SpecialReadCancellationFailed = 'SPECIAL_READ_CANCELLATION_FAILED',
  SpecialReadFailed = 'SPECIAL_READ_FAILED',
  SpecialReadOutstanding = 'SPECIAL_READ_OUTSTANDING',
  SpecialReadWithEstimateReadReceived = 'SPECIAL_READ_WITH_ESTIMATE_READ_RECEIVED',
  UnsupportedFeature = 'UNSUPPORTED_FEATURE',
  UnsupportedPaymentDay = 'UNSUPPORTED_PAYMENT_DAY',
  WaterAccountReversion = 'WATER_ACCOUNT_REVERSION',
  WaterMeterReadingIssue = 'WATER_METER_READING_ISSUE',
  WithdrawalReceived = 'WITHDRAWAL_RECEIVED',
  WorkflowCancellationFailed = 'WORKFLOW_CANCELLATION_FAILED',
  WorkflowStepErrored = 'WORKFLOW_STEP_ERRORED',
  WorkflowStepFailed = 'WORKFLOW_STEP_FAILED',
}

export type AccountRepaymentConnectionTypeConnection = {
  __typename?: 'AccountRepaymentConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AccountRepaymentConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `AccountRepaymentConnectionType` and its cursor. */
export type AccountRepaymentConnectionTypeEdge = {
  __typename?: 'AccountRepaymentConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AccountRepaymentType>;
};

/** An enumeration. */
export enum AccountRepaymentStatusOptions {
  /** The repayment has been approved but not made yet. */
  Approved = 'APPROVED',
  /** The repayment failed permanently. This could be because of technical issues, or if the merchant rejects the payment for some reason. The payment will need to be retried by ops. */
  Failed = 'FAILED',
  /** Payments made in a previous system and then imported into Kraken. */
  Historic = 'HISTORIC',
  /** The repayment has been made to the merchant to be sent to the customer. This is a terminal state, we don't get any further confirmation. */
  Paid = 'PAID',
  /** The request for a repayment has been received but not actioned yet. */
  Requested = 'REQUESTED',
  /** The payment has been submitted to the merchant. It is still possible for this repayment to fail. */
  Submitted = 'SUBMITTED',
  /** Third Party payments are those recorded for financial purposes in a different system but should be added to statements. */
  ThirdParty = 'THIRD_PARTY',
}

export type AccountRepaymentType = {
  __typename?: 'AccountRepaymentType';
  /** Amount of payment in pence */
  amount: Scalars['BigInt'];
  id: Scalars['ID'];
  /** The date this payment is scheduled to be debited */
  paymentDate: Scalars['Date'];
  /** The current status of the repayment */
  status?: Maybe<AccountRepaymentStatusOptions>;
};

export type AccountSearchInputType = {
  /** Internal account id (not account number). */
  account?: InputMaybe<Scalars['String']>;
  /** The account number eg. A-FF15AE70. */
  accountNumber?: InputMaybe<Scalars['String']>;
  /** Account Reference. */
  accountReferences?: InputMaybe<Scalars['String']>;
  /** Account or Billing name. */
  billingName?: InputMaybe<Scalars['String']>;
  /** Business name. */
  businessName?: InputMaybe<Scalars['String']>;
  /** Business identifier or number. */
  businessNumber?: InputMaybe<Scalars['String']>;
  /** Location (Supply or Billing, full or partial, address or post code). */
  location?: InputMaybe<Scalars['String']>;
  /** Meter Serial Number. */
  meterSerialNumber?: InputMaybe<Scalars['String']>;
  /** The portfolio number eg. P-A123B456. */
  portfolioNumber?: InputMaybe<Scalars['String']>;
  /** Statements. */
  statements?: InputMaybe<Scalars['String']>;
  /** URN Number. */
  urn?: InputMaybe<Scalars['String']>;
  /** The Account User ID (not account number). */
  user?: InputMaybe<Scalars['String']>;
};

/** A single accout search hit */
export type AccountSearchItemType = {
  __typename?: 'AccountSearchItemType';
  /** The account found. */
  account?: Maybe<AccountType>;
  /** How well the account matched the search terms. */
  score?: Maybe<Scalars['Decimal']>;
};

/** An enumeration. */
export enum AccountStatementStatus {
  Closed = 'CLOSED',
  Open = 'OPEN',
}

export enum AccountStatus {
  /** Supply could have started, be ongoing or ended. */
  Active = 'ACTIVE',
  /** Dormant. Users should not be able to log into dormant accounts. */
  Dormant = 'DORMANT',
  /** An error occurred when we tried to enroll a meter point. This may be deprecated in future in favour of exposing this through enrollment property of a meter point. */
  EnrolmentError = 'ENROLMENT_ERROR',
  /** Meter point enrollment was rejected. This may be deprecated in future in favour of exposing this through enrollment property of a meter point. */
  EnrolmentRejected = 'ENROLMENT_REJECTED',
  /** Account requires processes to be completed before supply can be set up */
  Incomplete = 'INCOMPLETE',
  /** A pending account is one that has been created but no registrations have started. */
  Pending = 'PENDING',
  /** Void. Account created in error. */
  Void = 'VOID',
  /** Withdrawn before supply started */
  Withdrawn = 'WITHDRAWN',
}

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountType = AccountInterface & {
  __typename?: 'AccountType';
  /** The type of account. */
  accountType?: Maybe<AccountTypeChoices>;
  /** List of active hardship agreements for the user when is_in_hardship is True. */
  activeHardshipAgreements?: Maybe<Array<Maybe<HardshipAgreementType>>>;
  /** The referral schemes currently active for this account. */
  activeReferralSchemes?: Maybe<ReferralSchemeTypes>;
  /**
   *
   * The billing address of this account, stored in the new
   * libaddressinput-based format.
   *
   * Note that `name` and `organization` are very unlikely to be
   * supplied here; the `billing_name` field on the account
   * itself is generally used for that purpose instead.
   *
   */
  address?: Maybe<RichAddressType>;
  /** Fetch annual statements for the account. */
  annualStatements?: Maybe<AnnualStatementConnectionTypeConnection>;
  /** Applications by this account to become our customer. More recent applications will be listed first. */
  applications?: Maybe<AccountApplicationConnectionTypeConnection>;
  /** Assistance agreements for account. */
  assistanceAgreements?: Maybe<Array<Maybe<AssistanceAgreementType>>>;
  /** The current account balance. */
  balance: Scalars['Int'];
  /** Fetch a specific issued bill (invoice/statement) for the account. */
  bill?: Maybe<BillInterface>;
  /** The billing address of the account. */
  billingAddress?: Maybe<Scalars['String']>;
  billingAddressLine1?: Maybe<Scalars['String']>;
  billingAddressLine2?: Maybe<Scalars['String']>;
  billingAddressLine3?: Maybe<Scalars['String']>;
  billingAddressLine4?: Maybe<Scalars['String']>;
  billingAddressLine5?: Maybe<Scalars['String']>;
  billingAddressPostcode?: Maybe<Scalars['String']>;
  billingCountryCode?: Maybe<Scalars['String']>;
  billingDeliveryPointIdentifier?: Maybe<Scalars['String']>;
  /** The billing email of the account. */
  billingEmail?: Maybe<Scalars['String']>;
  /** The billing name of the account. */
  billingName?: Maybe<Scalars['String']>;
  /** Information about the account's billing cycle. */
  billingOptions?: Maybe<BillingOptionsType>;
  /** The billing sub name of the account. */
  billingSubName?: Maybe<Scalars['String']>;
  /** Fetch issued bills (invoices/statements) for the account. */
  bills?: Maybe<BillConnectionTypeConnection>;
  /** The brand of the account. */
  brand?: Maybe<Scalars['String']>;
  /** Business info related to a business account. */
  business?: Maybe<BusinessType>;
  /**
   * The company type of a business account.
   * @deprecated The 'businessType' field is deprecated.
   *
   * Use `business.businessType` instead
   *
   * - Marked as deprecated on 2022-03-09.
   * - Will be removed on 2024-01-01.
   */
  businessType?: Maybe<BusinessTypeOptions>;
  /** The campaigns associated with an account. */
  campaigns?: Maybe<Array<Maybe<AccountCampaignType>>>;
  /** Whether the account can request a credit refund. */
  canRequestRefund?: Maybe<Scalars['Boolean']>;
  /** The method the account has specified they prefer we contact them */
  commsDeliveryPreference?: Maybe<CommsDeliveryPreference>;
  /**
   * @deprecated The 'communicationDeliveryPreference' field is deprecated.
   *
   * Use `commsDeliveryPreference` instead
   *
   * - Marked as deprecated on 2022-05-27.
   * - Will be removed on 2024-01-01.
   */
  communicationDeliveryPreference?: Maybe<Scalars['String']>;
  /** Contribution agreements for account. */
  contributionAgreements?: Maybe<Array<Maybe<ContributionAgreementType>>>;
  /** The datetime that the account was originally created. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Debt collection proceedings for account. */
  debtCollectionProceedings?: Maybe<Array<Maybe<DebtCollectionProceedingType>>>;
  /** The direct debit instructions of the account */
  directDebitInstructions?: Maybe<DirectDebitInstructionConnectionTypeConnection>;
  /** The document accessibility preference of the account. */
  documentAccessibility?: Maybe<DocumentAccessibilityChoices>;
  /** The account events that were recorded for the account. */
  events?: Maybe<AccountEventConnectionTypeConnection>;
  /** Files attached to this account. Only available if categories defined in settings. */
  fileAttachments?: Maybe<Array<Maybe<AccountFileAttachment>>>;
  id: Scalars['ID'];
  /** True if there is an active Hardship Agreement for this account. False otherwise. */
  isInHardship?: Maybe<Scalars['Boolean']>;
  /** Ledgers provide the foundation of bookkeeping functionality. Similar to a bank account, they allow us to keep track of financial activity on a particular customer account. */
  ledgers?: Maybe<Array<Maybe<LedgerType>>>;
  /** The maximum amount a customer is allowed to request as a refund and the reason why that's the maximum amount. */
  maximumRefund?: Maybe<MaximumRefundType>;
  /** Metadata associated with the account. */
  metadata?: Maybe<Array<Maybe<Metadata>>>;
  /** Notes for the account. */
  notes?: Maybe<Array<Maybe<AccountNoteType>>>;
  /** A code that uniquely identifies the account. */
  number?: Maybe<Scalars['String']>;
  /** The current account overdue balance. */
  overdueBalance?: Maybe<Scalars['Int']>;
  /** Files attached to this account. */
  paginatedFileAttachments?: Maybe<AccountFileAttachmentConnectionTypeConnection>;
  /** Paginated payment forecasts for an account. Starts from today's date (inclusive). The interface supports `last` but does not guarantee 'lastness'. */
  paginatedPaymentForecast?: Maybe<PaymentForecastConnectionTypeConnection>;
  /**
   * A list displaying the payment forecast for an account. The list starts from today's date (inclusive).
   * @deprecated The 'paymentForecast' field is deprecated.
   *
   * Please use 'paginatedPaymentForecast' instead.
   *
   * - Marked as deprecated on 2024-01-03.
   * - Will be removed on 2025-01-01.
   */
  paymentForecast?: Maybe<Array<Maybe<PaymentForecastType>>>;
  /** The schedules that describe how we would expect to take payments for an account on a given month. */
  paymentSchedules?: Maybe<PaymentScheduleConnectionTypeConnection>;
  /** The payments made into an account from a payment instruction. */
  payments?: Maybe<AccountPaymentConnectionTypeConnection>;
  /** The portfolio this account is linked to. */
  portfolio?: Maybe<PortfolioType>;
  /** The language that the account preferred for communications. */
  preferredLanguageForComms?: Maybe<Scalars['String']>;
  /** Properties linked to the account now and in the future */
  properties?: Maybe<Array<Maybe<PropertyType>>>;
  provisionalTransactions?: Maybe<ProvisionalTransactionConnectionTypeConnection>;
  /** Account references linked to this account. */
  references?: Maybe<Array<Maybe<AccountReferenceType>>>;
  /** The referrals created by this account. */
  referrals?: Maybe<ReferralConnectionTypeConnection>;
  /** Number of referrals created by this account. */
  referralsCreated?: Maybe<Scalars['Int']>;
  /** The repayments that have been requested for this account. */
  repayments?: Maybe<AccountRepaymentConnectionTypeConnection>;
  /** Details about the eligibility status for requesting a refund. */
  requestRefundEligibility?: Maybe<RequestRefundEligibilityType>;
  /** The rewards applied to this account. */
  rewards?: Maybe<Array<Maybe<RewardType>>>;
  /** List of billing address lines. */
  splitBillingAddress?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The current status of the account. */
  status?: Maybe<AccountStatus>;
  /** Fetch transactions that have taken place on the account. */
  transactions?: Maybe<TransactionConnectionTypeConnection>;
  /** Unique reference number from a 3rd party enrolment. */
  urn?: Maybe<Scalars['String']>;
  users: Array<AccountUserType>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountTypeAnnualStatementsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountTypeApplicationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountTypeBalanceArgs = {
  includeAllLedgers?: InputMaybe<Scalars['Boolean']>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountTypeBillArgs = {
  billType?: InputMaybe<BillTypeEnum>;
  id: Scalars['ID'];
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountTypeBillsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  fromDate?: InputMaybe<Scalars['Date']>;
  includeBillsWithoutPDF?: InputMaybe<Scalars['Boolean']>;
  includeHeldStatements?: InputMaybe<Scalars['Boolean']>;
  includeHistoricStatements?: InputMaybe<Scalars['Boolean']>;
  includeOpenStatements?: InputMaybe<Scalars['Boolean']>;
  issuedFromDate?: InputMaybe<Scalars['Date']>;
  issuedToDate?: InputMaybe<Scalars['Date']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  onlyCurrentEmail?: InputMaybe<Scalars['Boolean']>;
  orderBy?: InputMaybe<BillsOrderBy>;
  toDate?: InputMaybe<Scalars['Date']>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountTypeDirectDebitInstructionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<InputMaybe<DirectDebitInstructionStatus>>>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountTypeEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  eventTypes?: InputMaybe<Array<InputMaybe<AccountEventType>>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountTypeLedgersArgs = {
  ledgerId?: InputMaybe<Scalars['ID']>;
  ledgerNumber?: InputMaybe<Scalars['String']>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountTypePaginatedFileAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountTypePaginatedPaymentForecastArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  dateTo?: InputMaybe<Scalars['Date']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  ledgerId?: InputMaybe<Scalars['Int']>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountTypePaymentForecastArgs = {
  dateTo: Scalars['Date'];
  ledgerId?: InputMaybe<Scalars['Int']>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountTypePaymentSchedulesArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  activeOnDate?: InputMaybe<Scalars['Date']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  includeDormant?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  ledgerId?: InputMaybe<Scalars['ID']>;
  ledgerType?: InputMaybe<Scalars['Int']>;
  reason?: InputMaybe<PaymentScheduleReasonOptions>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountTypePaymentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  includePromises?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  ledgerId?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<PaymentReasonOptions>;
  status?: InputMaybe<AccountPaymentStatusOptions>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountTypePropertiesArgs = {
  activeFrom?: InputMaybe<Scalars['DateTime']>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountTypeProvisionalTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountTypeReferralsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountTypeRepaymentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<InputMaybe<AccountRepaymentStatusOptions>>>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountTypeTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  fromDate?: InputMaybe<Scalars['Date']>;
  includeAllLedgers?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  ledgerId?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TransactionsOrderBy>;
  toDate?: InputMaybe<Scalars['Date']>;
  transactionTypes?: InputMaybe<Array<InputMaybe<TransactionTypeFilter>>>;
  transactionTypesExcluded?: InputMaybe<
    Array<InputMaybe<TransactionTypeFilter>>
  >;
};

/** An enumeration. */
export enum AccountTypeChoices {
  /** An account designed to supply/bill business premises. */
  Business = 'BUSINESS',
  /** An account created when we supply a business premises but do not have details for the occupants. */
  BusinessOccupier = 'BUSINESS_OCCUPIER',
  /** An account created when we supply a business premises and know there are definitely no occupants. */
  BusinessVacant = 'BUSINESS_VACANT',
  /** An account designed to supply/bill domestic premises. */
  Domestic = 'DOMESTIC',
  /** An account created when we supply domestic premises that are managed by a business, i.e., a B2B2C model. */
  Managed = 'MANAGED',
  /** An account created when we supply a domestic premises but do not have details for the occupants. */
  Occupier = 'OCCUPIER',
  /** An account which is responsible for all other accounts in the portfolio, i.e. pays the bills for them. */
  PortfolioLead = 'PORTFOLIO_LEAD',
  /** An account created when we supply a domestic premises and know there are definitely no occupants. */
  Vacant = 'VACANT',
}

/** Information about the preferences set up for a user. */
export type AccountUserCommsPreferences = {
  __typename?: 'AccountUserCommsPreferences';
  /** What format the user would like to receive their emails in. */
  emailFormat?: Maybe<EmailFormats>;
  /** This setting allows the user to adjust the default font size of the communications sent to them. */
  fontSizeMultiplier?: Maybe<Scalars['Float']>;
  /** Whether the user has opted in to receive meter reading confirmation emails. */
  isOptedInMeterReadingConfirmations?: Maybe<Scalars['Boolean']>;
  /** Whether a user has opted in to receive messages from the client or client group. For example, for Octopus Energy this describes whether a user is opted in to offers from Octopus Investments. */
  isOptedInToClientMessages?: Maybe<Scalars['Boolean']>;
  /** Whether a user has opted in to receive messages offering discounts or other services not directly related to the services the client provides. */
  isOptedInToOfferMessages?: Maybe<Scalars['Boolean']>;
  /** Whether a user has opted in to receive messages we recommend they read, but are not vital to the utilities the client provides. For example, these could be reminders that the client will take a payment. */
  isOptedInToRecommendedMessages?: Maybe<Scalars['Boolean']>;
  /** Whether the user has opted in to receive SMS messages. */
  isOptedInToSmsMessages?: Maybe<Scalars['Boolean']>;
  /** Whether a user has opted in to receive messages from the client's preferred third parties. */
  isOptedInToThirdPartyMessages?: Maybe<Scalars['Boolean']>;
  /** Whether a user has opted in to receive messages updating them on client activities. */
  isOptedInToUpdateMessages?: Maybe<Scalars['Boolean']>;
  /** Whether a user has opted to have inverted colours in their emails. This is currently only relevant to the Octopus Energy brand, whose emails have a dark background by default. */
  isUsingInvertedEmailColours?: Maybe<Scalars['Boolean']>;
  /** Song which will be used as hold music for the user. */
  preferredHoldMusic?: Maybe<Songs>;
};

/** Details of comms preferences set up for a user. */
export type AccountUserCommsPreferencesType = {
  __typename?: 'AccountUserCommsPreferencesType';
  /** Whether user has opted to receive company surveys. */
  isOptedInToCompanySurvey?: Maybe<Scalars['Boolean']>;
  /** Whether user has opted to receive generic marketing. */
  isOptedInToMarketing?: Maybe<Scalars['Boolean']>;
  /** Whether the user has opted to receive messages offering plumbing discounts or other services (home serve) not directly related to the services the client provides. */
  isOptedInToPlumbingOffer?: Maybe<Scalars['Boolean']>;
  /** Whether user has opted to receive regulatory surveys. */
  isOptedInToRegulatorySurvey?: Maybe<Scalars['Boolean']>;
  /** Whether the user has opted to receive SMS messages. */
  isOptedInToSms?: Maybe<Scalars['Boolean']>;
  /** Whether the user has opted to receive WhatsApp messages. */
  isOptedInToWhatsapp?: Maybe<Scalars['Boolean']>;
};

export type AccountUserDetailType = {
  __typename?: 'AccountUserDetailType';
  /** Namespace of the detail. */
  namespace: Scalars['String'];
  /** Value of the detail. */
  value?: Maybe<Scalars['String']>;
};

/** Names of an account user. */
export type AccountUserNameType = {
  __typename?: 'AccountUserNameType';
  /** The family name. */
  familyName?: Maybe<Scalars['String']>;
  /** The given name. */
  givenName?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum AccountUserRoleEnum {
  BulkOwnerDept = 'BULK_OWNER_DEPT',
  CollectionAgency = 'COLLECTION_AGENCY',
  CoPrimary = 'CO_PRIMARY',
  CustomerRepresentative = 'CUSTOMER_REPRESENTATIVE',
  ExternalAgency = 'EXTERNAL_AGENCY',
  Nominee = 'NOMINEE',
  PowerOfAttorney = 'POWER_OF_ATTORNEY',
  Primary = 'PRIMARY',
  ThirdParty = 'THIRD_PARTY',
}

/** The role a user has in association with one account. */
export type AccountUserRoleType = {
  __typename?: 'AccountUserRoleType';
  account: AccountType;
  id: Scalars['ID'];
  /** The account role. */
  role?: Maybe<AccountUserRoleEnum>;
  user: AccountUserType;
};

/** User objects are the core of the authentication system. They typically represent a customer who manages a portfolio of one or more accounts. */
export type AccountUserType = {
  __typename?: 'AccountUserType';
  /** List of roles a user has for each account they're linked to. */
  accountUserRoles?: Maybe<Array<Maybe<AccountUserRoleType>>>;
  /** List of accounts that the user is linked to either via portfolio role or account role. */
  accounts?: Maybe<Array<Maybe<AccountInterface>>>;
  /** List of alternative phone numbers for the account user. */
  alternativePhoneNumbers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Details of comms preferences set up for a user. */
  commsPreferences?: Maybe<AccountUserCommsPreferencesType>;
  /** AccountUser's date of birth. */
  dateOfBirth?: Maybe<Scalars['Date']>;
  /** List of details linked to this user. */
  details?: Maybe<Array<AccountUserDetailType>>;
  /**
   * We recommend you use fullName instead of this field.
   * @deprecated The 'displayName' field is deprecated.
   *
   * Please use fullName instead of this field.
   *
   * - Marked as deprecated on 2019-12-11.
   * - Will be removed on 2024-01-01.
   */
  displayName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  familyName: Scalars['String'];
  /**
   * We recommend you use preferredName or fullName instead of this field.
   * @deprecated The 'firstName' field is deprecated.
   *
   * Use 'givenName' instead.
   *
   * - Marked as deprecated on 2020-09-23.
   * - Will be removed on 2023-06-05.
   */
  firstName?: Maybe<Scalars['String']>;
  /** The user's full name. */
  fullName?: Maybe<Scalars['String']>;
  givenName: Scalars['String'];
  /** Whether there are family issues. */
  hasFamilyIssues?: Maybe<Scalars['Boolean']>;
  /** Whether the user has set a password. */
  hasSetPassword?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  /** Designates whether this user is deceased. */
  isDeceased: Scalars['Boolean'];
  /** True if user is linked to an account with an active hardship agreement. */
  isInHardship?: Maybe<Scalars['Boolean']>;
  landline: Scalars['String'];
  /**
   * The user's landline phone number.
   * @deprecated The 'landlinePhoneNumber' field is deprecated.
   *
   * Use 'landline' instead.
   *
   * - Marked as deprecated on 2021-03-22.
   * - Will be removed on 2024-01-01.
   */
  landlinePhoneNumber?: Maybe<Scalars['String']>;
  /**
   * We recommend you use preferredName or fullName instead of this field.
   * @deprecated The 'lastName' field is deprecated.
   *
   * Use 'familyName' instead.
   *
   * - Marked as deprecated on 2020-09-23.
   * - Will be removed on 2023-06-05.
   */
  lastName?: Maybe<Scalars['String']>;
  /** The user's secret key to access the Developer API. */
  liveSecretKey?: Maybe<Scalars['String']>;
  mobile: Scalars['String'];
  /**
   * We recommend you use portfolioIds instead of this field.
   * @deprecated The 'portfolioId' field is deprecated.
   *
   * Please use 'portfolioIds' instead.
   *
   * - Marked as deprecated on 2022-08-04.
   * - Will be removed on 2024-01-01.
   */
  portfolioId?: Maybe<Scalars['ID']>;
  /** List of portfolio ids that the user is linked to via their portfolio roles. */
  portfolioIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** List of roles a user has for each portfolio they're linked to. */
  portfolioUserRoles?: Maybe<Array<Maybe<PortfolioUserRoleType>>>;
  /** List of portfolios that the user is linked to via their portfolio roles. */
  portfolios?: Maybe<PortfolioConnectionTypeConnection>;
  preferences?: Maybe<AccountUserCommsPreferences>;
  /** The user's preferred name. */
  preferredName?: Maybe<Scalars['String']>;
  /** The user's pronouns e.g. 'she/her', 'he/him', 'they/them'. */
  pronouns?: Maybe<Scalars['String']>;
  /** Information about the special circumstances that relate to a user. */
  specialCircumstances?: Maybe<SpecialCircumstancesType>;
  title?: Maybe<Scalars['String']>;
};

/** User objects are the core of the authentication system. They typically represent a customer who manages a portfolio of one or more accounts. */
export type AccountUserTypeAccountUserRolesArgs = {
  accountNumber?: InputMaybe<Scalars['String']>;
};

/** User objects are the core of the authentication system. They typically represent a customer who manages a portfolio of one or more accounts. */
export type AccountUserTypeAccountsArgs = {
  allowedBrandCodes?: InputMaybe<Array<InputMaybe<BrandChoices>>>;
  excludeAccountTypes?: InputMaybe<Array<InputMaybe<AccountTypeChoices>>>;
  excludeAccountsWithoutAgreements?: InputMaybe<Scalars['Boolean']>;
  restrictToAccountNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  restrictToPublicFacingBrands?: InputMaybe<Scalars['Boolean']>;
};

/** User objects are the core of the authentication system. They typically represent a customer who manages a portfolio of one or more accounts. */
export type AccountUserTypePortfolioIdsArgs = {
  allowedBrandCodes?: InputMaybe<Array<InputMaybe<BrandChoices>>>;
  restrictToPublicFacingBrands?: InputMaybe<Scalars['Boolean']>;
};

/** User objects are the core of the authentication system. They typically represent a customer who manages a portfolio of one or more accounts. */
export type AccountUserTypePortfolioUserRolesArgs = {
  accountNumber?: InputMaybe<Scalars['String']>;
  portfolioNumber?: InputMaybe<Scalars['String']>;
};

/** User objects are the core of the authentication system. They typically represent a customer who manages a portfolio of one or more accounts. */
export type AccountUserTypePortfoliosArgs = {
  after?: InputMaybe<Scalars['String']>;
  allowedBrandCodes?: InputMaybe<Array<InputMaybe<BrandChoices>>>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  restrictToPublicFacingBrands?: InputMaybe<Scalars['Boolean']>;
};

/** Actions are events created by buttons and other interaction. */
export type ActionInterface = {
  /** The name of the action object's type. */
  typeName?: Maybe<Scalars['String']>;
};

/** Actions are things to do upon a user interaction, such as tapping a button. */
export type ActionType =
  | BackendScreenEventActionType
  | CloseActionType
  | DeeplinkActionType
  | LinkActionType
  | ScreenActionType;

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-4123: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type AddCampaignToAccount = {
  __typename?: 'AddCampaignToAccount';
  /** Whether the campaign was successfully added. */
  campaignAdded?: Maybe<Scalars['Boolean']>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type AddCampaignToAccountInput = {
  /** The account number. */
  accountNumber: Scalars['String'];
  /** The slug of the campaign we want to assign. */
  campaign: Scalars['String'];
};

export type AddressConnectionTypeConnection = {
  __typename?: 'AddressConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AddressConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `AddressConnectionType` and its cursor. */
export type AddressConnectionTypeEdge = {
  __typename?: 'AddressConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AddressType>;
};

export type AddressInput = {
  /** Two letter country code. */
  countryCode?: InputMaybe<Scalars['String']>;
  /** Delivery point identifier. */
  deliveryPointIdentifier?: InputMaybe<Scalars['String']>;
  /** Up to 5 address lines. */
  lines: Array<InputMaybe<Scalars['String']>>;
  /** Postcode. */
  postcode: Scalars['String'];
};

export type AddressType = {
  __typename?: 'AddressType';
  /** An address that matches the search string. */
  address?: Maybe<Scalars['String']>;
  /** Property ID of matching address. */
  propertyId?: Maybe<Scalars['ID']>;
};

/** Affiliate link for the organization. */
export type AffiliateLinkType = {
  __typename?: 'AffiliateLinkType';
  contactEmail: Scalars['String'];
  contactName: Scalars['String'];
  id: Scalars['ID'];
  isBusiness: Scalars['Boolean'];
  landingUrl: Scalars['String'];
  organisation?: Maybe<AffiliateOrganisationType>;
  subdomain: Scalars['String'];
  trainingStatus: LinkTrainingStatus;
};

export type AffiliateOrganisationType = {
  __typename?: 'AffiliateOrganisationType';
  /** Is this partner allowed to specify payment methods other than Direct Debit in the import csv or API */
  allowAlternativePaymentMethods?: Maybe<Scalars['Boolean']>;
  /** Are meter point registrations limited for profile classes 1 and 2 for registrations from csv or API */
  canRegisterBusinessMeterPoints?: Maybe<Scalars['Boolean']>;
  /** Allow registration requests with customers without an email address. */
  canRegisterCustomersWithoutEmailAddress?: Maybe<Scalars['Boolean']>;
  /** Allow registration requests with exiting account user emails to add to the portfolio belonging to the account user. */
  canRegisterPortfolioAccounts?: Maybe<Scalars['Boolean']>;
  /** Allow performing tariff renewals via API. */
  canRenewTariffs?: Maybe<Scalars['Boolean']>;
  /** Allow this partner access to the IVR support API (modify their own IVR handling through third party 'IVR Flow Editor') */
  canUseIvrSupportApi?: Maybe<Scalars['Boolean']>;
  /** Default Account Type */
  defaultAccountType?: Maybe<AccountTypeChoices>;
  id: Scalars['ID'];
  /** Restrict to field-sales-only products? This is only allowed for the 'field-sales' and 'events' sales channels */
  isFieldSalesOnlyProduct?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  /** Sales Channel */
  salesChannel?: Maybe<SalesChannelChoices>;
  /** Allow this partner to skip validation that ensures all meter points belong to the same address */
  skipMeterPointAddressValidation?: Maybe<Scalars['Boolean']>;
};

/** A tracked session for the affiliate link. */
export type AffiliateSessionType = {
  __typename?: 'AffiliateSessionType';
  id: Scalars['ID'];
  ipAddress?: Maybe<Scalars['String']>;
  link?: Maybe<AffiliateLinkType>;
  queryParams: Scalars['JSONString'];
  userAgent: Scalars['String'];
};

export enum Alignment {
  Center = 'CENTER',
  End = 'END',
  Start = 'START',
}

/**
 * Allow a repayment to be submitted.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-3944: Account repayment does not exist.
 * - KT-CT-3945: Unable to allow a repayment to be submitted.
 * - KT-CT-3950: The provided reason text is too long.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type AllowRepaymentSubmission = {
  __typename?: 'AllowRepaymentSubmission';
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** The repayment ID. */
  repaymentId?: Maybe<Scalars['ID']>;
  /** Resulting Repayment Intervention details. */
  repaymentIntervention?: Maybe<RepaymentInterventionType>;
};

/** Returned when an account already has a LINE account linked to it. */
export type AlreadyLinkedError = {
  __typename?: 'AlreadyLinkedError';
  /** The type of error that occurred. */
  type: LineLinkErrorType;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-3924: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type AmendPayment = {
  __typename?: 'AmendPayment';
  payment?: Maybe<AccountPaymentType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type AmendPaymentInput = {
  /** The account number. */
  accountNumber: Scalars['String'];
  /** The new amount for the amended payment. */
  amount: Scalars['Int'];
  /** The new date to collect the payment. */
  paymentDate: Scalars['Date'];
  /** The ID of the payment to amend. */
  paymentId: Scalars['Int'];
  /** Reason for amending the payment. */
  reason?: InputMaybe<Scalars['String']>;
};

/** A media element containing an animation, such as a Lottie. */
export type AnimationType = AccessibilityInterface &
  IdentifiableInterface &
  MediaInterface &
  SizedItemInterface & {
    __typename?: 'AnimationType';
    /** Whether the element is hidden from view. */
    accessibilityHidden?: Maybe<Scalars['Boolean']>;
    /** Accessible description of the element. */
    accessibilityLabel?: Maybe<Scalars['String']>;
    /** The horizontal alignment of the media. */
    horizontalAlignment?: Maybe<Alignment>;
    /** Unique identifier of the object. */
    id?: Maybe<Scalars['ID']>;
    /** The resource URL of the media. */
    mediaUrl: Scalars['String'];
    /** The name of the object's type. */
    typename?: Maybe<Scalars['String']>;
    /** The measurement of the element. */
    width?: Maybe<ItemSizeType>;
  };

export type AnnualStatementConnectionTypeConnection = {
  __typename?: 'AnnualStatementConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AnnualStatementConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `AnnualStatementConnectionType` and its cursor. */
export type AnnualStatementConnectionTypeEdge = {
  __typename?: 'AnnualStatementConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AnnualStatementType>;
};

/** Annual statements that are sent to the account. They summarize important information about usage and tariffs. */
export type AnnualStatementType = {
  __typename?: 'AnnualStatementType';
  id?: Maybe<Scalars['ID']>;
  pdfUrl?: Maybe<Scalars['String']>;
  periodEndsAt: Scalars['DateTime'];
  periodStartsAt: Scalars['DateTime'];
};

/** An annulment is a billing document that annuls another billing document. */
export type AnnulmentBillingDocumentType = {
  __typename?: 'AnnulmentBillingDocumentType';
  /** ID of the billing document annulled by this annulment. */
  annulledBillingDocumentId: Scalars['Int'];
  /** First time the annulment was issued. */
  firstIssued?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  /** URL to the PDF of the annulment. */
  pdfUrl?: Maybe<Scalars['String']>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-3934: Repayment request already approved.
 * - KT-CT-3935: Repayment request cannot be paid.
 * - KT-CT-3959: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type ApproveRepayment = {
  __typename?: 'ApproveRepayment';
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** The approved repayment. */
  repayment?: Maybe<AccountRepaymentType>;
};

export type ApproveRepaymentInput = {
  /** The account number for the requested repayment. */
  accountNumber: Scalars['ID'];
  /** The id of the account repayment to be approved. */
  repaymentId: Scalars['ID'];
};

export type AssignInkBucketInput = {
  /** The name of the bucket to assign the conversation to. */
  bucketName: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The relay id of the conversation that will be assigned to the bucket. */
  conversationRelayId: Scalars['ID'];
};

export type AssignInkBucketPayload = {
  __typename?: 'AssignInkBucketPayload';
  /** The bucket that the conversation will be assigned to. */
  bucket: InkBucket;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The conversation that will be assigned to the bucket. */
  conversation: InkConversation;
};

export type AssignedUserType = {
  __typename?: 'AssignedUserType';
  /** The ID of the assigned user. */
  id: Scalars['ID'];
  /** The username of the assigned user. */
  username: Scalars['String'];
};

/** A single Assistance Agreement. */
export type AssistanceAgreementType = {
  __typename?: 'AssistanceAgreementType';
  /** The start datetime of the agreement. */
  activeFrom?: Maybe<Scalars['Date']>;
  /** The end datetime of the agreement, if any. */
  activeTo?: Maybe<Scalars['Date']>;
  /** The type of assistance provided by the agreement. */
  assistanceType?: Maybe<Scalars['String']>;
};

/**
 * Returns an Action to perform, e.g. a screen to load.
 *
 * BackendScreenEvents are specific types of Action which trigger a mutation in the Kraken backend.
 * They return an action (any type), such as a ScreenActionType (which is then used to load the next screen).
 * Any action registered in the registry should really be an "event" with some side-effect in the backend.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-8002: No event found.
 * - KT-CT-8003: Event has no execute function.
 * - KT-CT-8004: Error executing event in the backend.
 * - KT-CT-8007: Incorrect or missing parameters for backend screen event.
 * - KT-GB-9310: Account ineligible for joining Octoplus.
 * - KT-CT-1113: Disabled GraphQL field requested.
 *
 */
export type BackendScreenEvent = {
  __typename?: 'BackendScreenEvent';
  /** An action to perform. */
  action?: Maybe<ActionType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** An action which triggers some event in the Kraken backend. */
export type BackendScreenEventActionType = ActionInterface &
  IdentifiableInterface & {
    __typename?: 'BackendScreenEventActionType';
    /** The ID of the event to trigger. */
    eventId: Scalars['String'];
    /** Unique identifier of the object. */
    id?: Maybe<Scalars['ID']>;
    /** List of key-value pairs to pass as parameters to the event. */
    params: Array<Maybe<BackendScreenParam>>;
    /** The name of the action object's type. */
    typeName?: Maybe<Scalars['String']>;
    /** The name of the object's type. */
    typename?: Maybe<Scalars['String']>;
  };

/** Input for a backend action. */
export type BackendScreenEventInput = {
  /** The ID of the action to perform. */
  eventId: Scalars['ID'];
  /** List of key-value pairs (strings) to pass as parameters to the mutation. */
  params?: InputMaybe<Array<InputMaybe<BackendScreenParamInputType>>>;
};

/** A backend screen is the top-level container for mobile UI. */
export type BackendScreenInterface = {
  /** The name of the screen. */
  name: Scalars['String'];
  /** The refresh / polling frequency in milliseconds. */
  refreshFrequency?: Maybe<Scalars['Int']>;
  /** Serialized JSON representation of the screen. */
  screenData?: Maybe<Scalars['String']>;
};

/** A key-value pair (both Strings) which is passed as a parameter to a screen. */
export type BackendScreenParam = {
  __typename?: 'BackendScreenParam';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** A key-value pair (both Strings) which is passed in parameters to a backend action. */
export type BackendScreenParamInputType = {
  key: Scalars['String'];
  value: Scalars['String'];
};

/** A backend screen is the top-level container for mobile UI. */
export type BackendScreenType =
  | ComponentListType
  | Dashboard
  | GenericBackendScreen;

export type BankDetailsInput = {
  accountHolder?: InputMaybe<Scalars['String']>;
  accountNumber?: InputMaybe<Scalars['String']>;
  accountType?: InputMaybe<Scalars['String']>;
  bankCode?: InputMaybe<Scalars['String']>;
  branchCode?: InputMaybe<Scalars['String']>;
  iban?: InputMaybe<Scalars['String']>;
};

/**
 *
 *
 * This field is a connection type. Connections are used to implement [cursor based pagination](https://graphql.org/learn/pagination/#pagination-and-edges).
 *
 *
 */
export type BillConnectionTypeConnection = {
  __typename?: 'BillConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BillConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `BillConnectionType` and its cursor. */
export type BillConnectionTypeEdge = {
  __typename?: 'BillConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<BillInterface>;
};

export type BillInterface = {
  billType?: Maybe<BillTypeEnum>;
  fromDate?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['ID']>;
  /** The date the bill was sent to the customer. */
  issuedDate?: Maybe<Scalars['Date']>;
  /**
   * Requesting this field generates a temporary URL at which bill is available.
   *         This URL will expire after approximately an hour.  It is intended for redirection purposes,
   *         NOT persistence in any form (e.g. inclusion in emails or the body of a web page).
   *         This field can raise an error with errorClass NOT_FOUND if the bill document has not
   *         been created/issued yet.
   *
   *
   *
   */
  temporaryUrl?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['Date']>;
};

export enum BillTypeEnum {
  CreditNote = 'CREDIT_NOTE',
  Invoice = 'INVOICE',
  PreKraken = 'PRE_KRAKEN',
  Statement = 'STATEMENT',
}

export type BillingAddressDetailsInput = {
  /** Administrative area. */
  administrativeArea?: InputMaybe<Scalars['String']>;
  /** Billing country. */
  country?: InputMaybe<Scalars['String']>;
  /** Billing delivery point identifier. */
  deliveryPointIdentifier?: InputMaybe<Scalars['String']>;
  /** Billing dependent locality. */
  dependentLocality?: InputMaybe<Scalars['String']>;
  /** Billing locality. */
  locality?: InputMaybe<Scalars['String']>;
  /** Billing postal code. */
  postalCode?: InputMaybe<Scalars['String']>;
  /** Billing sorting code. */
  sortingCode?: InputMaybe<Scalars['String']>;
  /** Billing street address. */
  streetAddress?: InputMaybe<Scalars['String']>;
  /** Billing structured street address. */
  structuredStreetAddress?: InputMaybe<Scalars['GenericScalar']>;
};

/** Information about an account's billing schedule. */
export type BillingOptionsType = {
  __typename?: 'BillingOptionsType';
  /** The date on which the current billing cycle will end. Null if the account is on flexible billing. */
  currentBillingPeriodEndDate?: Maybe<Scalars['Date']>;
  /** The date on which the current billing cycle started. */
  currentBillingPeriodStartDate?: Maybe<Scalars['Date']>;
  /** If true, this account is billed on specific day of a regular cycle. If false, the billing schedule is flexible, depending on when meter readings are submitted. */
  isFixed: Scalars['Boolean'];
  /** The next date on which this account will next be billed. This is the same as the start date for their next bill cycle. Null if the account is on flexible billing. */
  nextBillingDate?: Maybe<Scalars['Date']>;
  periodLength?: Maybe<AccountBillingOptionsPeriodLength>;
  periodLengthMultiplier?: Maybe<Scalars['Int']>;
  /** The day of the month on which the account's billing period should start. */
  periodStartDay?: Maybe<Scalars['Int']>;
};

export enum BillsOrderBy {
  FromDateDesc = 'FROM_DATE_DESC',
  IssuedDateDesc = 'ISSUED_DATE_DESC',
}

/** Details of an account of an account user updating their blackhole email. */
export type BlackholeEmailAccountType = {
  __typename?: 'BlackholeEmailAccountType';
  /** The names of each active account user associated with the account. */
  accountUserNames?: Maybe<Array<Maybe<AccountUserNameType>>>;
  /** The billing address. */
  address?: Maybe<Scalars['String']>;
  /** The account number. */
  number?: Maybe<Scalars['String']>;
};

/** Details of an account of an account user updating their blackhole email. */
export type BlackholeEmailAccountTypeAccountUserNamesArgs = {
  familyName?: InputMaybe<Scalars['String']>;
};

/** Details of an account user updating their blackhole email. */
export type BlackholeEmailAccountUserType = {
  __typename?: 'BlackholeEmailAccountUserType';
  /** List of accounts that the user is linked to either via portfolio role or account role. */
  accounts?: Maybe<Array<Maybe<BlackholeEmailAccountType>>>;
  /** The email address. */
  email: Scalars['String'];
  /** Family name. */
  familyName: Scalars['String'];
  /** Full name. */
  fullName: Scalars['String'];
  /** Given name. */
  givenName: Scalars['String'];
  /** Whether the user has a date of birth. */
  hasDateOfBirth: Scalars['Boolean'];
  /** Whether the user has a mobile number. */
  hasMobileNumber: Scalars['Boolean'];
  /** Whether the user has set a password. */
  hasSetPassword: Scalars['Boolean'];
  /** The user ID. */
  userId: Scalars['ID'];
};

/** Details of an account user updating their blackhole email. */
export type BlackholeEmailAccountUserTypeAccountsArgs = {
  restrictToAccountNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/**
 * Block a repayment from being submitted.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-3944: Account repayment does not exist.
 * - KT-CT-3946: Unable to block a repayment from being submitted.
 * - KT-CT-3950: The provided reason text is too long.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type BlockRepaymentSubmission = {
  __typename?: 'BlockRepaymentSubmission';
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** The repayment ID. */
  repaymentId?: Maybe<Scalars['ID']>;
  /** Resulting Repayment Intervention details. */
  repaymentIntervention?: Maybe<RepaymentInterventionType>;
};

/** An enumeration. */
export enum BrandChoices {
  /** Severn Trent Hafren Dyfrdwy. */
  SevernTrentHafrenDyfrdwy = 'SEVERN_TRENT_HAFREN_DYFRDWY',
  /** Severn Trent Hafren Dyfrdwy Nhh. */
  SevernTrentHafrenDyfrdwyNhh = 'SEVERN_TRENT_HAFREN_DYFRDWY_NHH',
  /** Severn Trent Nhh. */
  SevernTrentNhh = 'SEVERN_TRENT_NHH',
  /** Severn Trent Water. */
  SevernTrentWater = 'SEVERN_TRENT_WATER',
}

export type BusinessType = {
  __typename?: 'BusinessType';
  /** The company type of a business account. */
  businessType?: Maybe<BusinessTypeOptions>;
  name: Scalars['String'];
  number: Scalars['String'];
};

/** An enumeration. */
export enum BusinessTypeOptions {
  /** A business account where the company type is charity. */
  Charity = 'CHARITY',
  /** A business account for a church or other religious organisation. */
  Church = 'CHURCH',
  /** A business account for a government institution. */
  Government = 'GOVERNMENT',
  /** A business account where the company type is limited. */
  Limited = 'LIMITED',
  /** A business account where the company type is limited liability partnership. */
  LimitedLiabilityPartnership = 'LIMITED_LIABILITY_PARTNERSHIP',
  /** A business account for a non-profit organisation. */
  NonProfit = 'NON_PROFIT',
  /** A business account where the company type is partnership. */
  Partnership = 'PARTNERSHIP',
  /** A business account where the company type is proprietary limited. */
  ProprietaryLimitedCompany = 'PROPRIETARY_LIMITED_COMPANY',
  /** A business account where the company type is public limited. */
  PublicLimitedCompany = 'PUBLIC_LIMITED_COMPANY',
  /** A business account where the company type is sole trader. */
  SoleTrader = 'SOLE_TRADER',
  /** A business account where the company has a trading name to carry out its business activities. */
  TradingAs = 'TRADING_AS',
  /** A business account where the company type is a trust. */
  Trust = 'TRUST',
}

/** The button interface. */
export type ButtonInterface = {
  /** The action to perform when the button is pressed. */
  buttonAction: ActionType;
  /** The button style. */
  buttonStyle?: Maybe<ButtonStyle>;
  /** Title text of the button. */
  title: Scalars['String'];
};

export enum ButtonStyle {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
}

export enum ButtonVariance {
  Filled = 'FILLED',
  Outlined = 'OUTLINED',
  TextOnly = 'TEXT_ONLY',
}

/** Input required to cancel a LeaveSupplier journey. */
export type CancelLeaveSupplierInput = {
  /** The ID of the LeaveSupplier process to cancel. */
  leaveSupplierProcessId: Scalars['ID'];
  /** The reason for the cancellation. */
  reason?: InputMaybe<Scalars['String']>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-3924: Unauthorized.
 * - KT-CT-3954: Payment cancellation failed.
 * - KT-CT-3955: Payment cannot be cancelled.
 * - KT-CT-3956: Temporary error occurred.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CancelPayment = {
  __typename?: 'CancelPayment';
  /** The cancelled payment. */
  payment?: Maybe<AccountPaymentType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CancelPaymentInput = {
  /** The account number. */
  accountNumber: Scalars['ID'];
  /** The ID of the payment to cancel. */
  paymentId: Scalars['ID'];
  /** Reason for cancelling the payment. */
  reason?: InputMaybe<Scalars['String']>;
};

/**
 * Cancel a repayment or refund request.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4231: Unauthorized.
 * - KT-CT-3930: The repayment or refund request does not exist.
 * - KT-CT-3931: This repayment or refund request cannot be cancelled.
 * - KT-CT-1113: Disabled GraphQL field requested.
 *
 */
export type CancelRepaymentRequest = {
  __typename?: 'CancelRepaymentRequest';
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** The cancelled repayment/refund request. */
  repaymentRequest?: Maybe<CancelRepaymentRequestOutputType>;
};

export type CancelRepaymentRequestInputType = {
  /** The id of the request to be cancelled. */
  requestId: Scalars['String'];
};

/** Output from cancelling a repayment or refund request. */
export type CancelRepaymentRequestOutputType = {
  __typename?: 'CancelRepaymentRequestOutputType';
  /** The ID of the cancelled request. */
  requestId?: Maybe<Scalars['String']>;
  /** The current status of the cancelled request. */
  status?: Maybe<RepaymentRequestStatus>;
};

/** A card containing a list of items */
export type CardComponentType = AccessibilityInterface &
  IdentifiableInterface & {
    __typename?: 'CardComponentType';
    /** Whether the element is hidden from view. */
    accessibilityHidden?: Maybe<Scalars['Boolean']>;
    /** Accessible description of the element. */
    accessibilityLabel?: Maybe<Scalars['String']>;
    /** Unique identifier of the object. */
    id?: Maybe<Scalars['ID']>;
    /** The list of components. */
    items: Array<Maybe<CardItemType>>;
    /** The name of the object's type. */
    typename?: Maybe<Scalars['String']>;
  };

/** Items are sections making up a card. */
export type CardItemType =
  | AnimationType
  | ImageType
  | PillButtonType
  | RectangularButtonType
  | TextType;

export type CarouselItemType = IdentifiableInterface & {
  __typename?: 'CarouselItemType';
  /** The list of cards. */
  cards: Array<Maybe<CardComponentType>>;
  /** Unique identifier of the object. */
  id?: Maybe<Scalars['ID']>;
  /** The name of the object's type. */
  typename?: Maybe<Scalars['String']>;
};

/** A charge to the customer. */
export type Charge = TransactionType & {
  __typename?: 'Charge';
  /** Unique identifier of the account the transaction belongs to. */
  accountNumber?: Maybe<Scalars['String']>;
  /**
   * Gross amount including tax (when payable). Refer to the `amounts` field for a breakdown of this information.
   * @deprecated The 'amount' field is deprecated.
   *
   * Use `amounts` instead for a breakdown of the relevant net, tax, and gross amounts.
   *
   * - Marked as deprecated on 2023-12-06.
   * - Will be removed on 2024-06-01.
   */
  amount?: Maybe<Scalars['Int']>;
  /** The net, tax and gross amounts for the transaction. Note: for payments and repayments, only the net amount is returned. */
  amounts?: Maybe<TransactionAmountType>;
  /** The customer's resulting balance after this transaction has been applied, in the smallest unit of currency. */
  balanceCarriedForward?: Maybe<Scalars['Int']>;
  /** The unique identifier for the most recent billing document linked with the transaction.Note: a transaction may be linked with multiple documents, but this field will only return the identifier for the most recent billing document. */
  billingDocumentIdentifier?: Maybe<Scalars['ID']>;
  /** If this charge is for consumption of a good or service, this field will contain details of how much was consumed. Omitted in cases where the charge is not for consumption, or where consumption information is not available (e.g. for some older records). */
  consumption?: Maybe<Consumption>;
  /** The date time when the transaction is created. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Returns True if the transaction is linked with a statement. */
  hasStatement?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  /**
   * Deprecated.
   * @deprecated The 'isAccountCharge' field is deprecated.
   *
   * This information is provided by the __typename introspection query.
   *
   * - Marked as deprecated on 2020-06-19.
   * - Will be removed on 2022-11-15.
   */
  isAccountCharge?: Maybe<Scalars['Boolean']>;
  /**
   * Deprecated.
   * @deprecated The 'isAccountPayment' field is deprecated.
   *
   * This information is provided by the __typename introspection query.
   *
   * - Marked as deprecated on 2020-06-19.
   * - Will be removed on 2022-11-15.
   */
  isAccountPayment?: Maybe<Scalars['Boolean']>;
  /**
   * Deprecated.
   * @deprecated The 'isCredit' field is deprecated.
   *
   * This information is provided by the __typename introspection query.
   *
   * - Marked as deprecated on 2020-06-19.
   * - Will be removed on 2022-11-15.
   */
  isCredit?: Maybe<Scalars['Boolean']>;
  /** Whether the statement this transaction is on has been held. A held statement is not sent to a customer automatically, but is instead marked for manual attention by operations staff.  Returns False if a statement is not linked with the transaction. */
  isHeld?: Maybe<Scalars['Boolean']>;
  /** Whether this transaction has been issued on any billing document.Note: Look for the most recently issued transaction instead of looking through all transactions as some accounts may have initial transactions that were not issued.This will return False if the transaction is not associated with any billing documents. */
  isIssued?: Maybe<Scalars['Boolean']>;
  isReversed: Scalars['Boolean'];
  /** Returns the note field value for the transaction, which contains additional info. */
  note?: Maybe<Scalars['String']>;
  postedDate?: Maybe<Scalars['Date']>;
  /**
   * Returns None if a statement is not linked with the transaction.
   * @deprecated The 'statementId' field is deprecated.
   *
   * Use `billingDocumentIdentifier` instead.
   *
   * - Marked as deprecated on 2023-11-30.
   * - Will be removed on 2024-06-01.
   */
  statementId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export type ChargeReasonType = {
  __typename?: 'ChargeReasonType';
  /** The charge reason code. */
  code?: Maybe<Scalars['String']>;
  /** The charge reason display text. */
  display?: Maybe<Scalars['String']>;
  /** The group the charge reason belongs to (if applicable). */
  group?: Maybe<Scalars['String']>;
  /** Whether the charge reason is deprecated. */
  isDeprecated?: Maybe<Scalars['Boolean']>;
  /** Whether the charge reason is hidden. */
  isHidden?: Maybe<Scalars['Boolean']>;
  /** Whether the charge reason is sales tax exempt. */
  isTaxExempt?: Maybe<Scalars['Boolean']>;
};

/** Closes the screen. */
export type CloseActionType = AccessibilityInterface &
  ActionInterface &
  IdentifiableInterface & {
    __typename?: 'CloseActionType';
    /** Whether the element is hidden from view. */
    accessibilityHidden?: Maybe<Scalars['Boolean']>;
    /** Accessible description of the element. */
    accessibilityLabel?: Maybe<Scalars['String']>;
    /** Unique identifier of the object. */
    id?: Maybe<Scalars['ID']>;
    /** The name of the action object's type. */
    typeName?: Maybe<Scalars['String']>;
    /** The name of the object's type. */
    typename?: Maybe<Scalars['String']>;
  };

/**
 * Close the Open Print Batch if any.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-9010: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CloseOpenPrintBatch = {
  __typename?: 'CloseOpenPrintBatch';
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  printBatch?: Maybe<PrintBatchType>;
};

/**
 * Collect deposit for the given account.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4177: Unauthorized.
 * - KT-CT-5711: No collection is required.
 * - KT-CT-5712: Deposit agreement does not exist or has not been accepted.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CollectDeposit = {
  __typename?: 'CollectDeposit';
  payment?: Maybe<CollectDepositOutput>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CollectDepositInput = {
  accountNumber: Scalars['String'];
  depositKey: Scalars['String'];
  idempotencyKey: Scalars['String'];
};

export type CollectDepositOutput = {
  __typename?: 'CollectDepositOutput';
  paymentDate?: Maybe<Scalars['Date']>;
  status?: Maybe<CollectDepositStatusChoices>;
};

export enum CollectDepositStatusChoices {
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Cleared = 'CLEARED',
  Failed = 'FAILED',
  HeldForReview = 'HELD_FOR_REVIEW',
  None = 'NONE',
  Pending = 'PENDING',
  Requested = 'REQUESTED',
  Scheduled = 'SCHEDULED',
}

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-3932: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CollectPayment = {
  __typename?: 'CollectPayment';
  /** Details about the collected payment. Note that we might not be able to collect the payment (e.g. if there is no usable payment instruction), in which case the status of the returned payment might be failed or cancelled. */
  payment?: Maybe<AccountPaymentType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CollectPaymentInput = {
  /** The account number. */
  accountNumber: Scalars['String'];
  /** The payment amount (in pence). */
  amount: Scalars['Int'];
  /** The type of the payment instruction. */
  collectionMethod?: InputMaybe<PaymentType>;
  /** The reason a payment is being collected, for internal audit purposes */
  description: Scalars['String'];
  idempotencyKey: Scalars['String'];
  /** The ID of the ledger on which to collect the payment. */
  ledgerId: Scalars['ID'];
  /** The date to attempt to take the payment. Cannot be a date in the past. Payment will be collected on the requested date or as soon as possible after that date. */
  paymentDate: Scalars['Date'];
};

export enum CollectionCampaignType {
  None = 'NONE',
}

export enum CollectionMethod {
  Card = 'CARD',
  DirectDebit = 'DIRECT_DEBIT',
}

export type CombinedRateLimitInformation = {
  __typename?: 'CombinedRateLimitInformation';
  /** Information about points-allowance rate limit for viewer. */
  pointsAllowanceRateLimit?: Maybe<PointsAllowanceRateLimitInformation>;
};

export type CommonSupplyPointInterface = {
  /** The external identifier of the supply point. */
  externalIdentifier?: Maybe<Scalars['String']>;
  /** The ID of the supply point. */
  id: Scalars['ID'];
  /** The market this supply point belongs to. */
  marketName: Scalars['String'];
};

/** The method the account has specified they prefer we contact them */
export enum CommsDeliveryPreference {
  Email = 'EMAIL',
  PostalMail = 'POSTAL_MAIL',
}

/**
 * A list of components which comprise a screen.
 *
 * This is a legacy type; GenericBackendScreen should be preferred. This is because API clients
 * should not need to explicitly query for screen attributes like `items` - these fields are
 * embedded in the screenData field.
 */
export type ComponentListType = AccessibilityInterface &
  BackendScreenInterface &
  IdentifiableInterface & {
    __typename?: 'ComponentListType';
    /** Whether the element is hidden from view. */
    accessibilityHidden?: Maybe<Scalars['Boolean']>;
    /** Accessible description of the element. */
    accessibilityLabel?: Maybe<Scalars['String']>;
    /** Unique identifier of the object. */
    id?: Maybe<Scalars['ID']>;
    /**
     * The list of components.
     * @deprecated The 'items' field is deprecated.
     *
     * Access `items` via `screenData` instead.
     *
     * - Marked as deprecated on 2024-07-02.
     * - Will be removed on 2024-08-01.
     */
    items: Array<Maybe<ItemType>>;
    /** The name of the screen. */
    name: Scalars['String'];
    /** The refresh / polling frequency in milliseconds. */
    refreshFrequency?: Maybe<Scalars['Int']>;
    /** Serialized JSON representation of the screen. */
    screenData?: Maybe<Scalars['String']>;
    /** The name of the object's type. */
    typename?: Maybe<Scalars['String']>;
  };

export type Consumption = {
  __typename?: 'Consumption';
  endDate?: Maybe<Scalars['Date']>;
  quantity?: Maybe<Scalars['Decimal']>;
  startDate?: Maybe<Scalars['Date']>;
  supplyCharge?: Maybe<Scalars['Int']>;
  unit?: Maybe<ConsumptionUnit>;
  usageCost?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum ConsumptionUnit {
  Mj = 'MJ',
  KWh = 'kWh',
}

/** A single Contribution Agreement. */
export type ContributionAgreementType = {
  __typename?: 'ContributionAgreementType';
  /** The start datetime of the agreement. */
  activeFrom?: Maybe<Scalars['DateTime']>;
  /** The end datetime of the agreement, if any. */
  activeTo?: Maybe<Scalars['DateTime']>;
  /** The amount contributed per interval. Note, this is in the smallest domination that the currecy support. e.g. Pence, Cents, Yen, etc. */
  amount?: Maybe<Scalars['Int']>;
  /** The scheme to which the agreement contributes. */
  contributionScheme?: Maybe<ContributionSchemeType>;
  id: Scalars['ID'];
  /** The frequency of contributions. */
  interval?: Maybe<Interval>;
  /** The periods over which contributions have been made. */
  periods?: Maybe<ContributionPeriodConnection>;
};

/** A single Contribution Agreement. */
export type ContributionAgreementTypePeriodsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** Pagination for contribution periods. */
export type ContributionPeriodConnection = {
  __typename?: 'ContributionPeriodConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ContributionPeriodEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `ContributionPeriod` and its cursor. */
export type ContributionPeriodEdge = {
  __typename?: 'ContributionPeriodEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ContributionPeriodType>;
};

/** A single Contribution Period. */
export type ContributionPeriodType = {
  __typename?: 'ContributionPeriodType';
  agreement: ContributionAgreementType;
  /** When the contribution was fulfilled */
  fulfilledAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** The datetime the marks the beginning of the period. */
  periodFrom?: Maybe<Scalars['DateTime']>;
  /** The datetime the marks the end of the period. */
  periodTo?: Maybe<Scalars['DateTime']>;
};

/** A single Contribution Scheme. */
export type ContributionSchemeType = {
  __typename?: 'ContributionSchemeType';
  /** Is this scheme currently accepting contributions? */
  acceptingContributions: Scalars['Boolean'];
  code: Scalars['String'];
  displayName: Scalars['String'];
  id: Scalars['ID'];
  /** Are contributions to this scheme taxable? */
  taxable: Scalars['Boolean'];
};

export type CoordinatesType = {
  __typename?: 'CoordinatesType';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-7803: Received an invalid apiExceptionId.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateApiCall = {
  __typename?: 'CreateAPICall';
  /** The created APICall. */
  apiCall?: Maybe<ApiCallType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateApiCallInput = {
  /** The ID of the associated API exception, if any. */
  apiExceptionId?: InputMaybe<Scalars['Int']>;
  /** Any optional useful context involved in the API call. */
  context?: InputMaybe<Scalars['JSONString']>;
  /** The correlation id header from the HTTP request. */
  correlationId: Scalars['String'];
  /** The input data provided to the API, if any. */
  inputData?: InputMaybe<Scalars['JSONString']>;
  /** The name of the operation associated with this call. */
  operationName: Scalars['String'];
  /** The response returned by the API. */
  response: Scalars['JSONString'];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-7801: Received an invalid operationsTeamId.
 * - KT-CT-7802: The external identifier already exists.
 * - KT-CT-7805: Too many tags associated with this API Exception.
 * - KT-CT-7806: Cannot create duplicate tags for the same API exception.
 * - KT-CT-7811: Received an invalid assignedUserId.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateApiException = {
  __typename?: 'CreateAPIException';
  /** The created APIException. */
  apiException?: Maybe<ApiExceptionType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-7803: Received an invalid apiExceptionId.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateApiExceptionEvent = {
  __typename?: 'CreateAPIExceptionEvent';
  /** The created APIExceptionEvent. */
  apiExceptionEvent?: Maybe<ApiExceptionEventType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateApiExceptionEventInput = {
  /** The ID of the associated API exception, if any. */
  apiExceptionId?: InputMaybe<Scalars['Int']>;
  /** The event category. */
  category: Scalars['String'];
  /** Any optional useful context involved in the event. */
  context?: InputMaybe<Scalars['JSONString']>;
  /** Any useful event description. */
  description: Scalars['String'];
  /** The event type. */
  eventType: Scalars['String'];
};

export type CreateApiExceptionInput = {
  /** The account number associated with the exception, if available. */
  accountNumber?: InputMaybe<Scalars['ID']>;
  /** The ID of the user assigned to handle this exception.If no user is provided, no user will be assigned to the exception. */
  assignedUserId?: InputMaybe<Scalars['Int']>;
  /** Category associated with this exception. Uses the default category if not provided. */
  category?: InputMaybe<ApiExceptionCategories>;
  /** The API client channel where the exception was triggered from. */
  channel: Scalars['String'];
  /** Contextual information about the exception, if any. */
  context?: InputMaybe<Scalars['JSONString']>;
  /** The customer contact associated with the exception, if available. */
  customerContact?: InputMaybe<Scalars['String']>;
  /** External identifier mapping an entity on the client's database. */
  externalIdentifier: Scalars['String'];
  /** The key date associated with the exception, if available. */
  keyDate?: InputMaybe<Scalars['Date']>;
  /** The ID of an operations team to handle this exception. If no team is provided, no team will be assigned to the exception. */
  operationsTeamId?: InputMaybe<Scalars['Int']>;
  /** The priority. Defaults to LOW if not provided. */
  priority?: InputMaybe<ApiExceptionPriority>;
  /** The resolution status. Defaults to UNASSIGNED if not provided. */
  resolutionStatus?: InputMaybe<ApiExceptionResolutionStatus>;
  /** The resolution type. Defaults to UNASSIGNED if not provided. */
  resolutionType?: InputMaybe<ApiExceptionResolutionType>;
  /** The supply point identifier associated with the exception, if available. */
  supplyPointIdentifier?: InputMaybe<Scalars['String']>;
  /** Tags associated with this exception if any. */
  tags?: InputMaybe<Array<InputMaybe<ApiExceptionTags>>>;
  /** The user ID associated with the exception, if available. */
  userId?: InputMaybe<Scalars['Int']>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-7803: Received an invalid apiExceptionId.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateApiExceptionNote = {
  __typename?: 'CreateAPIExceptionNote';
  /** The created APIExceptionNote. */
  apiException?: Maybe<ApiExceptionType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateApiExceptionNoteInput = {
  /** The ID of the associated API exception. */
  apiExceptionId: Scalars['ID'];
  /** The body of the note. */
  body: Scalars['String'];
};

/**
 * Add charges to an account.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-5211: The charge reason with the requested code is deprecated.
 * - KT-CT-5212: The charge reason with the requested code does not exist.
 * - KT-CT-5213: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateAccountCharge = {
  __typename?: 'CreateAccountCharge';
  accountCharge?: Maybe<AccountChargeType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** The input type for the account charge. */
export type CreateAccountChargeInput = {
  /** The account number. */
  accountNumber: Scalars['String'];
  /** Optional short note about account charge for customer display. */
  displayNote?: InputMaybe<Scalars['String']>;
  /** The gross amount of the charge to be added. */
  grossAmount: Scalars['Int'];
  /** Any extra data that will be associated with account charge. */
  metadata?: InputMaybe<Scalars['JSONString']>;
  /** Optional short note about account charge for internal use. */
  note?: InputMaybe<Scalars['String']>;
  /** The reason why the charge is added to the account. This should be a valid charge reason code. */
  reason: Scalars['String'];
};

/**
 * Add credits to an account.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-5315: Invalid data.
 * - KT-CT-5314: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateAccountCredit = {
  __typename?: 'CreateAccountCredit';
  accountCredit?: Maybe<AccountCreditType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** The input type for the account credit. */
export type CreateAccountCreditInput = {
  /** The account number. */
  accountNumber: Scalars['String'];
  /** The gross amount of the credit to be created. */
  grossAmount: Scalars['Int'];
  /** Any extra data that will be associated with account credit. */
  metadata?: InputMaybe<Scalars['JSONString']>;
  /** The net amount of the credit to be created. */
  netAmount: Scalars['Int'];
  /** Optional short note about account credit. */
  note?: InputMaybe<Scalars['String']>;
  /** The reason why the credit is added to the account. */
  reason: AccountCreditReasonType;
  /** The sales tax amount of the credit to be created. */
  salesTaxAmount: Scalars['Int'];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-4123: Unauthorized.
 * - KT-CT-4180: Account note must be a valid string.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateAccountNote = {
  __typename?: 'CreateAccountNote';
  /** Account, which has the added note. */
  account?: Maybe<AccountType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateAccountNoteInput = {
  /** The account number. */
  accountNumber: Scalars['String'];
  /** Pin the note to account. */
  isPinned: Scalars['Boolean'];
  /** The note to add. */
  note: Scalars['String'];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-3815: No active payment schedule found for this account.
 * - KT-CT-3822: Unauthorized.
 * - KT-CT-3923: Unauthorized.
 * - KT-CT-3941: Invalid data.
 * - KT-CT-3942: An unexpected error occurred.
 * - KT-CT-3947: An unexpected error occurred.
 * - KT-CT-3960: Invalid value for payment day.
 * - KT-CT-3961: Cannot update plan-associated payment schedule.
 * - KT-CT-3962: No new value provided to update payment schedule.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateAccountPaymentSchedule = {
  __typename?: 'CreateAccountPaymentSchedule';
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** New payment schedule. */
  schedule: PaymentScheduleType;
};

/** Input type for updating the payment schedule on a ledger. Requires an `account_number`, `ledger_id` and one of `payment_day` or `payment_amount` to be provided. */
export type CreateAccountPaymentScheduleInput = {
  /** Number of the account for which to update the schedule. */
  accountNumber: Scalars['String'];
  /** Number of the ledger associated with the current payment schedule. */
  ledgerNumber: Scalars['String'];
  /** The new fixed payment amount. */
  paymentAmount?: InputMaybe<Scalars['Int']>;
  /** The new day of the month at which to take payment; ranges from 1 to 28. */
  paymentDay?: InputMaybe<Scalars['Int']>;
};

/**
 * Create an account reference.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4123: Unauthorized.
 * - KT-CT-8310: Invalid data.
 * - KT-CT-8311: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateAccountReference = {
  __typename?: 'CreateAccountReference';
  accountReference?: Maybe<AccountReferenceType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-1401: Invalid data.
 * - KT-CT-1402: Unable to create account reminder.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateAccountReminder = {
  __typename?: 'CreateAccountReminder';
  /** Account reminder. */
  accountReminder?: Maybe<AccountReminder>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateAccountReminderInput = {
  /** The account number. */
  accountNumber: Scalars['String'];
  /** Reminder content. */
  content: Scalars['String'];
  /** When the reminder is due. */
  dueAt: Scalars['DateTime'];
  /** The reminder type. */
  reminderType: AccountReminderTypes;
};

/**
 * Create an affiliate link for a new sales agent.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-7711: Invalid data.
 * - KT-CT-7713: Invalid data.
 * - KT-CT-7714: Invalid data.
 * - KT-CT-7715: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateAffiliateLink = {
  __typename?: 'CreateAffiliateLink';
  affiliateLink?: Maybe<AffiliateLinkType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateAffiliateLinkInputType = {
  contactEmail: Scalars['String'];
  contactName: Scalars['String'];
  /** The organisation for whom to create the affiliate link for. */
  organisationId: Scalars['ID'];
  /**
   *
   * Will be validated as follows:
   *
   * - should be at least two characters
   * - should only contain (letters, numbers, and Hyphen)
   * - should not contain bad words
   * - should not contain any of the reserved words including:
   *  affiliates, api, business, click, consul, developer, friends, kraken, mail, sendgrid, tech, webhooks, www, www2
   */
  subdomain: Scalars['String'];
};

/**
 * Create an affiliate organisation.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-7716: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateAffiliateOrganisation = {
  __typename?: 'CreateAffiliateOrganisation';
  affiliateOrganisation?: Maybe<AffiliateOrganisationType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateAffiliateOrganisationInputType = {
  /** Is this partner allowed to specify payment methods other than Direct Debit in the import csv or API */
  allowAlternativePaymentMethods?: InputMaybe<Scalars['Boolean']>;
  /** Are meter point registrations limited for profile classes 1 and 2 for registrations from csv or API */
  canRegisterBusinessMeterPoints?: InputMaybe<Scalars['Boolean']>;
  /** Allow registration requests with customers without an email address. */
  canRegisterCustomersWithoutEmailAddress?: InputMaybe<Scalars['Boolean']>;
  /** Allow registration requests with exiting account user emails to add to the portfolio belonging to the account user. */
  canRegisterPortfolioAccounts?: InputMaybe<Scalars['Boolean']>;
  /** Allow performing tariff renewals via API. */
  canRenewTariffs?: InputMaybe<Scalars['Boolean']>;
  /** Allow this partner access to the IVR support API (modify their own IVR handling through third party 'IVR Flow Editor') */
  canUseIvrSupportApi?: InputMaybe<Scalars['Boolean']>;
  /** Default Account Type */
  defaultAccountType: AccountTypeChoices;
  /** Restrict to field-sales-only products? This is only allowed for the 'field-sales' and 'events' sales channels */
  isFieldSalesOnlyProduct?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  /** Sales Channel */
  salesChannel: SalesChannelChoices;
  /** Allow this partner to skip validation that ensures all meter points belong to the same address */
  skipMeterPointAddressValidation?: InputMaybe<Scalars['Boolean']>;
};

/** Create a session for an affiliate link. */
export type CreateAffiliateSession = {
  __typename?: 'CreateAffiliateSession';
  affiliateSession?: Maybe<AffiliateSessionType>;
};

export type CreateAffiliateSessionInputType = {
  /** The IP Address of the user. */
  ipAddress?: InputMaybe<Scalars['String']>;
  /** The affiliate link for whom to create the session for. */
  linkId: Scalars['ID'];
  /** Additional query parameters to attach to this session. */
  queryParams?: InputMaybe<Scalars['JSONString']>;
  /** The quote share that led to this session. */
  quoteShareId?: InputMaybe<Scalars['ID']>;
  /** The HTTP user agent. */
  userAgent?: InputMaybe<Scalars['String']>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-4123: Unauthorized.
 * - KT-CT-9601: Invalid data.
 * - KT-CT-9602: Unable to create contribution agreement.
 * - KT-CT-9605: Contribution amount cannot be 0 or negative.
 * - KT-CT-9606: Scheme is not accepting contributions at this time.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateContributionAgreement = {
  __typename?: 'CreateContributionAgreement';
  /** The created contribution agreement. */
  contributionAgreement?: Maybe<ContributionAgreementType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateContributionAgreementInput = {
  /** The account number. */
  accountNumber: Scalars['String'];
  /** The start datetime of the agreement. */
  activeFrom: Scalars['DateTime'];
  /** The end datetime of the agreement, if any. */
  activeTo?: InputMaybe<Scalars['DateTime']>;
  /** The amount contributed per interval. Note, this is in the smallest domination that the currency supports. e.g. Pence, Cents, Yen, etc. */
  amount: Scalars['Int'];
  /** The frequency of contributions. */
  interval: Interval;
  /** The code of the scheme to contribute to. */
  schemeCode: Scalars['String'];
};

/**
 * Create a new deposit agreement for the account if it needs one.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4177: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateDepositAgreement = {
  __typename?: 'CreateDepositAgreement';
  depositAgreement?: Maybe<CreateDepositAgreementOutput>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateDepositAgreementInput = {
  accountNumber: Scalars['String'];
  depositKey: Scalars['String'];
  reason: Scalars['String'];
};

export type CreateDepositAgreementOutput = {
  __typename?: 'CreateDepositAgreementOutput';
  depositAmount?: Maybe<Scalars['Int']>;
  depositRequired?: Maybe<Scalars['Boolean']>;
};

/**
 * Creates a direct debit instruction and a payment schedule for a given account.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1128: Unauthorized.
 * - KT-CT-3947: An unexpected error occurred.
 * - KT-CT-3948: Could not set up direct debit instruction.
 * - KT-GB-3930: Invalid data.
 * - KT-GB-3931: Customer is in too much debt to complete operation.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateDirectDebitInstructionAndSchedule = {
  __typename?: 'CreateDirectDebitInstructionAndSchedule';
  /** The payment instruction. */
  paymentInstruction: PaymentInstructionType;
  /** The payment schedule. */
  paymentSchedule: PaymentScheduleType;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateDirectDebitInstructionAndScheduleInput = {
  /** The account number. */
  accountNumber: Scalars['String'];
  /** Bank details. */
  bankDetails: BankDetailsInput;
  /** The preferred date from which the payment schedule takes first payment. */
  preferredValidFrom: Scalars['Date'];
  /** The type of schedule to create. */
  scheduleType: DirectDebitScheduleChoices;
};

/**
 * Create an external account event.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-7123: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateExternalAccountEvent = {
  __typename?: 'CreateExternalAccountEvent';
  /** The new event data. */
  event?: Maybe<ExternalAccountEvent>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateExternalAccountEventInput = {
  /** The number of the account that the event should be created for. */
  accountNumber: Scalars['String'];
  /** The category of the event. */
  category: ExternalAccountEventCategory;
  /** An array of content data associated with the event. */
  content: Array<InputMaybe<ExternalAccountEventContent>>;
  /** A human-readable description of the event. */
  description?: InputMaybe<Scalars['String']>;
  /** The subcategory of the event. */
  subcategory?: InputMaybe<ExternalAccountEventSubCategory>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-8206: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateGoodsPurchase = {
  __typename?: 'CreateGoodsPurchase';
  /** Goods purchase created. */
  goodsPurchase?: Maybe<GoodsPurchase>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-8202: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateGoodsQuote = {
  __typename?: 'CreateGoodsQuote';
  /** Goods quote created for the customer. */
  goodsQuote?: Maybe<GoodsQuote>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateGoodsQuoteInput = {
  /** The account number. */
  accountNumber: Scalars['String'];
  /** A JSON object containing client parameters to store on the quote. */
  clientParams?: InputMaybe<Scalars['GenericScalar']>;
  /** Products to get a quote for. */
  productsToQuote: Array<InputMaybe<ProductToQuoteInput>>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-8202: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateGoodsQuoteWithoutAccount = {
  __typename?: 'CreateGoodsQuoteWithoutAccount';
  /** Goods quote created for the customer. */
  goodsQuote?: Maybe<GoodsQuote>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateGoodsQuoteWithoutAccountInput = {
  /** A JSON object containing client parameters to store on the quote. */
  clientParams?: InputMaybe<Scalars['GenericScalar']>;
  /** Customer profile. */
  customerProfile: CustomerProfileInput;
  /** Products to get a quote for. */
  productsToQuote: Array<InputMaybe<ProductToQuoteInput>>;
};

export type CreateInkInboundMessageInput = {
  channel: InkCommunicationChannel;
  clientMutationId?: InputMaybe<Scalars['String']>;
  /**
   *
   * An optional parameter where we can pass the generic message headers if it has one
   *
   * Email channel tries to get the value `conversation-relay-id` from this
   * parameter
   *
   */
  messageHeaders?: InputMaybe<Scalars['JSONString']>;
  /**
   *
   * An arbitrary, unique ID for this message.
   *
   * This must be unique for each message that is supplied
   * using the same organisation; collisions between messages
   * provided by different organisations are tolerated.
   *
   * Stored as vendor_id.
   *
   */
  messageId: Scalars['String'];
  newMessage: InkMessageInput;
  /** When the message occurred in the system of origin. */
  occurredAt?: InputMaybe<Scalars['DateTime']>;
  /**
   *
   * An optional vendor value to denote which system it originated from.
   *
   * If no vendor is passed, we will get the default generic vendor from
   * the setting called INK_DEFAULT_GENERIC_MESSAGE_API_VENDOR.
   *
   */
  vendor?: InputMaybe<Scalars['String']>;
};

/** Register an Ink inbound message. */
export type CreateInkInboundMessagePayload = {
  __typename?: 'CreateInkInboundMessagePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  /** The Ink message that was created. */
  message: InkMessage;
};

/**
 * Create metadata on an object.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-8412: Invalid data.
 * - KT-CT-8414: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateMetadata = {
  __typename?: 'CreateMetadata';
  metadata?: Maybe<Metadata>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * Create a Lead instance.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-8901: Unable to create lead.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateNewLead = {
  __typename?: 'CreateNewLead';
  /** Lead created. */
  lead?: Maybe<NewLeadType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateOrUpdateLoyaltyCardInput = {
  /** The id of the account user. */
  accountUserId?: InputMaybe<Scalars['String']>;
  /** The number of the loyalty card. */
  number?: InputMaybe<Scalars['String']>;
  /** The scheme of the loyalty card. */
  scheme?: InputMaybe<Scalars['String']>;
};

/**
 * Create a Loyalty Card for the given account user.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-5412: No account user exists with the given id.
 * - KT-CT-8610: Invalid data.
 * - KT-CT-8611: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateOrUpdateLoyaltyCardMutation = {
  __typename?: 'CreateOrUpdateLoyaltyCardMutation';
  /** Created or updated loyalty card. */
  loyaltyCard?: Maybe<LoyaltyCardType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * Create a new portfolio.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-9402: Received an invalid brandCode.
 * - KT-CT-9401: Received an invalid operationsTeamId.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreatePortfolio = {
  __typename?: 'CreatePortfolio';
  /** The created portfolio. */
  portfolio?: Maybe<PortfolioType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreatePortfolioInput = {
  /** The brand to associate with this portfolio, if not provided the default brand will be used. */
  brandCode?: InputMaybe<Scalars['String']>;
  /** Whether collective bills should be issued for the portfolio's accounts. The default value is False. */
  collectiveBilling?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the operations team to associate with this portfolio.If no team is provided, no team will be assigned to the portfolio. */
  operationsTeamId?: InputMaybe<Scalars['ID']>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-9403: Received an invalid portfolioId.
 * - KT-CT-9404: Received an invalid accountUserId.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreatePortfolioUserRole = {
  __typename?: 'CreatePortfolioUserRole';
  /** The created role for a user in association with a portfolio. */
  portfolioUserRole?: Maybe<PortfolioUserRoleType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreatePortfolioUserRoleInput = {
  /** The user to associate with the portfolio. */
  accountUserId: Scalars['ID'];
  /** The portfolio to associate the user with. */
  portfolioId: Scalars['ID'];
  /** The role to assign to the user. If not provided the default role will be used. */
  role?: InputMaybe<PortfolioUserRoleEnum>;
};

export type CreatePurchaseInput = {
  /** The account number. */
  accountNumber: Scalars['String'];
  /** A JSON object containing client parameters to store on the quote. */
  clientParams?: InputMaybe<Scalars['JSONString']>;
  /** A JSON object containing client parameters to store on the purchase. */
  marketParams?: InputMaybe<Scalars['JSONString']>;
  /** Products being purchased. */
  saleItems: Array<InputMaybe<ProductToPurchaseInput>>;
};

/**
 * Use a referral code to create a referral and trigger a referral reward.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-6723: Unauthorized.
 * - KT-CT-6710: Unable to create referral.
 * - KT-CT-6711: Accounts may not self-refer.
 * - KT-CT-6713: Referring and referred account brands do not match.
 * - KT-CT-6712: Invalid reference.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateReferral = {
  __typename?: 'CreateReferral';
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** The reward amount to be issued to the referred account, in smallest currency subunits. */
  referredAccountRewardAmount?: Maybe<Scalars['Int']>;
};

/** Required information for creating a referral */
export type CreateReferralInput = {
  /** The account number for the referred account. */
  accountNumber: Scalars['String'];
  /** An email address, link or code, referencing the referring account. */
  reference: Scalars['String'];
};

export type CreateShellAccountInput = {
  billingAddressLine1?: InputMaybe<Scalars['String']>;
  billingAddressLine2?: InputMaybe<Scalars['String']>;
  billingAddressLine3?: InputMaybe<Scalars['String']>;
  billingAddressLine4?: InputMaybe<Scalars['String']>;
  billingAddressLine5?: InputMaybe<Scalars['String']>;
  billingName?: InputMaybe<Scalars['String']>;
  /** Day to fixed bill on if billing_period_length set. */
  billingPeriodDay?: InputMaybe<Scalars['Int']>;
  /** For fixed billing accounts only, the length of their billing period. Can be MONTHLY or QUARTERLY. */
  billingPeriodLength?: InputMaybe<Scalars['String']>;
  /** Month to start billing from if billing_period_length set to QUARTERLY or the multiplier is > 1. */
  billingPeriodMonth?: InputMaybe<Scalars['Int']>;
  /** For fixed billing accounts only, the number the period length is to be multiplied by to get the total period length, i.e. for billing every second month, select 2 combined with a billing period length MONTHLY. Can't be > 1 for quarterly billing. */
  billingPeriodMultiplier?: InputMaybe<Scalars['Int']>;
  billingPostcode?: InputMaybe<Scalars['String']>;
  billingRichAddress?: InputMaybe<Scalars['String']>;
  brand?: InputMaybe<Scalars['String']>;
  businessType?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  companyNumber?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  email: Scalars['String'];
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  isBusinessAccount?: InputMaybe<Scalars['Boolean']>;
  landline?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  passwordUpdateToken?: InputMaybe<Scalars['String']>;
  portfolioNumber?: InputMaybe<Scalars['String']>;
  urn?: InputMaybe<Scalars['String']>;
};

/** Create a shell account (a billable account with no property/energy supply). */
export type CreateShellAccountPayload = {
  __typename?: 'CreateShellAccountPayload';
  account?: Maybe<AccountInterface>;
  billingAddressLine1?: Maybe<Scalars['String']>;
  billingAddressLine2?: Maybe<Scalars['String']>;
  billingAddressLine3?: Maybe<Scalars['String']>;
  billingAddressLine4?: Maybe<Scalars['String']>;
  billingAddressLine5?: Maybe<Scalars['String']>;
  billingName?: Maybe<Scalars['String']>;
  /** Day to fixed bill on if billing_period_length set. */
  billingPeriodDay?: Maybe<Scalars['Int']>;
  /** For fixed billing accounts only, the length of their billing period. Can be MONTHLY or QUARTERLY. */
  billingPeriodLength?: Maybe<Scalars['String']>;
  /** Month to start billing from if billing_period_length set to QUARTERLY or the multiplier is > 1. */
  billingPeriodMonth?: Maybe<Scalars['Int']>;
  /** For fixed billing accounts only, the number the period length is to be multiplied by to get the total period length, i.e. for billing every second month, select 2 combined with a billing period length MONTHLY. Can't be > 1 for quarterly billing. */
  billingPeriodMultiplier?: Maybe<Scalars['Int']>;
  billingPostcode?: Maybe<Scalars['String']>;
  billingRichAddress?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  businessType?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyNumber?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  email: Scalars['String'];
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  isBusinessAccount?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  passwordUpdateToken?: Maybe<Scalars['String']>;
  portfolioNumber?: Maybe<Scalars['String']>;
  urn?: Maybe<Scalars['String']>;
};

/** A credit to the customer from the energy retailer. */
export type Credit = TransactionType & {
  __typename?: 'Credit';
  /** Unique identifier of the account the transaction belongs to. */
  accountNumber?: Maybe<Scalars['String']>;
  /**
   * Gross amount including tax (when payable). Refer to the `amounts` field for a breakdown of this information.
   * @deprecated The 'amount' field is deprecated.
   *
   * Use `amounts` instead for a breakdown of the relevant net, tax, and gross amounts.
   *
   * - Marked as deprecated on 2023-12-06.
   * - Will be removed on 2024-06-01.
   */
  amount?: Maybe<Scalars['Int']>;
  /** The net, tax and gross amounts for the transaction. Note: for payments and repayments, only the net amount is returned. */
  amounts?: Maybe<TransactionAmountType>;
  /** The customer's resulting balance after this transaction has been applied, in the smallest unit of currency. */
  balanceCarriedForward?: Maybe<Scalars['Int']>;
  /** The unique identifier for the most recent billing document linked with the transaction.Note: a transaction may be linked with multiple documents, but this field will only return the identifier for the most recent billing document. */
  billingDocumentIdentifier?: Maybe<Scalars['ID']>;
  /** The date time when the transaction is created. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Returns True if the transaction is linked with a statement. */
  hasStatement?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  /**
   * Deprecated.
   * @deprecated The 'isAccountCharge' field is deprecated.
   *
   * This information is provided by the __typename introspection query.
   *
   * - Marked as deprecated on 2020-06-19.
   * - Will be removed on 2022-11-15.
   */
  isAccountCharge?: Maybe<Scalars['Boolean']>;
  /**
   * Deprecated.
   * @deprecated The 'isAccountPayment' field is deprecated.
   *
   * This information is provided by the __typename introspection query.
   *
   * - Marked as deprecated on 2020-06-19.
   * - Will be removed on 2022-11-15.
   */
  isAccountPayment?: Maybe<Scalars['Boolean']>;
  /**
   * Deprecated.
   * @deprecated The 'isCredit' field is deprecated.
   *
   * This information is provided by the __typename introspection query.
   *
   * - Marked as deprecated on 2020-06-19.
   * - Will be removed on 2022-11-15.
   */
  isCredit?: Maybe<Scalars['Boolean']>;
  /** Whether the statement this transaction is on has been held. A held statement is not sent to a customer automatically, but is instead marked for manual attention by operations staff.  Returns False if a statement is not linked with the transaction. */
  isHeld?: Maybe<Scalars['Boolean']>;
  /** Whether this transaction has been issued on any billing document.Note: Look for the most recently issued transaction instead of looking through all transactions as some accounts may have initial transactions that were not issued.This will return False if the transaction is not associated with any billing documents. */
  isIssued?: Maybe<Scalars['Boolean']>;
  isReversed: Scalars['Boolean'];
  /** Returns the note field value for the transaction, which contains additional info. */
  note?: Maybe<Scalars['String']>;
  postedDate?: Maybe<Scalars['Date']>;
  /**
   * Returns None if a statement is not linked with the transaction.
   * @deprecated The 'statementId' field is deprecated.
   *
   * Use `billingDocumentIdentifier` instead.
   *
   * - Marked as deprecated on 2023-11-30.
   * - Will be removed on 2024-06-01.
   */
  statementId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export type CreditReasonType = {
  __typename?: 'CreditReasonType';
  /** The credit reason code. */
  code?: Maybe<Scalars['String']>;
  /** The credit reason display text. */
  display?: Maybe<Scalars['String']>;
  /** The group the credit reason belongs to (if applicable). */
  group?: Maybe<Scalars['String']>;
  /** Whether the credit reason is deprecated. */
  isDeprecated?: Maybe<Scalars['Boolean']>;
  /** Whether the credit reason is hidden. */
  isHidden?: Maybe<Scalars['Boolean']>;
  /** Whether the credit reason is sales tax exempt. */
  isTaxExempt?: Maybe<Scalars['Boolean']>;
};

/** Details about the customer. */
export type CustomerDetailsInput = {
  /** The customer's date of birth. */
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  /** Account email. */
  email?: InputMaybe<Scalars['String']>;
  /** Family name. */
  familyName: Scalars['String'];
  /** Given name. */
  givenName: Scalars['String'];
  /** Account landline number. */
  landline?: InputMaybe<Scalars['String']>;
  /** Account mobile phone number. */
  mobile?: InputMaybe<Scalars['String']>;
  /** The customer's communication preferences. */
  preferences?: InputMaybe<UpdateAccountUserCommsPreferencesInput>;
  /** The customer's pronouns. */
  pronouns?: InputMaybe<Scalars['String']>;
  /** The customer's title. */
  title?: InputMaybe<Scalars['String']>;
};

export type CustomerFeedbackInputType = {
  accountNumber: Scalars['String'];
  answer?: InputMaybe<Scalars['String']>;
  feedbackId: Scalars['Int'];
  formId: Scalars['Int'];
  issueResolved: Scalars['Boolean'];
};

export type CustomerFeedbackType = {
  __typename?: 'CustomerFeedbackType';
  id: Scalars['ID'];
  /** The value attached to the source */
  rawScore?: Maybe<Scalars['Int']>;
  /** The datetime the feedback was submitted */
  submittedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomerProfileInput = {
  /** Line 1 of customer's address. */
  addressLine1: Scalars['String'];
  /** Line 2 of customer's address. */
  addressLine2?: InputMaybe<Scalars['String']>;
  /** Line 3 of customer's address. */
  addressLine3?: InputMaybe<Scalars['String']>;
  /** Line 4 of customer's address. */
  addressLine4?: InputMaybe<Scalars['String']>;
  /** Line 5 of customer's address. */
  addressLine5?: InputMaybe<Scalars['String']>;
  /** Customer's email. */
  email: Scalars['String'];
  /** Customer's family name. */
  familyName: Scalars['String'];
  /** Customer's given name. */
  givenName: Scalars['String'];
  /** Customer's phone number. */
  phoneNumber: Scalars['String'];
  /** Customer's postcode. */
  postcode: Scalars['String'];
};

/** A list of components which comprise a dashboard screen. */
export type Dashboard = IdentifiableInterface & {
  __typename?: 'Dashboard';
  /** The list of sections for a dashboard. */
  dashboardItems: Array<Maybe<SectionType>>;
  /** Unique identifier of the object. */
  id?: Maybe<Scalars['ID']>;
  /** The serialised dashboard items. */
  serialisedDashboardItems: Scalars['String'];
  /** The name of the object's type. */
  typename?: Maybe<Scalars['String']>;
};

export type DebtCollectionAgencyType = {
  __typename?: 'DebtCollectionAgencyType';
  code: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
};

/** An enumeration. */
export enum DebtCollectionProceedingStopReason {
  /** Arrears to client */
  ArrearsToClient = 'ARREARS_TO_CLIENT',
  /** Bankrupt */
  Bankrupt = 'BANKRUPT',
  /** Confirmed empty */
  ConfirmedEmpty = 'CONFIRMED_EMPTY',
  /** COS */
  Cos = 'COS',
  /** COT / COS */
  CotCos = 'COT_COS',
  /** COT no proof */
  CotNoProof = 'COT_NO_PROOF',
  /** COT proof seen */
  CotProofSeen = 'COT_PROOF_SEEN',
  /** Deceased */
  Deceased = 'DECEASED',
  /** Demolished */
  Demolished = 'DEMOLISHED',
  /** Enrolled in Error */
  EnrolledInError = 'ENROLLED_IN_ERROR',
  /** Expired */
  Exp = 'EXP',
  /** Gone away */
  GoneAway = 'GONE_AWAY',
  /** High level complaint */
  HighLevelComplaint = 'HIGH_LEVEL_COMPLAINT',
  /** Insolvencies dealing */
  InsolvenciesDealing = 'INSOLVENCIES_DEALING',
  /** In prison */
  InPrison = 'IN_PRISON',
  /** Live to final */
  LiveToFinal = 'LIVE_TO_FINAL',
  /** Low balance */
  LowBalance = 'LOW_BALANCE',
  /** Negative trace */
  NegativeTrace = 'NEGATIVE_TRACE',
  /** Paid in full */
  PaidInFull = 'PAID_IN_FULL',
  /** Partial settlement */
  PartialSettlement = 'PARTIAL_SETTLEMENT',
  /** Part payment */
  PartPayment = 'PART_PAYMENT',
  /** Payment arrangement */
  PaymentArrangement = 'PAYMENT_ARRANGEMENT',
  /** Payment plan agreed */
  PaymentPlanAgreed = 'PAYMENT_PLAN_AGREED',
  /** PPM arrangement */
  PpmArrangement = 'PPM_ARRANGEMENT',
  /** PPM fitted */
  PpmFitted = 'PPM_FITTED',
  /** PPM requested */
  PpmRequested = 'PPM_REQUESTED',
  /** Process exhausted */
  ProcessExhausted = 'PROCESS_EXHAUSTED',
  /** Process exhausted - contact */
  ProcessExhaustedContact = 'PROCESS_EXHAUSTED_CONTACT',
  /** Process exhausted - no contact */
  ProcessExhaustedNoContact = 'PROCESS_EXHAUSTED_NO_CONTACT',
  /** Query */
  Query = 'QUERY',
  /** Reduced settlement */
  ReducedSettlement = 'REDUCED_SETTLEMENT',
  /** Refused to deal */
  RefusedToDeal = 'REFUSED_TO_DEAL',
  /** Unable to locate property */
  UnableToLocateProperty = 'UNABLE_TO_LOCATE_PROPERTY',
  /** Unable to trace */
  UnableToTrace = 'UNABLE_TO_TRACE',
  /** Vulnerable */
  Vulnerable = 'VULNERABLE',
  /** Withdrawn */
  Withdrawn = 'WITHDRAWN',
  /** Write off */
  WriteOff = 'WRITE_OFF',
}

export type DebtCollectionProceedingType = {
  __typename?: 'DebtCollectionProceedingType';
  /** The agency responsible for the collection proceedings. */
  agency?: Maybe<DebtCollectionAgencyType>;
  amount?: Maybe<Scalars['Int']>;
  /** The campaign type of the collection proceedings. */
  campaign?: Maybe<CollectionCampaignType>;
  startedAt: Scalars['DateTime'];
  stopReason?: Maybe<DebtCollectionProceedingStopReason>;
  stoppedAt?: Maybe<Scalars['DateTime']>;
};

/** An action which navigates to the URL of another backend screen. */
export type DeeplinkActionType = ActionInterface &
  IdentifiableInterface & {
    __typename?: 'DeeplinkActionType';
    /** Unique identifier of the object. */
    id?: Maybe<Scalars['ID']>;
    /** The name of the action object's type. */
    typeName?: Maybe<Scalars['String']>;
    /** The name of the object's type. */
    typename?: Maybe<Scalars['String']>;
    /** The URL to navigate to. */
    url: Scalars['String'];
  };

/**
 * Delete a reference for a particular account and namespace.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4123: Unauthorized.
 * - KT-CT-8310: Invalid data.
 * - KT-CT-8312: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type DeleteAccountReference = {
  __typename?: 'DeleteAccountReference';
  accountReference?: Maybe<DeleteAccountReferenceType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type DeleteAccountReferenceInput = {
  /** The account number associated with the removed AccountReference. */
  accountNumber: Scalars['String'];
  /** The namespace associated with the removed AccountReference */
  namespace: Scalars['String'];
};

export type DeleteAccountReferenceType = {
  __typename?: 'DeleteAccountReferenceType';
  /** The namespace associated with the removed AccountReference */
  namespace: Scalars['String'];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-5411: Invalid token or no push notification binding found for the given account user.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type DeletePushNotificationBinding = {
  __typename?: 'DeletePushNotificationBinding';
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  status?: Maybe<DeletePushNotificationBindingOutput>;
};

export type DeletePushNotificationBindingInput = {
  /** Device push notification token. */
  token: Scalars['String'];
};

export enum DeletePushNotificationBindingOutput {
  Failed = 'FAILED',
  Successful = 'SUCCESSFUL',
}

export type DepositAgreementInput = {
  accountNumber: Scalars['String'];
  depositKey: Scalars['String'];
};

export type DepositAgreementOutput = {
  __typename?: 'DepositAgreementOutput';
  acceptedAt?: Maybe<Scalars['DateTime']>;
  depositAmount?: Maybe<Scalars['Int']>;
  depositKey?: Maybe<Scalars['String']>;
  fulfilledAt?: Maybe<Scalars['DateTime']>;
};

export type DetailsInputType = {
  /** Namespace of the detail. */
  namespace: Scalars['String'];
  /** Value of the detail. */
  value?: InputMaybe<Scalars['String']>;
};

export type DirectDebitInstructionConnectionTypeConnection = {
  __typename?: 'DirectDebitInstructionConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DirectDebitInstructionConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `DirectDebitInstructionConnectionType` and its cursor. */
export type DirectDebitInstructionConnectionTypeEdge = {
  __typename?: 'DirectDebitInstructionConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<DirectDebitInstructionType>;
};

/** An enumeration. */
export enum DirectDebitInstructionStatus {
  /** The account for this instruction was closed. */
  AccountClosed = 'ACCOUNT_CLOSED',
  /** The instruction is active and can be used to take payments. */
  Active = 'ACTIVE',
  /** The instruction could not be set up with the vendor. */
  Failed = 'FAILED',
  /** The instruction has not yet been set up. */
  Provisional = 'PROVISIONAL',
}

/** Direct Debit Instructions */
export type DirectDebitInstructionType = {
  __typename?: 'DirectDebitInstructionType';
  accountHolder: Scalars['String'];
  cardExpiryMonth?: Maybe<Scalars['Int']>;
  cardExpiryYear?: Maybe<Scalars['Int']>;
  cardPaymentNetwork?: Maybe<Scalars['String']>;
  iban: Scalars['String'];
  id: Scalars['ID'];
  instructionType: Scalars['String'];
  /**
   * The last four digits of the account number.
   * @deprecated The 'lastFourDigitsOfAccountNumber' field is deprecated.
   *
   * Use 'maskedAccountIdentifier' for a masked reference to the instruction.
   *
   * - Marked as deprecated on 2021-12-23.
   * - Will be removed on 2024-01-01.
   */
  lastFourDigitsOfAccountNumber?: Maybe<Scalars['String']>;
  /** A masked reference to a recurring payment method. */
  maskedAccountIdentifier?: Maybe<Scalars['String']>;
  /** A masked version of the IBAN. */
  maskedIban?: Maybe<Scalars['String']>;
  sortCode: Scalars['String'];
  status: Scalars['String'];
};

export enum DirectDebitScheduleChoices {
  Monthly = 'MONTHLY',
  PayOnReceiptOfBill = 'PAY_ON_RECEIPT_OF_BILL',
}

/** An enumeration. */
export enum DocumentAccessibilityChoices {
  /** Audio-accessible documents are requested for this account. */
  Audio = 'AUDIO',
  /** Braille documents are requested for this account. */
  Braille = 'BRAILLE',
  /** Large print documents are requested for this account. */
  LargePrint = 'LARGE_PRINT',
}

/**
 *
 * Filter measurements by electricity parameters.
 *
 */
export type ElectricityFiltersInput = {
  deviceId?: InputMaybe<Scalars['String']>;
  marketSupplyPointId?: InputMaybe<Scalars['String']>;
  readingDirection?: InputMaybe<ReadingDirectionType>;
  readingFrequencyType?: InputMaybe<ReadingFrequencyType>;
  readingQuality?: InputMaybe<ReadingQualityType>;
  registerId?: InputMaybe<Scalars['String']>;
};

export type ElectricityFiltersOutput = {
  __typename?: 'ElectricityFiltersOutput';
  deviceId?: Maybe<Scalars['String']>;
  marketSupplyPointId?: Maybe<Scalars['String']>;
  readingDirection?: Maybe<ReadingDirectionType>;
  readingFrequencyType?: Maybe<ReadingFrequencyType>;
  readingQuality?: Maybe<ReadingQualityType>;
  registerId?: Maybe<Scalars['String']>;
};

/** Represents a file to attach to a email message. */
export type EmailAttachmentType = {
  __typename?: 'EmailAttachmentType';
  filename: Scalars['String'];
  id: Scalars['ID'];
  /** Temporary URL at which the attachment is available. This URL will expire after approximately an hour. It is intended for redirection purposes, NOT persistence in any form (e.g. inclusion in emails or the body of a web page). */
  temporaryUrl?: Maybe<Scalars['String']>;
};

export type EmailEventType = Node & {
  __typename?: 'EmailEventType';
  eventType: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  /** Email message of the email event. Returns null for message's sent/received by other user's on the account */
  message?: Maybe<EmailType>;
  occurredAt: Scalars['DateTime'];
};

/** An enumeration. */
export enum EmailFormats {
  Html = 'HTML',
  Text = 'TEXT',
}

/** Represents an email communication */
export type EmailType = {
  __typename?: 'EmailType';
  /** Attachments of the email message. */
  attachments?: Maybe<Array<Maybe<EmailAttachmentType>>>;
  channel: MessageChannel;
  createdAt: Scalars['DateTime'];
  fromEmail: Scalars['String'];
  fromNumber: Scalars['String'];
  htmlBody?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Email recipient */
  recipient?: Maybe<Scalars['String']>;
  /** Email sender */
  sender?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  /** Subject line of the email message. */
  subject?: Maybe<Scalars['String']>;
  templateCode: Scalars['String'];
  textBody?: Maybe<Scalars['String']>;
  toAddress: Scalars['String'];
  toNumber: Scalars['String'];
};

export type EmbeddedElectricityFiltersOutput = {
  __typename?: 'EmbeddedElectricityFiltersOutput';
  deviceId?: Maybe<Scalars['String']>;
  marketSupplyPointId?: Maybe<Scalars['String']>;
  readingDirection?: Maybe<ReadingDirectionType>;
  readingFrequencyType?: Maybe<ReadingFrequencyType>;
  readingQuality?: Maybe<ReadingQualityType>;
  registerId?: Maybe<Scalars['String']>;
};

/** Represents an embedded network that holds multiple embedded properties. */
export type EmbeddedNetworkType = {
  __typename?: 'EmbeddedNetworkType';
  /** Get details about properties in an embedded network. */
  embeddedProperties?: Maybe<Array<Maybe<EmbeddedPropertyType>>>;
  id: Scalars['ID'];
  /** A unique name/code for the network */
  name: Scalars['String'];
};

/** Represents an embedded property in an embedded network. */
export type EmbeddedPropertyType = {
  __typename?: 'EmbeddedPropertyType';
  embeddedNetwork: EmbeddedNetworkType;
  id: Scalars['ID'];
  /** The id of the physical property related to this embedded property type. */
  propertyId?: Maybe<Scalars['ID']>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-9603: Unable to find contribution agreement.
 * - KT-CT-4123: Unauthorized.
 * - KT-CT-9604: Unable to end contribution agreement.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type EndContributionAgreement = {
  __typename?: 'EndContributionAgreement';
  /** The created contribution agreement. */
  contributionAgreement?: Maybe<ContributionAgreementType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type EndContributionAgreementInput = {
  /** The ID of the Contribution Agreement to end. */
  contributionAgreementId: Scalars['ID'];
  /** The future end datetime of the agreement. If not given, terminate now. */
  endAt?: InputMaybe<Scalars['DateTime']>;
};

/** Energy mix data can include the latest carbon intensity index in a region. */
export type EnergyMixDataType = {
  __typename?: 'EnergyMixDataType';
  /** Current carbon intensity index. */
  carbonIntensityIndex?: Maybe<Scalars['String']>;
};

export type ErrorType = {
  __typename?: 'ErrorType';
  field: Scalars['String'];
  messages: Array<Scalars['String']>;
};

export type ErrorTypeUnion = SerializerErrorType | SerializerFieldErrorsType;

export type EstimatedMoneyType = {
  __typename?: 'EstimatedMoneyType';
  /** Monetary currency of the statistic in ISO-4217 format. */
  costCurrency: Scalars['String'];
  /**
   *
   *             Monetary cost of the statistic. This is the smallest unit of currency,
   *             e.g. cents for USD or yen for JPY. Because electricity is priced as a commodity, we must
   *             account for fractional cents and this field must be a Decimal. Values from this field should
   *             likely not be used for accounting purposes.
   *
   */
  estimatedAmount: Scalars['Decimal'];
  /** Net price per unit of the statistic if applicable. */
  pricePerUnit?: Maybe<PricePerUnit>;
};

/** An enumeration. */
export enum ExpiringTokenScope {
  /** Scope that enables account user to accept the terms and conditions for a product. */
  AcceptTermsAndConditions = 'ACCEPT_TERMS_AND_CONDITIONS',
  /** Scope that enables account user to book smart meter appointments. */
  BookSmartMeterAppointments = 'BOOK_SMART_METER_APPOINTMENTS',
  /** Scope that enables account user to checkout a quote (validate terms & conds and provide a payment detail). */
  CheckoutQuote = 'CHECKOUT_QUOTE',
  /** Edit Customer Marketing Preference */
  EditCustomerMarketingPreference = 'EDIT_CUSTOMER_MARKETING_PREFERENCE',
  /** Scope that enables account user to join campaigns. */
  JoinCampaigns = 'JOIN_CAMPAIGNS',
  /** Scope that enables account user to join campaign events. */
  JoinCampaignEvents = 'JOIN_CAMPAIGN_EVENTS',
  /** Scope that enables account user to generate a renewal quote and renew agreements. */
  ManageAccountRenewals = 'MANAGE_ACCOUNT_RENEWALS',
  /** Scope that enables account user to manage security deposit payments for business accounts. */
  ManageBusinessSecurityDeposit = 'MANAGE_BUSINESS_SECURITY_DEPOSIT',
  /** Scope that enables account user to accept goods quotes and process goods purchases. */
  ManageGoodsPurchases = 'MANAGE_GOODS_PURCHASES',
  /** Scope that enables account user to do a self-serve product switch through the Dashboard. */
  ManageProductSwitch = 'MANAGE_PRODUCT_SWITCH',
  /** Scope that enables account user to redeem loyalty points */
  RedeemLoyaltyPoints = 'REDEEM_LOYALTY_POINTS',
  /** Scope that enables account user to report a property move-out. */
  ReportMoveOut = 'REPORT_MOVE_OUT',
  /** Scope that enables account user to submit customer feedback. */
  SubmitCustomerFeedback = 'SUBMIT_CUSTOMER_FEEDBACK',
  /** Scope that enables account user to submit meter readings. */
  SubmitMeterReadings = 'SUBMIT_METER_READINGS',
  /** Scope that enables account user to update their blackhole email address. */
  UpdateBlackholeEmail = 'UPDATE_BLACKHOLE_EMAIL',
  /** Update Sensitive Customer Information */
  UpdateSensitiveCustomerInformation = 'UPDATE_SENSITIVE_CUSTOMER_INFORMATION',
  /** Scope that enables account user to visit campaign dashboard. */
  ViewCampaignDashboards = 'VIEW_CAMPAIGN_DASHBOARDS',
  /** Scope that enables account user to visit detailed property usage pages. */
  ViewDetailedUsage = 'VIEW_DETAILED_USAGE',
}

export type ExternalAccountEvent = {
  __typename?: 'ExternalAccountEvent';
  category: Scalars['String'];
  content?: Maybe<Scalars['JSONString']>;
  description?: Maybe<Scalars['String']>;
  eventType: Scalars['String'];
  id: Scalars['ID'];
  subcategory: Scalars['String'];
};

/** Enum of allowable event type categories for external account events. */
export enum ExternalAccountEventCategory {
  Communications = 'COMMUNICATIONS',
  Debt = 'DEBT',
  Messaging = 'MESSAGING',
  Mobile = 'MOBILE',
  Web = 'WEB',
}

/** A piece of content associated with an external account event. */
export type ExternalAccountEventContent = {
  /** The content type of the content. */
  contentType: ExternalAccountEventContentType;
  /** A human-readable description of the content. */
  description: Scalars['String'];
  /** The value of the content. */
  value: Scalars['String'];
};

/**
 * Enum of allowable content types for external account events.
 *
 * The content type field is used to determine how to display the content in the account event
 * description.
 */
export enum ExternalAccountEventContentType {
  Html = 'HTML',
  Link = 'LINK',
  Plaintext = 'PLAINTEXT',
  S3 = 'S3',
}

/** Enum of allowable event type subcategories for external account events. */
export enum ExternalAccountEventSubCategory {
  ClickToCall = 'CLICK_TO_CALL',
  Dunning = 'DUNNING',
  Email = 'EMAIL',
  Feedback = 'FEEDBACK',
  Print = 'PRINT',
  PushNotification = 'PUSH_NOTIFICATION',
  Sms = 'SMS',
  SocialMedia = 'SOCIAL_MEDIA',
  Telephone = 'TELEPHONE',
  Whatsapp = 'WHATSAPP',
}

export type FileAttachment = {
  fetchUrl?: Maybe<Scalars['String']>;
  /** Is the file ready for use / downloadable? */
  isReady?: Maybe<Scalars['Boolean']>;
  /** Is the file uploaded to S3? */
  isUploaded?: Maybe<Scalars['Boolean']>;
  sizeInBytes?: Maybe<Scalars['Int']>;
};

export type ForceReauthentication = {
  __typename?: 'ForceReauthentication';
  /** The time at which forced reauthentication is effective.  Kraken and refresh tokens issued before this time will be invalid. */
  effectiveAt: Scalars['DateTime'];
  /** Reports whether the mutation applied successfully.  Should always be 'true'. */
  tokensInvalidated: Scalars['Boolean'];
};

/** The input type for repudiating previously issued Kraken Tokens and refresh tokens. */
export type ForceReauthenticationInput = {
  /** Also force third-party applications you have authorized to use your account to reauthenticate. */
  includeThirdParties: Scalars['Boolean'];
};

export type FormSubmissionInput = {
  accountNumber: Scalars['String'];
  /** Form content */
  content: Scalars['JSONString'];
  /** Form type */
  formType?: InputMaybe<FormType>;
};

export type FormSubmissionOuput = {
  __typename?: 'FormSubmissionOuput';
  content?: Maybe<Scalars['JSONString']>;
  errors?: Maybe<Array<Maybe<SerializerFieldErrorsType>>>;
  id?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum FormType {
  /** Covid-19 Financial Energy Assessment */
  Covid_19FinancialEnergyAssessment = 'COVID_19_FINANCIAL_ENERGY_ASSESSMENT',
  /** Covid-19 Gas Prices Financial Energy Assessment */
  Covid_19GasPricesFinancialEnergyAssessment = 'COVID_19_GAS_PRICES_FINANCIAL_ENERGY_ASSESSMENT',
}

/** A fractional measurement. */
export type FractionSizeType = IdentifiableInterface & {
  __typename?: 'FractionSizeType';
  /** The fractional value. */
  fraction: Scalars['Decimal'];
  /** Unique identifier of the object. */
  id?: Maybe<Scalars['ID']>;
  /** The name of the object's type. */
  typename?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum FreshWaterSupplyPointPropertyType {
  /** Detached */
  Detached = 'DETACHED',
  /** Flat */
  Flat = 'FLAT',
  /** Semi-detached */
  SemiDetached = 'SEMI_DETACHED',
  /** Terraced */
  Terraced = 'TERRACED',
}

export type FreshWaterSupplyPointType = {
  __typename?: 'FreshWaterSupplyPointType';
  /** A client-defined classifier to segment supply points for billing purposes. */
  areaCode: Scalars['String'];
  id: Scalars['ID'];
  /** Inset for the supply point. */
  inset?: Maybe<InsetType>;
  /** All active linked services for the supply point. */
  linkedServices?: Maybe<Array<LinkedServiceType>>;
  /** Size of the physical pipe of the supply point. */
  pipeSize?: Maybe<Scalars['Int']>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** Type of property that the supply point is on */
  propertyType?: Maybe<FreshWaterSupplyPointPropertyType>;
  /** An assessment of the annual rental value of a property between 1967 and 1990. Required for the billing of unmetered properties. */
  rateableValue?: Maybe<Scalars['Int']>;
  /** A unique identifier for every commercial fresh and waste water supply point in the UK. */
  spid?: Maybe<Scalars['String']>;
  wholesalerCode: FreshWaterSupplyPointWholesalerCode;
};

/** An enumeration. */
export enum FreshWaterSupplyPointWholesalerCode {
  /** Affinity Water */
  Affinity = 'AFFINITY',
  /** Albion Water */
  Albion = 'ALBION',
  /** Anglian Water */
  Anglian = 'ANGLIAN',
  /** Bournemouth Water */
  Bournemouth = 'BOURNEMOUTH',
  /** Bristol Water */
  Bristol = 'BRISTOL',
  /** Dwr Cymru Welsh Water */
  DwrCymruWelsh = 'DWR_CYMRU_WELSH',
  /** Essex & Suffolk Water */
  EssexAndSuffolk = 'ESSEX_AND_SUFFOLK',
  /** Icosa Water */
  Icosa = 'ICOSA',
  /** Portsmouth Water */
  Portsmouth = 'PORTSMOUTH',
  /** Sutton and East Surrey Water (SES) */
  Ses = 'SES',
  /** Severn Trent Water */
  SevernTrent = 'SEVERN_TRENT',
  /** Southern Water */
  Southern = 'SOUTHERN',
  /** South East Water */
  SouthEast = 'SOUTH_EAST',
  /** South Staffordshire Water */
  SouthStaffordshire = 'SOUTH_STAFFORDSHIRE',
  /** Thames Water */
  Thames = 'THAMES',
  /** United Utilities */
  UnitedUtilities = 'UNITED_UTILITIES',
  /** Wessex Water */
  Wessex = 'WESSEX',
  /** Yorkshire Water */
  Yorkshire = 'YORKSHIRE',
}

/**
 *
 * Filter measurements by gas parameters.
 *
 */
export type GasFiltersOutput = {
  __typename?: 'GasFiltersOutput';
  deviceId?: Maybe<Scalars['String']>;
  marketSupplyPointId?: Maybe<Scalars['String']>;
  readingFrequencyType?: Maybe<ReadingFrequencyType>;
  registerId?: Maybe<Scalars['String']>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-7620: Channel not supported.
 * - KT-CT-7618: Unable to process message.
 * - KT-CT-7624: Error when generating the presigned URL.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type GenerateInkPresignedUrl = {
  __typename?: 'GenerateInkPresignedUrl';
  /** Presigned post fields required to upload the file. */
  fields: Scalars['JSONString'];
  /** The key for the item. */
  key: Scalars['String'];
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /**
   * Presigned post fields; key, awsAccessKeyId, xAmzSecurityToken, policy, signature.
   * @deprecated Replaced in favor of fields.
   */
  presignedPostFields?: Maybe<PresignedPostFields>;
  /** A presigned URL for the user to upload to the quarantine bucket. */
  uploadUrl: Scalars['String'];
};

export type GenerateInkPresignedUrlInput = {
  /** The channel of the contact. */
  channel: InkCommunicationChannel;
  /** The name of the file. */
  filename: Scalars['String'];
};

/**
 * Mutation to generate a pre-signed token.
 *
 * The pre-signed, expiring and opaque tokens will be swapped
 * for a limited scope JWT (Kraken Token).
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1128: Unauthorized.
 * - KT-CT-1120: The Kraken Token has expired.
 * - KT-CT-1131: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type GeneratePreSignedToken = {
  __typename?: 'GeneratePreSignedToken';
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  scope?: Maybe<PreSignedTokenScope>;
  token?: Maybe<Scalars['String']>;
  tokenExpiryDatetime?: Maybe<Scalars['DateTime']>;
};

/** A generic backend screen that can be used to define any type of screen. */
export type GenericBackendScreen = BackendScreenInterface & {
  __typename?: 'GenericBackendScreen';
  /** The name of the screen. */
  name: Scalars['String'];
  /** The refresh / polling frequency in milliseconds. */
  refreshFrequency?: Maybe<Scalars['Int']>;
  /** Serialized JSON representation of the screen. */
  screenData?: Maybe<Scalars['String']>;
};

/**
 * Get the client secret needed to create a new payment instruction using an embedded form.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4177: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type GetEmbeddedSecretForNewPaymentInstruction = {
  __typename?: 'GetEmbeddedSecretForNewPaymentInstruction';
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  secretKey?: Maybe<Scalars['String']>;
};

/** The input for getting the client secret for an embedded new card payment method form. */
export type GetEmbeddedSecretForNewPaymentInstructionInput = {
  /** The account number. */
  accountNumber: Scalars['String'];
  /** The type of the new payment instruction. */
  instructionType: PaymentType;
  /**
   * **WARNING: Will be mandatory in future versions**
   *
   *  The ledger ID.
   */
  ledgerId?: InputMaybe<Scalars['String']>;
};

/**
 * Get external URL where the user can set up a payment instruction.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1128: Unauthorized.
 * - KT-CT-3822: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type GetHostedUrlForNewPaymentInstruction = {
  __typename?: 'GetHostedUrlForNewPaymentInstruction';
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** URL at which payment instruction can be set up. */
  url?: Maybe<Scalars['String']>;
};

/** The input needed for getting the external URL for setting up a payment instruction. */
export type GetHostedUrlForNewPaymentInstructionInput = {
  /** The account number. */
  accountNumber: Scalars['String'];
  /** The type of the new payment instruction. */
  instructionType: PaymentType;
  /** The ledger number. */
  ledgerNumber: Scalars['String'];
  /** The URL to redirect the user to after the action was cancelled. */
  returnUrlCancel?: InputMaybe<Scalars['String']>;
  /** The URL to redirect the user to after the action resulted in an error. */
  returnUrlError?: InputMaybe<Scalars['String']>;
  /** The URL to redirect the user to after the action resulted in a failure. */
  returnUrlFailure?: InputMaybe<Scalars['String']>;
  /** The URL to redirect the user to after the action was completed successfuly. */
  returnUrlSuccess?: InputMaybe<Scalars['String']>;
};

export type GoodsGrant = {
  __typename?: 'GoodsGrant';
  /** Currency. */
  currency?: Maybe<Scalars['String']>;
  /** Gross amount. */
  grossAmount?: Maybe<Scalars['Float']>;
  /** Net amount. */
  netAmount?: Maybe<Scalars['Float']>;
  /** Grant type. */
  type?: Maybe<Scalars['String']>;
};

export type GoodsProduct = {
  __typename?: 'GoodsProduct';
  /** Product available from. */
  availableFrom?: Maybe<Scalars['Date']>;
  /** Product available to. */
  availableTo?: Maybe<Scalars['Date']>;
  /** Product code. */
  code?: Maybe<Scalars['String']>;
  /** Currency. */
  currency?: Maybe<Scalars['String']>;
  /** Product customer name. */
  customerName?: Maybe<Scalars['String']>;
  /** Product description. */
  description?: Maybe<Scalars['String']>;
  /** Gross price per unit. */
  grossPricePerUnit?: Maybe<Scalars['Float']>;
  /** Product ID. */
  id?: Maybe<Scalars['Int']>;
  /** Product internal name. */
  internalName?: Maybe<Scalars['String']>;
  /** Market of the product. */
  marketName?: Maybe<Scalars['String']>;
  /** Product notes. */
  notes?: Maybe<Scalars['String']>;
  /** Price per unit. */
  pricePerUnit?: Maybe<Scalars['Float']>;
  /** Type of the product. */
  productType?: Maybe<GoodsProductType>;
};

export type GoodsProductConnectionTypeConnection = {
  __typename?: 'GoodsProductConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GoodsProductConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `GoodsProductConnectionType` and its cursor. */
export type GoodsProductConnectionTypeEdge = {
  __typename?: 'GoodsProductConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<GoodsProduct>;
};

export type GoodsProductType = {
  __typename?: 'GoodsProductType';
  /** Product type code. */
  code?: Maybe<Scalars['String']>;
  /** Product type name. */
  internalName?: Maybe<Scalars['String']>;
};

export type GoodsPurchase = {
  __typename?: 'GoodsPurchase';
  /** Client parameters of the purchase. */
  clientParams?: Maybe<Scalars['JSONString']>;
  /** Purchase code. */
  code?: Maybe<Scalars['String']>;
  /** Grants that apply in this purchase. */
  goodsGrants?: Maybe<Array<Maybe<GoodsGrant>>>;
  /** Sale items in this purchase. */
  goodsSaleItems?: Maybe<Array<Maybe<GoodsSaleItem>>>;
  /** Ledger ID associated to the purchase. */
  ledgerId?: Maybe<Scalars['String']>;
  /** Market parameters of the purchase. */
  marketParams?: Maybe<Scalars['JSONString']>;
};

export type GoodsQuote = {
  __typename?: 'GoodsQuote';
  /** Code of the quote. */
  code?: Maybe<Scalars['String']>;
  /** Products of this quote. */
  goodsQuotedProducts?: Maybe<Array<Maybe<GoodsQuotedProduct>>>;
  /** Indicates whether or not the quote is expired. */
  hasQuoteExpired?: Maybe<Scalars['Boolean']>;
  /** ID of the quote. */
  id?: Maybe<Scalars['Int']>;
  /** Date and time when the quote was created. */
  quotedAt?: Maybe<Scalars['DateTime']>;
  /** Total net amount of the quote in cents. */
  totalNetAmount?: Maybe<Scalars['Int']>;
};

export type GoodsQuoteShare = {
  __typename?: 'GoodsQuoteShare';
  /** The ID of the quote share. */
  id?: Maybe<Scalars['Int']>;
};

export type GoodsQuotedProduct = {
  __typename?: 'GoodsQuotedProduct';
  /** Currency. */
  currency?: Maybe<Scalars['String']>;
  /** Net amount. */
  netAmount?: Maybe<Scalars['Int']>;
  /** Number of units. */
  numberOfUnits?: Maybe<Scalars['Int']>;
  /** Price per unit. */
  pricePerUnit?: Maybe<Scalars['Int']>;
  /** Product code. */
  product?: Maybe<Scalars['String']>;
};

export type GoodsSaleItem = {
  __typename?: 'GoodsSaleItem';
  /** Currency. */
  currency?: Maybe<Scalars['String']>;
  /** Gross amount. */
  grossAmount?: Maybe<Scalars['Float']>;
  /** Net amount. */
  netAmount?: Maybe<Scalars['Float']>;
  /** Number of units. */
  numberOfUnits?: Maybe<Scalars['Int']>;
  /** Price per unit. */
  pricePerUnit?: Maybe<Scalars['Float']>;
  /** Product code. */
  product?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum HardshipAgreementHardshipEntryReason {
  /** Financial counsellor or external agent referral */
  ExternalReference = 'EXTERNAL_REFERENCE',
  /** Retailer referral */
  RetailerReferral = 'RETAILER_REFERRAL',
  /** Customer self-identified as being in hardship */
  SelfIdentified = 'SELF_IDENTIFIED',
}

/** An enumeration. */
export enum HardshipAgreementHardshipType {
  /** Death in the family */
  DeathInFamily = 'DEATH_IN_FAMILY',
  /** Family violence */
  FamilyViolence = 'FAMILY_VIOLENCE',
  /** Household illness */
  HouseholdIllness = 'HOUSEHOLD_ILLNESS',
  /** Other */
  Other = 'OTHER',
  /** Reduced income */
  ReducedIncome = 'REDUCED_INCOME',
  /** Unemployment */
  Unemployment = 'UNEMPLOYMENT',
}

/** Represents a Hardship Agreement for a particular Account. */
export type HardshipAgreementType = {
  __typename?: 'HardshipAgreementType';
  /** These are internal notes detailing the hardship. */
  hardshipDetails: Scalars['String'];
  hardshipEntryReason?: Maybe<HardshipAgreementHardshipEntryReason>;
  hardshipType: HardshipAgreementHardshipType;
  id: Scalars['ID'];
  paymentPlanDetails: Scalars['String'];
  startDate: Scalars['Date'];
};

export type HeldStatus = {
  __typename?: 'HeldStatus';
  /** Whether a statement is currently held. */
  isHeld?: Maybe<Scalars['Boolean']>;
  /** Reason for statement being held. */
  reason?: Maybe<Scalars['String']>;
};

/** The identity of an object. */
export type IdentifiableInterface = {
  /** Unique identifier of the object. */
  id?: Maybe<Scalars['ID']>;
  /** The name of the object's type. */
  typename?: Maybe<Scalars['String']>;
};

/** A media element containing an image. */
export type ImageType = AccessibilityInterface &
  IdentifiableInterface &
  MediaInterface &
  SizedItemInterface & {
    __typename?: 'ImageType';
    /** Whether the element is hidden from view. */
    accessibilityHidden?: Maybe<Scalars['Boolean']>;
    /** Accessible description of the element. */
    accessibilityLabel?: Maybe<Scalars['String']>;
    /** The horizontal alignment of the media. */
    horizontalAlignment?: Maybe<Alignment>;
    /** Unique identifier of the object. */
    id?: Maybe<Scalars['ID']>;
    /** The resource URL of the media. */
    mediaUrl: Scalars['String'];
    /** The name of the object's type. */
    typename?: Maybe<Scalars['String']>;
    /** The measurement of the element. */
    width?: Maybe<ItemSizeType>;
  };

/**
 * Initiate a standalone payment and return the client secret required to complete it.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1128: Unauthorized.
 * - KT-CT-3822: Unauthorized.
 * - KT-CT-3943: Invalid ledger.
 * - KT-CT-3957: No collection method provided.
 * - KT-CT-3958: Provide either ledger ID or ledger number.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type InitiateHostedStandalonePayment = {
  __typename?: 'InitiateHostedStandalonePayment';
  /** The details required to refer to and complete a hosted payment. */
  payment?: Maybe<InitiateHostedStandalonePaymentOutput>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * Input fields for initiating a hosted standalone payment.
 *
 * The amount should always be provided in the minor unit of currency (e.g., pence not pounds,
 * cents not dollars, etc.).
 *
 * A standalone payment can be made against a specific ledger (e.g., a debt ledger) by
 * providing the ledger id. Accounts have a default ledger that will be used if not provided.
 */
export type InitiateHostedStandalonePaymentInput = {
  /** The account number. */
  accountNumber: Scalars['String'];
  /** The amount to be collected in the minor unit of currency. */
  amount: Scalars['Int'];
  /** The method by which the payment is being collected. */
  collectionMethod: CollectionMethod;
  /** A description of the purpose of the payment. */
  description: Scalars['String'];
  /** The id of the specific ledger against which this payment should be applied. Please provide either ledger number or ID. */
  ledgerId?: InputMaybe<Scalars['ID']>;
  /** The number of the specific ledger against which this payment should be applied. Please provide either ledger number or ID. */
  ledgerNumber?: InputMaybe<Scalars['String']>;
  /** The URL to redirect the user to after the action was cancelled. */
  returnUrlCancel?: InputMaybe<Scalars['String']>;
  /** The URL to redirect the user to after the action resulted in an error. */
  returnUrlError?: InputMaybe<Scalars['String']>;
  /** The URL to redirect the user to if the url is not longer valid. */
  returnUrlExpired?: InputMaybe<Scalars['String']>;
  /** The URL to redirect the user to after the action resulted in a failure. */
  returnUrlFailure?: InputMaybe<Scalars['String']>;
  /** The URL to redirect the user to after the action was completed but the payment is still being processed. */
  returnUrlPending?: InputMaybe<Scalars['String']>;
  /** The URL to redirect the user to after the action was completed successfuly. */
  returnUrlSuccess?: InputMaybe<Scalars['String']>;
};

/** Tokens required to collect and retrieve a standalone payment. */
export type InitiateHostedStandalonePaymentOutput = {
  __typename?: 'InitiateHostedStandalonePaymentOutput';
  /** The retrieval token for this standalone payment. */
  retrievalToken: Scalars['String'];
  /** The url for the customer to complete the payment. */
  url: Scalars['String'];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-4619: Quote with given code not found.
 * - KT-CT-4624: Unable to accept the given product code.
 * - KT-CT-4626: No product selected for the given quote code.
 * - KT-CT-4719: No supply point found for identifier provided.
 * - KT-CT-4922: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type InitiateProductSwitch = {
  __typename?: 'InitiateProductSwitch';
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** The selected product for a specific product switch. */
  productCode: Scalars['String'];
  /** The date at which the product switch becomes effective. */
  switchDate: Scalars['Date'];
};

export type InitiateProductSwitchInput = {
  /** Identification of the account that requesting the product switch. */
  accountNumber: Scalars['String'];
  /** ID of the selected quoted product, obtain from quoting that supply point. */
  quotedProductId: Scalars['ID'];
  /** The date at which the product switch becomes effective. */
  switchDate: Scalars['Date'];
};

/**
 * Initiate a standalone payment and return the client secret required to complete it.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4177: Unauthorized.
 * - KT-CT-3943: Invalid ledger.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type InitiateStandalonePayment = {
  __typename?: 'InitiateStandalonePayment';
  payment?: Maybe<InitiateStandalonePaymentOutput>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * Input fields for initiating a standalone payment.
 *
 * The amount should always be provided in the minor unit of currency (e.g., pence not pounds,
 * cents not dollars, etc.).
 *
 * A standalone payment can be made against a specific ledger (e.g., a debt ledger) by
 * providing the ledger id. Accounts have a default ledger that will be used if not provided.
 */
export type InitiateStandalonePaymentInput = {
  /** The account number. */
  accountNumber: Scalars['String'];
  /** The amount to be collected in the minor unit of currency. */
  amount: Scalars['Int'];
  /** The method by which the payment is being collected. */
  collectionMethod?: InputMaybe<CollectionMethod>;
  /** A description of the purpose of the payment. */
  description: Scalars['String'];
  /** The id of the specific ledger against which this payment should be applied. */
  ledgerId?: InputMaybe<Scalars['ID']>;
};

/** Tokens required to collect and retrieve a standalone payment. */
export type InitiateStandalonePaymentOutput = {
  __typename?: 'InitiateStandalonePaymentOutput';
  /** The retrieval token for this standalone payment. */
  retrievalToken: Scalars['String'];
  /** The secret used to collect the payment. */
  secretToken: Scalars['String'];
};

export type InkBucket = Node & {
  __typename?: 'InkBucket';
  /** The icon code point. */
  icon?: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  /** The ink bucket name. */
  name: Scalars['String'];
};

/** An enumeration. */
export enum InkCommunicationChannel {
  Email = 'EMAIL',
  GenericApi = 'GENERIC_API',
  Post = 'POST',
  Sms = 'SMS',
  TwilioWhatsapp = 'TWILIO_WHATSAPP',
}

export type InkContactChannelIdentities = {
  __typename?: 'InkContactChannelIdentities';
  /** All contacts for this conversation. */
  all: Array<InkContactChannelIdentity>;
  /** The default contact for this conversation. */
  default?: Maybe<InkContactChannelIdentity>;
};

export type InkContactChannelIdentity = {
  __typename?: 'InkContactChannelIdentity';
  /** The channel of the contact. */
  channel: InkCommunicationChannel;
  /** The name to display to the user. */
  displayName: Scalars['String'];
  /** The handle. */
  handle: Scalars['String'];
};

export type InkConversation = {
  __typename?: 'InkConversation';
  /** The account users on the conversation. */
  accountUsers?: Maybe<Array<AccountUserType>>;
  /** The buckets the conversation is currently in. */
  buckets?: Maybe<Array<InkBucket>>;
  /** The contact channel identities associated with this conversation. */
  contactChannelIdentities: InkContactChannelIdentities;
  /** Conversation events. */
  events: InkConversationEventsConnection;
  id: Scalars['ID'];
  /** The status of the conversation. */
  status: InkConversationStatus;
};

export type InkConversationEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type InkConversationEvent = InkNewMessage;

export type InkConversationEventInterface = {
  /** The time the conversation event occurred. */
  occurredAt: Scalars['DateTime'];
};

export type InkConversationEventsConnection = {
  __typename?: 'InkConversationEventsConnection';
  /** Contains the nodes in this connection. */
  edges: Array<InkConversationEventsEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `InkConversationEvents` and its cursor. */
export type InkConversationEventsEdge = {
  __typename?: 'InkConversationEventsEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: InkConversationEvent;
};

export enum InkConversationStatus {
  Closed = 'CLOSED',
  Open = 'OPEN',
  OpenCustomerReplied = 'OPEN_CUSTOMER_REPLIED',
  OpenNew = 'OPEN_NEW',
  OpenReminded = 'OPEN_REMINDED',
  Snoozed = 'SNOOZED',
}

/** This type wraps around the `Message` type for emails. */
export type InkEmail = {
  __typename?: 'InkEmail';
  /** Attachments on the message. */
  attachments: Array<InkMessageAttachment>;
  /** CC recipients on the message. */
  cc: Array<Scalars['String']>;
  /** The contact channel identity. */
  contactChannelIdentity: InkContactChannelIdentity;
  /** The delivery status of the message. */
  delivery: InkMessageDelivery;
  /** The direction of the email. */
  direction: InkMessageDirection;
  /** The content of the message. */
  displayContent: Scalars['String'];
  /** From email address. */
  fromHandle: Scalars['String'];
  /** Is this an message an email. */
  isChannelEmail: Scalars['Boolean'];
  /** The time the message was sent/received. */
  occurredAt: Scalars['DateTime'];
  /** The email subject. */
  subject: Scalars['String'];
  /** All Tags associated with a message. */
  tags: Array<InkTag>;
  /** The addresses that the message was sent to. */
  toHandles?: Maybe<Array<Scalars['String']>>;
};

/** This type is used to create an inbound email. */
export type InkEmailMessageInput = {
  /** Message attachments. */
  attachments?: InputMaybe<Array<InkGenericMessageAttachmentInput>>;
  /** The carbon copy (cc) email addresses the message was sent to. */
  ccAddresses?: InputMaybe<Array<Scalars['Email']>>;
  /** The email address the message was sent from. */
  fromAddress: Scalars['Email'];
  /** The content of the message, as plain text. */
  plainTextContent: Scalars['String'];
  /** The S3 bucket in which the original email is stored. */
  s3Bucket?: InputMaybe<Scalars['String']>;
  /** The S3 key of the original email. */
  s3Key?: InputMaybe<Scalars['String']>;
  /** The email subject/title. */
  subject: Scalars['String'];
  /** The email addresses the message was sent to. */
  toAddresses: Array<Scalars['Email']>;
};

/**
 * This message type is used for messages that belong to contact
 * channels without a more granular message type.
 */
export type InkGenericMessage = Node & {
  __typename?: 'InkGenericMessage';
  /** The contact channel identity. */
  contactChannelIdentity: InkContactChannelIdentity;
  /** The delivery status. */
  delivery: InkMessageDelivery;
  /** The direction of the message. */
  direction: InkMessageDirection;
  /** The content of the message. */
  displayContent: Scalars['String'];
  /** The identity the message was sent from. */
  fromHandle: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  /** The time the message was sent/received at. */
  occurredAt: Scalars['DateTime'];
  /** The identity the message was sent to. */
  toHandle: Scalars['String'];
};

export type InkGenericMessageAttachmentInput = {
  /** The S3 bucket of the attachment. */
  s3Bucket: Scalars['String'];
  /** The S3 key of the attachment. */
  s3Key: Scalars['String'];
};

/** This type is used to create an generic message. */
export type InkGenericMessageInput = {
  /** Message attachments. */
  attachments?: InputMaybe<Array<InkGenericMessageAttachmentInput>>;
  /** The identity the message was sent from. */
  fromHandle: Scalars['String'];
  /** The content of the message, as plain text. */
  plainTextContent: Scalars['String'];
  /** The identity the message was sent to. */
  toHandle: Scalars['String'];
};

/** This type wraps around the `Message` type for LINE message. */
export type InkLine = {
  __typename?: 'InkLine';
  /** Attachments on the LINE message. */
  attachments: Array<InkMessageAttachment>;
  /** The contact channel identity. */
  contactChannelIdentity: InkContactChannelIdentity;
  /** The delivery status of the message. */
  delivery: InkMessageDelivery;
  /** The direction of the message. */
  direction: InkMessageDirection;
  /** From LINE id. */
  fromHandle: Scalars['String'];
  /** Is this a LINE message. */
  isChannelLine: Scalars['Boolean'];
  /** The line message content. */
  lineMessage: LineMessage;
  /** The time the message was sent/received. */
  occurredAt: Scalars['DateTime'];
  /** All Tags associated with a message. */
  tags: Array<InkTag>;
  /** To LINE id. */
  toHandle: Scalars['String'];
};

export type InkMessage =
  | InkEmail
  | InkGenericMessage
  | InkLine
  | InkPost
  | InkSms
  | InkWhatsApp;

export type InkMessageAttachment = {
  __typename?: 'InkMessageAttachment';
  /** The url for fetching the attachment. */
  fetchUrl?: Maybe<Scalars['String']>;
  /** The filename. */
  filename: Scalars['String'];
  /** The size in bytes. */
  sizeInBytes?: Maybe<Scalars['Int']>;
};

export type InkMessageDelivery = {
  __typename?: 'InkMessageDelivery';
  /** Message delivery status. */
  status: InkMessageDeliveryStatus;
};

export enum InkMessageDeliveryStatus {
  Delivered = 'DELIVERED',
  Failed = 'FAILED',
  OutsideReplyWindow = 'OUTSIDE_REPLY_WINDOW',
  Pending = 'PENDING',
  Sent = 'SENT',
}

export enum InkMessageDirection {
  Inbound = 'INBOUND',
  Outbound = 'OUTBOUND',
}

/**
 * An Ink message used as an input.
 *
 * This is intended to be morally equivalent to a tagged union; exactly
 * one of the properties provided here is expected to be provided.
 *
 * At current, only the generic message type is provided, because only
 * the generic message type is currently supported as an input type.
 * This is intended to be a backwards-compatible extension point to
 * allow other message input types to be added in the future.
 */
export type InkMessageInput = {
  email?: InputMaybe<InkEmailMessageInput>;
  generic?: InputMaybe<InkGenericMessageInput>;
  post?: InputMaybe<InkPostMessageInput>;
};

/** This types is used for both the message-received and message-sent conversation events. */
export type InkNewMessage = InkConversationEventInterface & {
  __typename?: 'InkNewMessage';
  /** The message. */
  message: InkMessage;
  /** The time the conversation event occurred. */
  occurredAt: Scalars['DateTime'];
};

/** This type wraps around the `Message` type for Post. */
export type InkPost = {
  __typename?: 'InkPost';
  /** The contact channel identity. */
  contactChannelIdentity: InkContactChannelIdentity;
  /** The notes left when a message was uploaded. */
  displayContent: Scalars['String'];
  /** The from property id. */
  fromHandle: Scalars['String'];
  /** Determine if the message is a post message. */
  isChannelPost: Scalars['Boolean'];
  /** The content of the message. */
  rawPlainTextContent: Scalars['String'];
  /** The to property id. */
  toHandle: Scalars['String'];
};

/** This type is used to create an inbound post. */
export type InkPostMessageInput = {
  /** The account number that the letter was sent from. */
  accountNumber?: InputMaybe<Scalars['String']>;
  /** Message attachments. */
  attachments?: InputMaybe<Array<InkGenericMessageAttachmentInput>>;
  /** Notes on the letter. */
  notes?: InputMaybe<Scalars['String']>;
  /** The content of the message, as plain text. */
  plainTextContent: Scalars['String'];
};

/** This type wraps around the `Message` type for SMS. */
export type InkSms = {
  __typename?: 'InkSMS';
  /** The contact channel identity. */
  contactChannelIdentity: InkContactChannelIdentity;
  /** The delivery status. */
  delivery: InkMessageDelivery;
  /** The direction of the message. */
  direction: InkMessageDirection;
  /** The content of the message. */
  displayContent: Scalars['String'];
  /** The phone number the message was sent from. */
  fromHandle: Scalars['String'];
  /** Is this an SMS message. */
  isChannelSms: Scalars['Boolean'];
  /** The time the message was sent/received at. */
  occurredAt: Scalars['DateTime'];
  /** All Tags associated with a message. */
  tags: Array<InkTag>;
  /** The phone number the message was sent to. */
  toHandle: Scalars['String'];
};

export type InkTag = Node & {
  __typename?: 'InkTag';
  /** The ID of the object */
  id: Scalars['ID'];
  /** Tag for a message. */
  name: Scalars['String'];
};

/** This type wraps around the `Message` type for WhatsApp message. */
export type InkWhatsApp = {
  __typename?: 'InkWhatsApp';
  /** Whatsapp message contact channel identity. */
  contactChannelIdentity: InkContactChannelIdentity;
  /** Whatsapp message delivery status. */
  delivery: InkMessageDelivery;
  /** The direction of the message. */
  direction: InkMessageDirection;
  /** From WhatsApp phone number. */
  fromHandle: Scalars['String'];
  /** Whether or not the message is a whatsapp message. */
  isChannelWhatsapp: Scalars['Boolean'];
  /** Date when the conversation event was created. */
  occurredAt: Scalars['DateTime'];
  /** All Tags associated with a message. */
  tags: Array<InkTag>;
  /** Whatsapp contact phone number. */
  toHandle: Scalars['String'];
  /** The vendor id. */
  vendorId?: Maybe<Scalars['String']>;
  /** Whatsapp message content. */
  whatsappContent: WhatsAppTextMessage;
};

/**
 * Insert special circumstance priority services for the user.
 * This mutation replaces all the active PSR records with the given input.
 * Make sure to include all existing PSR records for the user if you do not intend
 * to deactivate any records.
 *
 * The possible errors that can be raised are:
 *
 * - KT-GB-5415: The requested PSR record type is not valid.
 * - KT-GB-5411: Failed to update special circumstance record.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type InsertUserPsrRecords = {
  __typename?: 'InsertUserPSRRecords';
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** List of special circumstance records for the user. */
  records?: Maybe<Array<Maybe<SpecialCircumstanceRecordType>>>;
};

export type InsetType = {
  __typename?: 'InsetType';
  id: Scalars['ID'];
  name: Scalars['String'];
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  reference: Scalars['String'];
};

/**
 *
 *     The frequency at which contributations are made
 *
 */
export enum Interval {
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
}

export type IntervalMeasurementType = MeasurementInterface & {
  __typename?: 'IntervalMeasurementType';
  accumulation?: Maybe<Scalars['Decimal']>;
  durationInSeconds: Scalars['Int'];
  endAt: Scalars['DateTime'];
  /** This type will return more granular data about the measurement. */
  metaData?: Maybe<MeasurementsMetadataOutput>;
  readAt: Scalars['DateTime'];
  source: Scalars['String'];
  startAt: Scalars['DateTime'];
  unit: Scalars['String'];
  value: Scalars['Decimal'];
};

/**
 * Invalidates a payment instruction.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-3926: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type InvalidatePaymentInstruction = {
  __typename?: 'InvalidatePaymentInstruction';
  instruction?: Maybe<InvalidatePaymentInstructionOutput>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** Input for invalidating an arbitrary payment instruction. */
export type InvalidatePaymentInstructionInput = {
  accountNumber: Scalars['String'];
  /** The id of the payment instruction to be invalidated. */
  id: Scalars['String'];
};

/** Output for invalidating an arbitrary payment instruction. */
export type InvalidatePaymentInstructionOutput = {
  __typename?: 'InvalidatePaymentInstructionOutput';
  id?: Maybe<Scalars['Int']>;
};

/**
 * Invalidate a previously issued expiring/pre-signed token.
 *
 * This mutation can be used to invalidate the token itself.
 *
 * To invalidate tokens issued to a particular user, use
 * InvalidatePreSignedTokensForUser mutation.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1129: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type InvalidatePreSignedToken = {
  __typename?: 'InvalidatePreSignedToken';
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  token?: Maybe<PreSignedToken>;
};

/** Input type for the InvalidatePreSignedToken mutation. */
export type InvalidatePreSignedTokenInput = {
  token: Scalars['String'];
};

/**
 * Invalidate pre-signed tokens previously issued to a particular user.
 *
 * This mutation can invalidate all pre-signed tokens issued to a
 * customer, or only tokens of a given scope.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1129: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type InvalidatePreSignedTokensForUser = {
  __typename?: 'InvalidatePreSignedTokensForUser';
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  tokens?: Maybe<Array<Maybe<PreSignedToken>>>;
};

/** Input type for the InvalidatePreSignedTokensForUser mutation. */
export type InvalidatePreSignedTokensForUserInput = {
  /** The email address of the user whose tokens should be invalidated. */
  email: Scalars['String'];
  /** The scope of the token to invalidate.  If this argument is not specified, all pre-signed tokens issued to the user are invalidated. */
  scope?: InputMaybe<PreSignedTokenScope>;
};

/**
 * Invalidate a previously issued refresh token.
 *
 * This mutation can be used to invalidate the token itself.  To
 * invalidate tokens issued to a particular user, use
 * InvalidateRefreshTokensForUser.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1130: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type InvalidateRefreshToken = {
  __typename?: 'InvalidateRefreshToken';
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  token?: Maybe<RefreshToken>;
};

/** Input type for the InvalidateRefreshToken mutation. */
export type InvalidateRefreshTokenInput = {
  refreshToken: Scalars['String'];
};

/**
 * Invalidate refresh tokens previously issued to a particular user.
 *
 * This mutation will invalidate all refresh tokens issued to a
 * customer.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1128: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type InvalidateRefreshTokensForUser = {
  __typename?: 'InvalidateRefreshTokensForUser';
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  tokens?: Maybe<Array<Maybe<RefreshToken>>>;
};

/** Input type for the InvalidateRefreshTokensForUser mutation. */
export type InvalidateRefreshTokensForUserInput = {
  /** The email address of the user whose tokens should be invalidated. */
  email: Scalars['String'];
};

/** An invoice is a bill that contains individual transactions  (i.e. charges, credits, payments, and repayments). These may come from any period of time. */
export type InvoiceBillingDocumentConnectionTypeConnection = {
  __typename?: 'InvoiceBillingDocumentConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InvoiceBillingDocumentConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `InvoiceBillingDocumentConnectionType` and its cursor. */
export type InvoiceBillingDocumentConnectionTypeEdge = {
  __typename?: 'InvoiceBillingDocumentConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<InvoiceBillingDocumentType>;
};

/** An invoice is a bill that contains individual transactions  (i.e. charges, credits, payments, and repayments). These may come from any period of time. */
export type InvoiceBillingDocumentType = {
  __typename?: 'InvoiceBillingDocumentType';
  /** Billing document that annuls this invoice. */
  annulledBy?: Maybe<AnnulmentBillingDocumentType>;
  /** First time the invoice was issued. */
  firstIssued?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  /** The unique billing document's reference that can be used for identifying it externally. */
  number?: Maybe<Scalars['String']>;
  /** URL to the PDF of the Invoice. */
  pdfUrl?: Maybe<Scalars['String']>;
};

export type InvoiceType = BillInterface & {
  __typename?: 'InvoiceType';
  billType?: Maybe<BillTypeEnum>;
  fromDate?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['ID']>;
  /** The date the bill was sent to the customer. */
  issuedDate?: Maybe<Scalars['Date']>;
  /**
   * Requesting this field generates a temporary URL at which bill is available.
   *         This URL will expire after approximately an hour.  It is intended for redirection purposes,
   *         NOT persistence in any form (e.g. inclusion in emails or the body of a web page).
   *         This field can raise an error with errorClass NOT_FOUND if the bill document has not
   *         been created/issued yet.
   *
   *
   *
   */
  temporaryUrl?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['Date']>;
};

export type ItemSizeType = FractionSizeType | PointsSizeType;

/** Items are sections making up a screen. They can be different types, hence Union. */
export type ItemType =
  | AnimationType
  | CardComponentType
  | ImageType
  | PillButtonType
  | RectangularButtonType
  | TextType;

export type JoinSupplierProcessConnectionTypeConnection = {
  __typename?: 'JoinSupplierProcessConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<JoinSupplierProcessConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `JoinSupplierProcessConnectionType` and its cursor. */
export type JoinSupplierProcessConnectionTypeEdge = {
  __typename?: 'JoinSupplierProcessConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<JoinSupplierProcessType>;
};

/** Represents a Join Supplier process. */
export type JoinSupplierProcessType = AbstractSupplyPointProcessInterface & {
  __typename?: 'JoinSupplierProcessType';
  /** The ID or the primary key of the lifecycle process. */
  id?: Maybe<Scalars['ID']>;
  /** The status of the process. */
  status?: Maybe<LeaveSupplierProcessStatus>;
  /** The supply points associated with the process. */
  supplyPoints: SupplyPointConnectionTypeConnection;
};

/** Represents a Join Supplier process. */
export type JoinSupplierProcessTypeSupplyPointsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** Information about what version of Kraken is being executed by this service. */
export type KrakenVersionType = {
  __typename?: 'KrakenVersionType';
  /** The git commit SHA that is being executed. */
  SHA?: Maybe<Scalars['String']>;
  /** The version number that is being executed. */
  number?: Maybe<Scalars['String']>;
};

export type LeavePropertyProcessConnectionTypeConnection = {
  __typename?: 'LeavePropertyProcessConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LeavePropertyProcessConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `LeavePropertyProcessConnectionType` and its cursor. */
export type LeavePropertyProcessConnectionTypeEdge = {
  __typename?: 'LeavePropertyProcessConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<LeavePropertyProcessType>;
};

/** Represents a Leave Property process. */
export type LeavePropertyProcessType = AbstractSupplyPointProcessInterface & {
  __typename?: 'LeavePropertyProcessType';
  /** The ID or the primary key of the lifecycle process. */
  id?: Maybe<Scalars['ID']>;
  /** The status of the process. */
  status?: Maybe<LeaveSupplierProcessStatus>;
  /** The supply points associated with the process. */
  supplyPoints: SupplyPointConnectionTypeConnection;
};

/** Represents a Leave Property process. */
export type LeavePropertyProcessTypeSupplyPointsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** Output of a LeaveSupplier journey cancellation. */
export type LeaveSupplierCancelled = {
  __typename?: 'LeaveSupplierCancelled';
  /** The message to display to the user on cancellation. */
  message: Scalars['String'];
};

export type LeaveSupplierInput = {
  /** The Kraken account number. */
  accountNumber: Scalars['String'];
  marketData?: InputMaybe<LeaveSupplierMarketInputType>;
  /** The requested last day of supply. */
  requestedSupplyEndDate: Scalars['Date'];
};

/** Termination was successfully initiated. */
export type LeaveSupplierInstigated = {
  __typename?: 'LeaveSupplierInstigated';
  /** The ID of the newly created or existing leave supplier process. */
  leaveSupplierProcessId: Scalars['ID'];
  /** The message to display to the user on termination initiation. */
  message: Scalars['String'];
};

export type LeaveSupplierMarketInputType = {
  /** A list of supply points for initiating a leave supplier journey. */
  supplyPointData?: InputMaybe<
    Array<InputMaybe<_DefaultMarketTerminationInput>>
  >;
};

export type LeaveSupplierProcessConnectionTypeConnection = {
  __typename?: 'LeaveSupplierProcessConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LeaveSupplierProcessConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `LeaveSupplierProcessConnectionType` and its cursor. */
export type LeaveSupplierProcessConnectionTypeEdge = {
  __typename?: 'LeaveSupplierProcessConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<LeaveSupplierProcessType>;
};

/** The status of the LeaveSupplier process. */
export enum LeaveSupplierProcessStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Errored = 'ERRORED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  PartiallyCancelled = 'PARTIALLY_CANCELLED',
  PartiallyCompleted = 'PARTIALLY_COMPLETED',
  PartiallyReversed = 'PARTIALLY_REVERSED',
  Pending = 'PENDING',
  Reversed = 'REVERSED',
  ReverseInProgress = 'REVERSE_IN_PROGRESS',
  Stalled = 'STALLED',
}

/** Represents a Leave Supplier process. Conceptually, it contains information related to supply points that are associated with a Leave Supplier journey. */
export type LeaveSupplierProcessType = AbstractSupplyPointProcessInterface & {
  __typename?: 'LeaveSupplierProcessType';
  /** The ID or the primary key of the lifecycle process. */
  id?: Maybe<Scalars['ID']>;
  /** The status of the process. */
  status?: Maybe<LeaveSupplierProcessStatus>;
  /** The supply points associated with the process. */
  supplyPoints: SupplyPointConnectionTypeConnection;
};

/** Represents a Leave Supplier process. Conceptually, it contains information related to supply points that are associated with a Leave Supplier journey. */
export type LeaveSupplierProcessTypeSupplyPointsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type LedgerInterface = {
  /** Whether this ledger's balance contributes to the account's balance. */
  affectsAccountBalance?: Maybe<Scalars['Boolean']>;
  /** The amount owed from the customer perspective. A positive value implies the customer owes the business, while a negative amount implies the customer is in credit. */
  amountOwedByCustomer?: Maybe<Scalars['Int']>;
  /** The current balance on the ledger in minor units of currency. */
  balance?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  /** An invoice is a bill that contains individual transactions  (i.e. charges, credits, payments, and repayments). These may come from any period of time. */
  invoices?: Maybe<InvoiceBillingDocumentConnectionTypeConnection>;
  ledgerType?: Maybe<Scalars['String']>;
  /** The display name of the ledger. */
  name?: Maybe<Scalars['String']>;
  /** The canonical name of the ledger. */
  number?: Maybe<Scalars['String']>;
  paymentAdequacy?: Maybe<PaymentAdequacyDetailsType>;
  /** Refund requests for a given ledger. */
  refundRequests?: Maybe<RefundRequestConnectionTypeConnection>;
  /** Repayment requests for a given ledger. */
  repaymentRequests?: Maybe<RepaymentRequestConnectionTypeConnection>;
  /** A statement is a billing document that contains all entries on a ledger during a period of time. A customer can understand how their ledger's balance has changed by looking at each statement in series. */
  statements?: Maybe<StatementBillingDocumentConnectionTypeConnection>;
  transactions?: Maybe<TransactionConnectionTypeConnection>;
};

export type LedgerInterfaceInvoicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type LedgerInterfaceRefundRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type LedgerInterfaceRepaymentRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type LedgerInterfaceStatementsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type LedgerInterfaceTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  transactionTypes?: InputMaybe<Array<InputMaybe<TransactionTypeFilter>>>;
};

/** Ledgers provide the foundation of Kraken’s bookkeeping functionality. Similar to a bank account, they allow us to keep track of financial activity on a particular Kraken account. */
export type LedgerType = LedgerInterface & {
  __typename?: 'LedgerType';
  /** Whether this ledger's balance contributes to the account's balance. */
  affectsAccountBalance?: Maybe<Scalars['Boolean']>;
  /** The amount owed from the customer perspective. A positive value implies the customer owes the business, while a negative amount implies the customer is in credit. */
  amountOwedByCustomer?: Maybe<Scalars['Int']>;
  /** The current balance on the ledger in minor units of currency. */
  balance?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  /** An invoice is a bill that contains individual transactions  (i.e. charges, credits, payments, and repayments). These may come from any period of time. */
  invoices?: Maybe<InvoiceBillingDocumentConnectionTypeConnection>;
  /** The ledger type code. */
  ledgerType?: Maybe<Scalars['String']>;
  /** The display name of the ledger. */
  name?: Maybe<Scalars['String']>;
  /** The canonical name of the ledger. */
  number?: Maybe<Scalars['String']>;
  paymentAdequacy?: Maybe<PaymentAdequacyDetailsType>;
  /** Refund requests for a given ledger. */
  refundRequests?: Maybe<RefundRequestConnectionTypeConnection>;
  /** Repayment requests for a given ledger. */
  repaymentRequests?: Maybe<RepaymentRequestConnectionTypeConnection>;
  /** A statement is a billing document that contains all entries on a ledger during a period of time. A customer can understand how their ledger's balance has changed by looking at each statement in series. */
  statements?: Maybe<StatementBillingDocumentConnectionTypeConnection>;
  transactions?: Maybe<TransactionConnectionTypeConnection>;
};

/** Ledgers provide the foundation of Kraken’s bookkeeping functionality. Similar to a bank account, they allow us to keep track of financial activity on a particular Kraken account. */
export type LedgerTypeInvoicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  invoiceId?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** Ledgers provide the foundation of Kraken’s bookkeeping functionality. Similar to a bank account, they allow us to keep track of financial activity on a particular Kraken account. */
export type LedgerTypeRefundRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** Ledgers provide the foundation of Kraken’s bookkeeping functionality. Similar to a bank account, they allow us to keep track of financial activity on a particular Kraken account. */
export type LedgerTypeRepaymentRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** Ledgers provide the foundation of Kraken’s bookkeeping functionality. Similar to a bank account, they allow us to keep track of financial activity on a particular Kraken account. */
export type LedgerTypeStatementsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  statementId?: InputMaybe<Scalars['Int']>;
};

/** Ledgers provide the foundation of Kraken’s bookkeeping functionality. Similar to a bank account, they allow us to keep track of financial activity on a particular Kraken account. */
export type LedgerTypeTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  transactionTypes?: InputMaybe<Array<InputMaybe<TransactionTypeFilter>>>;
};

export type LifecycleAddressInput = {
  /** Country code. */
  countryCode?: InputMaybe<Scalars['String']>;
  /** Line 1 of address. */
  line1: Scalars['String'];
  /** Line 2 of address. */
  line2?: InputMaybe<Scalars['String']>;
  /** Line 3 of address. */
  line3?: InputMaybe<Scalars['String']>;
  /** Line 4 of address. */
  line4?: InputMaybe<Scalars['String']>;
  /** Line 5 of address. */
  line5?: InputMaybe<Scalars['String']>;
  /** Postal code. */
  postalCode: Scalars['String'];
};

export type LifecycleProcessesType = {
  __typename?: 'LifecycleProcessesType';
  /** List of JoinSupplierProcess for an account. */
  joinSupplierProcesses?: Maybe<JoinSupplierProcessConnectionTypeConnection>;
  /** List of LeavePropertyProcess for an account. */
  leavePropertyProcesses?: Maybe<LeavePropertyProcessConnectionTypeConnection>;
  /** List of LeaveSupplierProcess for an account. */
  leaveSupplierProcesses?: Maybe<LeaveSupplierProcessConnectionTypeConnection>;
  /** List of OccupyPropertyProcess for an account. */
  occupyPropertyProcesses?: Maybe<OccupyPropertyProcessConnectionTypeConnection>;
};

export type LifecycleProcessesTypeJoinSupplierProcessesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type LifecycleProcessesTypeLeavePropertyProcessesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type LifecycleProcessesTypeLeaveSupplierProcessesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type LifecycleProcessesTypeOccupyPropertyProcessesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** A base error type. Should be used for general application or lower level errors. */
export type LineCommonError = {
  __typename?: 'LineCommonError';
  /** The field that for which this error should be associated */
  field?: Maybe<Scalars['String']>;
  /** The error message to display to the user. */
  message: Scalars['String'];
};

/**
 * A LINE specific emoji object.
 * refs: https://developers.line.biz/en/reference/messaging-api/#text-message
 */
export type LineEmoji = {
  __typename?: 'LineEmoji';
  /** The emoji id. */
  emojiId: Scalars['String'];
  /** The location of the emoji in the message. */
  index: Scalars['Int'];
  /** The length of the emoji string placeholder. */
  length?: Maybe<Scalars['Int']>;
  /** The product id. */
  productId: Scalars['String'];
};

export type LineImageMessage = {
  __typename?: 'LineImageMessage';
  id: Scalars['ID'];
};

export enum LineLinkErrorType {
  AlreadyLinked = 'ALREADY_LINKED',
  NoMatchingLineLink = 'NO_MATCHING_LINE_LINK',
}

/** Link Successful. Complete link process with LINE. */
export type LineLinkRedirectResponse = {
  __typename?: 'LineLinkRedirectResponse';
  redirectUrl: Scalars['String'];
};

export type LineMessage =
  | LineImageMessage
  | LineStickerMessage
  | LineTextMessage;

export type LineStickerMessage = {
  __typename?: 'LineStickerMessage';
  /** Keywords describing the sticker. */
  keywords: Array<Scalars['String']>;
  /** Sticker package id. */
  packageId: Scalars['String'];
  /** Sticker resource type. */
  resourceType: Scalars['String'];
  /** Sticker id. */
  stickerId: Scalars['String'];
  /** Text used to customize some stickers. */
  text: Scalars['String'];
};

export type LineTextMessage = {
  __typename?: 'LineTextMessage';
  /** The display content. */
  displayContent: Scalars['String'];
  /** The emojis in the message. */
  emojis?: Maybe<Array<LineEmoji>>;
};

export type LineUnlinkedResponse = {
  __typename?: 'LineUnlinkedResponse';
  message: Scalars['String'];
};

/** An action which navigates to any URL. */
export type LinkActionType = ActionInterface &
  IdentifiableInterface & {
    __typename?: 'LinkActionType';
    /** Unique identifier of the object. */
    id?: Maybe<Scalars['ID']>;
    /** The name of the action object's type. */
    typeName?: Maybe<Scalars['String']>;
    /** The name of the object's type. */
    typename?: Maybe<Scalars['String']>;
    /** The URL to navigate to. */
    url: Scalars['String'];
  };

/** Returned when no LineAccountLink record matching the parameters exists. */
export type LinkTokenNotFound = {
  __typename?: 'LinkTokenNotFound';
  /** The type of error that occurred. */
  type: LineLinkErrorType;
};

/** An enumeration. */
export enum LinkTrainingStatus {
  /** In training */
  InTraining = 'IN_TRAINING',
  /** Not applicable */
  NotApplicable = 'NOT_APPLICABLE',
  /** Qualified */
  Qualified = 'QUALIFIED',
}

/** Link an AccountUser to a LINE account. */
export type LinkUserToLineInput = {
  linkToken: Scalars['String'];
};

export type LinkUserToLineResponse =
  | AlreadyLinkedError
  | LineLinkRedirectResponse
  | LinkTokenNotFound;

export enum LinkedObjectType {
  Account = 'ACCOUNT',
  AccountUser = 'ACCOUNT_USER',
}

/** An enumeration. */
export enum LinkedServiceService {
  /** Highway and surface drainage */
  CombinedDrainage = 'COMBINED_DRAINAGE',
  /** Abated highway and surface drainage */
  CombinedDrainageAbated = 'COMBINED_DRAINAGE_ABATED',
  /** Waste water, highway and surface drainage combined */
  CombinedWaste = 'COMBINED_WASTE',
  /** Abated waste water, highway and surface drainage combined */
  CombinedWasteAbated = 'COMBINED_WASTE_ABATED',
  /** Fresh water */
  Fresh = 'FRESH',
  /** Highway drainage */
  HighwayDrainage = 'HIGHWAY_DRAINAGE',
  /** Surface drainage */
  SurfaceDrainage = 'SURFACE_DRAINAGE',
  /** Waste water */
  Waste = 'WASTE',
  /** Waste water and surface drainage combined */
  WasteWaterAndSurfaceDrainage = 'WASTE_WATER_AND_SURFACE_DRAINAGE',
}

export type LinkedServiceType = {
  __typename?: 'LinkedServiceType';
  activeFrom: Scalars['DateTime'];
  activeTo?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  service: LinkedServiceService;
};

/** A loyalty card. */
export type LoyaltyCardType = {
  __typename?: 'LoyaltyCardType';
  id: Scalars['ID'];
  /** The number of the loyalty card. */
  number?: Maybe<Scalars['String']>;
  /** The scheme of the loyalty card. */
  scheme?: Maybe<Scalars['String']>;
  /** The status of the loyalty card. */
  status?: Maybe<Scalars['String']>;
};

/** A Loyalty Point ledger entry. */
export type LoyaltyPointLedgerEntryType = {
  __typename?: 'LoyaltyPointLedgerEntryType';
  /** The account number associated with the entry. */
  accountNumber?: Maybe<Scalars['String']>;
  /** Equal to the `balance_carried_forward` from the previous ledger entry or zero if this is the first one. */
  balanceBroughtForward?: Maybe<Scalars['String']>;
  /** Equal to the `balance_brought_forward` plus or minus the value depending on the ledger_type. */
  balanceCarriedForward?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** The `LedgerEntryType`. Either CHARGE or CREDIT. */
  ledgerType?: Maybe<Scalars['String']>;
  /** The date the points were added to the ledger. */
  postedAt?: Maybe<Scalars['DateTime']>;
  /** The reason the entry was being added. */
  reasonCode?: Maybe<Scalars['String']>;
  /** The value of the charge or credit. */
  value?: Maybe<Scalars['String']>;
};

/**
 * Mark a closed print batch as Processed
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-9011: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type MarkPrintBatchAsProcessed = {
  __typename?: 'MarkPrintBatchAsProcessed';
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  printBatch?: Maybe<PrintBatchType>;
};

export type MasqueradeAuthentication = {
  __typename?: 'MasqueradeAuthentication';
  /** A list of any errors that occurred while running this mutation. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  /** A Kraken Token that can be used to authenticate to the API, masquerading as the desired user. */
  token?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum MaximumRefundReasonChoices {
  /** Maximum refund is equal to the maximum refund amount allowed to be requested via the dashboard. */
  MaxAllowedToRequestViaDashboard = 'MAX_ALLOWED_TO_REQUEST_VIA_DASHBOARD',
  /** Maximum refund is equal to the current balance minus the account recommended balance. */
  MaxAvailableAmount = 'MAX_AVAILABLE_AMOUNT',
  /** Maximum refund is equal to the total amount the customer has paid using the current account Direct Debit instruction. */
  TotalAmountPaidViaActiveDdi = 'TOTAL_AMOUNT_PAID_VIA_ACTIVE_DDI',
}

export type MaximumRefundType = {
  __typename?: 'MaximumRefundType';
  /** The maximum amount available to be requested as a refund. */
  amount?: Maybe<Scalars['Int']>;
  /** The reason why a specific amount is the maximum available to be requested as a refund. */
  reasonToRecommendAmount?: Maybe<MaximumRefundReasonChoices>;
  /** The recommended minimum balance an account should have when asking for a refund. */
  recommendedBalance?: Maybe<Scalars['Int']>;
};

/** Pagination for measurements. */
export type MeasurementConnection = {
  __typename?: 'MeasurementConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MeasurementEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `Measurement` and its cursor. */
export type MeasurementEdge = {
  __typename?: 'MeasurementEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<MeasurementInterface>;
};

export type MeasurementInterface = {
  /** This type will return more granular data about the measurement. */
  metaData?: Maybe<MeasurementsMetadataOutput>;
  readAt: Scalars['DateTime'];
  source: Scalars['String'];
  unit: Scalars['String'];
  value: Scalars['Decimal'];
};

export type MeasurementType = MeasurementInterface & {
  __typename?: 'MeasurementType';
  /** This type will return more granular data about the measurement. */
  metaData?: Maybe<MeasurementsMetadataOutput>;
  readAt: Scalars['DateTime'];
  source: Scalars['String'];
  unit: Scalars['String'];
  value: Scalars['Decimal'];
};

export type MeasurementsMetadataOutput = {
  __typename?: 'MeasurementsMetadataOutput';
  /** Statistics relating to the parent measurement node. */
  statistics?: Maybe<Array<Maybe<StatisticOutput>>>;
  /**
   * The source information relating to the parent measurement node.
   * @deprecated The 'typedSource' type is deprecated.
   *
   *
   * Please use 'utilityFilters' and the corresponding filter output instead.
   *
   *
   * - Marked as deprecated on 2024-06-17.
   * - Will be removed on 2026-01-30.
   */
  typedSource?: Maybe<TypedSourceOutput>;
  /** The source information relating to the parent measurement node. */
  utilityFilters?: Maybe<UtilityFiltersOutput>;
};

/** The media interface. */
export type MediaInterface = {
  /** The horizontal alignment of the media. */
  horizontalAlignment?: Maybe<Alignment>;
  /** The resource URL of the media. */
  mediaUrl: Scalars['String'];
};

/** An enumeration. */
export enum MessageChannel {
  /** Email */
  Email = 'EMAIL',
  /** Intercom */
  Intercom = 'INTERCOM',
  /** Junifer */
  Junifer = 'JUNIFER',
  /** Print */
  Print = 'PRINT',
  /** Push Notification */
  PushNotification = 'PUSH_NOTIFICATION',
  /** SMS */
  Sms = 'SMS',
}

export type Metadata = {
  __typename?: 'Metadata';
  /** The key for the metadata. */
  key: Scalars['String'];
  /** The metadata value. */
  value?: Maybe<Scalars['JSONString']>;
};

/** The metadata input type for mutations. */
export type MetadataInput = {
  /** An identifier for the associated object, e.g. account_number for the Account linked object type. */
  identifier: Scalars['String'];
  /** The key for the metadata. */
  key: Scalars['String'];
  /** The object that the metadata is associated with. */
  linkedObjectType: LinkedObjectType;
  /** The metadata value which should be a valid JSON string. */
  value: Scalars['JSONString'];
};

export type MoveIntoPropertyInput = {
  /** Date of move in. */
  moveInDate: Scalars['Date'];
  /** Property id of property to move into. */
  moveIntoPropertyId: Scalars['ID'];
  /** Is the customer responsible for billing at this moveIntoProperty. */
  responsibleForBilling: Scalars['Boolean'];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-GB-6628: Invalid data.
 * - KT-GB-6624: An error occurred when trying to process this house move.
 * - KT-CT-1113: Disabled GraphQL field requested.
 *
 */
export type MoveOut = {
  __typename?: 'MoveOut';
  /** The account initiating the move out. */
  account?: Maybe<AccountType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type MoveOutInput = {
  /** The account number. */
  accountNumber: Scalars['String'];
  /** Property to move into, for move within. */
  moveIntoProperty?: InputMaybe<MoveIntoPropertyInput>;
  /** Date of move out. */
  moveOutDate: Scalars['Date'];
  /** Address of 'where to send bill next'. */
  newBillingAddress?: InputMaybe<AddressInput>;
  /** Property id. */
  propertyId: Scalars['ID'];
};

/** Class to inherit mutations. */
export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Accept a goods quote.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-8223: Unauthorized.
   * - KT-CT-8201: Received an invalid quoteId.
   * - KT-CT-8224: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  acceptGoodsQuote?: Maybe<AcceptGoodsQuote>;
  /**
   * The possible errors that can be raised are:
   *
   * - KT-CT-4123: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  addCampaignToAccount?: Maybe<AddCampaignToAccount>;
  /**
   * Allow a repayment to be submitted.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-3944: Account repayment does not exist.
   * - KT-CT-3945: Unable to allow a repayment to be submitted.
   * - KT-CT-3950: The provided reason text is too long.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  allowRepaymentSubmission?: Maybe<AllowRepaymentSubmission>;
  /**
   * Amend an existing payment.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-3924: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  amendPayment?: Maybe<AmendPayment>;
  /**
   * Approve a repayment.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-3934: Repayment request already approved.
   * - KT-CT-3935: Repayment request cannot be paid.
   * - KT-CT-3959: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  approveRepayment?: Maybe<ApproveRepayment>;
  assignInkBucket?: Maybe<AssignInkBucketPayload>;
  /**
   * Look up an event to perform from its event_id, and return the next action to perform.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-8002: No event found.
   * - KT-CT-8003: Event has no execute function.
   * - KT-CT-8004: Error executing event in the backend.
   * - KT-CT-8007: Incorrect or missing parameters for backend screen event.
   * - KT-GB-9310: Account ineligible for joining Octoplus.
   * - KT-CT-1113: Disabled GraphQL field requested.
   *
   */
  backendScreenEvent?: Maybe<BackendScreenEvent>;
  /**
   * Block a repayment from being submitted.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-3944: Account repayment does not exist.
   * - KT-CT-3946: Unable to block a repayment from being submitted.
   * - KT-CT-3950: The provided reason text is too long.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  blockRepaymentSubmission?: Maybe<BlockRepaymentSubmission>;
  /**
   * Cancel a leave supplier process.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-10304: Mutation not enabled in this environment.
   * - KT-CT-10302: Invalid data.
   * - KT-CT-10305: Failed to cancel leave supplier process - market actions are no longer cancellable.
   * - KT-CT-10306: Failed to cancel leave supplier process - the cancellation workflow has not been configured.
   * - KT-CT-10307: Failed to cancel leave supplier process - failed to cancel market actions.
   * - KT-CT-10308: Failed to cancel leave supplier process.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  cancelLeaveSupplier: LeaveSupplierCancelled;
  /**
   * Cancel an in-flight payment.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-3924: Unauthorized.
   * - KT-CT-3954: Payment cancellation failed.
   * - KT-CT-3955: Payment cannot be cancelled.
   * - KT-CT-3956: Temporary error occurred.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  cancelPayment?: Maybe<CancelPayment>;
  /**
   * Cancel a repayment or refund request.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4231: Unauthorized.
   * - KT-CT-3930: The repayment or refund request does not exist.
   * - KT-CT-3931: This repayment or refund request cannot be cancelled.
   * - KT-CT-1113: Disabled GraphQL field requested.
   *
   */
  cancelRepaymentRequest?: Maybe<CancelRepaymentRequest>;
  /**
   * Close the Open Print Batch if any.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-9010: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  closeOpenPrintBatch: CloseOpenPrintBatch;
  /**
   * Collect deposit for the given account.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4177: Unauthorized.
   * - KT-CT-5711: No collection is required.
   * - KT-CT-5712: Deposit agreement does not exist or has not been accepted.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  collectDeposit?: Maybe<CollectDeposit>;
  /**
   * Attempt to collect a one-off payment. If an instruction type is provided and there is an existing payment instruction, the payment can be collected immediately. A request to collect a payment at a future date can also be made, in which case the instruction input type is not necessary, but an instruction must exist at the specified collection date for the payment to be collected successfully.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-3932: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  collectPayment?: Maybe<CollectPayment>;
  /**
   * Add charge to an account.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-5211: The charge reason with the requested code is deprecated.
   * - KT-CT-5212: The charge reason with the requested code does not exist.
   * - KT-CT-5213: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  createAccountCharge?: Maybe<CreateAccountCharge>;
  /**
   * Add credit to an account.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-5315: Invalid data.
   * - KT-CT-5314: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   * @deprecated The 'createAccountCredit' field is deprecated.
   *
   * Use postCredit mutation as it is ledger aware.
   *
   * - Marked as deprecated on 2022-07-04.
   * - Will be removed on 2024-01-01.
   */
  createAccountCredit?: Maybe<CreateAccountCredit>;
  /**
   * Add a note to an account.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4123: Unauthorized.
   * - KT-CT-4180: Account note must be a valid string.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  createAccountNote?: Maybe<CreateAccountNote>;
  /**
   * Replace an existing payment schedule with a new one that updates either the payment amount or payment day.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-3815: No active payment schedule found for this account.
   * - KT-CT-3822: Unauthorized.
   * - KT-CT-3923: Unauthorized.
   * - KT-CT-3941: Invalid data.
   * - KT-CT-3942: An unexpected error occurred.
   * - KT-CT-3947: An unexpected error occurred.
   * - KT-CT-3960: Invalid value for payment day.
   * - KT-CT-3961: Cannot update plan-associated payment schedule.
   * - KT-CT-3962: No new value provided to update payment schedule.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  createAccountPaymentSchedule?: Maybe<CreateAccountPaymentSchedule>;
  /**
   * Create an account reference.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4123: Unauthorized.
   * - KT-CT-8310: Invalid data.
   * - KT-CT-8311: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  createAccountReference?: Maybe<CreateAccountReference>;
  /**
   * Create an account reminder.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1401: Invalid data.
   * - KT-CT-1402: Unable to create account reminder.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  createAccountReminder?: Maybe<CreateAccountReminder>;
  /**
   * Create an affiliate link for a new sales agent.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-7711: Invalid data.
   * - KT-CT-7713: Invalid data.
   * - KT-CT-7714: Invalid data.
   * - KT-CT-7715: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  createAffiliateLink: CreateAffiliateLink;
  /**
   * Create an affiliate organisation.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-7716: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  createAffiliateOrganisation: CreateAffiliateOrganisation;
  /** Create a session for an affiliate link. */
  createAffiliateSession: CreateAffiliateSession;
  /**
   * Mutation to create a new APICall instance.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-7803: Received an invalid apiExceptionId.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  createApiCall?: Maybe<CreateApiCall>;
  /**
   * Mutation to create a new APIException instance.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-7801: Received an invalid operationsTeamId.
   * - KT-CT-7802: The external identifier already exists.
   * - KT-CT-7805: Too many tags associated with this API Exception.
   * - KT-CT-7806: Cannot create duplicate tags for the same API exception.
   * - KT-CT-7811: Received an invalid assignedUserId.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  createApiException?: Maybe<CreateApiException>;
  /**
   * Mutation to create a new APIExceptionEvent instance.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-7803: Received an invalid apiExceptionId.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  createApiExceptionEvent?: Maybe<CreateApiExceptionEvent>;
  /**
   * Mutation to create a new APIExceptionNote instance.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-7803: Received an invalid apiExceptionId.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  createApiExceptionNote?: Maybe<CreateApiExceptionNote>;
  /**
   * Create a contribution agreement for an account.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4123: Unauthorized.
   * - KT-CT-9601: Invalid data.
   * - KT-CT-9602: Unable to create contribution agreement.
   * - KT-CT-9605: Contribution amount cannot be 0 or negative.
   * - KT-CT-9606: Scheme is not accepting contributions at this time.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  createContributionAgreement?: Maybe<CreateContributionAgreement>;
  /**
   * Create a new deposit agreement for the account if it needs one.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4177: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  createDepositAgreement?: Maybe<CreateDepositAgreement>;
  /**
   * Create direct debit payment schedule
   *
   *
   *
   * This field requires the `Authorization` header to be set.
   *
   *
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1128: Unauthorized.
   * - KT-CT-3947: An unexpected error occurred.
   * - KT-CT-3948: Could not set up direct debit instruction.
   * - KT-GB-3930: Invalid data.
   * - KT-GB-3931: Customer is in too much debt to complete operation.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  createDirectDebitInstructionAndSchedule?: Maybe<CreateDirectDebitInstructionAndSchedule>;
  /**
   * Create an external account event.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-7123: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  createExternalAccountEvent?: Maybe<CreateExternalAccountEvent>;
  /**
   * Create a "form submission" entity. This is only meant to be used as a quick way of putting together a form and submit data for it, in the form of JSON - it is not expected that all form submissions will come through this path.
   *
   * This field requires the `Authorization` header to be set.
   *
   *
   */
  createFormSubmission?: Maybe<FormSubmissionOuput>;
  /**
   * Create a goods purchase.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-8206: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  createGoodsPurchase?: Maybe<CreateGoodsPurchase>;
  /**
   * Create a goods quote.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-8202: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  createGoodsQuote?: Maybe<CreateGoodsQuote>;
  /**
   * Create a goods quote without an account.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-8202: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  createGoodsQuoteWithoutAccount?: Maybe<CreateGoodsQuoteWithoutAccount>;
  /** Register an Ink inbound message. */
  createInkInboundMessage?: Maybe<CreateInkInboundMessagePayload>;
  /**
   * Create metadata on an object.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-8412: Invalid data.
   * - KT-CT-8414: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  createMetadata?: Maybe<CreateMetadata>;
  /**
   * Create a lead.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-8901: Unable to create lead.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  createNewLead?: Maybe<CreateNewLead>;
  /**
   * Create or update a loyalty card for the given account user.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-5412: No account user exists with the given id.
   * - KT-CT-8610: Invalid data.
   * - KT-CT-8611: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  createOrUpdateLoyaltyCard?: Maybe<CreateOrUpdateLoyaltyCardMutation>;
  /**
   * Mutation to create a new Portfolio instance.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-9402: Received an invalid brandCode.
   * - KT-CT-9401: Received an invalid operationsTeamId.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  createPortfolio?: Maybe<CreatePortfolio>;
  /**
   * Mutation to create a new portfolio user role. This will effectively link the user to the portfolio giving them all the permissions enabled for the specific role.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-9403: Received an invalid portfolioId.
   * - KT-CT-9404: Received an invalid accountUserId.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  createPortfolioUserRole?: Maybe<CreatePortfolioUserRole>;
  /**
   * Create a referral using an email address, personal link or code.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-6723: Unauthorized.
   * - KT-CT-6710: Unable to create referral.
   * - KT-CT-6711: Accounts may not self-refer.
   * - KT-CT-6713: Referring and referred account brands do not match.
   * - KT-CT-6712: Invalid reference.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  createReferral?: Maybe<CreateReferral>;
  /** Create a shell/payment account. */
  createShellAccount?: Maybe<CreateShellAccountPayload>;
  /**
   * Delete an account reference.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4123: Unauthorized.
   * - KT-CT-8310: Invalid data.
   * - KT-CT-8312: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  deleteAccountReference?: Maybe<DeleteAccountReference>;
  /**
   * Delete a device token used for push notifications.
   *
   *
   *
   * This field requires the `Authorization` header to be set.
   *
   *
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-5411: Invalid token or no push notification binding found for the given account user.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  deletePushNotificationBinding?: Maybe<DeletePushNotificationBinding>;
  /**
   * End a contribution agreement for an account.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-9603: Unable to find contribution agreement.
   * - KT-CT-4123: Unauthorized.
   * - KT-CT-9604: Unable to end contribution agreement.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  endContributionAgreement?: Maybe<EndContributionAgreement>;
  /**
   * Force users of Kraken Tokens and refresh tokens issued to the viewer to reauthenticate.
   *
   * Calling this mutation will cause all Kraken Tokens and refresh tokens issued to the authenticated viewer before the mutation was called to become invalid.
   */
  forceReauthentication?: Maybe<ForceReauthentication>;
  /**
   * The possible errors that can be raised are:
   *
   * - KT-CT-7620: Channel not supported.
   * - KT-CT-7618: Unable to process message.
   * - KT-CT-7624: Error when generating the presigned URL.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  generateInkPresignedUrl?: Maybe<GenerateInkPresignedUrl>;
  /**
   * Generate a pre-signed token with a set expiry time.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1128: Unauthorized.
   * - KT-CT-1120: The Kraken Token has expired.
   * - KT-CT-1131: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  generatePreSignedToken?: Maybe<GeneratePreSignedToken>;
  /**
   * Get the client secret needed to create a new payment instruction using an embedded form.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4177: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  getEmbeddedSecretForNewPaymentInstruction?: Maybe<GetEmbeddedSecretForNewPaymentInstruction>;
  /**
   * Get the external URL where the user can set up a payment instruction.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1128: Unauthorized.
   * - KT-CT-3822: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  getHostedUrlForNewPaymentInstruction?: Maybe<GetHostedUrlForNewPaymentInstruction>;
  /**
   * Initiate a standalone payment and return the url where the customer can complete it.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1128: Unauthorized.
   * - KT-CT-3822: Unauthorized.
   * - KT-CT-3943: Invalid ledger.
   * - KT-CT-3957: No collection method provided.
   * - KT-CT-3958: Provide either ledger ID or ledger number.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  initiateHostedStandalonePayment?: Maybe<InitiateHostedStandalonePayment>;
  /**
   * Do a product switch for a user.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-4619: Quote with given code not found.
   * - KT-CT-4624: Unable to accept the given product code.
   * - KT-CT-4626: No product selected for the given quote code.
   * - KT-CT-4719: No supply point found for identifier provided.
   * - KT-CT-4922: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  initiateProductSwitch?: Maybe<InitiateProductSwitch>;
  /**
   * Initiate a standalone payment and return the client secret required to complete it.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4177: Unauthorized.
   * - KT-CT-3943: Invalid ledger.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  initiateStandalonePayment?: Maybe<InitiateStandalonePayment>;
  /**
   * Insert special circumstance priority services for the user.
   * This mutation replaces all the active PSR records with the given input.
   * Make sure to include all existing PSR records for the user if you do not intend to deactivate any records.
   *
   * This field requires the `Authorization` header to be set.
   *
   *
   *
   * The possible errors that can be raised are:
   *
   * - KT-GB-5415: The requested PSR record type is not valid.
   * - KT-GB-5411: Failed to update special circumstance record.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  insertUserPsrRecords?: Maybe<InsertUserPsrRecords>;
  /**
   * Instigate a leave supplier process or update an existing process.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-10304: Mutation not enabled in this environment.
   * - KT-CT-4501: Unauthorized.
   * - KT-CT-1602: Serializer validation error.
   * - KT-JP-8801: Supply point not found.
   * - KT-CT-10301: Unable to instigate leave supplier process.
   * - KT-CT-10309: Failed to update leave supplier process - the service is not enabled.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  instigateLeaveSupplier: LeaveSupplierInstigated;
  /**
   * Invalidate an existing instruction.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-3926: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  invalidatePaymentInstruction?: Maybe<InvalidatePaymentInstruction>;
  /**
   * Invalidate a previously-issued pre-signed token.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1129: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  invalidatePreSignedToken?: Maybe<InvalidatePreSignedToken>;
  /**
   * Invalidate pre-signed tokens issued to a particular user.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1129: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  invalidatePreSignedTokensForUser?: Maybe<InvalidatePreSignedTokensForUser>;
  /**
   * Invalidate a previously-issued refresh token.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1130: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  invalidateRefreshToken?: Maybe<InvalidateRefreshToken>;
  /**
   * Invalidate refresh tokens issued to a particular user.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1128: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  invalidateRefreshTokensForUser?: Maybe<InvalidateRefreshTokensForUser>;
  /** Link an account user and line user together. */
  linkUserToLine: LinkUserToLineResponse;
  /**
   * Mark the print batch as processed.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-9011: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  markPrintBatchAsProcessed: MarkPrintBatchAsProcessed;
  /** Provide a temporary token to get an auth token. This is intended to allow support users to view customer data through the brand interface. */
  masqueradeAuthentication?: Maybe<MasqueradeAuthentication>;
  /**
   * Trigger a move out of a property for an account.Optionally provide details of the next property to trigger a move within.
   *
   * The possible errors that can be raised are:
   *
   * - KT-GB-6628: Invalid data.
   * - KT-GB-6624: An error occurred when trying to process this house move.
   * - KT-CT-1113: Disabled GraphQL field requested.
   *
   */
  moveOut?: Maybe<MoveOut>;
  /**
   * Create a Kraken Token (JWT) for authentication.
   *
   * Provide the required input fields to obtain the token.
   *
   * The token should be used as the `Authorization` header for any authenticated requests.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1135: Invalid data.
   * - KT-CT-1134: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   *
   */
  obtainKrakenToken?: Maybe<ObtainKrakenJsonWebToken>;
  /**
   * For authorized third-party organizations only.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1120: The Kraken Token has expired.
   * - KT-CT-1121: Please use Kraken Token to issue long-lived refresh tokens.
   * - KT-CT-1132: Unauthorized.
   * - KT-CT-1122: Long-lived refresh tokens can only be issued for account users.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  obtainLongLivedRefreshToken?: Maybe<ObtainLongLivedRefreshToken>;
  /**
   * Post credit to a ledger.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-5316: Invalid data.
   * - KT-CT-5311: The credit reason with the requested code is deprecated.
   * - KT-CT-5312: The credit reason with the requested code does not exist.
   * - KT-CT-5313: An error occurred whilst posting the credit.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  postCredit?: Maybe<PostCredit>;
  /**
   * Prepare account for sign up. Returns the existing account and/or user if matching datafound for the provided input, otherwise creates a new account and account user.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-10303: Mutation not enabled in this environment.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  prepareAccount: PrepareAccountResult;
  /**
   * Publish a trigger within the transactional messaging service.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-9901: Invalid trigger type code.
   * - KT-CT-9902: Invalid trigger type params.
   * - KT-CT-9903: Trigger type cannot be published externally.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  publishTransactionalMessagingTrigger?: Maybe<PublishTransactionalMessagingTrigger>;
  /**
   * Record the customer's acceptance of a deposit agreement.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4177: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  recordDepositAgreementAccepted?: Maybe<RecordDepositAgreementAccepted>;
  /**
   * Redeem the passed number of Loyalty Points as account credit.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-9201: No Loyalty Point ledger found for the user.
   * - KT-CT-9202: Loyalty Points adapter not configured.
   * - KT-CT-9203: No ledger entries for the ledger.
   * - KT-CT-9205: Insufficient Loyalty Points.
   * - KT-CT-9206: Indivisible points.
   * - KT-CT-9204: Negative or zero points set.
   * - KT-CT-9208: Invalid posted at datetime.
   * - KT-CT-9209: Negative Loyalty Points balance.
   * - KT-CT-9210: Unhandled Loyalty Points exception.
   * - KT-CT-1113: Disabled GraphQL field requested.
   *
   */
  redeemLoyaltyPointsForAccountCredit?: Maybe<RedeemLoyaltyPointsForAccountCredit>;
  /**
   * Redeem the referral claim code from certain referral scheme.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-6723: Unauthorized.
   * - KT-CT-6724: Referral claim code not found.
   * - KT-CT-6725: Referral claim code redeeming error.
   * - KT-CT-1113: Disabled GraphQL field requested.
   *
   */
  redeemReferralClaimCode?: Maybe<RedeemReferralClaimCode>;
  /**
   * Refund a cleared payment.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-3924: Unauthorized.
   * - KT-CT-3928: Idempotency key used for another repayment request.
   * - KT-CT-3929: The payment is not in a refundable state.
   * - KT-CT-3933: Refund amount greater than payment amount.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  refundPayment?: Maybe<RefundPayment>;
  /** Regenerate the live secret key for the authenticated user. */
  regenerateSecretKey?: Maybe<RegenerateSecretKey>;
  /**
   * Register a device token to be used for push notifications for an app.
   *
   *
   *
   * This field requires the `Authorization` header to be set.
   *
   *
   */
  registerPushNotificationBinding?: Maybe<RegisterPushNotificationBinding>;
  /**
   * Provide the email address of an account user to send them an email with instructions on how to reset their password.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1133: Unable to request password reset email.
   * - KT-CT-1113: Disabled GraphQL field requested.
   *
   */
  requestPasswordReset?: Maybe<RequestPasswordResetOutputType>;
  /** Reset the password of an account user indicated by the userId to the value supplied. */
  resetPassword?: Maybe<ResetPasswordMutationPayload>;
  /**
   * Reset the password of an account user.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4125: Unauthorized.
   * - KT-CT-1132: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   *
   */
  resetUserPassword?: Maybe<ResetUserPasswordOutput>;
  /**
   * Set the Loyalty Point user for the account.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-9210: Unhandled Loyalty Points exception.
   * - KT-CT-1113: Disabled GraphQL field requested.
   *
   */
  setLoyaltyPointsUser?: Maybe<SetLoyaltyPointsUser>;
  /**
   * Set up a new direct debit instruction.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-3940: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  setUpDirectDebitInstruction?: Maybe<SetUpDirectDebitInstruction>;
  /**
   * Share a goods quote.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4122: Invalid email.
   * - KT-CT-8203: Received an invalid quote code.
   * - KT-CT-1113: Disabled GraphQL field requested.
   *
   */
  shareGoodsQuote?: Maybe<ShareGoodsQuote>;
  /**
   * Store a new payment instruction created through the embedded process.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4177: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  storePaymentInstruction?: Maybe<StorePaymentInstruction>;
  /**
   * Submit customer feedback.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-5514: Unable to submit feedback.
   * - KT-CT-5511: The feedback_id should be provided for feedback source.
   * - KT-CT-5512: The feedback doesn't match the account.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  submitCustomerFeedback?: Maybe<SubmitCustomerFeedback>;
  /**
   * Customer can submit a meter reading.
   *
   * The possible errors that can be raised are:
   *
   * - KT-GB-6414: Meter reading could not be updated. Input not valid.
   * - KT-CT-6422: Reading will be held.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  submitMeterReading?: Maybe<SubmitMeterReading>;
  /**
   * Submit a repayment request.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1132: Unauthorized.
   * - KT-CT-3927: Invalid Amount.
   * - KT-CT-3928: Idempotency key used for another repayment request.
   * - KT-CT-1113: Disabled GraphQL field requested.
   *
   */
  submitRepaymentRequest?: Maybe<SubmitRepaymentRequest>;
  /**
   * Transfer value from a source ledger to a destination ledger. This decreases the balance of the source ledger by the given amount and increases the balance of the destination ledger by the same amount. If the amount is negative, the effect is reversed (the source ledger's balance increases and the destination ledger's balance decreases).
   *
   *
   *
   * This field requires the `Authorization` header to be set.
   *
   *
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-3822: Unauthorized.
   * - KT-CT-3823: Unauthorized.
   * - KT-CT-9701: Balance transfer to same account is not allowed.
   * - KT-CT-9702: Balance transfer is not support for debit account with Zero balance.
   * - KT-CT-9703: Balance transfer is not supported for debit account.
   * - KT-CT-9704: Balance transfer amount should be non-zero.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  transferLedgerBalance?: Maybe<TransferLedgerBalance>;
  /**
   * Transfer Loyalty Point from one account user to another.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-9205: Insufficient Loyalty Points.
   * - KT-CT-9204: Negative or zero points set.
   * - KT-CT-9208: Invalid posted at datetime.
   * - KT-CT-9209: Negative Loyalty Points balance.
   * - KT-CT-9210: Unhandled Loyalty Points exception.
   * - KT-CT-1113: Disabled GraphQL field requested.
   *
   */
  transferLoyaltyPointsBetweenUsers?: Maybe<TransferLoyaltyPointsBetweenUsers>;
  /** Unlink an account user and line together. */
  unlinkUserFromLine: UnlinkUserFromLineResponse;
  /**
   * Update the billing address of given account.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4178: No account found with given account number.
   * - KT-CT-4411: Address in db is structured, cannot accept unstructured address fields.
   * - KT-CT-1113: Disabled GraphQL field requested.
   *
   */
  updateAccountAddress?: Maybe<UpdateAccountAddress>;
  /**
   * Update the account billing address.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4145: Invalid address.
   * - KT-CT-7123: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  updateAccountBillingAddress?: Maybe<UpdateAccountBillingAddress>;
  /**
   * Update account billing email.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4123: Unauthorized.
   * - KT-CT-4122: Invalid email.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  updateAccountBillingEmail?: Maybe<UpdateAccountBillingEmail>;
  /**
   * Update an account reference.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4123: Unauthorized.
   * - KT-CT-8310: Invalid data.
   * - KT-CT-8311: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  updateAccountReference?: Maybe<UpdateAccountReference>;
  /**
   * Update an existing affiliate link.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-7711: Invalid data.
   * - KT-CT-7713: Invalid data.
   * - KT-CT-7714: Invalid data.
   * - KT-CT-7715: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  updateAffiliateLink: UpdateAffiliateLink;
  /**
   * Update an existing affiliate organisation.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-7717: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  updateAffiliateOrganisation: UpdateAffiliateOrganisation;
  /**
   * Mutation to update an existing APIException instance.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-7804: No fields present in the input for updating the APIException.
   * - KT-CT-7803: Received an invalid apiExceptionId.
   * - KT-CT-7809: Update results in no changes to API Exception.
   * - KT-CT-7805: Too many tags associated with this API Exception.
   * - KT-CT-7806: Cannot create duplicate tags for the same API exception.
   * - KT-CT-7801: Received an invalid operationsTeamId.
   * - KT-CT-7811: Received an invalid assignedUserId.
   * - KT-CT-7812: Support user is inactive.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  updateApiException?: Maybe<UpdateApiException>;
  /**
   * Mutation to update an existing APIExceptionNote instance.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-7807: Received an invalid apiExceptionNoteId.
   * - KT-CT-7808: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  updateApiExceptionNote?: Maybe<UpdateApiExceptionNote>;
  /**
   * Change the auto top up amount for the payment schedule.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-3815: No active payment schedule found for this account.
   * - KT-CT-3941: Invalid data.
   * - KT-CT-3942: An unexpected error occurred.
   * - KT-CT-3947: An unexpected error occurred.
   * - KT-CT-3953: The payment schedule is not a balance triggered schedule.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  updateAutoTopUpAmount?: Maybe<UpdateAutoTopUpAmount>;
  /**
   * Update the email address of an account user that currently has a blackhole email.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1144: Unauthorized.
   * - KT-GB-5412: User already has an email address.
   * - KT-GB-5413: Invalid inputs for updating a blackhole email.
   * - KT-CT-1133: Unable to request password reset email.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  updateBlackholeEmail?: Maybe<UpdateBlackholeEmail>;
  /**
   * Update account communication delivery preference.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4123: Unauthorized.
   * - KT-CT-4136: Cannot set comms preference to email when account has no email.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  updateCommsDeliveryPreference?: Maybe<UpdateCommsDeliveryPreference>;
  /**
   * Update the comms preferences of the account user (the authenticated user).
   *
   * The possible errors that can be raised are:
   *
   * - KT-GB-5414: The given preferences are not valid.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  updateCommsPreferences?: Maybe<UpdateAccountUserCommsPreferencesMutation>;
  /**
   * Update the email address and password of an account user.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-5413: Invalid data.
   * - KT-CT-5414: Invalid data.
   * - KT-CT-5450: Password is invalid.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  updateEmailAndPassword?: Maybe<UpdateEmailAndPassword>;
  updateMessageTags?: Maybe<UpdateMessageTagsPayload>;
  /**
   * Update metadata on an object.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-8413: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  updateMetadata?: Maybe<UpdateMetadata>;
  /**
   * Update password of the authenticated user
   *
   *
   *
   * This field requires the `Authorization` header to be set.
   *
   *
   */
  updatePassword?: Maybe<UpdatePassword>;
  /**
   * Update the account user details of the authenticated user.
   *
   * Only one name field can be updated per day, other fields can be updated freely. This prevents users from switching accounts to someone else (usually when moving homes). All account changes should be handled by operations or the move out journey. New customers are exempt from this rule for the first 31 days.
   *
   *
   *
   * This field requires the `Authorization` header to be set.
   *
   *
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-5413: Invalid data.
   * - KT-CT-5414: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  updateUser?: Maybe<UpdateUserMutation>;
  /**
   * **DEPRECATED: Please use updateUser instead**
   *
   * Update the account user details of the authenticated user. Only one field can be updated per day. This prevents users from switching accounts to someone else (usually when moving homes) All account changes should be handled by operations or the move out journey. New customers are exempt from this rule for the first 31 days.
   * @deprecated The 'updateUserDetails' field is deprecated.
   *
   *
   * Please use the 'updateUser' mutation instead.
   *
   *
   * - Marked as deprecated on 2020-10-02.
   * - Will be removed on 2023-04-06.
   */
  updateUserDetails?: Maybe<UpdateAccountUserMutationPayload>;
  /** Validate user's email address. */
  validateEmail?: Maybe<ValidateEmail>;
  /** Validate user's phone number. */
  validatePhone?: Maybe<ValidatePhone>;
  /**
   * Verify user's email address.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   *
   */
  verifyEmail?: Maybe<VerifyEmail>;
  /**
   * Provide identifying information about an account and user to get a scoped token that will permit access to associate an email address with the account’s user.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1145: Account/user details do not match.
   * - KT-CT-1113: Disabled GraphQL field requested.
   *
   */
  verifyIdentity?: Maybe<VerifyIdentity>;
};

/** Class to inherit mutations. */
export type MutationAcceptGoodsQuoteArgs = {
  input: AcceptGoodsQuoteInput;
};

/** Class to inherit mutations. */
export type MutationAddCampaignToAccountArgs = {
  input: AddCampaignToAccountInput;
};

/** Class to inherit mutations. */
export type MutationAllowRepaymentSubmissionArgs = {
  input: RepaymentInput;
};

/** Class to inherit mutations. */
export type MutationAmendPaymentArgs = {
  input: AmendPaymentInput;
};

/** Class to inherit mutations. */
export type MutationApproveRepaymentArgs = {
  input: ApproveRepaymentInput;
};

/** Class to inherit mutations. */
export type MutationAssignInkBucketArgs = {
  input: AssignInkBucketInput;
};

/** Class to inherit mutations. */
export type MutationBackendScreenEventArgs = {
  input: BackendScreenEventInput;
};

/** Class to inherit mutations. */
export type MutationBlockRepaymentSubmissionArgs = {
  input: RepaymentInput;
};

/** Class to inherit mutations. */
export type MutationCancelLeaveSupplierArgs = {
  input: CancelLeaveSupplierInput;
};

/** Class to inherit mutations. */
export type MutationCancelPaymentArgs = {
  input: CancelPaymentInput;
};

/** Class to inherit mutations. */
export type MutationCancelRepaymentRequestArgs = {
  input: CancelRepaymentRequestInputType;
};

/** Class to inherit mutations. */
export type MutationCollectDepositArgs = {
  input: CollectDepositInput;
};

/** Class to inherit mutations. */
export type MutationCollectPaymentArgs = {
  input: CollectPaymentInput;
};

/** Class to inherit mutations. */
export type MutationCreateAccountChargeArgs = {
  input: CreateAccountChargeInput;
};

/** Class to inherit mutations. */
export type MutationCreateAccountCreditArgs = {
  input: CreateAccountCreditInput;
};

/** Class to inherit mutations. */
export type MutationCreateAccountNoteArgs = {
  input: CreateAccountNoteInput;
};

/** Class to inherit mutations. */
export type MutationCreateAccountPaymentScheduleArgs = {
  input: CreateAccountPaymentScheduleInput;
};

/** Class to inherit mutations. */
export type MutationCreateAccountReferenceArgs = {
  input: AccountReferenceInput;
};

/** Class to inherit mutations. */
export type MutationCreateAccountReminderArgs = {
  input: CreateAccountReminderInput;
};

/** Class to inherit mutations. */
export type MutationCreateAffiliateLinkArgs = {
  input: CreateAffiliateLinkInputType;
};

/** Class to inherit mutations. */
export type MutationCreateAffiliateOrganisationArgs = {
  input: CreateAffiliateOrganisationInputType;
};

/** Class to inherit mutations. */
export type MutationCreateAffiliateSessionArgs = {
  input: CreateAffiliateSessionInputType;
};

/** Class to inherit mutations. */
export type MutationCreateApiCallArgs = {
  input: CreateApiCallInput;
};

/** Class to inherit mutations. */
export type MutationCreateApiExceptionArgs = {
  input: CreateApiExceptionInput;
};

/** Class to inherit mutations. */
export type MutationCreateApiExceptionEventArgs = {
  input: CreateApiExceptionEventInput;
};

/** Class to inherit mutations. */
export type MutationCreateApiExceptionNoteArgs = {
  input: CreateApiExceptionNoteInput;
};

/** Class to inherit mutations. */
export type MutationCreateContributionAgreementArgs = {
  input: CreateContributionAgreementInput;
};

/** Class to inherit mutations. */
export type MutationCreateDepositAgreementArgs = {
  input: CreateDepositAgreementInput;
};

/** Class to inherit mutations. */
export type MutationCreateDirectDebitInstructionAndScheduleArgs = {
  input: CreateDirectDebitInstructionAndScheduleInput;
};

/** Class to inherit mutations. */
export type MutationCreateExternalAccountEventArgs = {
  input: CreateExternalAccountEventInput;
};

/** Class to inherit mutations. */
export type MutationCreateFormSubmissionArgs = {
  input: FormSubmissionInput;
};

/** Class to inherit mutations. */
export type MutationCreateGoodsPurchaseArgs = {
  input: CreatePurchaseInput;
};

/** Class to inherit mutations. */
export type MutationCreateGoodsQuoteArgs = {
  input: CreateGoodsQuoteInput;
};

/** Class to inherit mutations. */
export type MutationCreateGoodsQuoteWithoutAccountArgs = {
  input: CreateGoodsQuoteWithoutAccountInput;
};

/** Class to inherit mutations. */
export type MutationCreateInkInboundMessageArgs = {
  input: CreateInkInboundMessageInput;
};

/** Class to inherit mutations. */
export type MutationCreateMetadataArgs = {
  input: MetadataInput;
};

/** Class to inherit mutations. */
export type MutationCreateNewLeadArgs = {
  input: NewLeadInputType;
};

/** Class to inherit mutations. */
export type MutationCreateOrUpdateLoyaltyCardArgs = {
  input: CreateOrUpdateLoyaltyCardInput;
};

/** Class to inherit mutations. */
export type MutationCreatePortfolioArgs = {
  input?: InputMaybe<CreatePortfolioInput>;
};

/** Class to inherit mutations. */
export type MutationCreatePortfolioUserRoleArgs = {
  input?: InputMaybe<CreatePortfolioUserRoleInput>;
};

/** Class to inherit mutations. */
export type MutationCreateReferralArgs = {
  input: CreateReferralInput;
};

/** Class to inherit mutations. */
export type MutationCreateShellAccountArgs = {
  input: CreateShellAccountInput;
};

/** Class to inherit mutations. */
export type MutationDeleteAccountReferenceArgs = {
  input: DeleteAccountReferenceInput;
};

/** Class to inherit mutations. */
export type MutationDeletePushNotificationBindingArgs = {
  input: DeletePushNotificationBindingInput;
};

/** Class to inherit mutations. */
export type MutationEndContributionAgreementArgs = {
  input: EndContributionAgreementInput;
};

/** Class to inherit mutations. */
export type MutationForceReauthenticationArgs = {
  input: ForceReauthenticationInput;
};

/** Class to inherit mutations. */
export type MutationGenerateInkPresignedUrlArgs = {
  input?: InputMaybe<GenerateInkPresignedUrlInput>;
};

/** Class to inherit mutations. */
export type MutationGeneratePreSignedTokenArgs = {
  email: Scalars['String'];
  numberOfDaysAllowed: Scalars['Int'];
  scope: PreSignedTokenScope;
};

/** Class to inherit mutations. */
export type MutationGetEmbeddedSecretForNewPaymentInstructionArgs = {
  input: GetEmbeddedSecretForNewPaymentInstructionInput;
};

/** Class to inherit mutations. */
export type MutationGetHostedUrlForNewPaymentInstructionArgs = {
  input: GetHostedUrlForNewPaymentInstructionInput;
};

/** Class to inherit mutations. */
export type MutationInitiateHostedStandalonePaymentArgs = {
  input: InitiateHostedStandalonePaymentInput;
};

/** Class to inherit mutations. */
export type MutationInitiateProductSwitchArgs = {
  input: InitiateProductSwitchInput;
};

/** Class to inherit mutations. */
export type MutationInitiateStandalonePaymentArgs = {
  input: InitiateStandalonePaymentInput;
};

/** Class to inherit mutations. */
export type MutationInsertUserPsrRecordsArgs = {
  input: Array<InputMaybe<SpecialCircumstanceRecordInput>>;
};

/** Class to inherit mutations. */
export type MutationInstigateLeaveSupplierArgs = {
  input: LeaveSupplierInput;
};

/** Class to inherit mutations. */
export type MutationInvalidatePaymentInstructionArgs = {
  input: InvalidatePaymentInstructionInput;
};

/** Class to inherit mutations. */
export type MutationInvalidatePreSignedTokenArgs = {
  input: InvalidatePreSignedTokenInput;
};

/** Class to inherit mutations. */
export type MutationInvalidatePreSignedTokensForUserArgs = {
  input: InvalidatePreSignedTokensForUserInput;
};

/** Class to inherit mutations. */
export type MutationInvalidateRefreshTokenArgs = {
  input: InvalidateRefreshTokenInput;
};

/** Class to inherit mutations. */
export type MutationInvalidateRefreshTokensForUserArgs = {
  input: InvalidateRefreshTokensForUserInput;
};

/** Class to inherit mutations. */
export type MutationLinkUserToLineArgs = {
  input: LinkUserToLineInput;
};

/** Class to inherit mutations. */
export type MutationMarkPrintBatchAsProcessedArgs = {
  printBatchId: Scalars['ID'];
};

/** Class to inherit mutations. */
export type MutationMasqueradeAuthenticationArgs = {
  masqueradeToken: Scalars['String'];
  userId: Scalars['String'];
};

/** Class to inherit mutations. */
export type MutationMoveOutArgs = {
  input: MoveOutInput;
};

/** Class to inherit mutations. */
export type MutationObtainKrakenTokenArgs = {
  input: ObtainJsonWebTokenInput;
};

/** Class to inherit mutations. */
export type MutationObtainLongLivedRefreshTokenArgs = {
  input: ObtainLongLivedRefreshTokenInput;
};

/** Class to inherit mutations. */
export type MutationPostCreditArgs = {
  input: PostCreditInput;
};

/** Class to inherit mutations. */
export type MutationPrepareAccountArgs = {
  input: PrepareAccountInput;
};

/** Class to inherit mutations. */
export type MutationPublishTransactionalMessagingTriggerArgs = {
  input: PublishTransactionalMessagingTriggerInput;
};

/** Class to inherit mutations. */
export type MutationRecordDepositAgreementAcceptedArgs = {
  input: DepositAgreementInput;
};

/** Class to inherit mutations. */
export type MutationRedeemLoyaltyPointsForAccountCreditArgs = {
  input: RedeemLoyaltyPointsInput;
};

/** Class to inherit mutations. */
export type MutationRedeemReferralClaimCodeArgs = {
  input: RedeemReferralClaimCodeInput;
};

/** Class to inherit mutations. */
export type MutationRefundPaymentArgs = {
  input: RefundPaymentInput;
};

/** Class to inherit mutations. */
export type MutationRegisterPushNotificationBindingArgs = {
  input: RegisterPushNotificationBindingInput;
};

/** Class to inherit mutations. */
export type MutationRequestPasswordResetArgs = {
  input: RequestPasswordResetInput;
};

/** Class to inherit mutations. */
export type MutationResetPasswordArgs = {
  input: ResetPasswordMutationInput;
};

/** Class to inherit mutations. */
export type MutationResetUserPasswordArgs = {
  input: ResetUserPasswordInput;
};

/** Class to inherit mutations. */
export type MutationSetLoyaltyPointsUserArgs = {
  input: SetLoyaltyPointsUserInput;
};

/** Class to inherit mutations. */
export type MutationSetUpDirectDebitInstructionArgs = {
  input: SetUpDirectDebitInstructionInput;
};

/** Class to inherit mutations. */
export type MutationShareGoodsQuoteArgs = {
  input: ShareGoodsQuoteInput;
};

/** Class to inherit mutations. */
export type MutationStorePaymentInstructionArgs = {
  input: StorePaymentInstructionInput;
};

/** Class to inherit mutations. */
export type MutationSubmitCustomerFeedbackArgs = {
  input: CustomerFeedbackInputType;
};

/** Class to inherit mutations. */
export type MutationSubmitMeterReadingArgs = {
  input: SubmitMeterReadingInputType;
};

/** Class to inherit mutations. */
export type MutationSubmitRepaymentRequestArgs = {
  input: RequestRepaymentInputType;
};

/** Class to inherit mutations. */
export type MutationTransferLedgerBalanceArgs = {
  input: TransferLedgerBalanceInputType;
};

/** Class to inherit mutations. */
export type MutationTransferLoyaltyPointsBetweenUsersArgs = {
  input: TransferLoyaltyPointsBetweenUsersInput;
};

/** Class to inherit mutations. */
export type MutationUpdateAccountAddressArgs = {
  input: UpdateAccountAddressInput;
};

/** Class to inherit mutations. */
export type MutationUpdateAccountBillingAddressArgs = {
  input: AccountBillingAddressInput;
};

/** Class to inherit mutations. */
export type MutationUpdateAccountBillingEmailArgs = {
  input: UpdateAccountBillingEmailInput;
};

/** Class to inherit mutations. */
export type MutationUpdateAccountReferenceArgs = {
  input: AccountReferenceInput;
};

/** Class to inherit mutations. */
export type MutationUpdateAffiliateLinkArgs = {
  input: UpdateAffiliateLinkInputType;
};

/** Class to inherit mutations. */
export type MutationUpdateAffiliateOrganisationArgs = {
  input: UpdateAffiliateOrganisationInputType;
};

/** Class to inherit mutations. */
export type MutationUpdateApiExceptionArgs = {
  input: UpdateApiExceptionInput;
};

/** Class to inherit mutations. */
export type MutationUpdateApiExceptionNoteArgs = {
  input: UpdateApiExceptionNoteInput;
};

/** Class to inherit mutations. */
export type MutationUpdateAutoTopUpAmountArgs = {
  input: UpdateAutoTopUpAmountInput;
};

/** Class to inherit mutations. */
export type MutationUpdateBlackholeEmailArgs = {
  input: UpdateBlackholeEmailInput;
};

/** Class to inherit mutations. */
export type MutationUpdateCommsDeliveryPreferenceArgs = {
  input: UpdateCommsDeliveryPreferenceInput;
};

/** Class to inherit mutations. */
export type MutationUpdateCommsPreferencesArgs = {
  input: UpdateAccountUserCommsPreferencesInput;
};

/** Class to inherit mutations. */
export type MutationUpdateEmailAndPasswordArgs = {
  input: UpdateEmailAndPasswordInput;
};

/** Class to inherit mutations. */
export type MutationUpdateMessageTagsArgs = {
  input: UpdateMessageTagsInput;
};

/** Class to inherit mutations. */
export type MutationUpdateMetadataArgs = {
  input: MetadataInput;
};

/** Class to inherit mutations. */
export type MutationUpdatePasswordArgs = {
  input?: InputMaybe<UpdatePasswordInput>;
};

/** Class to inherit mutations. */
export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

/** Class to inherit mutations. */
export type MutationUpdateUserDetailsArgs = {
  input: UpdateAccountUserMutationInput;
};

/** Class to inherit mutations. */
export type MutationValidateEmailArgs = {
  input: ValidateEmailInput;
};

/** Class to inherit mutations. */
export type MutationValidatePhoneArgs = {
  input: ValidatePhoneNumberInput;
};

/** Class to inherit mutations. */
export type MutationVerifyEmailArgs = {
  input: VerifyEmailInput;
};

/** Class to inherit mutations. */
export type MutationVerifyIdentityArgs = {
  input: VerifyIdentityInput;
};

export type NewLeadInputType = {
  /** Lead email. */
  email?: InputMaybe<Scalars['String']>;
  /** To know if the lead is domestic or business. */
  leadType?: InputMaybe<Scalars['String']>;
  /** The product the lead is interested in. */
  marketName?: InputMaybe<Scalars['String']>;
  /** Lead name. */
  name?: InputMaybe<Scalars['String']>;
  /** Lead phone. */
  phone?: InputMaybe<Scalars['String']>;
  /** The source where the lead comes from. */
  source?: InputMaybe<Scalars['String']>;
};

export type NewLeadType = {
  __typename?: 'NewLeadType';
  /** Lead email. */
  email?: Maybe<Scalars['String']>;
  /** ID of the lead. */
  id?: Maybe<Scalars['Int']>;
  /** To know if the lead is domestic or business. */
  leadType?: Maybe<Scalars['String']>;
  /** Lead name. */
  name?: Maybe<Scalars['String']>;
  /** Lead phone. */
  phone?: Maybe<Scalars['String']>;
  /** The source where the lead comes from. */
  source?: Maybe<Scalars['String']>;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object */
  id: Scalars['ID'];
};

/** An enumeration. */
export enum NotifiableApplicationExternalProvider {
  /** AWS Pinpoint */
  Pinpoint = 'PINPOINT',
}

/** An enumeration. */
export enum NotifiableApplicationService {
  /** iOS (APNs) */
  Apns = 'APNS',
  /** iOS Sandbox (APNs Sandbox) */
  ApnsSandbox = 'APNS_SANDBOX',
  /** Android (GCM) */
  Gcm = 'GCM',
}

/** Represents an application that can receive push notifications. */
export type NotifiableApplicationType = {
  __typename?: 'NotifiableApplicationType';
  /** Bundle ID or package name of the app. */
  bundleId: Scalars['String'];
  description: Scalars['String'];
  /** Project ID used in push notification delivery service. (Currently: AWS Pinpoint) */
  externalProjectId: Scalars['String'];
  externalProvider: NotifiableApplicationExternalProvider;
  id: Scalars['ID'];
  /** Human readable name for the app. */
  name: Scalars['String'];
  pushNotificationBindings: Array<PushNotificationBindingType>;
  service: NotifiableApplicationService;
};

/** The input type for obtaining a Kraken Token (JWT). */
export type ObtainJsonWebTokenInput = {
  /** API key of the account user. Use standalone, don't provide a second input field. */
  APIKey?: InputMaybe<Scalars['String']>;
  /** Email address of the account user. Use with 'password' field. */
  email?: InputMaybe<Scalars['String']>;
  /** Live secret key of an third-party organization. Use standalone, don't provide a second input field. */
  organizationSecretKey?: InputMaybe<Scalars['String']>;
  /** Password of the account user. Use with 'email' field. */
  password?: InputMaybe<Scalars['String']>;
  /** Short-lived, temporary key (that's pre-signed). Use standalone, don't provide a second input field. */
  preSignedKey?: InputMaybe<Scalars['String']>;
  /** The refresh token that can be used to extend the expiry claim of a Kraken token. Use standalone, don't provide a second input field. */
  refreshToken?: InputMaybe<Scalars['String']>;
};

/**
 * The unifying approach used to get a Kraken token (JWT: JSON Web Token) with
 * different types of input.
 *
 * The currently supported inputs are:
 * - account user email/password combination
 * - account user API key
 * - organization live secret key
 * - pre-signed key
 * - refresh token
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1135: Invalid data.
 * - KT-CT-1134: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 *
 */
export type ObtainKrakenJsonWebToken = {
  __typename?: 'ObtainKrakenJSONWebToken';
  /** The body payload of the Kraken Token.  The same information can be obtained by using JWT decoding tools on the value of the `token` field. */
  payload: Scalars['GenericScalar'];
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** A Unix timestamp representing the point in time at which the refresh token will expire. */
  refreshExpiresIn?: Maybe<Scalars['Int']>;
  /** A token that can be used in a subsequent call to `obtainKrakenToken` to get a new Kraken Token with the same access conditions after the previous one has expired. */
  refreshToken?: Maybe<Scalars['String']>;
  /** The Kraken Token.  Can be used in the `Authorization` header for subsequent calls to the API to access protected resources. */
  token: Scalars['String'];
};

/**
 * Obtain a long-lived refresh token.
 *
 * This mutation is limited to authorized third-party organizations only.
 *
 * Account users can only generate short-lived refresh tokens.
 *
 * The short-lived refresh tokens (for account users) can be obtained from
 * the 'refreshToken' field in 'obtainKrakenToken' mutation.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1120: The Kraken Token has expired.
 * - KT-CT-1121: Please use Kraken Token to issue long-lived refresh tokens.
 * - KT-CT-1132: Unauthorized.
 * - KT-CT-1122: Long-lived refresh tokens can only be issued for account users.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type ObtainLongLivedRefreshToken = {
  __typename?: 'ObtainLongLivedRefreshToken';
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  refreshExpiresIn: Scalars['Int'];
  refreshToken?: Maybe<Scalars['String']>;
};

/** The input type for obtaining a long-lived refresh token. */
export type ObtainLongLivedRefreshTokenInput = {
  /** The Kraken Token that will be used to generate the long-lived refresh token. */
  krakenToken: Scalars['String'];
};

/** An occupancy period for a property. */
export type OccupancyPeriodType = {
  __typename?: 'OccupancyPeriodType';
  /** Date the occupancy period is effective from. */
  effectiveFrom?: Maybe<Scalars['DateTime']>;
  /** Date the occupancy period is effective to. */
  effectiveTo?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  /** Whether the account associated with the occupancy period is an occupier account type. */
  isOccupier?: Maybe<Scalars['Boolean']>;
};

export type OccupyPropertyProcessConnectionTypeConnection = {
  __typename?: 'OccupyPropertyProcessConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OccupyPropertyProcessConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `OccupyPropertyProcessConnectionType` and its cursor. */
export type OccupyPropertyProcessConnectionTypeEdge = {
  __typename?: 'OccupyPropertyProcessConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<OccupyPropertyProcessType>;
};

/** Represents a Occupy Property process. */
export type OccupyPropertyProcessType = AbstractSupplyPointProcessInterface & {
  __typename?: 'OccupyPropertyProcessType';
  /** The ID or the primary key of the lifecycle process. */
  id?: Maybe<Scalars['ID']>;
  occupyAt: Scalars['DateTime'];
  /** The status of the process. */
  status?: Maybe<LeaveSupplierProcessStatus>;
  /** The supply points associated with the process. */
  supplyPoints: SupplyPointConnectionTypeConnection;
};

/** Represents a Occupy Property process. */
export type OccupyPropertyProcessTypeSupplyPointsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type OperationsTeamType = {
  __typename?: 'OperationsTeamType';
  /** ID for the Operations Team. */
  id?: Maybe<Scalars['ID']>;
  /** Name for the Operations Team. */
  name?: Maybe<Scalars['String']>;
};

export type PsrRecordType = {
  __typename?: 'PSRRecordType';
  description: Scalars['String'];
  extraInfoFields?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  internalCode: Scalars['String'];
  isTemporary?: Maybe<Scalars['Boolean']>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** A payment from the customer to the energy supplier. */
export type Payment = TransactionType & {
  __typename?: 'Payment';
  /** Unique identifier of the account the transaction belongs to. */
  accountNumber?: Maybe<Scalars['String']>;
  /**
   * Gross amount including tax (when payable). Refer to the `amounts` field for a breakdown of this information.
   * @deprecated The 'amount' field is deprecated.
   *
   * Use `amounts` instead for a breakdown of the relevant net, tax, and gross amounts.
   *
   * - Marked as deprecated on 2023-12-06.
   * - Will be removed on 2024-06-01.
   */
  amount?: Maybe<Scalars['Int']>;
  /** The net, tax and gross amounts for the transaction. Note: for payments and repayments, only the net amount is returned. */
  amounts?: Maybe<TransactionAmountType>;
  /** The customer's resulting balance after this transaction has been applied, in the smallest unit of currency. */
  balanceCarriedForward?: Maybe<Scalars['Int']>;
  /** The unique identifier for the most recent billing document linked with the transaction.Note: a transaction may be linked with multiple documents, but this field will only return the identifier for the most recent billing document. */
  billingDocumentIdentifier?: Maybe<Scalars['ID']>;
  /** The date time when the transaction is created. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Returns True if the transaction is linked with a statement. */
  hasStatement?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  /**
   * Deprecated.
   * @deprecated The 'isAccountCharge' field is deprecated.
   *
   * This information is provided by the __typename introspection query.
   *
   * - Marked as deprecated on 2020-06-19.
   * - Will be removed on 2022-11-15.
   */
  isAccountCharge?: Maybe<Scalars['Boolean']>;
  /**
   * Deprecated.
   * @deprecated The 'isAccountPayment' field is deprecated.
   *
   * This information is provided by the __typename introspection query.
   *
   * - Marked as deprecated on 2020-06-19.
   * - Will be removed on 2022-11-15.
   */
  isAccountPayment?: Maybe<Scalars['Boolean']>;
  /**
   * Deprecated.
   * @deprecated The 'isCredit' field is deprecated.
   *
   * This information is provided by the __typename introspection query.
   *
   * - Marked as deprecated on 2020-06-19.
   * - Will be removed on 2022-11-15.
   */
  isCredit?: Maybe<Scalars['Boolean']>;
  /** Whether the statement this transaction is on has been held. A held statement is not sent to a customer automatically, but is instead marked for manual attention by operations staff.  Returns False if a statement is not linked with the transaction. */
  isHeld?: Maybe<Scalars['Boolean']>;
  /** Whether this transaction has been issued on any billing document.Note: Look for the most recently issued transaction instead of looking through all transactions as some accounts may have initial transactions that were not issued.This will return False if the transaction is not associated with any billing documents. */
  isIssued?: Maybe<Scalars['Boolean']>;
  isReversed: Scalars['Boolean'];
  /** Returns the note field value for the transaction, which contains additional info. */
  note?: Maybe<Scalars['String']>;
  postedDate?: Maybe<Scalars['Date']>;
  /**
   * Returns None if a statement is not linked with the transaction.
   * @deprecated The 'statementId' field is deprecated.
   *
   * Use `billingDocumentIdentifier` instead.
   *
   * - Marked as deprecated on 2023-11-30.
   * - Will be removed on 2024-06-01.
   */
  statementId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

/** Payment adequacy adjusts fixed payment schedules to maintain a healthy ledger balance over a year. */
export type PaymentAdequacyDetailsType = {
  __typename?: 'PaymentAdequacyDetailsType';
  /** This ledger will be exempt from default Payment Adequacy. This may mean that it is completely exempt, or handled with special rules. */
  isCurrentlyExempt?: Maybe<Scalars['Boolean']>;
};

export type PaymentForecastConnectionTypeConnection = {
  __typename?: 'PaymentForecastConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PaymentForecastConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `PaymentForecastConnectionType` and its cursor. */
export type PaymentForecastConnectionTypeEdge = {
  __typename?: 'PaymentForecastConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<PaymentForecastType>;
};

export type PaymentForecastType = {
  __typename?: 'PaymentForecastType';
  amount?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['Date']>;
  /** The payment method used for the forecasted payment. */
  method?: Maybe<ScheduleType>;
  paymentNumber?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum PaymentFrequencyOptions {
  /** Monthly */
  Monthly = 'Monthly',
  /** Planned */
  Planned = 'Planned',
  /** Weekly */
  Weekly = 'Weekly',
}

/** Payment Instructions */
export type PaymentInstructionType = {
  __typename?: 'PaymentInstructionType';
  accountHolder: Scalars['String'];
  accountType?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  cardExpiryMonth?: Maybe<Scalars['Int']>;
  cardExpiryYear?: Maybe<Scalars['Int']>;
  cardNumber: Scalars['String'];
  cardPaymentNetwork?: Maybe<Scalars['String']>;
  cardType?: Maybe<Scalars['String']>;
  iban: Scalars['String'];
  id: Scalars['ID'];
  instructionType: Scalars['String'];
  /** A masked reference to a recurring payment method. */
  maskedAccountIdentifier?: Maybe<Scalars['String']>;
  sortCode: Scalars['String'];
  status: Scalars['String'];
  validFrom: Scalars['DateTime'];
  /** The provider to use for this DDI. Most accounts would have SmartDebit as their provider, Iresa customers use GoCardless */
  vendor: Scalars['String'];
};

export enum PaymentReasonOptions {
  BalanceThresholdCrossed = 'BALANCE_THRESHOLD_CROSSED',
  BillIssued = 'BILL_ISSUED',
  PaymentPlan = 'PAYMENT_PLAN',
  RegularSchedule = 'REGULAR_SCHEDULE',
}

export type PaymentScheduleConnectionTypeConnection = {
  __typename?: 'PaymentScheduleConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PaymentScheduleConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `PaymentScheduleConnectionType` and its cursor. */
export type PaymentScheduleConnectionTypeEdge = {
  __typename?: 'PaymentScheduleConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<PaymentScheduleType>;
};

/** An enumeration. */
export enum PaymentScheduleReasonOptions {
  /** A payment schedule created to take payments to pay back a debt. These schedules typically expire once the debt has been re-payed. */
  DebtRepaymentPlan = 'DEBT_REPAYMENT_PLAN',
  /** A payment schedule created to take the final payment when an account is closed. */
  FinalPayment = 'FINAL_PAYMENT',
  /** The default value for usual account payments. */
  GeneralAccountPayment = 'GENERAL_ACCOUNT_PAYMENT',
  /** A payment schedule created to take a payment around the supply start date of a meterpoint to help prevent accounts accruing debt. */
  SsdPayment = 'SSD_PAYMENT',
}

/** An object that represents when we have agreed to take payments from a payment instruction. */
export type PaymentScheduleType = {
  __typename?: 'PaymentScheduleType';
  id: Scalars['ID'];
  isPaymentHoliday?: Maybe<Scalars['Boolean']>;
  isVariablePaymentAmount: Scalars['Boolean'];
  paymentAdequacyAdjustment?: Maybe<Scalars['Int']>;
  paymentAdequacyAdjustmentExpiryDate?: Maybe<Scalars['Date']>;
  paymentAmount: Scalars['Int'];
  paymentDay?: Maybe<Scalars['Int']>;
  /** The frequency of the payment schedule */
  paymentFrequency?: Maybe<PaymentFrequencyOptions>;
  paymentFrequencyMultiplier: Scalars['Int'];
  paymentHolidayReason: Scalars['String'];
  /** The reason the payment schedule was created. */
  reason?: Maybe<PaymentScheduleReasonOptions>;
  /** The method of payment for the schedule. */
  scheduleType?: Maybe<ScheduleType>;
  /** The supplementary ledger for this payment schedule, if it is on one */
  supplementaryLedger?: Maybe<SupplementaryLedgerType>;
  /** The sum of the payment adequacy contributions on the payment schedule that are expected to be taken before the debt repayment is complete. */
  totalDebtAmount?: Maybe<Scalars['Int']>;
  validFrom: Scalars['Date'];
  validTo?: Maybe<Scalars['Date']>;
};

/** An enumeration. */
export enum PaymentType {
  Bpay = 'BPAY',
  Card = 'CARD',
  DirectDebit = 'DIRECT_DEBIT',
  GmoRefund = 'GMO_REFUND',
  PaymentSlip = 'PAYMENT_SLIP',
}

export type PeriodBasedDocumentType = BillInterface & {
  __typename?: 'PeriodBasedDocumentType';
  billType?: Maybe<BillTypeEnum>;
  fromDate?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['ID']>;
  /**
   *
   * The unique identifier for the billing document. Note: a pending billing document will not have an identifier yet;
   * and not all finalized billing documents will have an identifier assigned to them, in which case this will be null.
   *
   */
  identifier?: Maybe<Scalars['ID']>;
  /** The date the bill was sent to the customer. */
  issuedDate?: Maybe<Scalars['Date']>;
  /**
   * Requesting this field generates a temporary URL at which bill is available.
   *         This URL will expire after approximately an hour.  It is intended for redirection purposes,
   *         NOT persistence in any form (e.g. inclusion in emails or the body of a web page).
   *         This field can raise an error with errorClass NOT_FOUND if the bill document has not
   *         been created/issued yet.
   *
   *
   *
   */
  temporaryUrl?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['Date']>;
  /** The total amounts for all charges on the billing document. */
  totalCharges?: Maybe<StatementTotalType>;
  /** The total amounts for all credits on the statement. */
  totalCredits?: Maybe<StatementTotalType>;
};

export type PillButtonType = ButtonInterface &
  IdentifiableInterface & {
    __typename?: 'PillButtonType';
    /** The action to perform when the button is pressed. */
    buttonAction: ActionType;
    /** The button style. */
    buttonStyle?: Maybe<ButtonStyle>;
    /** Unique identifier of the object. */
    id?: Maybe<Scalars['ID']>;
    /** Title text of the button. */
    title: Scalars['String'];
    /** The name of the object's type. */
    typename?: Maybe<Scalars['String']>;
  };

export type PointsAllowanceRateLimitInformation = {
  __typename?: 'PointsAllowanceRateLimitInformation';
  /** Whether the viewer has been blocked due to spending all its allowed points. */
  isBlocked?: Maybe<Scalars['Boolean']>;
  /** The maximum number of points the viewer gets for requests per hour. */
  limit?: Maybe<Scalars['Int']>;
  /** The remaining points for the viewer in one hour time limit. */
  remainingPoints?: Maybe<Scalars['Int']>;
  /** Time To Live: UNIX timestamp when the viewer will get a new allowance of points. */
  ttl?: Maybe<Scalars['Int']>;
  /** The points used so far in one hour time limit. */
  usedPoints?: Maybe<Scalars['Int']>;
};

/** A measurement in points. */
export type PointsSizeType = IdentifiableInterface & {
  __typename?: 'PointsSizeType';
  /** Unique identifier of the object. */
  id?: Maybe<Scalars['ID']>;
  /** The points value. */
  points: Scalars['Int'];
  /** The name of the object's type. */
  typename?: Maybe<Scalars['String']>;
};

/** Paginator of Operations Team */
export type PortfolioConnectionTypeConnection = {
  __typename?: 'PortfolioConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PortfolioConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `PortfolioConnectionType` and its cursor. */
export type PortfolioConnectionTypeEdge = {
  __typename?: 'PortfolioConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<PortfolioType>;
};

export type PortfolioType = {
  __typename?: 'PortfolioType';
  billingName?: Maybe<Scalars['String']>;
  collectiveBilling: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  number: Scalars['String'];
  /** Operations team for this portfolio. */
  operationsTeam?: Maybe<OperationsTeamType>;
  updatedAt: Scalars['DateTime'];
};

/** An enumeration. */
export enum PortfolioUserRoleEnum {
  BulkOwnerDept = 'BULK_OWNER_DEPT',
  CollectionAgency = 'COLLECTION_AGENCY',
  CoPrimary = 'CO_PRIMARY',
  CustomerRepresentative = 'CUSTOMER_REPRESENTATIVE',
  ExternalAgency = 'EXTERNAL_AGENCY',
  Nominee = 'NOMINEE',
  PowerOfAttorney = 'POWER_OF_ATTORNEY',
  Primary = 'PRIMARY',
  ThirdParty = 'THIRD_PARTY',
}

/** The role a user has in association with one portfolio. */
export type PortfolioUserRoleType = {
  __typename?: 'PortfolioUserRoleType';
  id: Scalars['ID'];
  /** Portfolio object. */
  portfolio: PortfolioType;
  /** The portfolio role. */
  role?: Maybe<PortfolioUserRoleEnum>;
  user: AccountUserType;
};

/**
 * The GraphQL error type for displaying information about
 * GraphQL errors that might be raised from the API.
 */
export type PossibleErrorType = {
  __typename?: 'PossibleErrorType';
  /** The error code that might be returned from the query/mutation. */
  code?: Maybe<Scalars['String']>;
  /** The error description that might be returned from the query/mutation. */
  description?: Maybe<Scalars['String']>;
  /** The error message that might be returned from the query/mutation. */
  message?: Maybe<Scalars['String']>;
  /** The error type that might be returned from the query/mutation. */
  type?: Maybe<Scalars['String']>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-5316: Invalid data.
 * - KT-CT-5311: The credit reason with the requested code is deprecated.
 * - KT-CT-5312: The credit reason with the requested code does not exist.
 * - KT-CT-5313: An error occurred whilst posting the credit.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type PostCredit = {
  __typename?: 'PostCredit';
  /** Posted account credit. */
  credit?: Maybe<Credit>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type PostCreditInput = {
  /** The account number. */
  accountNumber: Scalars['String'];
  /** Optional short note about the credit, to be displayed to the user. */
  displayNote?: InputMaybe<Scalars['String']>;
  /** The ID of the ledger where the credit will be posted. */
  ledgerId: Scalars['ID'];
  /** The net amount of the credit to be posted. Amount should be posted in the smallest unit of currency. */
  netAmount: Scalars['Int'];
  /** Optional short note about the credit, to be displayed to internal systems. */
  note?: InputMaybe<Scalars['String']>;
  /** The reason why the credit is posted. This should be a valid credit reason code. */
  reason: Scalars['String'];
  /** The tax amount of the credit to be posted. Amount should be posted in the smallest unit of currency. */
  taxAmount: Scalars['Int'];
};

export type PreKrakenBillType = BillInterface & {
  __typename?: 'PreKrakenBillType';
  billType?: Maybe<BillTypeEnum>;
  fromDate?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['ID']>;
  /** The date the bill was sent to the customer. */
  issuedDate?: Maybe<Scalars['Date']>;
  /** The params associated with the historical bill. */
  params?: Maybe<Scalars['JSONString']>;
  /**
   * Requesting this field generates a temporary URL at which bill is available.
   *         This URL will expire after approximately an hour.  It is intended for redirection purposes,
   *         NOT persistence in any form (e.g. inclusion in emails or the body of a web page).
   *         This field can raise an error with errorClass NOT_FOUND if the bill document has not
   *         been created/issued yet.
   *
   *
   *
   */
  temporaryUrl?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['Date']>;
};

/** A pre-signed, expiring and opaque tokens that can be swapped for a limited scope JWT (Kraken Token). */
export type PreSignedToken = {
  __typename?: 'PreSignedToken';
  isValid?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  /** The scope that the token will grant to the account user. */
  scope: ExpiringTokenScope;
};

/**
 *
 *     Choices class for the pre-signed expiring tokens.
 *
 *     These choices must have a certain format:
 *
 *     {ACTION-VERB}_{DEFINING-NOUN}
 *
 *     They should start with an action verb. It should be a single word.
 *     The action verb enables the account user to do the thing (defining noun)
 *     that comes after the action verb. Together they represent a task.
 *
 *     The defining noun could be longer than a single word.
 *     Preferably, it should be kept short and simple as much as possible.
 *
 */
export enum PreSignedTokenScope {
  /** Scope that enables account user to accept the terms and conditions for a product. */
  AcceptTermsAndConditions = 'ACCEPT_TERMS_AND_CONDITIONS',
  /** Scope that enables account user to book smart meter appointments. */
  BookSmartMeterAppointments = 'BOOK_SMART_METER_APPOINTMENTS',
  /** Scope that enables account user to checkout a quote (validate terms & conds and provide a payment detail). */
  CheckoutQuote = 'CHECKOUT_QUOTE',
  /** Edit Customer Marketing Preference */
  EditCustomerMarketingPreference = 'EDIT_CUSTOMER_MARKETING_PREFERENCE',
  /** Scope that enables account user to join campaigns. */
  JoinCampaigns = 'JOIN_CAMPAIGNS',
  /** Scope that enables account user to join campaign events. */
  JoinCampaignEvents = 'JOIN_CAMPAIGN_EVENTS',
  /** Scope that enables account user to generate a renewal quote and renew agreements. */
  ManageAccountRenewals = 'MANAGE_ACCOUNT_RENEWALS',
  /** Scope that enables account user to manage security deposit payments for business accounts. */
  ManageBusinessSecurityDeposit = 'MANAGE_BUSINESS_SECURITY_DEPOSIT',
  /** Scope that enables account user to accept goods quotes and process goods purchases. */
  ManageGoodsPurchases = 'MANAGE_GOODS_PURCHASES',
  /** Scope that enables account user to do a self-serve product switch through the Dashboard. */
  ManageProductSwitch = 'MANAGE_PRODUCT_SWITCH',
  /** Scope that enables account user to redeem loyalty points */
  RedeemLoyaltyPoints = 'REDEEM_LOYALTY_POINTS',
  /** Scope that enables account user to report a property move-out. */
  ReportMoveOut = 'REPORT_MOVE_OUT',
  /** Scope that enables account user to submit customer feedback. */
  SubmitCustomerFeedback = 'SUBMIT_CUSTOMER_FEEDBACK',
  /** Scope that enables account user to submit meter readings. */
  SubmitMeterReadings = 'SUBMIT_METER_READINGS',
  /** Scope that enables account user to update their blackhole email address. */
  UpdateBlackholeEmail = 'UPDATE_BLACKHOLE_EMAIL',
  /** Update Sensitive Customer Information */
  UpdateSensitiveCustomerInformation = 'UPDATE_SENSITIVE_CUSTOMER_INFORMATION',
  /** Scope that enables account user to visit campaign dashboard. */
  ViewCampaignDashboards = 'VIEW_CAMPAIGN_DASHBOARDS',
  /** Scope that enables account user to visit detailed property usage pages. */
  ViewDetailedUsage = 'VIEW_DETAILED_USAGE',
}

export type PrepareAccountInput = {
  /** The type of account to create. */
  accountType?: InputMaybe<AccountTypeChoices>;
  /** The billing address. */
  billingAddress: LifecycleAddressInput;
  /** The billing name. */
  billingName: Scalars['String'];
  /** The brand of the created account. */
  brandCode: Scalars['String'];
  /** The chosen payment day. */
  chosenPaymentDay?: InputMaybe<Scalars['Int']>;
  /** The customer's details. */
  customerDetails: CustomerDetailsInput;
  /** The date of sale, defaults to today if not provided. */
  dateOfSale?: InputMaybe<Scalars['Date']>;
  /** The preferred supply start date. */
  preferredSsd?: InputMaybe<Scalars['Date']>;
  /** Sales information. */
  salesInfo: SalesInformationInput;
};

export type PrepareAccountResult = {
  __typename?: 'PrepareAccountResult';
  /** The account number of the newly created account or the existing account to be re-used. */
  accountNumber?: Maybe<Scalars['String']>;
  /** Was a new account created. */
  isNewAccount?: Maybe<Scalars['Boolean']>;
  /** Was a new user created. */
  isNewUser?: Maybe<Scalars['Boolean']>;
  /** The ID of the newly created or existing account user. */
  userId?: Maybe<Scalars['ID']>;
};

/** Fields returned by generate_presigned_post. */
export type PresignedPostFields = {
  __typename?: 'PresignedPostFields';
  /** The AWS access key ID. */
  awsAccessKeyId: Scalars['String'];
  /** The S3 bucket key. */
  key: Scalars['String'];
  /** The S3 policy. */
  policy: Scalars['String'];
  /** AWS Signature Version 4 Authentication. */
  signature: Scalars['String'];
  /** The AMZ security token. */
  xAmzSecurityToken: Scalars['String'];
};

export type PricePerUnit = {
  __typename?: 'PricePerUnit';
  /**
   *
   *             Monetary value of a single unit of the measurement.
   *             This is the smallest unit of currency e.g. cents for USD or yen for JPY.
   *
   */
  amount: Scalars['Decimal'];
  /** Unit that monetary amount relates to eg. 27 cents per kwh. */
  unit?: Maybe<Unit>;
};

/** Represents a print attachment */
export type PrintAttachmentType = {
  __typename?: 'PrintAttachmentType';
  filename: Scalars['String'];
  id: Scalars['ID'];
  s3Bucket: Scalars['String'];
  s3Key: Scalars['String'];
  /** Temporary URL at which the attachment is available. This URL will expire after approximately an hour. It is intended for redirection purposes, NOT persistence in any form (e.g. inclusion in emails or the body of a web page). */
  temporaryUrl?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum PrintBatchStatus {
  Closed = 'CLOSED',
  Open = 'OPEN',
  Processed = 'PROCESSED',
}

/** Represents print batch details */
export type PrintBatchType = {
  __typename?: 'PrintBatchType';
  id: Scalars['ID'];
  /** Messages in a print batch. */
  messages?: Maybe<PrintMessageTypeConnection>;
  /** The status of the print batch. */
  status?: Maybe<PrintBatchStatus>;
};

/** Represents print batch details */
export type PrintBatchTypeMessagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isHighPriority?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type PrintEventType = Node & {
  __typename?: 'PrintEventType';
  eventType: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  /** Print message of the print event. */
  message?: Maybe<PrintMessageType>;
  occurredAt: Scalars['DateTime'];
};

/** Represents a print communication. */
export type PrintMessageType = Node & {
  __typename?: 'PrintMessageType';
  account?: Maybe<AccountType>;
  /** Attachments of the message. */
  attachments?: Maybe<Array<Maybe<PrintAttachmentType>>>;
  /** Comms that are marked as high priority. */
  highPriority?: Maybe<Scalars['Boolean']>;
  /** The ID of the object */
  id: Scalars['ID'];
  templateCode: Scalars['String'];
};

export type PrintMessageTypeConnection = {
  __typename?: 'PrintMessageTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PrintMessageTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `PrintMessageType` and its cursor. */
export type PrintMessageTypeEdge = {
  __typename?: 'PrintMessageTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<PrintMessageType>;
};

export type ProductToPurchaseInput = {
  /** Number of units. */
  numberOfUnits: Scalars['Int'];
  /** Products code to purchase. */
  productCode: Scalars['String'];
};

/** Represents a product and the quantity to quote for a customer. */
export type ProductToQuoteInput = {
  /** Currency. */
  currency: Scalars['String'];
  /** Number of units. */
  numberOfUnits: Scalars['Int'];
  /** Price per unit in smallest sub-unit of the currency. */
  pricePerUnit?: InputMaybe<Scalars['Int']>;
  /** ID of the product to quote. */
  productId: Scalars['Int'];
};

/** Additional details about a property specific to the water market. */
export type PropertyDetailType = {
  __typename?: 'PropertyDetailType';
  addressIdentifier: Scalars['String'];
  /** DMA */
  districtMeteredArea: Scalars['String'];
  easting?: Maybe<Scalars['Int']>;
  laneRentalStreet?: Maybe<Scalars['Boolean']>;
  lowPressureRisk?: Maybe<Scalars['Boolean']>;
  northing?: Maybe<Scalars['Int']>;
  pressureManagedZone: Scalars['String'];
  /** SAM */
  smallAreaMonitor: Scalars['String'];
  /** SMA */
  strategicMeteredArea: Scalars['String'];
  trafficSensitive?: Maybe<Scalars['Boolean']>;
  uprn?: Maybe<Scalars['BigInt']>;
  wasteMeterArea: Scalars['String'];
  waterHardness?: Maybe<PropertyDetailWaterHardness>;
  waterHardnessMgPerL?: Maybe<Scalars['Float']>;
  waterPressureMax?: Maybe<Scalars['Float']>;
  waterPressureMin?: Maybe<Scalars['Float']>;
  waterQualityZone: Scalars['String'];
  waterReservoirZone: Scalars['String'];
};

/** An enumeration. */
export enum PropertyDetailWaterHardness {
  /** Hard */
  Hard = 'HARD',
  /** Moderately Hard */
  ModeratelyHard = 'MODERATELY_HARD',
  /** Soft */
  Soft = 'SOFT',
  /** Very Hard */
  VeryHard = 'VERY_HARD',
  /** Very Soft */
  VerySoft = 'VERY_SOFT',
}

export type PropertyInterface = {
  /** The address of the property, formatted into a single string */
  address?: Maybe<Scalars['String']>;
  /** Coordinates for the property, useful for displaying the property on a map. */
  coordinates?: Maybe<CoordinatesType>;
  /** The embedded network this property belongs to, if any. */
  embeddedNetwork?: Maybe<EmbeddedNetworkType>;
  id?: Maybe<Scalars['String']>;
  /** Measurements at a property */
  measurements?: Maybe<MeasurementConnection>;
  /** Time periods during which the property is associated with an account. Useful to display information about house-moves, as performing a move out of a property will set the end date for the occupancy period. */
  occupancyPeriods?: Maybe<Array<Maybe<OccupancyPeriodType>>>;
  /** Property rich address. */
  richAddress?: Maybe<PropertyRichAddressType>;
  /** List of address lines. */
  splitAddress?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PropertyInterfaceMeasurementsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endAt?: InputMaybe<Scalars['DateTime']>;
  endOn?: InputMaybe<Scalars['Date']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
  startOn?: InputMaybe<Scalars['Date']>;
  timezone?: InputMaybe<Scalars['String']>;
  utilityFilters?: InputMaybe<Array<InputMaybe<UtilityFiltersInput>>>;
};

export type PropertyRichAddressType = {
  __typename?: 'PropertyRichAddressType';
  /**
   *
   * Top-level administrative subdivision, e.g. US state, AU
   * state/territory, IT region, JP prefecture.
   *
   * ### `AU`: Australia
   *
   * This must be one of `NSW`, `VIC`, `QLD`, `TAS`, `ACT`, `SA`,
   * `NT`, `WA`. For addresses not within these locations, use
   * the value that Australia Post uses, e.g. `ACT` for the
   * Jervis Bay Territory or `WA` for Christmas Island.
   *
   */
  administrativeArea?: Maybe<Scalars['String']>;
  /**
   *
   * ISO 3166-1 alpha-2 code of the country this address belongs
   * to, e.g. `AU`, `GB`.
   *
   */
  country?: Maybe<Scalars['String']>;
  /**
   *
   * Identifier used by the local postal service for this
   * address, e.g. AU DPID, GB postcode + Delivery Point Suffix,
   * US Zip-9 + Delivery Point.
   *
   * This is the value that gets encoded in the barcode printed
   * on the envelope by large-volume bulk mail providers.
   *
   */
  deliveryPointIdentifier?: Maybe<Scalars['String']>;
  /**
   *
   * UK dependent localities, or neighbourhoods or boroughs in
   * some other locations.
   *
   */
  dependentLocality?: Maybe<Scalars['String']>;
  /**
   *
   * City or town portion of an address, e.g. US city, AU
   * suburb/town, IT comune, UK post town.
   *
   */
  locality?: Maybe<Scalars['String']>;
  /** A personal name. */
  name?: Maybe<Scalars['String']>;
  /** The name of a business or organisation. */
  organization?: Maybe<Scalars['String']>;
  /**
   *
   * Postal code (ZIP code in the US).
   *
   */
  postalCode?: Maybe<Scalars['String']>;
  /**
   *
   * Sorting code, e.g. FR CEDEX code. This field is not used in many countries.
   *
   */
  sortingCode?: Maybe<Scalars['String']>;
  /**
   *
   * The 'street address' component.
   *
   * This value can (and often will) contain newline characters
   * when appropriate.
   *
   * In some cases, data may appear in this field instead of the
   * below fields; e.g. a UK post town name may appear here
   * instead of in the `dependent_locality` field. This happens
   * when data has been migrated from a legacy format, and that
   * format had insufficient metadata to determine the
   * appropriate field.
   *
   * If `structured_street_address` is also set, the value of
   * this field will be a string generated from that value.
   *
   */
  streetAddress?: Maybe<Scalars['String']>;
  /**
   *
   * The 'street address' component, in a structured format.
   *
   * This field stores the same value as `street_address`, but
   * with more detail; for instance, instead of `123 Example
   * Street` it might be `{'street_number': '123',
   * 'street_name': 'Example', 'street_type': 'Street'}`. In
   * many cases this will be blank; we only use this field for
   * Krakens where we need to supply this level of granularity
   * to some third-party service, like a bulk mail provider.
   *
   * The exact structure of this value depends on the country _of
   * the address_, which is not necessarily the same as the
   * country this Kraken is configured to serve. For addresses
   * outside of the countries listed below, this field will be
   * left blank.
   *
   * ### `AU`: Australia
   *
   * The following keys may be present; all are optional. All
   * keys have string values, and their meaning is the same as
   * their aseXML counterparts. (Note that, unlike aseXML, all
   * keys are provided at the top level, rather than being
   * nested.)
   *
   * - `flat_or_unit_type`
   * - `flat_or_unit_number`
   * - `floor_or_level_type`
   * - `floor_or_level_number`
   * - `building_or_property_name`
   * - `location_descriptor`
   * - `lot_number`
   * - `house_number_1`
   * - `house_number_suffix_1`
   * - `house_number_2`
   * - `house_number_suffix_2`
   * - `street_name`
   * - `street_type`
   * - `street_suffix`
   * - `postal_delivery_type`
   * - `postal_delivery_number_prefix`
   * - `postal_delivery_number_value`
   * - `postal_delivery_number_suffix`
   *
   * ### `JP`: Japan
   *
   * The following keys may be present; all are optional.
   * If keys are empty, they may be omitted from the response entirely.
   *
   * - `chome`
   * - `banchi`
   * - `go`
   * - `edaban`
   * - `kana_building_name`
   * - `kanji_building_name`
   * - `building_number`
   * - `room_number`
   * - `address_code`
   * - `physical_location_identifier`
   *
   */
  structuredStreetAddress?: Maybe<Scalars['GenericScalar']>;
};

export type PropertySearchResult = {
  __typename?: 'PropertySearchResult';
  /** The matched property. */
  property: PropertyType;
  /** A score representing the degree of confidence for a match. */
  score: Scalars['Decimal'];
};

/** Represents a property. Conceptually, it collects supply points under an address. */
export type PropertyType = PropertyInterface & {
  __typename?: 'PropertyType';
  /** All active water meters for the property. */
  activeWaterMeters?: Maybe<Array<WaterMeterType>>;
  /** The address of the property, formatted into a single string */
  address?: Maybe<Scalars['String']>;
  /** Coordinates for the property, useful for displaying the property on a map. */
  coordinates?: Maybe<CoordinatesType>;
  /** The embedded network this property belongs to, if any. */
  embeddedNetwork?: Maybe<EmbeddedNetworkType>;
  /** Reference of the property in external system. */
  externalPropertyReference?: Maybe<Scalars['String']>;
  /** Fresh water supply point for the property. */
  freshWaterSupplyPoint?: Maybe<FreshWaterSupplyPointType>;
  id?: Maybe<Scalars['String']>;
  /** Measurements at a property */
  measurements?: Maybe<MeasurementConnection>;
  /** Time periods during which the property is associated with an account. Useful to display information about house-moves, as performing a move out of a property will set the end date for the occupancy period. */
  occupancyPeriods?: Maybe<Array<Maybe<OccupancyPeriodType>>>;
  postcode: Scalars['String'];
  /**  Additional details about a property that are specific to the water market. */
  propertyDetail?: Maybe<PropertyDetailType>;
  /** Property rich address. */
  richAddress?: Maybe<PropertyRichAddressType>;
  /** List of address lines. */
  splitAddress?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Waste water supply point for the property. */
  wasteWaterSupplyPoint?: Maybe<WasteWaterSupplyPointType>;
};

/** Represents a property. Conceptually, it collects supply points under an address. */
export type PropertyTypeMeasurementsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endAt?: InputMaybe<Scalars['DateTime']>;
  endOn?: InputMaybe<Scalars['Date']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
  startOn?: InputMaybe<Scalars['Date']>;
  timezone?: InputMaybe<Scalars['String']>;
  utilityFilters?: InputMaybe<Array<InputMaybe<UtilityFiltersInput>>>;
};

export type ProposedDirectDebitPaymentScheduleType = {
  __typename?: 'ProposedDirectDebitPaymentScheduleType';
  /** The proposed fixed payment amount in pence. Null if amount strategy does not allow for one. */
  amount?: Maybe<Scalars['Int']>;
  /** The first possible date for payment to be collected via direct debit. */
  firstPossibleCollectionDate: Scalars['Date'];
  /** The proposed payment amounts for an instalments payment schedule. Will take into consideration the preferredFirstPaymentDate but if it is before the firstPossibleCollectionDate the function will return a proposal using the firstPossibleCollectionDate. */
  paymentAmounts?: Maybe<ProposedInstalmentsConnectionTypeConnection>;
};

export type ProposedDirectDebitPaymentScheduleTypePaymentAmountsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  preferredFirstPaymentDate?: InputMaybe<Scalars['Date']>;
};

export type ProposedInstalmentType = {
  __typename?: 'ProposedInstalmentType';
  /** Amount of payment. */
  amount: Scalars['Int'];
  /** Date of payment. */
  date: Scalars['Date'];
  /** Payment number. */
  paymentNumber: Scalars['Int'];
};

export type ProposedInstalmentsConnectionTypeConnection = {
  __typename?: 'ProposedInstalmentsConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProposedInstalmentsConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `ProposedInstalmentsConnectionType` and its cursor. */
export type ProposedInstalmentsConnectionTypeEdge = {
  __typename?: 'ProposedInstalmentsConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ProposedInstalmentType>;
};

export type ProvisionalTransactionConnectionTypeConnection = {
  __typename?: 'ProvisionalTransactionConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProvisionalTransactionConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `ProvisionalTransactionConnectionType` and its cursor. */
export type ProvisionalTransactionConnectionTypeEdge = {
  __typename?: 'ProvisionalTransactionConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ProvisionalTransactionType>;
};

/** A provisional transaction represents some debit or credit to or from a customer's account which we cannot yet finalise for some reason, but which is still useful to keep a note of, and display to the customer. Provisional transactions are purely to give guidance in the absence of finalised information. We therefore only return provisional transactions that have not been finalised. When a transaction is finalised, it is available through the `transactions` field. */
export type ProvisionalTransactionType = {
  __typename?: 'ProvisionalTransactionType';
  /** The amount in pence for this provisional transaction. It will be negative for charges, positive for credits. */
  amount?: Maybe<Scalars['Int']>;
  /** The date at which the charge should be applied to the account. */
  date?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  /** A user readable string that indicates what this transaction relates to. */
  title?: Maybe<Scalars['String']>;
};

/**
 * Publish a trigger within the transactional messaging service.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-9901: Invalid trigger type code.
 * - KT-CT-9902: Invalid trigger type params.
 * - KT-CT-9903: Trigger type cannot be published externally.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type PublishTransactionalMessagingTrigger = {
  __typename?: 'PublishTransactionalMessagingTrigger';
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** The trigger that has been published. */
  trigger?: Maybe<TriggerType>;
};

export type PublishTransactionalMessagingTriggerInput = {
  /** The params of the trigger type, as a JSON string. These are defined in the Params class for a trigger type. */
  params: Scalars['JSONString'];
  /** The code of the trigger type to be published. */
  triggerTypeCode: Scalars['String'];
};

/** Represents a pairing of a single app installation to an account user. */
export type PushNotificationBindingType = {
  __typename?: 'PushNotificationBindingType';
  application: NotifiableApplicationType;
  expiresAt: Scalars['DateTime'];
  id: Scalars['ID'];
  messages: PrintMessageTypeConnection;
  registeredAt: Scalars['DateTime'];
  token: Scalars['String'];
  user: AccountUserType;
};

/** Represents a pairing of a single app installation to an account user. */
export type PushNotificationBindingTypeMessagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** Class to inherit queries. */
export type Query = {
  __typename?: 'Query';
  /** Get details about an account. */
  account?: Maybe<AccountType>;
  /** Available reasons for use in account charge mutations. */
  accountChargeReasons?: Maybe<Array<Maybe<ChargeReasonType>>>;
  /** Available reasons for use in account credit mutations. */
  accountCreditReasons?: Maybe<Array<Maybe<CreditReasonType>>>;
  /** List of matching account references. */
  accountReference?: Maybe<Array<Maybe<AccountReferenceType>>>;
  /**
   *  Search account user by unique and non-encrypted detail.
   *
   *
   *
   * This field requires the `Authorization` header to be set.
   *
   *
   */
  accountUserByUniqueDetailValue?: Maybe<AccountUserType>;
  /** Get details about multiple accounts. */
  accounts?: Maybe<Array<Maybe<AccountType>>>;
  /** Search for account that are already in Kraken and match the search terms. */
  accountsSearch?: Maybe<Array<Maybe<AccountSearchItemType>>>;
  /** Return the current active referral reward scheme of a given affiliate organisation, if any exists. */
  activeAffiliateReferralScheme?: Maybe<ReferralSchemeType>;
  /** Return the current active signup referral reward scheme with the given code, if any exists. */
  activeDomesticSignupRewardScheme?: Maybe<ReferralSchemeType>;
  /** Get a list of addresses that match given post code input. */
  addressLookup?: Maybe<AddressConnectionTypeConnection>;
  /** Link object for an affiliate organization. */
  affiliateLink: AffiliateLinkType;
  /** Return the details of a given affiliate organization, if any exists. */
  affiliateOrganisation?: Maybe<AffiliateOrganisationType>;
  /** Get a connection containing API Exceptions. */
  apiExceptions?: Maybe<ApiExceptionConnectionTypeConnection>;
  /** Get mobile screen details to render. */
  backendScreen?: Maybe<BackendScreenType>;
  /** Get all registered backend screen event IDs. */
  backendScreenEventIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Get all registered backend screen IDs. */
  backendScreenIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * Details of the account of an account user updating their blackhole email.
   * @deprecated The 'blackholeEmailAccount' field is deprecated.
   *
   * Please use `blackholeEmailAccountUser.accounts` query instead.
   *
   * - Marked as deprecated on 2024-07-09.
   * - Will be removed on 2024-11-01.
   */
  blackholeEmailAccount?: Maybe<BlackholeEmailAccountType>;
  /**
   *  Details of account user updating their blackhole email.
   *
   *
   *
   * This field requires the `Authorization` header to be set.
   *
   *
   */
  blackholeEmailAccountUser?: Maybe<BlackholeEmailAccountUserType>;
  /** Get contribution schemes. */
  contributionSchemes?: Maybe<Array<Maybe<ContributionSchemeType>>>;
  /** Get a dashboard screen to render in the form of a json list of sections containing cards or grouped cards each with an order attribute. */
  dashboardScreen?: Maybe<Dashboard>;
  /** Get the default payment instruction for the account's main ledger. */
  defaultPaymentInstruction?: Maybe<PaymentInstructionType>;
  /** Get default raw score for a customer feedback form. */
  defaultRawScore?: Maybe<Scalars['Int']>;
  /** Get deposit agreements for a given account. */
  depositAgreements?: Maybe<Array<Maybe<DepositAgreementOutput>>>;
  /** Return a referral reward scheme for the given account referral code. */
  domesticAccountReferralRewardScheme?: Maybe<ReferralSchemeType>;
  /** Return a joining reward scheme with the given code, if it's active. A joining reward can be a signup reward or a promotional reward. */
  domesticJoiningRewardScheme?: Maybe<ReferralSchemeType>;
  /** Return a signup referral reward scheme with the given code, if it's active. */
  domesticSignupRewardScheme?: Maybe<ReferralSchemeType>;
  /** Get details about an embedded network. */
  embeddedNetwork?: Maybe<EmbeddedNetworkType>;
  /** The current energy generation mix. */
  energyMixData?: Maybe<EnergyMixDataType>;
  /** List Goods products given a market. */
  goodsProducts?: Maybe<GoodsProductConnectionTypeConnection>;
  /** List purchases for an account. */
  goodsPurchases?: Maybe<Array<Maybe<GoodsPurchase>>>;
  /** List quotes given an account number or retrieve a Goods quote given a quote code. */
  goodsQuotes?: Maybe<Array<Maybe<GoodsQuote>>>;
  /** Get the Ink conversation for a given account. */
  inkConversation: InkConversation;
  /** Get the content for a given message. */
  inkMessage: InkMessage;
  /** Check validity of a password reset token. */
  isPasswordResetTokenValid?: Maybe<Scalars['Boolean']>;
  /** The current version of kraken. */
  krakenVersion?: Maybe<KrakenVersionType>;
  /** Details associated with a LeaveSupplier process. */
  leaveSupplierProcess?: Maybe<LeaveSupplierProcessType>;
  /** Get all lifecycle processes associated with an account. */
  lifecycleProcesses?: Maybe<LifecycleProcessesType>;
  /** Get all loyalty cards for the given account user. */
  loyaltyCards?: Maybe<Array<Maybe<LoyaltyCardType>>>;
  /** Get the Loyalty Point ledger entries for the passed user. */
  loyaltyPointLedgers?: Maybe<Array<Maybe<LoyaltyPointLedgerEntryType>>>;
  /** Metadata for a linked object. */
  metadata?: Maybe<Array<Maybe<Metadata>>>;
  /** Metadata for a linked object with key. */
  metadataForKey?: Maybe<Metadata>;
  node?: Maybe<Node>;
  /** The help text of all configured password validators as plain-text or html. Defaults to plain-text. */
  passwordValidatorHelpTexts?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Get print batch details, including messages in the batch. */
  printBatch: PrintBatchType;
  /** Search for properties that are already in Kraken and match the search term. */
  propertiesSearch: Array<PropertySearchResult>;
  /** A property with the given ID. Usually associated with supply points. */
  property?: Maybe<PropertyType>;
  /**
   * Search for properties that are already in Kraken and match the search term.
   * @deprecated The 'propertySearch' field is deprecated.
   *
   * This query is being deprecated in favour of `propertiesSearch`. The latter returns not only the matched properties but the level of confidence in the results through the `score` field.
   *
   * - Marked as deprecated on 2023-05-23.
   * - Will be removed on 2024-01-01.
   */
  propertySearch?: Maybe<Array<Maybe<PropertyType>>>;
  /** The payment schedule that would be set up with the given parameters. Used to inform customers of the Direct Debit plan that they are about to set up. */
  proposedDirectDebitPaymentSchedule: ProposedDirectDebitPaymentScheduleType;
  /** List of all available active PSR record types. */
  psrRecordType?: Maybe<Array<Maybe<PsrRecordType>>>;
  /** Get the complexity of a query. */
  queryComplexity?: Maybe<QueryComplexityOutputType>;
  /** Get the customer feedback survey question. */
  question?: Maybe<Scalars['String']>;
  /**
   * Information about rate limit for viewer.
   * @deprecated The 'rateLimit' field is deprecated.
   *
   * `rateLimit` has been replaced by 'rateLimitInfo', which contains more detailed info about rate limit than the former one
   *
   * - Marked as deprecated on 2024-07-17.
   * - Will be removed on 2025-01-01.
   */
  rateLimit?: Maybe<RateLimitInformation>;
  /** Combined information about points-allowance rate limiting and request-specific rate limiting. */
  rateLimitInfo?: Maybe<CombinedRateLimitInformation>;
  /** Get the status of a background task. */
  taskResult?: Maybe<TaskResult>;
  /** Get the active terms and conditions for a product. */
  termsAndConditionsForProduct?: Maybe<TermsAndConditionsType>;
  /**
   *  Verify mobile number or date of birth of the authenticated user.
   *
   *
   *
   * This field requires the `Authorization` header to be set.
   *
   *
   */
  verifyBlackholeEmailUserDetails?: Maybe<Scalars['Boolean']>;
  /**
   *  The currently authenticated user.
   *
   *
   *
   * This field requires the `Authorization` header to be set.
   *
   *
   */
  viewer?: Maybe<AccountUserType>;
};

/** Class to inherit queries. */
export type QueryAccountArgs = {
  accountNumber: Scalars['String'];
};

/** Class to inherit queries. */
export type QueryAccountReferenceArgs = {
  value?: InputMaybe<Scalars['String']>;
};

/** Class to inherit queries. */
export type QueryAccountUserByUniqueDetailValueArgs = {
  namespace: Scalars['String'];
  value: Scalars['String'];
};

/** Class to inherit queries. */
export type QueryAccountsArgs = {
  phoneNumber?: InputMaybe<Scalars['String']>;
  portfolioNumber?: InputMaybe<Scalars['String']>;
};

/** Class to inherit queries. */
export type QueryAccountsSearchArgs = {
  maxResults?: InputMaybe<Scalars['Int']>;
  searchTerms?: InputMaybe<AccountSearchInputType>;
};

/** Class to inherit queries. */
export type QueryActiveAffiliateReferralSchemeArgs = {
  subdomain: Scalars['String'];
};

/** Class to inherit queries. */
export type QueryAddressLookupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  postcode?: InputMaybe<Scalars['String']>;
};

/** Class to inherit queries. */
export type QueryAffiliateLinkArgs = {
  subdomain: Scalars['String'];
};

/** Class to inherit queries. */
export type QueryAffiliateOrganisationArgs = {
  id: Scalars['Int'];
};

/** Class to inherit queries. */
export type QueryApiExceptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  input?: InputMaybe<ApiExceptionQueryInput>;
  last?: InputMaybe<Scalars['Int']>;
};

/** Class to inherit queries. */
export type QueryBackendScreenArgs = {
  maxVersionSupported?: InputMaybe<Scalars['Int']>;
  params?: InputMaybe<Array<InputMaybe<BackendScreenParamInputType>>>;
  screenId: Scalars['ID'];
};

/** Class to inherit queries. */
export type QueryBlackholeEmailAccountArgs = {
  accountNumber: Scalars['String'];
};

/** Class to inherit queries. */
export type QueryDashboardScreenArgs = {
  accountNumber: Scalars['String'];
  dashboardId: Scalars['ID'];
  ledgerId?: InputMaybe<Scalars['String']>;
  maxVersionSupported?: Scalars['Int'];
  params?: InputMaybe<Array<InputMaybe<BackendScreenParamInputType>>>;
  propertyId?: InputMaybe<Scalars['String']>;
};

/** Class to inherit queries. */
export type QueryDefaultPaymentInstructionArgs = {
  accountNumber: Scalars['String'];
  instructionType?: InputMaybe<PaymentType>;
};

/** Class to inherit queries. */
export type QueryDefaultRawScoreArgs = {
  formId: Scalars['Int'];
};

/** Class to inherit queries. */
export type QueryDepositAgreementsArgs = {
  accountNumber: Scalars['String'];
};

/** Class to inherit queries. */
export type QueryDomesticAccountReferralRewardSchemeArgs = {
  code: Scalars['String'];
};

/** Class to inherit queries. */
export type QueryDomesticJoiningRewardSchemeArgs = {
  code: Scalars['String'];
};

/** Class to inherit queries. */
export type QueryDomesticSignupRewardSchemeArgs = {
  code: Scalars['String'];
};

/** Class to inherit queries. */
export type QueryEmbeddedNetworkArgs = {
  id: Scalars['ID'];
};

/** Class to inherit queries. */
export type QueryGoodsProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  marketName: Scalars['String'];
  productType?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Class to inherit queries. */
export type QueryGoodsPurchasesArgs = {
  accountNumber: Scalars['String'];
};

/** Class to inherit queries. */
export type QueryGoodsQuotesArgs = {
  accountNumber?: InputMaybe<Scalars['String']>;
  quoteCode?: InputMaybe<Scalars['String']>;
};

/** Class to inherit queries. */
export type QueryInkConversationArgs = {
  accountNumber?: InputMaybe<Scalars['String']>;
  conversationRelayId?: InputMaybe<Scalars['String']>;
};

/** Class to inherit queries. */
export type QueryInkMessageArgs = {
  messageRelayId: Scalars['String'];
};

/** Class to inherit queries. */
export type QueryIsPasswordResetTokenValidArgs = {
  token: Scalars['String'];
  userId: Scalars['String'];
};

/** Class to inherit queries. */
export type QueryLeaveSupplierProcessArgs = {
  leaveSupplierProcessId: Scalars['ID'];
};

/** Class to inherit queries. */
export type QueryLifecycleProcessesArgs = {
  accountNumber: Scalars['String'];
  onlyActive?: InputMaybe<Scalars['Boolean']>;
};

/** Class to inherit queries. */
export type QueryLoyaltyCardsArgs = {
  accountUserId: Scalars['String'];
};

/** Class to inherit queries. */
export type QueryMetadataArgs = {
  identifier: Scalars['String'];
  linkedObjectType?: InputMaybe<LinkedObjectType>;
};

/** Class to inherit queries. */
export type QueryMetadataForKeyArgs = {
  identifier: Scalars['String'];
  key: Scalars['String'];
  linkedObjectType?: InputMaybe<LinkedObjectType>;
};

/** Class to inherit queries. */
export type QueryNodeArgs = {
  id: Scalars['ID'];
};

/** Class to inherit queries. */
export type QueryPasswordValidatorHelpTextsArgs = {
  asHtml?: InputMaybe<Scalars['Boolean']>;
};

/** Class to inherit queries. */
export type QueryPrintBatchArgs = {
  batchId?: InputMaybe<Scalars['ID']>;
};

/** Class to inherit queries. */
export type QueryPropertiesSearchArgs = {
  searchTerm: Scalars['String'];
};

/** Class to inherit queries. */
export type QueryPropertyArgs = {
  id: Scalars['ID'];
};

/** Class to inherit queries. */
export type QueryPropertySearchArgs = {
  searchTerm: Scalars['String'];
};

/** Class to inherit queries. */
export type QueryProposedDirectDebitPaymentScheduleArgs = {
  accountNumber: Scalars['String'];
  scheduleType: DirectDebitScheduleChoices;
};

/** Class to inherit queries. */
export type QueryQueryComplexityArgs = {
  input: QueryComplexityInputType;
};

/** Class to inherit queries. */
export type QueryQuestionArgs = {
  formId: Scalars['Int'];
};

/** Class to inherit queries. */
export type QueryTaskResultArgs = {
  accountNumber: Scalars['String'];
  taskId: Scalars['String'];
};

/** Class to inherit queries. */
export type QueryTermsAndConditionsForProductArgs = {
  productCode: Scalars['String'];
};

/** Class to inherit queries. */
export type QueryVerifyBlackholeEmailUserDetailsArgs = {
  accountNumber: Scalars['String'];
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
};

/** Information about the complexity of the query. */
export type QueryComplexityInputType = {
  /** The operation name of the query to calculate complexity for if more than one is provided. */
  operationName?: InputMaybe<Scalars['String']>;
  /** The query to calculate complexity for. */
  query: Scalars['String'];
  /** Any variables to include for the query. Pagination variables should be included as they will affect the overall weight of the query. */
  variables?: InputMaybe<Scalars['JSONString']>;
};

export type QueryComplexityOutputType = {
  __typename?: 'QueryComplexityOutputType';
  /** The complexity of the query. */
  complexityValue?: Maybe<Scalars['Int']>;
};

export type RateLimitInformation = {
  __typename?: 'RateLimitInformation';
  /** The maximum number of points the viewer gets for requests per hour. */
  limit?: Maybe<Scalars['Int']>;
  /** The remaining points for the viewer in one hour time limit. */
  remainingPoints?: Maybe<Scalars['Int']>;
  /** The points used so far in one hour time limit. */
  usedPoints?: Maybe<Scalars['Int']>;
};

export type ReadingConnectionTypeConnection = {
  __typename?: 'ReadingConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ReadingConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `ReadingConnectionType` and its cursor. */
export type ReadingConnectionTypeEdge = {
  __typename?: 'ReadingConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ReadingType>;
};

/** Reading direction is based on the utility generated or consumed by the customer. */
export enum ReadingDirectionType {
  /** Reading is based on the customer's usage of the utility. */
  Consumption = 'CONSUMPTION',
  /**
   *
   * Reading is based on the utility generated by the customer.
   *
   * For example: This will return solar readings if a customer has solar panels installed at their location.
   *
   */
  Generation = 'GENERATION',
}

/** The frequency of the reading. */
export enum ReadingFrequencyType {
  /** Readings taken on a day to day basis. */
  Daily = 'DAILY',
  DayInterval = 'DAY_INTERVAL',
  /** Readings taken in every 15 minute intervals. */
  FifteenMinInterval = 'FIFTEEN_MIN_INTERVAL',
  /** Readings taken in every 5 minute intervals. */
  FiveMinInterval = 'FIVE_MIN_INTERVAL',
  HourInterval = 'HOUR_INTERVAL',
  MonthInterval = 'MONTH_INTERVAL',
  /** Readings taken at a point in time. */
  PointInTime = 'POINT_IN_TIME',
  QuarterInterval = 'QUARTER_INTERVAL',
  /** Interval Readings as provided, may be variable in length. */
  RawInterval = 'RAW_INTERVAL',
  /** Readings taken in every 30 minute intervals. */
  ThirtyMinInterval = 'THIRTY_MIN_INTERVAL',
  WeekInterval = 'WEEK_INTERVAL',
}

export enum ReadingQualityType {
  Actual = 'ACTUAL',
  Combined = 'COMBINED',
  Estimate = 'ESTIMATE',
}

export enum ReadingReasonChoices {
  MoveOut = 'MOVE_OUT',
  Routine = 'ROUTINE',
}

/** The type of statistic for the reading interval. */
export enum ReadingStatisticTypeEnum {
  /** The estimated carbon cost of the interval. */
  CarbonCost = 'CARBON_COST',
  /** The calculated cost of consumption for the interval. */
  ConsumptionCost = 'CONSUMPTION_COST',
  /** The calculated monetary value of generation for the interval */
  GenerationValue = 'GENERATION_VALUE',
  /** The calculated cost of standing charges for the interval. */
  StandingChargeCost = 'STANDING_CHARGE_COST',
  /** The apportion cost of a time of use bucket for the interval. */
  TouBucketCost = 'TOU_BUCKET_COST',
}

/** A reading from a water meter. */
export type ReadingType = {
  __typename?: 'ReadingType';
  id: Scalars['ID'];
  /** True if the reading is currently held. */
  isHeld: Scalars['Boolean'];
  /** True if the reading has been quarantined. */
  isQuarantined: Scalars['Boolean'];
  /** Notes for submitting a reading. */
  notes: Scalars['String'];
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** Date of reading. */
  readingDate: Scalars['Date'];
  /** The reason for the reading submission. */
  reason: Scalars['String'];
  /** The origin of the reading submission. */
  source: Scalars['String'];
  /** A cumulative value displaying total consumption that can roll over when the maximum is reached. */
  valueCubicMetres: Scalars['Decimal'];
};

/**
 * Record the customer's acceptance of a deposit agreement.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4177: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type RecordDepositAgreementAccepted = {
  __typename?: 'RecordDepositAgreementAccepted';
  isRecorded?: Maybe<Scalars['Boolean']>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type RectangularButtonType = ButtonInterface &
  IdentifiableInterface & {
    __typename?: 'RectangularButtonType';
    /** The action to perform when the button is pressed. */
    buttonAction: ActionType;
    /** The button style. */
    buttonStyle?: Maybe<ButtonStyle>;
    /** Unique identifier of the object. */
    id?: Maybe<Scalars['ID']>;
    /** Title text of the button. */
    title: Scalars['String'];
    /** The name of the object's type. */
    typename?: Maybe<Scalars['String']>;
    /** Colour style of button eg. filled, outlined, text_only. */
    variant?: Maybe<ButtonVariance>;
  };

/**
 * Redeem Loyalty Points as account credit.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-9201: No Loyalty Point ledger found for the user.
 * - KT-CT-9202: Loyalty Points adapter not configured.
 * - KT-CT-9203: No ledger entries for the ledger.
 * - KT-CT-9205: Insufficient Loyalty Points.
 * - KT-CT-9206: Indivisible points.
 * - KT-CT-9204: Negative or zero points set.
 * - KT-CT-9208: Invalid posted at datetime.
 * - KT-CT-9209: Negative Loyalty Points balance.
 * - KT-CT-9210: Unhandled Loyalty Points exception.
 * - KT-CT-1113: Disabled GraphQL field requested.
 *
 */
export type RedeemLoyaltyPointsForAccountCredit = {
  __typename?: 'RedeemLoyaltyPointsForAccountCredit';
  /** The number of OctoPoints that were redeemed. */
  pointsRedeemed?: Maybe<Scalars['Int']>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** The input type for redeeming Loyalty Points. */
export type RedeemLoyaltyPointsInput = {
  /** The account number. */
  accountNumber: Scalars['String'];
  /** The number of Loyalty Points to redeem. */
  points: Scalars['Int'];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-6723: Unauthorized.
 * - KT-CT-6724: Referral claim code not found.
 * - KT-CT-6725: Referral claim code redeeming error.
 * - KT-CT-1113: Disabled GraphQL field requested.
 *
 */
export type RedeemReferralClaimCode = {
  __typename?: 'RedeemReferralClaimCode';
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** Required payload to redeem the benefit for partner reward referral scheme */
export type RedeemReferralClaimCodeInput = {
  /** The account number for the referred account. */
  accountNumber: Scalars['String'];
  /** Referral scheme claim code value. */
  code: Scalars['String'];
};

export type ReferralConnectionTypeConnection = {
  __typename?: 'ReferralConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ReferralConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `ReferralConnectionType` and its cursor. */
export type ReferralConnectionTypeEdge = {
  __typename?: 'ReferralConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ReferralType>;
};

export type ReferralInterface = {
  code?: Maybe<Scalars['String']>;
  /** The payment amount in pence received by the referrer and the referee combined. */
  combinedPaymentAmount?: Maybe<Scalars['Int']>;
  paymentDate?: Maybe<Scalars['Date']>;
  paymentStatus?: Maybe<Scalars['String']>;
  referredUserJoinDate?: Maybe<Scalars['DateTime']>;
  referredUserName?: Maybe<Scalars['String']>;
  /** Payment amount given to the referred account in pence. */
  referredUserPaymentAmount?: Maybe<Scalars['Int']>;
  /** Payment amount given to the referring account in pence. */
  referringUserPaymentAmount?: Maybe<Scalars['Int']>;
  /** The type of reward scheme. */
  schemeType?: Maybe<ReferralSchemeTypeChoices>;
};

/** A referral scheme is a way for one account to earn a reward for referring another. This is achieved by the referred account using a url (provided by the referring account) to sign up. */
export type ReferralSchemeType = {
  __typename?: 'ReferralSchemeType';
  /** Whether the current account is eligible to be referred under this scheme. */
  canBeReferred?: Maybe<Scalars['Boolean']>;
  /** The unique code for the scheme. */
  code?: Maybe<Scalars['String']>;
  /** The reward amount received by the referrer and the referee combined. */
  combinedRewardAmount?: Maybe<Scalars['Int']>;
  /** The number of loyalty points to be awarded to the referrer in addition to the reward amount. */
  loyaltyPointsBonus?: Maybe<Scalars['Int']>;
  /** A referral url for display purposes. */
  referralDisplayUrl?: Maybe<Scalars['String']>;
  /** A fully qualified url give people to create accounts referred by this scheme. */
  referralUrl?: Maybe<Scalars['String']>;
  /** The reward amount received by the referred party. */
  referredRewardAmount?: Maybe<Scalars['Int']>;
  /**
   * The family name of the person making the referral.
   * @deprecated The 'referringFamilyName' field is deprecated.
   *
   * Only make use of the referrerGivenName for privacy reasons.
   *
   * - Marked as deprecated on 2022-11-07.
   * - Will be removed on 2023-01-07.
   */
  referrerFamilyName?: Maybe<Scalars['String']>;
  /** The given name of the person making the referral. */
  referrerGivenName?: Maybe<Scalars['String']>;
  /** The reward amount received by the referrer. */
  referrerRewardAmount?: Maybe<Scalars['Int']>;
  /** Scheme type of the referral scheme. */
  schemeType?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum ReferralSchemeTypeChoices {
  /** Legacy Referral. */
  LegacyReferral = 'LEGACY_REFERRAL',
  /** Partner Reward. */
  PartnerReward = 'PARTNER_REWARD',
  /** Promo Reward. */
  PromoReward = 'PROMO_REWARD',
  /** Referral Reward. */
  ReferralReward = 'REFERRAL_REWARD',
  /** Signup Reward. */
  SignupReward = 'SIGNUP_REWARD',
}

export type ReferralSchemeTypes = {
  __typename?: 'ReferralSchemeTypes';
  business?: Maybe<ReferralSchemeType>;
  domestic?: Maybe<ReferralSchemeType>;
  /**
   * @deprecated The 'friendsAndFamily' field is deprecated.
   *
   * Please use domestic instead.
   *
   * - Marked as deprecated on 2020-03-05.
   * - Will be removed on 2024-01-01.
   */
  friendsAndFamily?: Maybe<ReferralSchemeType>;
};

/** An enumeration. */
export enum ReferralStatusChoices {
  /** Cancelled. */
  Cancelled = 'Cancelled',
  /** Paid. */
  Paid = 'Paid',
  /** Pending. */
  Pending = 'Pending',
}

/** Details of an account referral */
export type ReferralType = ReferralInterface & {
  __typename?: 'ReferralType';
  code?: Maybe<Scalars['String']>;
  /** The payment amount in pence received by the referrer and the referee combined. */
  combinedPaymentAmount?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  paymentDate?: Maybe<Scalars['Date']>;
  paymentStatus?: Maybe<Scalars['String']>;
  referredUserJoinDate?: Maybe<Scalars['DateTime']>;
  referredUserName?: Maybe<Scalars['String']>;
  /** Payment amount given to the referred account in pence. */
  referredUserPaymentAmount?: Maybe<Scalars['Int']>;
  /** Payment amount given to the referring account in pence. */
  referringUserPaymentAmount?: Maybe<Scalars['Int']>;
  /** The type of reward scheme. */
  schemeType?: Maybe<ReferralSchemeTypeChoices>;
};

/** An opaque token that can be used to renew a Kraken Token. */
export type RefreshToken = {
  __typename?: 'RefreshToken';
  /** The datetime when the token will expire. */
  expiryDt: Scalars['DateTime'];
  isValid?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
};

/** A refund to the customer from the energy supplier. */
export type Refund = TransactionType & {
  __typename?: 'Refund';
  /** Unique identifier of the account the transaction belongs to. */
  accountNumber?: Maybe<Scalars['String']>;
  /**
   * Gross amount including tax (when payable). Refer to the `amounts` field for a breakdown of this information.
   * @deprecated The 'amount' field is deprecated.
   *
   * Use `amounts` instead for a breakdown of the relevant net, tax, and gross amounts.
   *
   * - Marked as deprecated on 2023-12-06.
   * - Will be removed on 2024-06-01.
   */
  amount?: Maybe<Scalars['Int']>;
  /** The net, tax and gross amounts for the transaction. Note: for payments and repayments, only the net amount is returned. */
  amounts?: Maybe<TransactionAmountType>;
  /** The customer's resulting balance after this transaction has been applied, in the smallest unit of currency. */
  balanceCarriedForward?: Maybe<Scalars['Int']>;
  /** The unique identifier for the most recent billing document linked with the transaction.Note: a transaction may be linked with multiple documents, but this field will only return the identifier for the most recent billing document. */
  billingDocumentIdentifier?: Maybe<Scalars['ID']>;
  /** The date time when the transaction is created. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Returns True if the transaction is linked with a statement. */
  hasStatement?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  /**
   * Deprecated.
   * @deprecated The 'isAccountCharge' field is deprecated.
   *
   * This information is provided by the __typename introspection query.
   *
   * - Marked as deprecated on 2020-06-19.
   * - Will be removed on 2022-11-15.
   */
  isAccountCharge?: Maybe<Scalars['Boolean']>;
  /**
   * Deprecated.
   * @deprecated The 'isAccountPayment' field is deprecated.
   *
   * This information is provided by the __typename introspection query.
   *
   * - Marked as deprecated on 2020-06-19.
   * - Will be removed on 2022-11-15.
   */
  isAccountPayment?: Maybe<Scalars['Boolean']>;
  /**
   * Deprecated.
   * @deprecated The 'isCredit' field is deprecated.
   *
   * This information is provided by the __typename introspection query.
   *
   * - Marked as deprecated on 2020-06-19.
   * - Will be removed on 2022-11-15.
   */
  isCredit?: Maybe<Scalars['Boolean']>;
  /** Whether the statement this transaction is on has been held. A held statement is not sent to a customer automatically, but is instead marked for manual attention by operations staff.  Returns False if a statement is not linked with the transaction. */
  isHeld?: Maybe<Scalars['Boolean']>;
  /** Whether this transaction has been issued on any billing document.Note: Look for the most recently issued transaction instead of looking through all transactions as some accounts may have initial transactions that were not issued.This will return False if the transaction is not associated with any billing documents. */
  isIssued?: Maybe<Scalars['Boolean']>;
  isReversed: Scalars['Boolean'];
  /** Returns the note field value for the transaction, which contains additional info. */
  note?: Maybe<Scalars['String']>;
  postedDate?: Maybe<Scalars['Date']>;
  /**
   * Returns None if a statement is not linked with the transaction.
   * @deprecated The 'statementId' field is deprecated.
   *
   * Use `billingDocumentIdentifier` instead.
   *
   * - Marked as deprecated on 2023-11-30.
   * - Will be removed on 2024-06-01.
   */
  statementId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-3924: Unauthorized.
 * - KT-CT-3928: Idempotency key used for another repayment request.
 * - KT-CT-3929: The payment is not in a refundable state.
 * - KT-CT-3933: Refund amount greater than payment amount.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type RefundPayment = {
  __typename?: 'RefundPayment';
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** The repayment for the requested refund. */
  repayment?: Maybe<AccountRepaymentType>;
};

export type RefundPaymentInput = {
  /** The account number. */
  accountNumber: Scalars['ID'];
  /** The amount to be repaid. */
  amountInMinorUnit: Scalars['Int'];
  /** Unique constraint to prevent duplicate requests. */
  idempotencyKey: Scalars['String'];
  /** The ID of the payment to refund. */
  paymentId: Scalars['ID'];
  /** Reason for refunding the payment. */
  reason: Scalars['String'];
};

export type RefundPaymentRequestType = {
  __typename?: 'RefundPaymentRequestType';
  /** The amount of money requested. */
  amount?: Maybe<Scalars['Int']>;
  /** The payment which is being refunded. */
  payment?: Maybe<AccountPaymentType>;
  /** Internal code for the reason the refund is being requested. */
  reasonCode?: Maybe<Scalars['String']>;
  /** The ID of the refund request. */
  requestId?: Maybe<Scalars['ID']>;
  /** The current status of the refund request. */
  status?: Maybe<RepaymentRequestStatus>;
};

export type RefundRequestConnectionTypeConnection = {
  __typename?: 'RefundRequestConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RefundRequestConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `RefundRequestConnectionType` and its cursor. */
export type RefundRequestConnectionTypeEdge = {
  __typename?: 'RefundRequestConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<RefundPaymentRequestType>;
};

/** Regenerate the user's live secret key. */
export type RegenerateSecretKey = {
  __typename?: 'RegenerateSecretKey';
  /**
   *  The currently authenticated user.
   *
   *
   *
   * This field requires the `Authorization` header to be set.
   *
   *
   */
  viewer?: Maybe<AccountUserType>;
};

export type RegisterPushNotificationBinding = {
  __typename?: 'RegisterPushNotificationBinding';
  pushNotificationBinding?: Maybe<PushNotificationBindingType>;
};

export type RegisterPushNotificationBindingInput = {
  /** Register a push notification binding. A push notification binding connects an account user to a specific application running on a specific device through a 'registration token' (Android) or 'device token' (iOS). Using this binding we can send push notifications to the account user's devices. */
  bundleId: Scalars['String'];
  /** Device push notification token. */
  token: Scalars['String'];
};

/** Input fields for Repayment Intervention. */
export type RepaymentInput = {
  /** The Repayment Intervention reason. */
  reason?: InputMaybe<Scalars['String']>;
  /** The repayment ID. */
  repaymentId: Scalars['ID'];
};

export type RepaymentInterventionType = {
  __typename?: 'RepaymentInterventionType';
  /** The repayment intervention outcome. */
  outcome?: Maybe<Scalars['String']>;
  /** The repayment intervention reason. */
  reason?: Maybe<Scalars['String']>;
};

/**
 *
 *     Methods by which repayments can be sent to the customer.
 *
 */
export enum RepaymentMethod {
  BankTransfer = 'BANK_TRANSFER',
  Card = 'CARD',
  Cheque = 'CHEQUE',
}

export type RepaymentRequestConnectionTypeConnection = {
  __typename?: 'RepaymentRequestConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RepaymentRequestConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `RepaymentRequestConnectionType` and its cursor. */
export type RepaymentRequestConnectionTypeEdge = {
  __typename?: 'RepaymentRequestConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<RepaymentRequestType>;
};

/**
 *
 *     Possible status' for a repayment (or refund) request
 *
 */
export enum RepaymentRequestStatus {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  Rejected = 'REJECTED',
  Requested = 'REQUESTED',
}

export type RepaymentRequestType = {
  __typename?: 'RepaymentRequestType';
  /** The amount of money requested. */
  amount?: Maybe<Scalars['Int']>;
  /** The payment instruction, if any, associated with the repayment request. */
  instruction?: Maybe<PaymentInstructionType>;
  /** The method by which the money will be transferred to the customer. */
  method?: Maybe<RepaymentMethod>;
  /** Classifier code for repayment reason. */
  reasonCode?: Maybe<Scalars['String']>;
  /** The ID of the repayment request. */
  requestId?: Maybe<Scalars['String']>;
  /** The current status of the repayment request. */
  status?: Maybe<RepaymentRequestStatus>;
};

/** Input type for the RequestPasswordReset mutation. */
export type RequestPasswordResetInput = {
  /** The email requesting a password reset email. */
  email: Scalars['String'];
};

export type RequestPasswordResetOutputType = {
  __typename?: 'RequestPasswordResetOutputType';
  /** The email that requested a password reset email. */
  email?: Maybe<Scalars['String']>;
};

export type RequestRefundEligibilityType = {
  __typename?: 'RequestRefundEligibilityType';
  /** Whether the account can request a refund. */
  canRequestRefund: Scalars['Boolean'];
  /** The reason why a refund cannot be requested. */
  reason?: Maybe<Scalars['String']>;
};

export type RequestRepaymentInputType = {
  /** The account number for the requested ledger's account. */
  accountNumber: Scalars['String'];
  /** The amount to be repaid. */
  amountInMinorUnit: Scalars['Int'];
  /** Unique constraint to prevent duplicate requests. */
  idempotencyKey: Scalars['String'];
  /** The ledger id from which the repayment will be requested. */
  ledgerId: Scalars['String'];
  /** The method by which the money will be transferred to the customer. */
  method?: InputMaybe<RequestableRepaymentMethod>;
  /** The reason for the repayment. */
  reason?: InputMaybe<Scalars['String']>;
};

/** Output for creating a repayment request. */
export type RequestRepaymentOutputType = {
  __typename?: 'RequestRepaymentOutputType';
  /** The ID of the repayment request. */
  requestId?: Maybe<Scalars['String']>;
  /** The current status of the repayment request. */
  status?: Maybe<RepaymentRequestStatus>;
};

/** An enumeration. */
export enum RequestableRepaymentMethod {
  BankTransfer = 'BANK_TRANSFER',
  Cheque = 'CHEQUE',
}

export type ResetPasswordMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  token: Scalars['String'];
  userId: Scalars['String'];
};

export type ResetPasswordMutationPayload = {
  __typename?: 'ResetPasswordMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<SerializerFieldErrorsType>>>;
};

export type ResetUserPasswordInput = {
  /** The new password. */
  newPassword: Scalars['String'];
  /** The token from the presigned url. */
  token: Scalars['String'];
  /** A base64 bytestring representing the user's unique id. */
  userId: Scalars['String'];
};

export type ResetUserPasswordOutput = {
  __typename?: 'ResetUserPasswordOutput';
  /** A list of which password validations the new password failed against if applicable. */
  failureReasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** True if the password update was successful, false otherwise. */
  passwordUpdated?: Maybe<Scalars['Boolean']>;
};

export type RewardInterface = {
  paymentDate?: Maybe<Scalars['Date']>;
  /** The status of the reward payment. */
  paymentStatus?: Maybe<ReferralStatusChoices>;
  /** Reward amount given to the account in pence. */
  rewardAmount?: Maybe<Scalars['Int']>;
  /** The type of reward scheme. */
  schemeType?: Maybe<ReferralSchemeTypeChoices>;
};

/**
 *
 *             A reward is based on a scheme that an account has applied for in order to be
 *             eligible for a discount. Examples can include signup, promo, or partner codes that
 *             were applied to an account.
 *
 */
export type RewardType = RewardInterface & {
  __typename?: 'RewardType';
  id: Scalars['ID'];
  paymentDate?: Maybe<Scalars['Date']>;
  /** The status of the reward payment. */
  paymentStatus?: Maybe<ReferralStatusChoices>;
  /** Reward amount given to the account in pence. */
  rewardAmount?: Maybe<Scalars['Int']>;
  /** The type of reward scheme. */
  schemeType?: Maybe<ReferralSchemeTypeChoices>;
};

/**
 *
 * A postal address.
 *
 * This data model is based on the structure used by Google's
 * [libaddressinput library](https://github.com/google/libaddressinput)&mdash;so
 * you can use it, or other libraries that use its data model
 * and reference data, to accept input.
 *
 * All fields can be blank, except for ``country`` which must
 * always be supplied.
 *
 */
export type RichAddressType = {
  __typename?: 'RichAddressType';
  /**
   *
   * Top-level administrative subdivision, e.g. US state, AU
   * state/territory, IT region, JP prefecture.
   *
   * ### `AU`: Australia
   *
   * This must be one of `NSW`, `VIC`, `QLD`, `TAS`, `ACT`, `SA`,
   * `NT`, `WA`. For addresses not within these locations, use
   * the value that Australia Post uses, e.g. `ACT` for the
   * Jervis Bay Territory or `WA` for Christmas Island.
   *
   */
  administrativeArea?: Maybe<Scalars['String']>;
  /**
   *
   * ISO 3166-1 alpha-2 code of the country this address belongs
   * to, e.g. `AU`, `GB`.
   *
   */
  country?: Maybe<Scalars['String']>;
  /**
   *
   * Identifier used by the local postal service for this
   * address, e.g. AU DPID, GB postcode + Delivery Point Suffix,
   * US Zip-9 + Delivery Point.
   *
   * This is the value that gets encoded in the barcode printed
   * on the envelope by large-volume bulk mail providers.
   *
   */
  deliveryPointIdentifier?: Maybe<Scalars['String']>;
  /**
   *
   * UK dependent localities, or neighbourhoods or boroughs in
   * some other locations.
   *
   */
  dependentLocality?: Maybe<Scalars['String']>;
  /**
   *
   * City or town portion of an address, e.g. US city, AU
   * suburb/town, IT comune, UK post town.
   *
   */
  locality?: Maybe<Scalars['String']>;
  /** A personal name. */
  name?: Maybe<Scalars['String']>;
  /** The name of a business or organisation. */
  organization?: Maybe<Scalars['String']>;
  /**
   *
   * Postal code (ZIP code in the US).
   *
   */
  postalCode?: Maybe<Scalars['String']>;
  /**
   *
   * Sorting code, e.g. FR CEDEX code. This field is not used in many countries.
   *
   */
  sortingCode?: Maybe<Scalars['String']>;
  /**
   *
   * The 'street address' component.
   *
   * This value can (and often will) contain newline characters
   * when appropriate.
   *
   * In some cases, data may appear in this field instead of the
   * below fields; e.g. a UK post town name may appear here
   * instead of in the `dependent_locality` field. This happens
   * when data has been migrated from a legacy format, and that
   * format had insufficient metadata to determine the
   * appropriate field.
   *
   * If `structured_street_address` is also set, the value of
   * this field will be a string generated from that value.
   *
   */
  streetAddress?: Maybe<Scalars['String']>;
  /**
   *
   * The 'street address' component, in a structured format.
   *
   * This field stores the same value as `street_address`, but
   * with more detail; for instance, instead of `123 Example
   * Street` it might be `{'street_number': '123',
   * 'street_name': 'Example', 'street_type': 'Street'}`. In
   * many cases this will be blank; we only use this field for
   * Krakens where we need to supply this level of granularity
   * to some third-party service, like a bulk mail provider.
   *
   * The exact structure of this value depends on the country _of
   * the address_, which is not necessarily the same as the
   * country this Kraken is configured to serve. For addresses
   * outside of the countries listed below, this field will be
   * left blank.
   *
   * ### `AU`: Australia
   *
   * The following keys may be present; all are optional. All
   * keys have string values, and their meaning is the same as
   * their aseXML counterparts. (Note that, unlike aseXML, all
   * keys are provided at the top level, rather than being
   * nested.)
   *
   * - `flat_or_unit_type`
   * - `flat_or_unit_number`
   * - `floor_or_level_type`
   * - `floor_or_level_number`
   * - `building_or_property_name`
   * - `location_descriptor`
   * - `lot_number`
   * - `house_number_1`
   * - `house_number_suffix_1`
   * - `house_number_2`
   * - `house_number_suffix_2`
   * - `street_name`
   * - `street_type`
   * - `street_suffix`
   * - `postal_delivery_type`
   * - `postal_delivery_number_prefix`
   * - `postal_delivery_number_value`
   * - `postal_delivery_number_suffix`
   *
   * ### `JP`: Japan
   *
   * The following keys may be present; all are optional.
   * If keys are empty, they may be omitted from the response entirely.
   *
   * - `chome`
   * - `banchi`
   * - `go`
   * - `edaban`
   * - `kana_building_name`
   * - `kanji_building_name`
   * - `building_number`
   * - `room_number`
   * - `address_code`
   * - `physical_location_identifier`
   *
   */
  structuredStreetAddress?: Maybe<Scalars['GenericScalar']>;
};

/** An enumeration. */
export enum SalesChannelChoices {
  Acquisition = 'ACQUISITION',
  Aggregator = 'AGGREGATOR',
  Broker = 'BROKER',
  DebtCollectionAgency = 'DEBT_COLLECTION_AGENCY',
  DigiTelesales = 'DIGI_TELESALES',
  Direct = 'DIRECT',
  Events = 'EVENTS',
  FieldSales = 'FIELD_SALES',
  GiftOfKit = 'GIFT_OF_KIT',
  HighReferrer = 'HIGH_REFERRER',
  Landlord = 'LANDLORD',
  MoveIn = 'MOVE_IN',
  NewTenant = 'NEW_TENANT',
  ParentPower = 'PARENT_POWER',
  Partnerships = 'PARTNERSHIPS',
  PeoplePower = 'PEOPLE_POWER',
  PriceComparison = 'PRICE_COMPARISON',
  SupplierOfLastResort = 'SUPPLIER_OF_LAST_RESORT',
  Telesales = 'TELESALES',
  WorkplacePopUp = 'WORKPLACE_POP_UP',
  WorksWithOctopus = 'WORKS_WITH_OCTOPUS',
}

/** Information about the sale to associate with the account. */
export type SalesInformationInput = {
  /** Unique reference number. */
  affiliateParams?: InputMaybe<Scalars['String']>;
  /** Unique reference number. */
  affiliateSubdomain?: InputMaybe<Scalars['String']>;
  /** Sales channel. */
  salesChannel: Scalars['String'];
  /** Sales subchannel. */
  salesSubchannel?: InputMaybe<Scalars['String']>;
  /** Unique reference number. */
  urn?: InputMaybe<Scalars['String']>;
};

/** An enumeration. */
export enum ScheduleType {
  BacsTransfer = 'BACS_TRANSFER',
  CardPayment = 'CARD_PAYMENT',
  DirectDebit = 'DIRECT_DEBIT',
  PaymentSlip = 'PAYMENT_SLIP',
}

/** An action which calls another backend screen via its screen id. */
export type ScreenActionType = ActionInterface &
  IdentifiableInterface & {
    __typename?: 'ScreenActionType';
    /** Whether to allow returning to the original caller screen. */
    allowBack: Scalars['Boolean'];
    /** Unique identifier of the object. */
    id?: Maybe<Scalars['ID']>;
    /** Map of the parameters (key-value pairs) to pass to the next backend screen. */
    params: Array<Maybe<BackendScreenParam>>;
    /** The ID of the screen to navigate to. */
    screenId: Scalars['String'];
    /** The name of the action object's type. */
    typeName?: Maybe<Scalars['String']>;
    /** The name of the object's type. */
    typename?: Maybe<Scalars['String']>;
  };

export type SectionContent = CardComponentType | CarouselItemType;

/** A section containing a list of cards or carousel items */
export type SectionType = IdentifiableInterface & {
  __typename?: 'SectionType';
  /** The content of the section. */
  content: SectionContent;
  /** Unique identifier of the object. */
  id?: Maybe<Scalars['ID']>;
  /** The order of the section. */
  order: Scalars['Int'];
  /** The name of the object's type. */
  typename?: Maybe<Scalars['String']>;
};

export type SerializerErrorType = {
  __typename?: 'SerializerErrorType';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type SerializerFieldErrorsType = {
  __typename?: 'SerializerFieldErrorsType';
  errors?: Maybe<Array<ErrorTypeUnion>>;
  field?: Maybe<Scalars['String']>;
};

/**
 * Set the Loyalty Points user for the account.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-9210: Unhandled Loyalty Points exception.
 * - KT-CT-1113: Disabled GraphQL field requested.
 *
 */
export type SetLoyaltyPointsUser = {
  __typename?: 'SetLoyaltyPointsUser';
  /** ID of the new Loyalty Points user. */
  newLoyaltyPointsUserId?: Maybe<Scalars['String']>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** The input type for setting the Loyalty Points user. */
export type SetLoyaltyPointsUserInput = {
  /** The account number. */
  accountNumber: Scalars['String'];
  /** The account user receiving the points. */
  newLoyaltyPointsUserId: Scalars['String'];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-3940: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type SetUpDirectDebitInstruction = {
  __typename?: 'SetUpDirectDebitInstruction';
  paymentInstruction?: Maybe<DirectDebitInstructionType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type SetUpDirectDebitInstructionInput = {
  accountNumber: Scalars['String'];
  bankDetails: BankDetailsInput;
  ledgerId: Scalars['ID'];
  validFrom: Scalars['DateTime'];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-4122: Invalid email.
 * - KT-CT-8203: Received an invalid quote code.
 * - KT-CT-1113: Disabled GraphQL field requested.
 *
 */
export type ShareGoodsQuote = {
  __typename?: 'ShareGoodsQuote';
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** Goods quote shared. */
  share?: Maybe<GoodsQuoteShare>;
};

export type ShareGoodsQuoteInput = {
  /** The email to share the quote with. */
  email: Scalars['String'];
  /** The quote to share. */
  quoteCode: Scalars['String'];
};

/** Sizes for elements. */
export type SizedItemInterface = {
  /** The measurement of the element. */
  width?: Maybe<ItemSizeType>;
};

/** An enumeration. */
export enum Songs {
  /** . */
  NoSongPreferred = 'NO_SONG_PREFERRED',
}

/** Input fields for inserting an account user's priority service details. */
export type SpecialCircumstanceRecordInput = {
  /** The end date of the temporary PSR record type. */
  expiryDate?: InputMaybe<Scalars['Date']>;
  /** Extra information about the PSR record type for a user. */
  extraInfo: Array<InputMaybe<StringKeyValueInput>>;
  /** The internal code of the PSR record type. */
  internalCode: Scalars['String'];
};

/**
 * Any special circumstances that the user has notified us about, which may entitle them to
 * some specialist services. These circumstances can be temporary.
 * Temporary circumstances will have an `expiry_date`, after which they will no
 * longer apply. Having young children is an example of this in the UK.
 */
export type SpecialCircumstanceRecordType = {
  __typename?: 'SpecialCircumstanceRecordType';
  /** Description of circumstance. */
  description: Scalars['String'];
  /** Expiry date of the special circumstance if temporary. */
  expiryDate?: Maybe<Scalars['Date']>;
  /** List of extra information about the special circumstance. */
  extraInfo: Array<Maybe<StringKeyValueType>>;
  /** ID. */
  id: Scalars['ID'];
  /** Internal code. */
  internalCode: Scalars['String'];
  /** Summary. */
  summary: Scalars['String'];
};

/** Information about the special circumstances that relate to a user. */
export type SpecialCircumstancesType = {
  __typename?: 'SpecialCircumstancesType';
  /** Whether the user has consented for their data to be given to the appropriate industry or regulatory bodies. We typically only ask for this once, so this field can be used to decide whether to ask the user for their initial consent. */
  isSharingConsentGiven?: Maybe<Scalars['Boolean']>;
  /** List of special circumstance records for the user. */
  records?: Maybe<Array<Maybe<SpecialCircumstanceRecordType>>>;
};

/** A statement is a billing document that contains all entries on a ledger during a period of time. A customer can understand how their ledger's balance has changed by looking at each statement in series. */
export type StatementBillingDocumentConnectionTypeConnection = {
  __typename?: 'StatementBillingDocumentConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StatementBillingDocumentConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `StatementBillingDocumentConnectionType` and its cursor. */
export type StatementBillingDocumentConnectionTypeEdge = {
  __typename?: 'StatementBillingDocumentConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<StatementBillingDocumentType>;
};

/** A statement is a billing document that contains all entries on a ledger during a period of time. A customer can understand how their ledger's balance has changed by looking at each statement in series. */
export type StatementBillingDocumentType = {
  __typename?: 'StatementBillingDocumentType';
  /** Billing document that annuls this statement. */
  annulledBy?: Maybe<AnnulmentBillingDocumentType>;
  /** The earliest charge date of the statement. */
  earliestChargeAt?: Maybe<Scalars['DateTime']>;
  /** The end of the statement's period. */
  endAt: Scalars['DateTime'];
  /** The date and time the statement was sent to the customer. */
  firstIssuedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  /** The unique reference of the statement that can be used for identifying the statement externally. */
  identifier?: Maybe<Scalars['String']>;
  /** The latest charge date of the statement. */
  latestChargeAt?: Maybe<Scalars['DateTime']>;
  /** URL to the PDF of the statement. */
  pdfUrl?: Maybe<Scalars['String']>;
  /** The start of the statement's period. */
  startAt: Scalars['DateTime'];
};

/** An enumeration. */
export enum StatementReversalsAfterClose {
  /** All charges have been reversed after the statement was closed. */
  All = 'ALL',
  /** No reversals after the statement was closed. */
  None = 'NONE',
  /** The statement has not been closed yet. */
  NotClosed = 'NOT_CLOSED',
  /** Some charges have been reversed after the statement was closed. */
  Some = 'SOME',
}

export type StatementTotalType = {
  __typename?: 'StatementTotalType';
  /** The gross total amount for the statement (in minor currency units). */
  grossTotal?: Maybe<Scalars['Int']>;
  /** The net total amount for the statement (in minor currency units). */
  netTotal?: Maybe<Scalars['Int']>;
  /** The total amount of tax on the statement (in minor currency units). */
  taxTotal?: Maybe<Scalars['Int']>;
};

export type StatementType = BillInterface & {
  __typename?: 'StatementType';
  billType?: Maybe<BillTypeEnum>;
  /** This field returns the closing balance of an issued statement. */
  closingBalance?: Maybe<Scalars['Int']>;
  /** The last day of consumption that this statement includes. */
  consumptionEndDate?: Maybe<Scalars['Date']>;
  /** The first day of consumption that this statement includes. */
  consumptionStartDate?: Maybe<Scalars['Date']>;
  fromDate?: Maybe<Scalars['Date']>;
  /** Retrieve the held status of a account statement. */
  heldStatus?: Maybe<HeldStatus>;
  id?: Maybe<Scalars['ID']>;
  /** Whether the bill originated in Kraken or externally. */
  isExternalBill?: Maybe<Scalars['Boolean']>;
  /** The date the bill was sent to the customer. */
  issuedDate?: Maybe<Scalars['Date']>;
  /** This field returns the opening balance of a statement. */
  openingBalance?: Maybe<Scalars['Int']>;
  /** The date the bill is due to be paid. */
  paymentDueDate?: Maybe<Scalars['Date']>;
  /** How many charges have been reversed after the close date. */
  reversalsAfterClose: StatementReversalsAfterClose;
  /** Current status of the associated statement. */
  status?: Maybe<AccountStatementStatus>;
  /**
   * Requesting this field generates a temporary URL at which bill is available.
   *         This URL will expire after approximately an hour.  It is intended for redirection purposes,
   *         NOT persistence in any form (e.g. inclusion in emails or the body of a web page).
   *         This field can raise an error with errorClass NOT_FOUND if the bill document has not
   *         been created/issued yet.
   *
   *
   *
   */
  temporaryUrl?: Maybe<Scalars['String']>;
  /** Email recipient address. */
  toAddress?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['Date']>;
  /** The total amounts for all charges on the statement. */
  totalCharges?: Maybe<StatementTotalType>;
  /** The total amounts for all credits on the statement. */
  totalCredits?: Maybe<StatementTotalType>;
  /** Transactions on the bill. */
  transactions?: Maybe<TransactionConnectionTypeConnection>;
  /** Email recipient user ID. */
  userId?: Maybe<Scalars['Int']>;
};

export type StatementTypeTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type StatisticOutput = {
  __typename?: 'StatisticOutput';
  /** Monetary cost of the statistic (excluding tax), if applicable. */
  costExclTax?: Maybe<EstimatedMoneyType>;
  /** Monetary cost of the statistic (including tax), if applicable. */
  costInclTax?: Maybe<EstimatedMoneyType>;
  /** Description of the statistic for the parent node. */
  description?: Maybe<Scalars['String']>;
  /** Display label of the statistic for the parent node. */
  label?: Maybe<Scalars['String']>;
  /** The type of statistic being measured for the parent node. */
  type?: Maybe<ReadingStatisticTypeEnum>;
  /** Consumption / generation value of the statistic, if applicable. */
  value?: Maybe<Scalars['Decimal']>;
};

/**
 * Store a new payment instruction created through the embedded process.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4177: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type StorePaymentInstruction = {
  __typename?: 'StorePaymentInstruction';
  /** The stored payment instruction. */
  paymentInstruction?: Maybe<PaymentInstructionType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** The input for storing a new payment instruction created through the embedded process. */
export type StorePaymentInstructionInput = {
  /** The account number. */
  accountNumber: Scalars['String'];
  /** The type of the new payment instruction. */
  instructionType: PaymentType;
  /**
   * **WARNING: Will be mandatory in future versions**
   *
   *  The ledger ID to which the instructions will be linked.
   */
  ledgerId?: InputMaybe<Scalars['String']>;
  /** The datetime from which the instruction is vaild. */
  validFrom: Scalars['DateTime'];
  /** The vendor's reference for this payment method. */
  vendorReference: Scalars['String'];
};

/** Key value input for dynamic field names. */
export type StringKeyValueInput = {
  /** The name of the field. */
  name: Scalars['String'];
  /** The value of the field. */
  value: Scalars['String'];
};

/** Key value type for dynamic field names. */
export type StringKeyValueType = {
  __typename?: 'StringKeyValueType';
  /** The name of the field. */
  name: Scalars['String'];
  /** The value of the field. */
  value: Scalars['String'];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-5514: Unable to submit feedback.
 * - KT-CT-5511: The feedback_id should be provided for feedback source.
 * - KT-CT-5512: The feedback doesn't match the account.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type SubmitCustomerFeedback = {
  __typename?: 'SubmitCustomerFeedback';
  customerFeedback?: Maybe<CustomerFeedbackType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * Create a customer meter reading.
 *
 * The possible errors that can be raised are:
 *
 * - KT-GB-6414: Meter reading could not be updated. Input not valid.
 * - KT-CT-6422: Reading will be held.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type SubmitMeterReading = {
  __typename?: 'SubmitMeterReading';
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** The submitted reading. */
  reading?: Maybe<ReadingType>;
};

/** Input fields for submitting a meter reading. */
export type SubmitMeterReadingInputType = {
  /** Unique account_number. */
  accountNumber: Scalars['String'];
  /** Unique meter_id. */
  meterId: Scalars['ID'];
  /** Notes for submitting a reading. */
  notes?: InputMaybe<Scalars['String']>;
  /** Date at which the reading took place. */
  readingDate: Scalars['Date'];
  /** Reason for submitting a reading. */
  reason?: InputMaybe<ReadingReasonChoices>;
  /** Option to skip held reading checks. */
  skipHoldChecks?: InputMaybe<Scalars['Boolean']>;
  /** Usage value of water in cubic metres. */
  valueCubicMetres: Scalars['Decimal'];
};

/**
 * Mutation for executing the repayment request use case.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1132: Unauthorized.
 * - KT-CT-3927: Invalid Amount.
 * - KT-CT-3928: Idempotency key used for another repayment request.
 * - KT-CT-1113: Disabled GraphQL field requested.
 *
 */
export type SubmitRepaymentRequest = {
  __typename?: 'SubmitRepaymentRequest';
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** The newly created repayment request. */
  repaymentRequest?: Maybe<RequestRepaymentOutputType>;
};

export type SupplementaryLedgerInterface = {
  /** The current final balance of the ledger in pence. */
  currentBalance?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  ledgerType?: Maybe<Scalars['String']>;
  /** The display name of the ledger. */
  name?: Maybe<Scalars['String']>;
  paymentAdequacy?: Maybe<PaymentAdequacyDetailsType>;
};

/** Ledgers provide the foundation of Kraken’s bookkeeping functionality. Similar to a bank account, they allow us to keep track of financial activity on a particular Kraken account. */
export type SupplementaryLedgerType = SupplementaryLedgerInterface & {
  __typename?: 'SupplementaryLedgerType';
  /** The current final balance of the ledger in pence. */
  currentBalance?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  ledgerType?: Maybe<Scalars['String']>;
  /** The display name of the ledger. */
  name?: Maybe<Scalars['String']>;
  paymentAdequacy?: Maybe<PaymentAdequacyDetailsType>;
};

export type SupplyPointConnectionTypeConnection = {
  __typename?: 'SupplyPointConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SupplyPointConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `SupplyPointConnectionType` and its cursor. */
export type SupplyPointConnectionTypeEdge = {
  __typename?: 'SupplyPointConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<SupplyPointType>;
};

export type SupplyPointNode =
  | FreshWaterSupplyPointType
  | WasteWaterSupplyPointType;

/** Represents a SupplyPoint. */
export type SupplyPointType = CommonSupplyPointInterface & {
  __typename?: 'SupplyPointType';
  /** The external identifier of the supply point. */
  externalIdentifier?: Maybe<Scalars['String']>;
  /** The ID of the supply point. */
  id: Scalars['ID'];
  /** The market this supply point belongs to. */
  marketName: Scalars['String'];
};

export type TaskResult = {
  __typename?: 'TaskResult';
  /** The error message if the task failed. */
  error?: Maybe<Scalars['String']>;
  /** The result of the task. */
  result?: Maybe<Scalars['JSONString']>;
  /** The status of the task. */
  status?: Maybe<TaskStatusEnum>;
};

/** An enumeration. */
export enum TaskStatusEnum {
  Failed = 'FAILED',
  Finished = 'FINISHED',
  Started = 'STARTED',
}

export type TermsAndConditionsType = {
  __typename?: 'TermsAndConditionsType';
  brandCode?: Maybe<Scalars['String']>;
  effectiveFrom?: Maybe<Scalars['DateTime']>;
  /** The html of the terms and conditions document rendered as a JSON string. */
  html?: Maybe<Scalars['String']>;
  /** The markdown text of the terms and conditions. */
  markdown?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pdfUrl?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type TextInterface = {
  /** The text alignment. */
  textAlignment?: Maybe<Alignment>;
  /** The text style, i.e. header, body. */
  textStyle?: Maybe<TextStyleV1>;
  /** The text content. */
  value: Scalars['String'];
};

/**
 * The style is the typographical hierarchy.
 * These are Typescale Categories from the Mobile Design System (Figma).
 */
export enum TextStyleV1 {
  Body1 = 'BODY1',
  Body2 = 'BODY2',
  ButtonText = 'BUTTON_TEXT',
  Callout1 = 'CALLOUT1',
  Callout2 = 'CALLOUT2',
  Callout3 = 'CALLOUT3',
  InputTitle = 'INPUT_TITLE',
  Small1 = 'SMALL1',
  Small2 = 'SMALL2',
  Small3 = 'SMALL3',
  Tabular = 'TABULAR',
  Title1 = 'TITLE1',
  Title2 = 'TITLE2',
  Title3 = 'TITLE3',
  Title4 = 'TITLE4',
  Title5 = 'TITLE5',
  Title6 = 'TITLE6',
}

/** A block of text. */
export type TextType = IdentifiableInterface &
  TextInterface & {
    __typename?: 'TextType';
    /** Unique identifier of the object. */
    id?: Maybe<Scalars['ID']>;
    /** The text alignment. */
    textAlignment?: Maybe<Alignment>;
    /** The text style, i.e. header, body. */
    textStyle?: Maybe<TextStyleV1>;
    /** The name of the object's type. */
    typename?: Maybe<Scalars['String']>;
    /** The text content. */
    value: Scalars['String'];
  };

export type TransactionAmountType = {
  __typename?: 'TransactionAmountType';
  /** The gross amount (in minor currency units). */
  gross?: Maybe<Scalars['Int']>;
  /** The net amount (in minor currency units). */
  net?: Maybe<Scalars['Int']>;
  /** The amount of tax (in minor currency units). */
  tax?: Maybe<Scalars['Int']>;
};

export type TransactionConnectionTypeConnection = {
  __typename?: 'TransactionConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TransactionConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `TransactionConnectionType` and its cursor. */
export type TransactionConnectionTypeEdge = {
  __typename?: 'TransactionConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TransactionType>;
};

/** Transactions are a record of money being added or subtracted from the overall account balance */
export type TransactionType = {
  /** Unique identifier of the account the transaction belongs to. */
  accountNumber?: Maybe<Scalars['String']>;
  /**
   * Gross amount including tax (when payable). Refer to the `amounts` field for a breakdown of this information.
   * @deprecated The 'amount' field is deprecated.
   *
   * Use `amounts` instead for a breakdown of the relevant net, tax, and gross amounts.
   *
   * - Marked as deprecated on 2023-12-06.
   * - Will be removed on 2024-06-01.
   */
  amount?: Maybe<Scalars['Int']>;
  /** The net, tax and gross amounts for the transaction. Note: for payments and repayments, only the net amount is returned. */
  amounts?: Maybe<TransactionAmountType>;
  /** The customer's resulting balance after this transaction has been applied, in the smallest unit of currency. */
  balanceCarriedForward?: Maybe<Scalars['Int']>;
  /** The unique identifier for the most recent billing document linked with the transaction.Note: a transaction may be linked with multiple documents, but this field will only return the identifier for the most recent billing document. */
  billingDocumentIdentifier?: Maybe<Scalars['ID']>;
  /** The date time when the transaction is created. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Returns True if the transaction is linked with a statement. */
  hasStatement?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  /**
   * Deprecated.
   * @deprecated The 'isAccountCharge' field is deprecated.
   *
   * This information is provided by the __typename introspection query.
   *
   * - Marked as deprecated on 2020-06-19.
   * - Will be removed on 2022-11-15.
   */
  isAccountCharge?: Maybe<Scalars['Boolean']>;
  /**
   * Deprecated.
   * @deprecated The 'isAccountPayment' field is deprecated.
   *
   * This information is provided by the __typename introspection query.
   *
   * - Marked as deprecated on 2020-06-19.
   * - Will be removed on 2022-11-15.
   */
  isAccountPayment?: Maybe<Scalars['Boolean']>;
  /**
   * Deprecated.
   * @deprecated The 'isCredit' field is deprecated.
   *
   * This information is provided by the __typename introspection query.
   *
   * - Marked as deprecated on 2020-06-19.
   * - Will be removed on 2022-11-15.
   */
  isCredit?: Maybe<Scalars['Boolean']>;
  /** Whether the statement this transaction is on has been held. A held statement is not sent to a customer automatically, but is instead marked for manual attention by operations staff.  Returns False if a statement is not linked with the transaction. */
  isHeld?: Maybe<Scalars['Boolean']>;
  /** Whether this transaction has been issued on any billing document.Note: Look for the most recently issued transaction instead of looking through all transactions as some accounts may have initial transactions that were not issued.This will return False if the transaction is not associated with any billing documents. */
  isIssued?: Maybe<Scalars['Boolean']>;
  isReversed: Scalars['Boolean'];
  /** Returns the note field value for the transaction, which contains additional info. */
  note?: Maybe<Scalars['String']>;
  postedDate?: Maybe<Scalars['Date']>;
  /**
   * Returns None if a statement is not linked with the transaction.
   * @deprecated The 'statementId' field is deprecated.
   *
   * Use `billingDocumentIdentifier` instead.
   *
   * - Marked as deprecated on 2023-11-30.
   * - Will be removed on 2024-06-01.
   */
  statementId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

/** Transaction types which will be included or excluded, depending on the input argument. */
export enum TransactionTypeFilter {
  /** For filtering/excluding energy charge transactions: Gas or Electricity. */
  EnergyCharges = 'ENERGY_CHARGES',
  /** For filtering/excluding imported charge transactions. */
  ImportedCharges = 'IMPORTED_CHARGES',
  /** For filtering/excluding imported credit transactions. */
  ImportedCredits = 'IMPORTED_CREDITS',
  /** For filtering/excluding imported payment transactions. */
  ImportedPayments = 'IMPORTED_PAYMENTS',
  /** For filtering/excluding imported repayment transactions. */
  ImportedRepayments = 'IMPORTED_REPAYMENTS',
  /** For filtering/excluding issued transactions. */
  IssuedTransactions = 'ISSUED_TRANSACTIONS',
}

export enum TransactionsOrderBy {
  PostedDateAsc = 'POSTED_DATE_ASC',
  PostedDateDesc = 'POSTED_DATE_DESC',
}

/**
 * Trigger balance transfer between accounts.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-3822: Unauthorized.
 * - KT-CT-3823: Unauthorized.
 * - KT-CT-9701: Balance transfer to same account is not allowed.
 * - KT-CT-9702: Balance transfer is not support for debit account with Zero balance.
 * - KT-CT-9703: Balance transfer is not supported for debit account.
 * - KT-CT-9704: Balance transfer amount should be non-zero.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type TransferLedgerBalance = {
  __typename?: 'TransferLedgerBalance';
  /** Balance transfer details. */
  balanceTransfer?: Maybe<AccountBalanceTransferType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type TransferLedgerBalanceInputType = {
  /** The amount ( in lowest unit ) to transfer. If the amount is negative,the effect is reversed  (the source ledger's balance increases and the destination ledger's balance decreases). */
  amount: Scalars['Int'];
  /** Optional short note about transfer reason. */
  note?: InputMaybe<Scalars['String']>;
  /** Account's ledger from which the requested amount is debited. */
  sourceAccountLedger: AccountLedgerInput;
  /** Account's ledger to which the requested amount is credited. */
  targetAccountLedger: AccountLedgerInput;
};

/**
 * Transfer Loyalty Points between users.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-9205: Insufficient Loyalty Points.
 * - KT-CT-9204: Negative or zero points set.
 * - KT-CT-9208: Invalid posted at datetime.
 * - KT-CT-9209: Negative Loyalty Points balance.
 * - KT-CT-9210: Unhandled Loyalty Points exception.
 * - KT-CT-1113: Disabled GraphQL field requested.
 *
 */
export type TransferLoyaltyPointsBetweenUsers = {
  __typename?: 'TransferLoyaltyPointsBetweenUsers';
  /** The number of OctoPoints that were transferred. */
  pointsTransferred?: Maybe<Scalars['Int']>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** The input type for transferring Loyalty Points. */
export type TransferLoyaltyPointsBetweenUsersInput = {
  /** The account number. */
  accountNumber: Scalars['String'];
  /** The number of Loyalty Points to transfer. */
  points: Scalars['Int'];
  /** The account user receiving the points. */
  receivingUserId: Scalars['String'];
};

export type TriggerType = {
  __typename?: 'TriggerType';
  /** The ID of the trigger. */
  id: Scalars['String'];
};

/**
 *
 * Provide a typed source to filter measurements.
 * If a typed_source and a raw source is given, preference is given to the raw source.
 * To get better results, make sure none of the input fields are empty.
 *
 */
export type TypedSourceInputType = {
  readingDirection?: InputMaybe<ReadingDirectionType>;
  readingFrequencyType?: InputMaybe<ReadingFrequencyType>;
  readingQuality?: InputMaybe<ReadingQualityType>;
  sourceIdentifier?: InputMaybe<Scalars['String']>;
  utility?: InputMaybe<UtilityType>;
};

/**
 * Note this is deprecated and will be removed in the future.
 * Please use the `UtilityFiltersOutput` type instead.
 */
export type TypedSourceOutput = {
  __typename?: 'TypedSourceOutput';
  readingDirection?: Maybe<ReadingDirectionType>;
  readingFrequencyType?: Maybe<ReadingFrequencyType>;
  readingQuality?: Maybe<ReadingQualityType>;
  sourceIdentifier?: Maybe<Scalars['String']>;
  utility?: Maybe<UtilityType>;
};

/** An enumeration. */
export enum Unit {
  KilowattHours = 'KILOWATT_HOURS',
  MetersCubed = 'METERS_CUBED',
}

export type UnlinkUserFromLineResponse =
  | LineCommonError
  | LineUnlinkedResponse
  | LinkTokenNotFound;

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-7804: No fields present in the input for updating the APIException.
 * - KT-CT-7803: Received an invalid apiExceptionId.
 * - KT-CT-7809: Update results in no changes to API Exception.
 * - KT-CT-7805: Too many tags associated with this API Exception.
 * - KT-CT-7806: Cannot create duplicate tags for the same API exception.
 * - KT-CT-7801: Received an invalid operationsTeamId.
 * - KT-CT-7811: Received an invalid assignedUserId.
 * - KT-CT-7812: Support user is inactive.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type UpdateApiException = {
  __typename?: 'UpdateAPIException';
  /** The updated APIException. */
  apiException?: Maybe<ApiExceptionType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type UpdateApiExceptionInput = {
  /** The ID of the user assigned to handle this exception.If no user is provided, no user will be assigned to the exception. */
  assignedUserId?: InputMaybe<Scalars['Int']>;
  /** The new category. If none is provided, the field won't be updated. */
  category?: InputMaybe<ApiExceptionCategories>;
  /** The new context. If none is provided, the field won't be updated. This will completely replace the existing context by the new one. */
  context?: InputMaybe<Scalars['JSONString']>;
  /** The ID of the API Exception that will be updated. */
  id: Scalars['Int'];
  /** The new key date. If none is provided, the field won't be updated. */
  keyDate?: InputMaybe<Scalars['Date']>;
  /** The ID of an operations team to handle this exception. If no team is provided, no team will be assigned to the exception. */
  operationsTeamId?: InputMaybe<Scalars['Int']>;
  /** The new priority. If none is provided, the field won't be updated. */
  priority?: InputMaybe<ApiExceptionPriority>;
  /** The new resolution status. If none is provided, the field won't be updated. */
  resolutionStatus?: InputMaybe<ApiExceptionResolutionStatus>;
  /** The new resolution type. If none is provided, the field won't be updated. */
  resolutionType?: InputMaybe<ApiExceptionResolutionType>;
  /** The updated list of tags. If none is provided, the field won't be updated. */
  tags?: InputMaybe<Array<InputMaybe<ApiExceptionTags>>>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-7807: Received an invalid apiExceptionNoteId.
 * - KT-CT-7808: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type UpdateApiExceptionNote = {
  __typename?: 'UpdateAPIExceptionNote';
  /** The updates APIExceptionNote. */
  apiException?: Maybe<ApiExceptionType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type UpdateApiExceptionNoteInput = {
  /** The ID of the API Exception note being updated. */
  apiExceptionNoteId: Scalars['ID'];
  /** The body of the note. */
  body: Scalars['String'];
};

/**
 * Update address for given account and optionally for the accounts in the same portfolio.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4178: No account found with given account number.
 * - KT-CT-4411: Address in db is structured, cannot accept unstructured address fields.
 * - KT-CT-1113: Disabled GraphQL field requested.
 *
 */
export type UpdateAccountAddress = {
  __typename?: 'UpdateAccountAddress';
  /** The updated account. */
  account?: Maybe<AccountType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /**
   *  The currently authenticated user.
   *
   *
   *
   * This field requires the `Authorization` header to be set.
   *
   *
   */
  viewer?: Maybe<AccountUserType>;
};

export type UpdateAccountAddressInput = {
  /** Unique account_number. */
  accountNumber: Scalars['String'];
  /** New billing address for account. */
  address: AddressInput;
  /** Option to apply to all accounts in portfolio. */
  applyToPortfolio?: InputMaybe<Scalars['Boolean']>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-4145: Invalid address.
 * - KT-CT-7123: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type UpdateAccountBillingAddress = {
  __typename?: 'UpdateAccountBillingAddress';
  /** The updated account. */
  account?: Maybe<AccountType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * Update the billing email for the input account number to the
 * received email value.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4123: Unauthorized.
 * - KT-CT-4122: Invalid email.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type UpdateAccountBillingEmail = {
  __typename?: 'UpdateAccountBillingEmail';
  /** Account that was changed. */
  account?: Maybe<AccountInterface>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** Input fields for updating billing email for an account. */
export type UpdateAccountBillingEmailInput = {
  /** Account number for account. */
  accountNumber: Scalars['String'];
  /** The billing_email which can be up to 512 characters. Use null to unset billing_email. */
  billingEmail?: InputMaybe<Scalars['String']>;
};

/**
 * Update a reference for a particular account and namespace.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4123: Unauthorized.
 * - KT-CT-8310: Invalid data.
 * - KT-CT-8311: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type UpdateAccountReference = {
  __typename?: 'UpdateAccountReference';
  accountReference?: Maybe<AccountReferenceType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** Input fields for updating an account user's comms prefereces. */
export type UpdateAccountUserCommsPreferencesInput = {
  /** Whether user has opted to receive company surveys. */
  isOptedInToCompanySurvey?: InputMaybe<Scalars['Boolean']>;
  /** Whether user has opted to receive generic marketing. */
  isOptedInToMarketing?: InputMaybe<Scalars['Boolean']>;
  /** Whether the user has opted to receive messages offering plumbing discounts or other services (home serve) not directly related to the services the client provides. */
  isOptedInToPlumbingOffer?: InputMaybe<Scalars['Boolean']>;
  /** Whether user has opted to receive regulatory surveys. */
  isOptedInToRegulatorySurvey?: InputMaybe<Scalars['Boolean']>;
  /** Whether the user has opted to receive SMS messages. */
  isOptedInToSms?: InputMaybe<Scalars['Boolean']>;
  /** Whether the user has opted to receive WhatsApp messages. */
  isOptedInToWhatsapp?: InputMaybe<Scalars['Boolean']>;
};

/**
 * Update the comms preferences of the account user (the authenticated user)."
 *
 * The possible errors that can be raised are:
 *
 * - KT-GB-5414: The given preferences are not valid.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type UpdateAccountUserCommsPreferencesMutation = {
  __typename?: 'UpdateAccountUserCommsPreferencesMutation';
  /** Details of comms preferences set up for a user. */
  commsPreferences?: Maybe<AccountUserCommsPreferencesType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type UpdateAccountUserMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  email?: InputMaybe<Scalars['String']>;
  familyName?: InputMaybe<Scalars['String']>;
  givenName?: InputMaybe<Scalars['String']>;
  landline?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  pronouns?: InputMaybe<Scalars['String']>;
};

export type UpdateAccountUserMutationPayload = {
  __typename?: 'UpdateAccountUserMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  email?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  landline?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  pronouns?: Maybe<Scalars['String']>;
};

/**
 * Update an affiliate link of an existing sales agent.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-7711: Invalid data.
 * - KT-CT-7713: Invalid data.
 * - KT-CT-7714: Invalid data.
 * - KT-CT-7715: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type UpdateAffiliateLink = {
  __typename?: 'UpdateAffiliateLink';
  affiliateLink?: Maybe<AffiliateLinkType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type UpdateAffiliateLinkInputType = {
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  isBusiness?: InputMaybe<Scalars['Boolean']>;
  landingUrl?: InputMaybe<Scalars['String']>;
  /** The id of the affiliate link that is going to be edited. */
  linkId: Scalars['ID'];
  /** The organisation for whom to update the affiliate link for. */
  organisationId?: InputMaybe<Scalars['ID']>;
  /**
   *
   * Will be validated as follows:
   *
   * - should be at least two characters
   * - should only contain (letters, numbers, and Hyphen)
   * - should not contain bad words
   * - should not contain any of the reserved words including:
   *  affiliates, api, business, click, consul, developer, friends, kraken, mail, sendgrid, tech, webhooks, www, www2
   */
  subdomain?: InputMaybe<Scalars['String']>;
  trainingStatus?: InputMaybe<Scalars['String']>;
};

/**
 * Update an affiliate organisation.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-7717: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type UpdateAffiliateOrganisation = {
  __typename?: 'UpdateAffiliateOrganisation';
  affiliateOrganisation?: Maybe<AffiliateOrganisationType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type UpdateAffiliateOrganisationInputType = {
  /** Is this partner allowed to specify payment methods other than Direct Debit in the import csv or API */
  allowAlternativePaymentMethods?: InputMaybe<Scalars['Boolean']>;
  /** Are meter point registrations limited for profile classes 1 and 2 for registrations from csv or API */
  canRegisterBusinessMeterPoints?: InputMaybe<Scalars['Boolean']>;
  /** Allow registration requests with customers without an email address. */
  canRegisterCustomersWithoutEmailAddress?: InputMaybe<Scalars['Boolean']>;
  /** Allow registration requests with exiting account user emails to add to the portfolio belonging to the account user. */
  canRegisterPortfolioAccounts?: InputMaybe<Scalars['Boolean']>;
  /** Allow performing tariff renewals via API. */
  canRenewTariffs?: InputMaybe<Scalars['Boolean']>;
  /** Allow this partner access to the IVR support API (modify their own IVR handling through third party 'IVR Flow Editor') */
  canUseIvrSupportApi?: InputMaybe<Scalars['Boolean']>;
  /** Default Account Type */
  defaultAccountType?: InputMaybe<AccountTypeChoices>;
  /** Restrict to field-sales-only products? This is only allowed for the 'field-sales' and 'events' sales channels */
  isFieldSalesOnlyProduct?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  /** The organisation that is going to be edited. */
  organisationId: Scalars['ID'];
  /** Sales Channel */
  salesChannel?: InputMaybe<SalesChannelChoices>;
  /** Allow this partner to skip validation that ensures all meter points belong to the same address */
  skipMeterPointAddressValidation?: InputMaybe<Scalars['Boolean']>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-3815: No active payment schedule found for this account.
 * - KT-CT-3941: Invalid data.
 * - KT-CT-3942: An unexpected error occurred.
 * - KT-CT-3947: An unexpected error occurred.
 * - KT-CT-3953: The payment schedule is not a balance triggered schedule.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type UpdateAutoTopUpAmount = {
  __typename?: 'UpdateAutoTopUpAmount';
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** The new schedule created. */
  schedule?: Maybe<PaymentScheduleType>;
};

/** Input type for updating the schedule auto top up amount for for an account. Requires an `account_number`, ledger_id and `payment_amount` to be provided. */
export type UpdateAutoTopUpAmountInput = {
  /** Account number to update the schedule auto top up amount for. */
  accountNumber: Scalars['String'];
  /** Specifies the ledger ID associated with the current schedule for updates. */
  ledgerId: Scalars['Int'];
  /** The new auto-top-up amount for the payment schedule. */
  paymentAmount: Scalars['Int'];
};

/**
 * Update the email address of an account user that currently has a blackhole email. And then send a create password email to the new email address.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1144: Unauthorized.
 * - KT-GB-5412: User already has an email address.
 * - KT-GB-5413: Invalid inputs for updating a blackhole email.
 * - KT-CT-1133: Unable to request password reset email.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type UpdateBlackholeEmail = {
  __typename?: 'UpdateBlackholeEmail';
  /** Details of an account of an account user updating their blackhole email. */
  blackholeEmailAccount?: Maybe<BlackholeEmailAccountType>;
  /** Details of an account user who has updated their blackhole email. */
  blackholeEmailAccountUser?: Maybe<BlackholeEmailAccountUserType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** Input fields for assigning email address to an account user.", */
export type UpdateBlackholeEmailInput = {
  /** The account number. */
  accountNumber: Scalars['String'];
  /** The date of birth of viewer. */
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  /** The email address. */
  email: Scalars['String'];
  /** The mobile number of viewer. */
  mobileNumber?: InputMaybe<Scalars['String']>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-4123: Unauthorized.
 * - KT-CT-4136: Cannot set comms preference to email when account has no email.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type UpdateCommsDeliveryPreference = {
  __typename?: 'UpdateCommsDeliveryPreference';
  account?: Maybe<AccountInterface>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** Result acknowledgement for viewer denied access to account. */
  success?: Maybe<Scalars['Boolean']>;
};

/** Input fields for updating comms delivery preferences for an account */
export type UpdateCommsDeliveryPreferenceInput = {
  accountNumber: Scalars['String'];
  commsDeliveryPreference: CommsDeliveryPreference;
};

/**
 * Update the email address and password of an account user.
 * This is for existing account users to establish access to the consumersite.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-5413: Invalid data.
 * - KT-CT-5414: Invalid data.
 * - KT-CT-5450: Password is invalid.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type UpdateEmailAndPassword = {
  __typename?: 'UpdateEmailAndPassword';
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** Whether the sign up was successful. */
  success?: Maybe<Scalars['Boolean']>;
};

/** Input fields for an account user to establish access to the consumersite. */
export type UpdateEmailAndPasswordInput = {
  /** The email address. */
  email: Scalars['String'];
  /** The password. */
  password: Scalars['String'];
};

export type UpdateMessageTagsInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The message to set the tags on. */
  messageRelayId: Scalars['ID'];
  /** The tag names to set on the message. */
  tagNames: Array<Scalars['String']>;
  /** The tag code to set on the message. */
  taggerCode: Scalars['String'];
  /** The tag version to set on the message. */
  taggerVersion: Scalars['String'];
};

export type UpdateMessageTagsPayload = {
  __typename?: 'UpdateMessageTagsPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  /** Confirmed tags. */
  tags: Array<InkTag>;
};

/**
 * Update existing metadata on an object.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-8413: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type UpdateMetadata = {
  __typename?: 'UpdateMetadata';
  metadata?: Maybe<Metadata>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** Update user's password. */
export type UpdatePassword = {
  __typename?: 'UpdatePassword';
  /**
   *  The currently authenticated user.
   *
   *
   *
   * This field requires the `Authorization` header to be set.
   *
   *
   */
  viewer?: Maybe<AccountUserType>;
};

export type UpdatePasswordInput = {
  /** New password */
  newPassword: Scalars['String'];
  /** Confirm new password */
  newPasswordConfirmed: Scalars['String'];
  /** Old password */
  oldPassword: Scalars['String'];
};

export type UpdateUserInput = {
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  /** User details. */
  details?: InputMaybe<Array<InputMaybe<DetailsInputType>>>;
  email?: InputMaybe<Scalars['String']>;
  familyName?: InputMaybe<Scalars['String']>;
  givenName?: InputMaybe<Scalars['String']>;
  /** Because this field is clearable, null and the empty string are treated differently; passing null or omitting the field leaves the value as-is, but explicitly passing an empty string clears this value. */
  landline?: InputMaybe<Scalars['String']>;
  /** Because this field is clearable, null and the empty string are treated differently; passing null or omitting the field leaves the value as-is, but explicitly passing an empty string clears this value. */
  mobile?: InputMaybe<Scalars['String']>;
  /** How the user would like us to address them (e.g. 'she/her', 'they/them'). Because this field is clearable, null and the empty string are treated differently; passing null or omitting the field leaves the value as-is, but explicitly passing an empty string clears this value. */
  pronouns?: InputMaybe<Scalars['String']>;
  /** The user's title. */
  title?: InputMaybe<Scalars['String']>;
  /** The user for whom to perform the update. This is only needed when using an Organisation role */
  userId?: InputMaybe<Scalars['String']>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-5413: Invalid data.
 * - KT-CT-5414: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type UpdateUserMutation = {
  __typename?: 'UpdateUserMutation';
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  viewer?: Maybe<AccountUserType>;
};

/**
 *
 * Filter measurements by the given utility parameters.
 *
 */
export type UtilityFiltersInput = {
  electricityFilters?: InputMaybe<ElectricityFiltersInput>;
};

export type UtilityFiltersOutput =
  | ElectricityFiltersOutput
  | EmbeddedElectricityFiltersOutput
  | GasFiltersOutput;

export enum UtilityType {
  Electricity = 'ELECTRICITY',
  EmbeddedElectricity = 'EMBEDDED_ELECTRICITY',
  Gas = 'GAS',
}

/** Validate whether a user's email is a valid email via the Kickbox API. */
export type ValidateEmail = {
  __typename?: 'ValidateEmail';
  /** Whether the email is valid or not. */
  isValid?: Maybe<Scalars['Boolean']>;
};

/** Input required to validate email address via Kickbox */
export type ValidateEmailInput = {
  /** Check if an email is already in use. */
  checkUniqueness?: InputMaybe<Scalars['Boolean']>;
  /** The user's email address. */
  email: Scalars['String'];
};

/** Validate whether a user's phone number is a valid phone number. */
export type ValidatePhone = {
  __typename?: 'ValidatePhone';
  /** Whether the phone number is valid or not. */
  isValid?: Maybe<Scalars['Boolean']>;
};

export type ValidatePhoneNumberInput = {
  /** The user's phone number. */
  phoneNumber: Scalars['String'];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type VerifyEmail = {
  __typename?: 'VerifyEmail';
  /** Whether the email is verified. */
  isVerified?: Maybe<Scalars['Boolean']>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type VerifyEmailInput = {
  /** Token string that will be used to verify the email. */
  token: Scalars['String'];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-1145: Account/user details do not match.
 * - KT-CT-1113: Disabled GraphQL field requested.
 *
 */
export type VerifyIdentity = {
  __typename?: 'VerifyIdentity';
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** An expiring token that can be used to request to update the user's email address. */
  token: Scalars['String'];
};

export type VerifyIdentityInput = {
  /** The number of the account belonging to the user (e.g. A-12345678). */
  accountNumber: Scalars['String'];
  /** The user's family name. */
  familyName: Scalars['String'];
  /** The user's given name. */
  givenName?: InputMaybe<Scalars['String']>;
  /** The user's postcode (this could be the postcode of the supplied property address or of the billing address on the account). */
  postcode: Scalars['String'];
};

/** An enumeration. */
export enum WasteWaterSupplyPointPropertyType {
  /** Detached */
  Detached = 'DETACHED',
  /** Flat */
  Flat = 'FLAT',
  /** Semi-detached */
  SemiDetached = 'SEMI_DETACHED',
  /** Terraced */
  Terraced = 'TERRACED',
}

export type WasteWaterSupplyPointType = {
  __typename?: 'WasteWaterSupplyPointType';
  /** A client-defined classifier to segment supply points for billing purposes. */
  areaCode: Scalars['String'];
  id: Scalars['ID'];
  /** Inset for the supply point. */
  inset?: Maybe<InsetType>;
  /** All active linked services for the supply point. */
  linkedServices?: Maybe<Array<LinkedServiceType>>;
  /** Size of the physical pipe of the supply point. */
  pipeSize?: Maybe<Scalars['Int']>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** Type of property that the supply point is on */
  propertyType?: Maybe<WasteWaterSupplyPointPropertyType>;
  /** An assessment of the annual rental value of a property between 1967 and 1990. Required for the billing of unmetered properties. */
  rateableValue?: Maybe<Scalars['Int']>;
  /** A unique identifier for every commercial fresh and waste water supply point in the UK. */
  spid?: Maybe<Scalars['String']>;
  wholesalerCode: WasteWaterSupplyPointWholesalerCode;
};

/** An enumeration. */
export enum WasteWaterSupplyPointWholesalerCode {
  /** Affinity Water */
  Affinity = 'AFFINITY',
  /** Albion Water */
  Albion = 'ALBION',
  /** Anglian Water */
  Anglian = 'ANGLIAN',
  /** Bournemouth Water */
  Bournemouth = 'BOURNEMOUTH',
  /** Bristol Water */
  Bristol = 'BRISTOL',
  /** Dwr Cymru Welsh Water */
  DwrCymruWelsh = 'DWR_CYMRU_WELSH',
  /** Essex & Suffolk Water */
  EssexAndSuffolk = 'ESSEX_AND_SUFFOLK',
  /** Icosa Water */
  Icosa = 'ICOSA',
  /** Portsmouth Water */
  Portsmouth = 'PORTSMOUTH',
  /** Sutton and East Surrey Water (SES) */
  Ses = 'SES',
  /** Severn Trent Water */
  SevernTrent = 'SEVERN_TRENT',
  /** Southern Water */
  Southern = 'SOUTHERN',
  /** South East Water */
  SouthEast = 'SOUTH_EAST',
  /** South Staffordshire Water */
  SouthStaffordshire = 'SOUTH_STAFFORDSHIRE',
  /** Thames Water */
  Thames = 'THAMES',
  /** United Utilities */
  UnitedUtilities = 'UNITED_UTILITIES',
  /** Wessex Water */
  Wessex = 'WESSEX',
  /** Yorkshire Water */
  Yorkshire = 'YORKSHIRE',
}

/** An enumeration. */
export enum WaterMeterCategory {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Normal */
  Normal = 'NORMAL',
}

export type WaterMeterType = {
  __typename?: 'WaterMeterType';
  category: WaterMeterCategory;
  id: Scalars['ID'];
  /** All active linked services for the meter. */
  linkedServices?: Maybe<Array<LinkedServiceType>>;
  make: Scalars['String'];
  model: Scalars['String'];
  /** Number of digits / dials that the meter has or can show. */
  numberOfDigits: Scalars['Int'];
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** All readings for the meter, including held and quarantined readings. */
  readings?: Maybe<ReadingConnectionTypeConnection>;
  serialNumber: Scalars['String'];
  /** Water supply points to which the meter is linked to. */
  supplyPoints?: Maybe<Array<SupplyPointNode>>;
};

export type WaterMeterTypeReadingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  excludeHeld?: InputMaybe<Scalars['Boolean']>;
  excludeQuarantined?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type WhatsAppTextMessage = {
  __typename?: 'WhatsAppTextMessage';
  /** Whatsapp text message body. */
  body: Scalars['String'];
};

/**
 * Default input required to initiate a LeaveSupplier journey for a given market.
 * This type should only be used when there are no configured markets while building
 * the LeaveSupplierMarketInputType.
 */
export type _DefaultMarketTerminationInput = {
  /** The market supply point identification number. */
  supplyPointIdentifier: Scalars['String'];
};
