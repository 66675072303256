import styled, { css } from 'styled-components';

export const StyledMoveOutCard = styled.div`
  ${({ theme }) => css`
    border: ${theme.overrides.card.root.borderWidth} solid
      ${theme.color.primary.main};
    border-radius: ${theme.spacing.xsSm};

    h2 {
      line-height: ${theme.spacing.mdLg};
    }

    a {
      border: none;
    }
  `}
`;

export const StyledSubmitReadingText = styled.span`
  text-wrap: nowrap;
`;
