import { useState } from 'react';
import { Typography } from '@krakentech/coral';

import BoxWithGreyBorder from '@/components/BoxWithGreyBorder';
import TryAgainError from '@/components/TryAgainError';

const FixedScheduleError = ({ refetch }: { refetch: () => void }) => {
  const [hasRetriedProposedScheduleQuery, setHasRetriedProposedScheduleQuery] =
    useState(false);

  return (
    <BoxWithGreyBorder darkBackground>
      <TryAgainError
        allowUserToRetryQuery={!hasRetriedProposedScheduleQuery}
        retryQuery={() => {
          refetch();
          setHasRetriedProposedScheduleQuery(true);
        }}
      >
        <Typography textAlign="center">
          Uh oh, looks like we can&apos;t display your recommended payment
          amount. Please try again or come back later
        </Typography>
      </TryAgainError>
    </BoxWithGreyBorder>
  );
};

export default FixedScheduleError;
