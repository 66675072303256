import { Stack } from '@krakentech/coral';
import { FormikTextField } from '@krakentech/coral-formik';

import MutedText from '@/components/MutedText';

import { FormTextFieldWithDescriptionProps } from './index.types';

const FormTextFieldWithDescription = ({
  fieldLabel,
  fieldName,
  fieldId,
  description,
  onChange,
}: FormTextFieldWithDescriptionProps) => {
  return (
    <Stack direction="vertical" gap="xs">
      <FormikTextField
        id={fieldId ? fieldId : fieldName}
        label={fieldLabel}
        name={fieldName}
        onChange={onChange}
      />
      <MutedText variant="caption">{description}</MutedText>
    </Stack>
  );
};

export default FormTextFieldWithDescription;
