import { Link as CoralLink } from '@krakentech/coral';

import { useFeatureFlag } from '@/hooks/utils/useFeatureFlags';
import { FeatureNames } from '@/types/features';

import ComingSoonBanner from '../../ComingSoonBanner';

const PaymentFeaturesComingSoonBanner = () => {
  const showOnlinePayment = useFeatureFlag(FeatureNames.OnlinePayment);
  const showDirectDebit = useFeatureFlag(FeatureNames.DirectDebit);

  const getUpcomingPaymentOptionCopy = () => {
    if (!showOnlinePayment && !showDirectDebit) {
      return 'via card and Direct Debit';
    } else if (!showOnlinePayment && showDirectDebit) {
      return 'via card';
    } else {
      return 'via Direct Debit';
    }
  };

  return (
    <>
      {/* Show the coming soon banner if either or both of the payments feature flags (online payments and Direct Debit) are switched off */}
      {(!showOnlinePayment || !showDirectDebit) && (
        <ComingSoonBanner>
          Option to pay {getUpcomingPaymentOptionCopy()} is coming soon.{' '}
          {showOnlinePayment && (
            <>
              If you would like to make a payment, please{' '}
              <CoralLink
                href={process.env.NEXT_PUBLIC_HELP_LINK}
                target="_blank"
                color="dark"
              >
                contact us
              </CoralLink>
            </>
          )}
        </ComingSoonBanner>
      )}
    </>
  );
};

export default PaymentFeaturesComingSoonBanner;
