import { FC, ReactNode } from 'react';
import { Container } from '@krakentech/coral';

const FormContainer: FC<{ children: ReactNode }> = ({ children }) => (
  <Container
    maxWidth="full"
    marginX="auto"
    md={{ maxWidth: 'md', marginX: 'auto' }}
  >
    {children}
  </Container>
);

export default FormContainer;
