import { ReactNode } from 'react';
import { Loader, Stack } from '@krakentech/coral';

import { StyledLoadingLabelContainer } from './index.styled';

const ButtonLoadingLabel = ({ children }: { children: ReactNode }) => (
  <StyledLoadingLabelContainer>
    <Stack alignItems="center" gap="sm">
      {children} <Loader size={18} />
    </Stack>
  </StyledLoadingLabelContainer>
);

export default ButtonLoadingLabel;
