import styled, { css } from 'styled-components';

export const StyledMobileTabContainer = styled.div`
  ${({ theme }) => css`
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 5rem;
    z-index: 1;
    background: ${theme.color.base.dark};

    @media (min-width: ${theme.breakpoints.xs}px) {
      height: 8rem;
    }

    @media (min-width: ${theme.breakpoints.md}px) {
      display: none;
    }
  `}
`;
