import { ReactNode } from 'react';
import { Typography } from '@krakentech/coral';

import Alert from '../Alert';

const ComingSoonBanner = ({ children }: { children: ReactNode }) => {
  return (
    <Alert severity="info">
      <Typography>
        <strong>Coming soon: </strong>
        {children}
      </Typography>
    </Alert>
  );
};

export default ComingSoonBanner;
