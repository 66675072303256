import { useEffect } from 'react';
import { Button, Container, Stack } from '@krakentech/coral';

import Error from '../Error';
import FormContainer from '../forms/FormContainer';

const PageError = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Container
      component="section"
      marginBottom="lg"
      marginX="auto"
      maxWidth="lg"
      padding="md"
      paddingY="xxl"
    >
      <FormContainer>
        <Stack justifyContent="center">
          <Error
            errorTitle="Uh oh.."
            subtitle="Don't worry, it's us not you"
            explanation="It looks like something is broken on our end, but this should be sorted soon. If you've seen this a few times please let us know by getting in contact."
          >
            <Button
              variant="text"
              color="secondary"
              href={process.env.NEXT_PUBLIC_HELP_LINK}
            >
              Contact us
            </Button>
          </Error>
        </Stack>
      </FormContainer>
    </Container>
  );
};

export default PageError;
