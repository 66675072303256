import { useState, useCallback, useEffect } from 'react';

import { useAuth } from '@/components/Providers/Auth';
import { useViewerId } from '@/hooks/accounts/useViewerId';
import { useFeatureFlag } from '@/hooks/utils/useFeatureFlags';
import { initAdobeDigitalData } from '@/lib/adobe';
import { setUserIdForGoogle } from '@/lib/gtag';
import { FeatureNames } from '@/types/features';

export const useAnalytics = () => {
  const [adobeDataLayerReady, setAdobeDataLayerReady] = useState(false);
  const { isAuthenticated } = useAuth();
  const adobeAnalyticsEnabled = useFeatureFlag(FeatureNames.AdobeAnalytics);
  const googleAnalyticsEnabled =
    useFeatureFlag(FeatureNames.GoogleAnalytics) &&
    process.env.NEXT_PUBLIC_GTAG_ID &&
    process.env.NEXT_PUBLIC_GA_TRACKING_ID;

  const { data: userId } = useViewerId();

  useEffect(() => {
    if (userId && googleAnalyticsEnabled) {
      setUserIdForGoogle(userId);
    }
  }, [userId, googleAnalyticsEnabled, setUserIdForGoogle]);

  const trackPageView = useCallback(() => {
    if (adobeAnalyticsEnabled) {
      setAdobeDataLayerReady(true);
    }
  }, [adobeAnalyticsEnabled]);

  useEffect(() => {
    if (typeof window !== 'undefined' && adobeAnalyticsEnabled) {
      initAdobeDigitalData({ isAuthenticated, userId });
    }
  });

  return {
    trackPageView,
    adobe: {
      enabled: adobeAnalyticsEnabled,
      ready: adobeAnalyticsEnabled && adobeDataLayerReady,
    },
    google: {
      enabled: googleAnalyticsEnabled,
    },
  };
};
