import { useMemo } from 'react';
import { Stack } from '@krakentech/coral';

import { Select } from '@/components';
import { cardinalNumberToOrdinalNumber } from '@/utils/formatters/number';

import MutedText from '../../MutedText';

const PaymentDayField = () => {
  const paymentDayOptions = useMemo(() => {
    return Array.from({ length: 28 }).map((_, i) => {
      return {
        label: cardinalNumberToOrdinalNumber(i + 1),
        value: i + 1,
      };
    });
  }, []);

  return (
    <Stack direction="vertical" gap="sm">
      <Select
        name="paymentDay"
        label="Select date"
        values={paymentDayOptions}
      />

      <MutedText>
        Payment will be taken on this date or the next working day
      </MutedText>
    </Stack>
  );
};

export default PaymentDayField;
