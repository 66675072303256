import { useEffect } from 'react';
import { setUser } from '@sentry/browser';

import { useViewerId } from '@/hooks/accounts/useViewerId';

const Sentry = () => {
  const { data: userId } = useViewerId();

  useEffect(() => {
    if (userId) {
      // Set the user ID in Sentry
      setUser({ id: userId });
    }
  }, [userId]);

  return null;
};

export default Sentry;
