import styled, { css } from 'styled-components';

export const StyledMobileTabLink = styled.a<{ active: boolean }>`
  ${({ theme, active }) => css`
    display: block;
    color: ${active ? theme.color.tertiary.main : theme.color.text.main};
    text-decoration: none;
    border-top: ${active
      ? `${theme.spacing.xxs} solid ${theme.color.tertiary.main}`
      : `${theme.spacing.xxs} solid ${theme.color.surface.light}`};

    padding-top: ${active ? '1rem' : `${theme.spacing.xsSm}`};

    &:focus {
      outline-width: ${theme.borderWidth};
      outline-style: solid;
      outline-color: ${theme.color.tertiary.main};
    }

    p {
      font-weight: ${active ? '400' : '300'};
      text-align: center;
      // TODO: check that this font size is ok on an actual defvice, Figma says to go even smaller
      font-size: 1.4rem;
    }
  `}
`;
