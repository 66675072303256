import { Grid } from '@krakentech/coral';
import { IconCreditCard } from '@krakentech/icons';

import { ActionCard } from '@/components';
import { INTERNAL_PATHS } from '@/utils/urls';

const MakeAOneOffPaymentCard = () => {
  const actionCardHref = INTERNAL_PATHS.MAKE_ONLINE_PAYMENT[0].path;

  return (
    <>
      <Grid.Item>
        <ActionCard
          href={actionCardHref}
          title="Make a one-off payment"
          titleComponent="p"
          icon={<IconCreditCard size={32} />}
        />
      </Grid.Item>
    </>
  );
};

export default MakeAOneOffPaymentCard;
