import styled, { css } from 'styled-components';

import { THEMES } from '@/consts/themes';

const hasWhiteHeader =
  process.env.NEXT_PUBLIC_THEME === THEMES.LEEP ||
  process.env.NEXT_PUBLIC_THEME === THEMES.STW;

export const StyledTabContainerOuter = styled.nav`
  ${({ theme }) => css`
    display: none;
    background-color: ${theme.color.surface.dark};

    ${hasWhiteHeader &&
    css`
      border-top: ${theme.borderWidth} solid ${theme.color.surface.light};
      border-bottom: ${theme.borderWidth} solid ${theme.color.surface.light};
    `}

    @media (min-width: ${theme.breakpoints.md}px) {
      display: block;
      position: sticky;
      top: 11rem;
      z-index: 31;
    }

    // TODO: Remove this when Coral gains the ability to remove bottom margin from the Container
    > section {
      margin-bottom: 0;
      height: 100%;
    }
  `}
`;

export const StyledTabLink = styled.a<{ active: boolean }>`
  ${({ theme, active }) => css`
    position: relative;
    color: ${active ? theme.color.text.light : theme.color.text.mid};
    text-decoration: none;
    border-bottom: ${active
      ? `${theme.spacing.xxs} solid ${theme.color.tertiary.main}`
      : `${theme.spacing.xxs} solid transparent`};
    p {
      font-weight: 300;
    }

    &:hover {
      color: ${theme.color.text.light};
    }

    &:focus-visible {
      outline-width: ${theme.borderWidth};
      outline-style: solid;
      outline-color: ${theme.color.tertiary.main};
      outline-offset: ${theme.spacing.xxs};
    }
  `}
`;
