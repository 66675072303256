
import { useKrakenQuery } from '@/components/GraphQLClient';
import { useAuth } from '@/components/Providers/Auth';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';

import OccupancyPeriods from '../useOccupancyPeriods/OccupancyPeriods.graphql';
import { occupancyPeriodSchema } from '../validations';

export const useHasNotMovedOut = () => {
  const { isAuthenticated } = useAuth();

  const { accountNumber } = useAccountNumberContext();

  return useKrakenQuery({
    queryKey: ['occupancyPeriods'],
    query: OccupancyPeriods,
    select: (data: any) => data.account?.properties.length > 0,
    validateFn(response) {
      return occupancyPeriodSchema.validate(response);
    },
    enabled: Boolean(isAuthenticated && accountNumber),
    variables: { accountNumber, activeFrom: new Date() },
    cacheTime: 0,
  });
};
