import {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
  useEffect,
  useReducer,
} from 'react';
import { useRouter } from 'next/router';
import { Loader } from '@krakentech/coral';

import { PageError } from '@/components';
import { useBalance } from '@/hooks/accounts/useBalance';
import { useHasCurrentDirectDebitInstruction } from '@/hooks/billsAndPayments/useHasCurrentDirectDebitInstruction';
import { PaymentFrequency } from '@/types/directDebit';
import { INTERNAL_PATHS } from '@/utils/urls';

import { DirectDebitContextReturnValue, OptionalDate } from './index.types';
import { paymentPlanInstalmentReducer, initialInstalment } from './reducers';

export const DirectDebitContext = createContext(
  {} as DirectDebitContextReturnValue
);

export const DirectDebitContextProvider = ({ children }: PropsWithChildren) => {
  const [paymentFrequency, setPaymentFrequency] = useState<
    PaymentFrequency | undefined
  >(undefined);
  const [paymentDay, setPaymentDay] = useState<number | undefined>(undefined);
  const [paymentAmount, setPaymentAmount] = useState<number>(0);
  const [
    firstPossibleFixedSchedulePaymentDate,
    setFirstPossibleFixedSchedulePaymentDate,
  ] = useState<OptionalDate>(undefined);
  const [
    firstPossibleVariableSchedulePaymentDate,
    setFirstPossibleVariableSchedulePaymentDate,
  ] = useState<OptionalDate>(undefined);
  const [paymentPlanFirstInstalment, setPaymentPlanFirstInstalment] =
    useReducer(paymentPlanInstalmentReducer, initialInstalment);
  const [paymentPlanLastInstalment, setPaymentPlanLastInstalment] = useReducer(
    paymentPlanInstalmentReducer,
    initialInstalment
  );
  const [hasActiveWaterMeter, setHasActiveWaterMeter] = useState(false);

  const { push, asPath } = useRouter();

  const {
    data: alreadyHasDirectDebitInstruction,
    isError: isErrorHasCurrentDirectDebitPaymentInstruction,
    isLoading: isLoadingHasCurrentDirectDebitPaymentInstruction,
  } = useHasCurrentDirectDebitInstruction();
  const {
    data: balance,
    isError: isErrorBalance,
    isLoading: isLoadingBalance,
  } = useBalance();

  // Prevent a user who already has a DDI (including a failed one, as these cause the mutation to break), or whose balance is too low from setting up a new one here. Each scenario leads to a different place. If they already have a DDI and they have too much debt, then we prioritise the redirect to the manage payments page.
  useEffect(() => {
    if (
      alreadyHasDirectDebitInstruction &&
      // Allow the user to always view the change payment schedule pages
      !asPath.includes(INTERNAL_PATHS.CHANGE_PAYMENT_SCHEDULE.path)
    )
      push(INTERNAL_PATHS.MANAGE_PAYMENTS.path);
  }, [alreadyHasDirectDebitInstruction, push, balance, asPath]);

  if (isLoadingHasCurrentDirectDebitPaymentInstruction || isLoadingBalance) {
    return <Loader type="linear" variant="indeterminate" />;
  }

  if (isErrorHasCurrentDirectDebitPaymentInstruction || isErrorBalance) {
    return <PageError />;
  }

  return (
    <DirectDebitContext.Provider
      value={{
        paymentFrequency,
        setPaymentFrequency,
        paymentDay,
        setPaymentDay,
        paymentAmount,
        setPaymentAmount,
        firstPossibleFixedSchedulePaymentDate,
        setFirstPossibleFixedSchedulePaymentDate,
        firstPossibleVariableSchedulePaymentDate,
        setFirstPossibleVariableSchedulePaymentDate,
        paymentPlanFirstInstalment,
        setPaymentPlanFirstInstalment,
        paymentPlanLastInstalment,
        setPaymentPlanLastInstalment,
        hasActiveWaterMeter,
        setHasActiveWaterMeter,
      }}
    >
      {children}
    </DirectDebitContext.Provider>
  );
};

export const useDirectDebitContext = () => {
  const context = useContext(DirectDebitContext);
  if (context === undefined) {
    throw new Error(
      'useDirectDebitContext must be used within a DirectDebitContextProvider'
    );
  }
  return context;
};
