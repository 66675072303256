import { Stack } from '@krakentech/coral';

import MutedText from '@/components/MutedText';
import {
  PASSWORD_MIN_LENGTH_LONG,
  PASSWORD_MIN_LENGTH_SHORT,
} from '@/consts/passwordValidation';
import { IS_STW } from '@/utils/environment';

import { StyledUl } from './index.styled';

const PasswordRequirementsList = ({
  hasPassword = true,
}: {
  hasPassword?: boolean;
}) => {
  const passwordRequirementsList = () => {
    const standardPasswordRequirements = [
      `Must be at least ${PASSWORD_MIN_LENGTH_SHORT} characters long`,
      hasPassword && 'Cannot match your last 3 passwords',
      'Must not contain your email or common passwords (e.g. “password”, “football”)',
    ];

    const stwPasswordRequirements = [
      `Must be at least ${PASSWORD_MIN_LENGTH_LONG} characters long`,
      'Include both uppercase and lowercase letters',
      'Include at least one number and one special character (e.g. £, #, %)',
      hasPassword && 'Cannot match your last 3 passwords',
      'Must not contain your account number, email, or common passwords (e.g. “password”, “football”)',
    ];

    const requirements = IS_STW
      ? stwPasswordRequirements
      : standardPasswordRequirements;

    return (
      <StyledUl>
        {requirements.map(
          (requirement) =>
            requirement && (
              <li key={requirement}>
                <MutedText>{requirement}</MutedText>
              </li>
            )
        )}
      </StyledUl>
    );
  };

  return (
    <Stack direction="vertical" gap="sm">
      {passwordRequirementsList()}
    </Stack>
  );
};

export default PasswordRequirementsList;
