import { useKrakenQuery } from '@/components/GraphQLClient';
import { useAuth } from '@/components/Providers/Auth';

import { latestBillResponseSchema } from '../validations';

import LatestBill from './LatestBill.graphql';

export const useLatestBill = ({
  accountNumber,
}: {
  accountNumber?: string;
}) => {
  const { isAuthenticated } = useAuth();

  return useKrakenQuery({
    queryKey: ['latestBill'],
    query: LatestBill,
    select: (data: any) => data.account.bills.edges[0],
    variables: { accountNumber },
    validateFn(response) {
      return latestBillResponseSchema.validate(response);
    },
    enabled: Boolean(isAuthenticated && accountNumber),
  });
};
