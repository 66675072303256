import { useKrakenMutation } from '@/components/GraphQLClient';
import { useUserRegistrationContext } from '@/context/UserRegistrationContext';

import { authWithPreSignedKeySchema } from '../validations';

import AuthWithPreSignedKey from './AuthWithPreSignedKey.graphql';

export const useAuthWithPreSignedKey = () => {
  const { setScopedToken } = useUserRegistrationContext();

  return useKrakenMutation({
    mutation: (_) => AuthWithPreSignedKey,
    validateFn(response) {
      return authWithPreSignedKeySchema.validate(response);
    },
    onSuccess(data) {
      setScopedToken(data.obtainKrakenToken.token);
    },
  });
};
