import { useCoralTheme } from '@krakentech/coral';

const PoundSignActive = () => {
  const { color } = useCoralTheme();

  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.6 19.2C11.5875 19.2 13.4625 18.6 15 17.55C14.625 16.8 14.4 15.9375 14.4 15C14.4 14.1375 14.5875 13.275 15 12.4875L17.85 7.1625C18.0375 6.825 18.3 6.5625 18.6 6.3375C17.2875 2.6625 13.725 0 9.6 0C4.275 0 0 4.3125 0 9.6C0 14.925 4.275 19.2 9.6 19.2ZM7.2 6V7.2C7.2 7.875 6.6375 8.4 6 8.4C5.325 8.4 4.8 7.875 4.8 7.2V6C4.8 5.3625 5.325 4.8 6 4.8C6.6375 4.8 7.2 5.3625 7.2 6ZM10.8 6V7.2C10.8 7.875 10.2375 8.4 9.6 8.4C8.925 8.4 8.4 7.875 8.4 7.2V6C8.4 5.3625 8.925 4.8 9.6 4.8C10.2375 4.8 10.8 5.3625 10.8 6ZM14.4 6V7.2C14.4 7.875 13.8375 8.4 13.2 8.4C12.525 8.4 12 7.875 12 7.2V6C12 5.3625 12.525 4.8 13.2 4.8C13.8375 4.8 14.4 5.3625 14.4 6ZM18.9 7.7625L16.0875 13.05C15.75 13.65 15.6 14.325 15.6 15C15.6 17.325 17.475 19.2 19.8 19.2C22.0875 19.2 24 17.325 24 15C24 14.325 23.8125 13.65 23.475 13.05L20.6625 7.7625C20.475 7.425 20.1375 7.2 19.8 7.2C19.425 7.2 19.0875 7.425 18.9 7.7625Z"
        fill={color.tertiary.main}
      />
    </svg>
  );
};

export default PoundSignActive;
