import { createGlobalStyle, css } from 'styled-components';

export const CustomGlobalStyles = createGlobalStyle<{ theme: any }>`
    ${({ theme }) => css`
      body {
        background: ${theme.color.base.main};
      }
      fieldset label {
        color: ${theme.color.text.dark} !important;
      }
      fieldset {
        min-inline-size: inherit;
        padding: 0;
      }
      a {
        cursor: pointer;
      }

      ul {
        padding-left: ${theme.spacing.smMd};
        margin: 0;

        li {
          ${({ theme }) => css`
            padding-left: ${theme.spacing.xxs};
          `}
        }
      }

      input::-ms-reveal {
        display: none;
      }
    `}
`;
