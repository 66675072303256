import { ReactNode, createContext, useState, useContext } from 'react';

import { MeterReadingContextReturnValue } from './index.types';

export const MeterReadingContext = createContext(
  {} as MeterReadingContextReturnValue
);

export const MeterReadingContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [valueCubicMetres, setValueCubicMetres] = useState<number>(0);
  const [isHeld, setIsHeld] = useState<boolean>(false);
  const [showHeldReadingModal, setShowHeldReadingModal] =
    useState<boolean>(false);

  return (
    <MeterReadingContext.Provider
      value={{
        setShowHeldReadingModal,
        showHeldReadingModal,
        setValueCubicMetres,
        valueCubicMetres,
        setIsHeld,
        isHeld,
      }}
    >
      {children}
    </MeterReadingContext.Provider>
  );
};

export const useMeterReadingContext = () => {
  const context = useContext(MeterReadingContext);
  if (context === undefined) {
    throw new Error(
      'useMeterReadingContext must be used within a MeterReadingContextProvider'
    );
  }
  return context;
};
