
import { useKrakenQuery } from '@/components/GraphQLClient';
import { useAuth } from '@/components/Providers/Auth';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';

import { upcomingPaymentScheduleSchema } from '../validations';

import UpcomingPaymentSchedule from './UpcomingPaymentSchedule.graphql';

export const useUpcomingPaymentSchedule = ({
  activeOnDate,
}: {
  activeOnDate: Date;
}) => {
  const { isAuthenticated } = useAuth();

  const { accountNumber } = useAccountNumberContext();

  return useKrakenQuery({
    queryKey: ['upcomingPaymentSchedule'],
    query: UpcomingPaymentSchedule,
    select: (data: any) => data.account.paymentSchedules.edges[0],
    variables: { accountNumber, activeOnDate },
    validateFn(response) {
      return upcomingPaymentScheduleSchema.validate(response);
    },
    enabled: Boolean(isAuthenticated && accountNumber && activeOnDate),
    cacheTime: 0,
  });
};
