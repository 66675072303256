import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import {
  SelectOption,
  Stack,
  Card,
  Typography,
  Link,
  useCoralBreakpoints,
} from '@krakentech/coral';
import {} from '@krakentech/coral';
import { Formik, Form } from 'formik';

import {
  FormContainer,
  FormSubmitButton,
  MutedText,
  Select,
  EmailAlreadyRegisteredAlert,
  NotEnoughInfoAlert,
  IdentifyAndVerifyGenericErrorAlert,
  PageError,
  H2Skeleton,
  TextFieldSkeleton,
  Body2Skeleton,
  FullWidthButtonSkeleton,
} from '@/components';
import {
  USER_REGISTRATION_JOURNEY_KEY_OPTIONS,
  useUserRegistrationContext,
} from '@/context/UserRegistrationContext';
import { useAccountIdentification } from '@/hooks/auth/useAccountIdentification';
import { useUserRegistrationStatus } from '@/hooks/auth/useUserRegistrationStatus';
import { useVerifyIdentity } from '@/hooks/auth/useVerifyIdentity';
import {
  fullNameToObject,
  generateUserNameSelectOptions,
} from '@/utils/formatters/name';
import {
  getUserIdFromBlackholeUserObject,
  userRegistrationJourneyConfig,
} from '@/utils/identifyAndVerify';
import { INTERNAL_PATHS } from '@/utils/urls';

const UserPicker = ({
  journeyKey,
}: {
  journeyKey: USER_REGISTRATION_JOURNEY_KEY_OPTIONS;
}) => {
  const [canRefetchAccountIdentification, setCanRefetchAccountIdentification] =
    useState(false);
  const { push } = useRouter();
  const { mutate, isError: isVerifyIdentityError } = useVerifyIdentity();

  const {
    accountNumber,
    postcode,
    selectedAccountUserName,
    setSelectedAccountUserName,
    setHasMobileNumber,
    setHasDateOfBirth,
    blackholeAccountUsers,
    setSelectedUserId,
    surname,
    scopedToken,
  } = useUserRegistrationContext();

  const {
    data: accountIdentificationData,
    refetch: refetchAccountIdentification,
    isError: isAccountIdentificationError,
    isSuccess: isAccountIdentificationSuccess,
  } = useAccountIdentification({
    scopedToken,
    familyName: surname,
  });
  const {
    data: userRegistrationStatusData,
    refetch: refetchUserRegistrationStatus,
    isError: isUserRegistrationStatusError,
  } = useUserRegistrationStatus({
    scopedToken:
      userRegistrationJourneyConfig[journeyKey].blockForRegisteredEmails &&
      isAccountIdentificationSuccess
        ? scopedToken
        : '',
  });

  const shouldRedirectToFirstStep = blackholeAccountUsers.length <= 1;

  const notEnoughInfoToProceed =
    accountIdentificationData?.blackholeEmailAccountUser &&
    !accountIdentificationData.blackholeEmailAccountUser.hasMobileNumber &&
    !accountIdentificationData.blackholeEmailAccountUser.hasDateOfBirth;

  const handleUserNameChange = (selectedUserName?: SelectOption | null) => {
    if (
      !blackholeAccountUsers.length ||
      !selectedUserName?.value ||
      !blackholeAccountUsers[0].givenName
    ) {
      return;
    }

    const currentSelectedFullName = fullNameToObject(
      selectedUserName?.value as string
    );

    // Using the selected user's given name, find the user's ID. We'll ideally want to just have the dropdown values as an index and use that instead. But this is fine for a hotfix so fuck it innit.
    const userId = getUserIdFromBlackholeUserObject(
      blackholeAccountUsers,
      currentSelectedFullName
    );

    /*,
        In case the user selected is a different one to that was previously selected, we will call the VerifyIdentity mutation again to get a different scoped token for the selected user
      */
    if (
      selectedAccountUserName.givenName !== currentSelectedFullName.givenName
    ) {
      mutate({
        input: {
          accountNumber,
          postcode,
          familyName: currentSelectedFullName.familyName,
          accountUserId: userId,
        },
      });

      setSelectedAccountUserName(currentSelectedFullName);
      setSelectedUserId(userId);

      // Set this to true so the blackhole email account can be refetched
      setCanRefetchAccountIdentification(true);
    }
  };

  const handleSubmit = ({
    userName: selectedUserName,
  }: {
    userName: { value: string; label: string };
  }) => {
    setHasMobileNumber(
      accountIdentificationData?.blackholeEmailAccountUser?.hasMobileNumber
    );
    setHasDateOfBirth(
      accountIdentificationData?.blackholeEmailAccountUser?.hasDateOfBirth
    );

    const currentSelectedFullName = fullNameToObject(
      selectedUserName?.value as string
    );
    // Using the selected user's given name, find the user's ID. We'll ideally want to just have the dropdown values as an index and use that instead. But this is fine for a hotfix so fuck it innit.
    const userId = getUserIdFromBlackholeUserObject(
      blackholeAccountUsers,
      currentSelectedFullName
    );

    setSelectedAccountUserName(currentSelectedFullName);
    setSelectedUserId(userId);

    if (!isVerifyIdentityError) {
      // This redirects to the third step in the journey
      push(INTERNAL_PATHS[`${journeyKey}_SECURITY`].path);
    }
  };

  // If there's only one user, they don't need to be on this page so they've got here sneakily or in error. So redirect them to the first step. This logic also navigates the user back to the first step if they've navigated back to this page without filling in the previous page
  useEffect(() => {
    if (shouldRedirectToFirstStep) {
      push(INTERNAL_PATHS[`${journeyKey}_YOUR_ACCOUNT`].path);
    }
  }, [shouldRedirectToFirstStep, push, journeyKey]);

  // Fetch the user's details if the scoped token changes. We need to do this so we can get updated data if the user interacts with the Select. This new data will contain the hasDateOfBirth and hasMobileNumber fields, as well as the email field.
  useEffect(() => {
    if (scopedToken && canRefetchAccountIdentification) {
      refetchAccountIdentification();

      // Get the new registration status for the new user so we know whether to display the email already registered alert for them. We only do this on the register journey because doing so on the forgotten email journey would incur a security issue. We don't technically need this if statement to check for the journey key because the userRegistrationStatus would return a GraphQL error instead of a token. However, having this check avoids unnecessary network requests, as well as Sentry potentially getting angry over unhandled errors.
      if (userRegistrationJourneyConfig[journeyKey].blockForRegisteredEmails) {
        refetchUserRegistrationStatus();
      }
    }
  }, [
    scopedToken,
    refetchAccountIdentification,
    canRefetchAccountIdentification,
    refetchUserRegistrationStatus,
    journeyKey,
  ]);

  if (shouldRedirectToFirstStep) {
    return <LoadingState />;
  }

  if (isAccountIdentificationError || isUserRegistrationStatusError) {
    return <PageError />;
  }

  const defaultSelectedOption =
    selectedAccountUserName ?? blackholeAccountUsers?.[0];
  const { givenName, familyName } = defaultSelectedOption || {};
  const selectOptions = generateUserNameSelectOptions(
    blackholeAccountUsers || []
  );

  const showNotEnoughInfoAlert =
    userRegistrationJourneyConfig[journeyKey].showDetailedNotEnoughInfoAlert &&
    notEnoughInfoToProceed &&
    !accountIdentificationData?.blackholeEmailAccountUser?.email;
  const showEmailAlreadyRegisteredAlert =
    userRegistrationJourneyConfig[journeyKey].blockForRegisteredEmails &&
    !!userRegistrationStatusData?.email;
  const showGenericErrorAlert =
    (!userRegistrationJourneyConfig[journeyKey]
      .showDetailedNotEnoughInfoAlert &&
      notEnoughInfoToProceed) ||
    isVerifyIdentityError;

  return (
    <Formik
      initialValues={{
        userName:
          familyName && givenName
            ? {
                label: `${givenName} ${familyName}`,
                value: `${givenName}_${familyName}`, // TODO: do something about this because it not just be the array index and then we can get rid of the stupid find operator to get the userId?
              }
            : selectOptions[0],
      }}
      onSubmit={handleSubmit}
    >
      <Form>
        <FormContainer>
          <Stack gap="lg" direction="vertical">
            <Card>
              <Stack direction="vertical" gap="smMd">
                <Typography variant="h2">Who are you?</Typography>
                <Select
                  onChange={handleUserNameChange}
                  name="userName"
                  id="userName"
                  label="Select your name"
                  values={selectOptions}
                />
                <MutedText>
                  If you can&apos;t see your name listed,{' '}
                  <Link
                    target="_blank"
                    href={process.env.NEXT_PUBLIC_HELP_LINK}
                  >
                    get in touch
                  </Link>
                </MutedText>
              </Stack>
            </Card>

            {showGenericErrorAlert && <IdentifyAndVerifyGenericErrorAlert />}

            {showNotEnoughInfoAlert && <NotEnoughInfoAlert />}

            {showEmailAlreadyRegisteredAlert &&
              userRegistrationStatusData?.email && (
                <EmailAlreadyRegisteredAlert
                  email={userRegistrationStatusData?.email}
                />
              )}
            <FormSubmitButton
              disabled={
                notEnoughInfoToProceed ||
                showNotEnoughInfoAlert ||
                (showEmailAlreadyRegisteredAlert &&
                  !!userRegistrationStatusData?.email)
              }
            >
              Continue
            </FormSubmitButton>
          </Stack>
        </FormContainer>
      </Form>
    </Formik>
  );
};

const LoadingState = () => {
  const { isMinMd } = useCoralBreakpoints();

  return (
    <FormContainer>
      <Stack gap="lg" direction="vertical">
        <Card>
          <Stack direction="vertical" gap="smMd">
            <H2Skeleton width={isMinMd ? 300 : undefined} />
            <TextFieldSkeleton width={isMinMd ? 600 : undefined} />
            <Body2Skeleton width={isMinMd ? 200 : undefined} />
          </Stack>
        </Card>

        <FullWidthButtonSkeleton />
      </Stack>
    </FormContainer>
  );
};

export default UserPicker;
