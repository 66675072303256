import styled, { css } from 'styled-components';

export const StyledHeaderContainer = styled.div`
  ${({ theme }) => css`
    position: sticky;
    top: 0;
    z-index: 31;

    div:not(button div):not(a div) {
      background: ${theme.color.surface.main};
    }

    div {
      height: auto;
    }

    header {
      align-items: center;
      padding-left: ${theme.spacing.sm};
      padding-right: ${theme.spacing.sm};

      @media (min-width: ${theme.breakpoints.md}px) {
        padding: ${theme.spacing.sm};
        max-width: 114rem;
        margin-left: auto;
        margin-right: auto;
      }
    }
  `}
`;

export const StyledLogoLink = styled.a`
  ${({ theme }) => css`
    &:focus {
      outline-width: ${theme.borderWidth};
      outline-style: solid;
      outline-color: ${theme.color.tertiary.main};
      outline-offset: ${theme.spacing.xs};
    }
  `}
`;
