import { Container, Stack, Typography } from '@krakentech/coral';

const NoDataMessage = ({ title, body }: { title: string; body: string }) => (
  <Container
    component="section"
    marginX="auto"
    marginY="none"
    maxWidth="lg"
    padding="lg"
    theme="mid"
  >
    <Stack direction="vertical" alignItems="center" gap="xs">
      <Typography variant="h2" textAlign="center">
        {title}
      </Typography>
      <Typography variant="body2" textAlign="center">
        {body}
      </Typography>
    </Stack>
  </Container>
);

export default NoDataMessage;
