import styled, { css } from 'styled-components';

import { ANIMATED_ALERT_STATUS } from '@/consts/animatedAlertStatus';

export const StyledAlertContainer = styled.div<{
  alertStatus: ANIMATED_ALERT_STATUS;
  relativelyPositioned: boolean;
}>`
  ${({ theme, alertStatus, relativelyPositioned }) => css`
    width: 100%;
    z-index: 10;
    opacity: 1;
    transition: opacity 0.7s;

    ${alertStatus === ANIMATED_ALERT_STATUS.CLOSING &&
    css`
      opacity: 0;
    `}

    ${!relativelyPositioned &&
    css`
      position: absolute;
      left: 0;
      top: 0;
    `}

        div[role='alert'] {
      box-shadow: ${theme.overrides.card.root.boxShadow};
    }
  `}
`;
