// A component to use on all forms instead of the <Button> component directly. This component is a wrapper around the Coral Button component, which standardises some styling and adds a loading state.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@krakentech/coral';

import ButtonLoadingLabel from '@/components/ButtonLoadingLabel';

import { FormSubmitButtonProps } from './index.types';

const FormSubmitButton = ({
  loading = false,
  children,
  disabled,
  icon,
}: FormSubmitButtonProps) => (
  <Button
    fullWidth
    type="submit"
    size="medium"
    loading={loading}
    disabled={disabled || loading}
    loadingLabel={
      <ButtonLoadingLabel>
        {icon && (
          <div>
            <FontAwesomeIcon icon={icon} />
          </div>
        )}

        {children}
      </ButtonLoadingLabel>
    }
  >
    {icon && (
      <div>
        <FontAwesomeIcon icon={icon} />
      </div>
    )}
    {children}
  </Button>
);

export default FormSubmitButton;
