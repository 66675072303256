import { useKrakenInfiniteQuery } from '@/components/GraphQLClient';
import { useAuth } from '@/components/Providers/Auth';

import { billsResponseSchema } from '../validations';

import Bills from './Bills.graphql';

export const useBills = ({
  accountNumber,
  first = 20,
}: {
  accountNumber?: string;
  first?: number;
}) => {
  const { isAuthenticated } = useAuth();

  return useKrakenInfiniteQuery({
    queryKey: ['bills'],
    query: Bills,
    variables: { accountNumber, first },
    // @ts-expect-error TODO: fix type error
    async validateFn(response) {
      const validatedData = await billsResponseSchema.validate(response);
      return validatedData.account.bills;
    },
    enabled: Boolean(isAuthenticated && accountNumber),
  });
};
