import { ReactNode, createContext, useState, useContext } from 'react';

import {
  OnlinePaymentContextReturnValue,
  CustomPaymentAmount,
  Balance,
} from './index.types';

export const OnlinePaymentContext = createContext(
  {} as OnlinePaymentContextReturnValue
);

export const OnlinePaymentContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [balance, setBalance] = useState<Balance>(undefined);
  const [customPaymentAmount, setCustomPaymentAmount] =
    useState<CustomPaymentAmount>(false);

  return (
    <OnlinePaymentContext.Provider
      value={{
        paymentAmount,
        setPaymentAmount,
        balance,
        setBalance,
        customPaymentAmount,
        setCustomPaymentAmount,
      }}
    >
      {children}
    </OnlinePaymentContext.Provider>
  );
};

export const useOnlinePaymentContext = () => {
  const context = useContext(OnlinePaymentContext);
  if (context === undefined) {
    throw new Error(
      'useOnlinePaymentContext must be used within a OnlinePaymentContextProvider'
    );
  }
  return context;
};
