import { useKrakenQuery } from '@/components/GraphQLClient';
import { useAuth } from '@/components/Providers/Auth';

import { personalDetailsSuccessSchema } from '../validations';

import PersonalDetails from './PersonalDetails.graphql';

export const usePersonalDetails = () => {
  const { isAuthenticated } = useAuth();

  return useKrakenQuery({
    queryKey: ['personalDetails'],
    query: PersonalDetails,
    select: (data: any) => data,
    validateFn(response) {
      return personalDetailsSuccessSchema.validate(response);
    },
    cacheTime: 0,
    enabled: Boolean(isAuthenticated),
  });
};
