import { useRouter } from 'next/router';

import { useKrakenMutation } from '@/components/GraphQLClient';
import { MutationRequestPasswordResetArgs } from '@/types/generated/graphql/schema';
import { INTERNAL_PATHS } from '@/utils/urls';

import { requestPasswordResetSchema } from '../validations';

import { AddEmailResentQueryParamArgs } from './index.types';
import RequestPasswordReset from './RequestPasswordReset.graphql';

const addEmailResentQueryParam = ({
  signUpPagePath,
  push,
}: AddEmailResentQueryParamArgs) => {
  push({
    pathname: signUpPagePath.path,
    query: { emailResent: true },
  });
};

export const useRequestPasswordReset = ({
  redirectAfterRequestPasswordReset,
}: {
  redirectAfterRequestPasswordReset: boolean;
}) => {
  const { push, pathname } = useRouter();

  return useKrakenMutation({
    mutation: (_: MutationRequestPasswordResetArgs) => RequestPasswordReset,
    async validateFn(response) {
      return await requestPasswordResetSchema.validate(response);
    },
    onSuccess() {
      if (redirectAfterRequestPasswordReset) {
        // If we're on one of the sign up pages, we want to remain on the same page and just add a query param to the URL to show a success message. If not, redirect to the forgotten password success page
        if (pathname.includes('sign-up')) {
          addEmailResentQueryParam({
            push,

            signUpPagePath: pathname.includes('success')
              ? INTERNAL_PATHS.SIGN_UP.SUCCESS
              : INTERNAL_PATHS.SIGN_UP.CHECK_USER_DETAILS,
          });
        } else {
          push(INTERNAL_PATHS.FORGOTTEN_PASSWORD_SUCCESS.path);
        }
      }
    },
  });
};
