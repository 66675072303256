import { useKrakenQuery } from '@/components/GraphQLClient';

import { accountIdentificationSchema } from '../validations';

import AccountIdentification from './AccountIdentification.graphql';

type AccountIdentificationType = {
  scopedToken: string;
  familyName?: string;
};

export const useAccountIdentification = ({
  scopedToken,
  familyName,
}: AccountIdentificationType) => {
  return useKrakenQuery({
    queryKey: ['accountIdentification'],
    query: AccountIdentification,
    select: (data: any) => data,
    validateFn(response) {
      return accountIdentificationSchema.validate(response);
    },
    // ),
    enabled: Boolean(scopedToken),
    variables: {
      ...(familyName && { familyName }),
    },
    requestHeaders: {
      Authorization: scopedToken,
    },
    cacheTime: 0,
  });
};
