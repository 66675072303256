import { FC, ReactNode } from 'react';
import { useRouter } from 'next/router';
import { Loader } from '@krakentech/coral';

import { useAuth } from '@/components/Providers/Auth';
import { AccountNumberContextProvider } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';
import CustomHead from '@/pages/_head.page';
import { INTERNAL_PATHS } from '@/utils/urls';

import { DesktopNav, MobileNav } from './components';

const AuthenticatedPageLayout: FC<{
  pageTitle?: string;
  children: ReactNode;
}> = ({ pageTitle, children }) => {
  const { isAuthenticated, hasLoaded } = useAuth();
  const { replace, isReady } = useRouter();

  if (!hasLoaded) {
    return <Loader type="linear" variant="indeterminate" />;
  }

  if (!isAuthenticated && isReady) {
    replace(INTERNAL_PATHS.LOGIN.path);
    return null;
  }

  return (
    <AccountNumberContextProvider>
      <CustomHead title={pageTitle} />
      <DesktopNav />
      {children}
      <MobileNav />
    </AccountNumberContextProvider>
  );
};

export default AuthenticatedPageLayout;
