import styled, { css } from 'styled-components';

export const StyledH1Container = styled.div`
  ${({ theme }) => css`
    color: ${theme.color.primary.main};
  `}
`;

export const StyledBackLinkContainer = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.smMd} 0;
    background: ${theme.color.primary.main10};
    border-radius: 0 0 ${theme.borderRadius} ${theme.borderRadius};
  `}
`;
