import { useKrakenQuery } from '@/components/GraphQLClient';
import { useAuth } from '@/components/Providers/Auth';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';

import { hasActiveWaterMeterSchema } from '../validations';

import HasActiveWaterMeter from './HasActiveWaterMeter.graphql';

export const useHasActiveWaterMeter = () => {
  const { accountNumber } = useAccountNumberContext();

  const { isAuthenticated } = useAuth();

  return useKrakenQuery({
    queryKey: ['hasActiveWaterMeter'],
    query: HasActiveWaterMeter,
    validateFn(response) {
      return hasActiveWaterMeterSchema.validate(response);
    },
    select: (data: any) =>
      data.account.properties[0]?.activeWaterMeters?.length as
        | number
        | undefined,
    enabled: Boolean(isAuthenticated && accountNumber),
    variables: { accountNumber, activeFrom: new Date() },
    cacheTime: 0,
  });
};
