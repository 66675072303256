import { createContext, ReactNode, useState, useContext } from 'react';

import { PaymentSchedule } from '@/types/paymentSchedules';

import {
  PaymentSchedulesContextReturnValue,
  NumberOfDesktopColumns,
} from './index.types';

export const PaymentSchedulesContext = createContext(
  {} as PaymentSchedulesContextReturnValue
);

export const PaymentSchedulesContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [currentActivePaymentSchedule, setCurrentActivePaymentSchedule] =
    useState<PaymentSchedule | Record<string, never>>({});
  const [
    currentActivePaymentScheduleLoading,
    setCurrentActivePaymentScheduleLoading,
  ] = useState(false);
  const [
    currentActivePaymentScheduleError,
    setCurrentActivePaymentScheduleError,
  ] = useState(false);
  const [isVariablePaymentAmount, setIsVariablePaymentAmount] = useState<
    boolean | undefined
  >(undefined);
  const [numberOfDesktopColumns, setNumberOfDesktopColumns] =
    useState<NumberOfDesktopColumns>(1);

  return (
    <PaymentSchedulesContext.Provider
      value={{
        currentActivePaymentSchedule,
        currentActivePaymentScheduleError,
        currentActivePaymentScheduleLoading,
        isVariablePaymentAmount,
        numberOfDesktopColumns,
        setCurrentActivePaymentScheduleLoading,
        setCurrentActivePaymentScheduleError,
        setNumberOfDesktopColumns,
        setCurrentActivePaymentSchedule,
        setIsVariablePaymentAmount,
      }}
    >
      {children}
    </PaymentSchedulesContext.Provider>
  );
};

export const usePaymentSchedulesContext = () => {
  const context = useContext(PaymentSchedulesContext);
  if (context === undefined) {
    throw new Error(
      'usePaymentSchedulesContext must be used within a PaymentSchedulesContextProvider'
    );
  }
  return context;
};
