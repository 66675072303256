import { setCookie, getCookie } from 'cookies-next';
import { addYears, addMonths } from 'date-fns';

import { setAdobeAnalyticsConsent } from '@/lib/adobe';
import { setGoogleAnalyticsConsent } from '@/lib/gtag';

const preferences = [
  'essential',
  'analytics',
  'marketing',
  'everything',
] as const;
export type CookiePreferenceType = (typeof preferences)[number];

export const COOKIES = {
  COOKIE_PREFERENCE: 'cookiePreference',
  SNOOZE_ACCOUNT_ENRICHMENT: 'SNOOZE_ACCOUNT_ENRICHMENT',
  DISMISS_COOKIE_BANNER: 'dismissCookieBanner',
  SNOOZE_PSR_UPDATE: 'SNOOZE_PSR_UPDATE',
};

// Copied from OEIT

/**
 * Return a valid CookiePreference from a JSON-encoded string or throw.
 */
const parseCookiePreference = (
  localStorageValue: string
): CookiePreferenceType => {
  const preference = preferences.find(
    (validName) => validName === localStorageValue
  );
  if (preference) {
    // `preference` comes from the list of `preferences` so the compiler is happy.
    return preference;
  }
  throw new Error(`${preference} is not a cookie preference.`);
};

const getCookieExpiry = (hasOptedOutOfSomeTracking: boolean) => {
  const today = new Date();
  let expires = addYears(today, 1);

  if (hasOptedOutOfSomeTracking) {
    expires = addMonths(today, 6);
  }

  return expires;
};

const setCookieBannerCookie = (hasOptedOutOfSomeTracking: boolean): void => {
  const expires = getCookieExpiry(hasOptedOutOfSomeTracking);

  setCookie(COOKIES.DISMISS_COOKIE_BANNER, 'true', { expires });
};

// TODO: have some sort of error handling for in case something goes wrong with opting in to cookies. Probably need a Toast to pop up. This may require a hook called useCookie or something
export const updateCookiePreference = (
  preference: CookiePreferenceType
): void => {
  const everythingNotSelected = preference !== 'everything';
  const expires = getCookieExpiry(everythingNotSelected);

  const hasConsentedToMarketingStorage = hasMarketingConsent(preference);
  const hasConsentedToAnalyticsStorage = hasAnalyticsConsent(preference);

  // Opt in/out of Google Analytics and Adobe Analytics
  updateConsent(hasConsentedToMarketingStorage, hasConsentedToAnalyticsStorage);

  setCookie(COOKIES.COOKIE_PREFERENCE, preference, { expires });

  setCookieBannerCookie(everythingNotSelected);
};

export const getSavedUserPreference = (): CookiePreferenceType | null => {
  /* checked we're not on the server */
  if (typeof window == 'undefined') {
    return null;
  }
  const preference = getCookie(COOKIES.COOKIE_PREFERENCE);
  // we may not have a stored preference, which is fine, we'll show the cookie banner
  if (!preference) {
    return null;
  }
  return parseCookiePreference(preference);
};

export const hasMarketingConsent = (
  preference: CookiePreferenceType
): boolean => {
  // whether the passed consent allows for marketing tracking
  return preference === 'marketing' || preference === 'everything';
};

export const hasAnalyticsConsent = (
  preference: CookiePreferenceType
): boolean => {
  // whether the passed consent allows for analytics tracking
  return preference === 'analytics' || preference === 'everything';
};

export const updateConsent = (
  hasConsentedToMarketingStorage: boolean,
  hasConsentedToAnalyticsStorage: boolean
): void => {
  setGoogleAnalyticsConsent(
    hasConsentedToMarketingStorage,
    hasConsentedToAnalyticsStorage
  );
  setAdobeAnalyticsConsent(hasConsentedToAnalyticsStorage);
};
