import styled, { css } from 'styled-components';

export const StyledNestedCard = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.color.base.main};
    border: 0.1rem solid ${theme.color.surface.light};
    border-radius: ${theme.spacing.xs};
    padding: ${theme.spacing.md};
  `}
`;
