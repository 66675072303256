import { Typography, Stack, useCoralBreakpoints } from '@krakentech/coral';

import { usePaymentSchedulesContext } from '@/context';
import { formatCurrency } from '@/utils/formatters/currency';

import BoxWithGreyBorder from '../../../../BoxWithGreyBorder';
import MutedText from '../../../../MutedText';

const PaymentAmount = () => {
  const { isMinMd } = useCoralBreakpoints();
  const {
    currentActivePaymentSchedule: { paymentAmount },
  } = usePaymentSchedulesContext();

  return (
    <BoxWithGreyBorder>
      <Stack
        direction="vertical"
        gap="xs"
        alignItems="center"
        md={{
          direction: 'vertical',
          gap: 'xs',
          alignItems: 'flex-start',
        }}
        data-testid="paymentAmount"
      >
        <MutedText variant="h3" textAlign={isMinMd ? 'left' : 'center'}>
          Payment amount:
        </MutedText>
        <Typography textAlign={isMinMd ? 'left' : 'center'}>
          {formatCurrency(paymentAmount!)}
        </Typography>
      </Stack>
    </BoxWithGreyBorder>
  );
};

export default PaymentAmount;
