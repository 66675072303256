import { Stack, Link, Typography } from '@krakentech/coral';

import { INTERNAL_PATHS } from '@/utils/urls';

import Error from '../Error';
import FormContainer from '../forms/FormContainer';
import { useAuth } from '../Providers/Auth';

const NotFoundError = () => {
  const { isAuthenticated } = useAuth();

  return (
    <FormContainer>
      <Stack justifyContent="center">
        <Error
          errorTitle="404"
          subtitle="Page not found"
          explanation="The link you clicked may be broken or the page may have been removed."
        >
          <Typography variant="h5">
            <Link
              href={
                isAuthenticated
                  ? INTERNAL_PATHS.HOME.path
                  : INTERNAL_PATHS.LOGIN.path
              }
            >
              {isAuthenticated ? 'Go to dashboard' : 'Go to login'}
            </Link>
          </Typography>
        </Error>
      </Stack>
    </FormContainer>
  );
};

export default NotFoundError;
