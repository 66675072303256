import styled, { css } from 'styled-components';

export const StyledFooter = styled.footer`
  ${({ theme }) => css`
    border-top: 2px solid ${theme.color.base.dark};
  `}
`;

export const StyledFooterContent = styled.div`
  ${({ theme }) => css`
    color: ${theme.color.text.dark};
  `}
`;
