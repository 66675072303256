import { FC } from 'react';
import { Button } from '@krakentech/coral';
import { IconHidePassword, IconShowPassword } from '@krakentech/icons';

const PasswordToggleButton: FC<{
  passwordVisible: boolean;
  togglePasswordVisibility: () => void;
}> = ({ passwordVisible, togglePasswordVisibility }) => {
  const endIcon = passwordVisible ? (
    <IconHidePassword size={24} />
  ) : (
    <IconShowPassword size={24} />
  );

  return (
    <Button
      color="secondary"
      variant="text"
      onClick={togglePasswordVisibility}
      title={passwordVisible ? 'Hide password' : 'Show password'}
      endIcon={endIcon}
    />
  );
};

export default PasswordToggleButton;
