export const MIDDLE_NAME = 'middle_name';
export const HOMEOWNERSHIP_STATUS = 'homeownership_status';
export const EMPLOYMENT_STATUS = 'employment_status';

export enum STANDARD_TITLE_OPTIONS {
  Mr = 'Mr',
  Mrs = 'Mrs',
  Ms = 'Ms',
  Miss = 'Miss',
  Dr = 'Dr',
  Mx = 'Mx',
}

export enum EXTENDED_TITLE_OPTIONS {
  Mr = 'Mr',
  Mrs = 'Mrs',
  Ms = 'Ms',
  Miss = 'Miss',
  Dr = 'Dr',
  Mx = 'Mx',
  Sir = 'Sir',
  Lord = 'Lord',
  Lady = 'Lady',
  Professor = 'Professor',
  Captain = 'Captain',
  Colonel = 'Colonel',
  Canon = 'Canon',
  Major = 'Major',
  Reverend = 'Reverend',
  Rabbi = 'Rabbi',
  Sister = 'Sister',
  Father = 'Father',
}

export enum HOME_OWNERSHIP_STATUS_OPTIONS {
  HOME_OWNER = 'Home owner',
  RENTED_HOUSING_ASSOCIATION = 'Rented housing association',
  RENTED_LOCAL_COUNCIL = 'Rented local council',
  RENTED_PRIVATELY = 'Rented privately',
  RENTED_STUDENT_HOUSE = 'Rented student house',
  RENTED_BUT_NOT_KNOWN = 'Rented but not known',
}

export enum EMPLOYMENT_STATUS_OPTIONS {
  EMPLOYED = 'Employed',
  NOT_ON_BENEFITS = 'Not on benefits',
  RETIRED = 'Retired',
  SELF_EMPLOYED = 'Self-employed',
  STUDENT = 'Student',
  UNEMPLOYED = 'Unemployed',
}
