import { ReactNode } from 'react';
import { Stack, Typography, Container, Card } from '@krakentech/coral';

import { StyledBackLinkContainer, StyledH1Container } from './index.styled';

const Error = ({
  errorTitle,
  subtitle,
  explanation,
  children,
}: {
  errorTitle: string;
  subtitle: string;
  explanation: string;
  children: ReactNode;
}) => (
  <Card padding="none">
    <Container component="section" marginX="auto" padding="lg">
      <Stack direction="vertical" gap="md" alignItems="center">
        <StyledH1Container>
          <Typography variant="h1">{errorTitle}</Typography>
        </StyledH1Container>
        <Typography variant="h2">{subtitle}</Typography>
        <Typography textAlign="center">{explanation}</Typography>
      </Stack>
    </Container>

    <StyledBackLinkContainer>
      <Stack justifyContent="center" alignItems="center">
        {children}
      </Stack>
    </StyledBackLinkContainer>
  </Card>
);

export default Error;
