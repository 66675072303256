import { useState } from 'react';
import { FormikTextField } from '@krakentech/coral-formik';

import PasswordToggleButton from '@/components/PasswordToggleButton';

type FormPasswordFieldProps = {
    name: string;
    label: string;
    id: string;
    error?: boolean;
    errorMessage?: string;
};

const FormPasswordField = ({
    name,
    label,
    id,
    error,
    errorMessage,
}: FormPasswordFieldProps) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    return (
        <FormikTextField
            name={name}
            label={label}
            id={id}
            {...(error && { error })}
            {...(errorMessage && { errorMessage })}
            type={isPasswordVisible ? 'text' : 'password'}
            endIcon={
                <PasswordToggleButton
                    passwordVisible={isPasswordVisible}
                    togglePasswordVisibility={() =>
                        setIsPasswordVisible(!isPasswordVisible)
                    }
                />
            }
        />
    );
};

export default FormPasswordField;
