import { SelectOption } from '@krakentech/coral';
import { FormikSelect } from '@krakentech/coral-formik';

import { SelectOverride } from './index.styled';

type SelectProps = {
  name: string;
  label: string;
  values: SelectOption[];
  selectedValue?: SelectOption;
  onChange?: (value?: SelectOption | null) => void;
  id?: string;
  disabled?: boolean;
};

export const mapEnumOptionsToSelectOptions = (options: {
  [key: string]: string;
}): SelectOption[] =>
  Object.entries(options).map(([key, value]) => ({
    label: value,
    value: key,
  }));

const Select = ({
  name,
  label,
  values,
  onChange,
  id,
  disabled = false,
}: SelectProps) => {
  return (
    // SelectOverride to be removed when the overLightBackground doesn't turn the dropdown text white
    <SelectOverride>
      <FormikSelect
        name={name}
        label={label}
        values={values}
        id={id}
        {...(onChange && { onChange })}
        disabled={disabled}
      />
    </SelectOverride>
  );
};

export default Select;
