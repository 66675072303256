import { PAYMENT_FREQUENCY } from '@/consts/paymentFrequency';

export const getPaymentFrequency = (
  paymentFrequency: PAYMENT_FREQUENCY,
  paymentFrequencyMultiplier: number
) => {
  const isWeekly = paymentFrequency === PAYMENT_FREQUENCY.WEEKLY;

  const unit = isWeekly ? 'week' : 'month';

  return paymentFrequencyMultiplier > 1
    ? `${paymentFrequencyMultiplier} ${unit}s`
    : unit;
};
