import { useKrakenQuery } from '@/components/GraphQLClient';
import { useAuth } from '@/components/Providers/Auth';

import { viewerIdSuccessSchema } from '../validations';

import ViewerId from './ViewerId.graphql';

export const useViewerId = () => {
  const { isAuthenticated } = useAuth();

  return useKrakenQuery({
    queryKey: ['viewerId'],
    query: ViewerId,
    select: (data: { viewer: { id: string } }) => data.viewer.id,
    validateFn(response) {
      return viewerIdSuccessSchema.validate(response);
    },
    enabled: Boolean(isAuthenticated),
  });
};
