import { Select } from '@/components';
import type { FieldTypeProps } from '@/components/accountManagement/personalDetails/fields/index.types';
import { mapEnumOptionsToSelectOptions } from '@/components/Select';
import { HOME_OWNERSHIP_STATUS_OPTIONS } from '@/consts/updatePersonalDetails';

const HomeOwnershipStatusField = ({
  name = 'homeOwnershipStatus',
  label = 'Homeowner status (optional)',
  disabled = false,
}: FieldTypeProps) => {
  return (
    <Select
      name={name}
      label={label}
      values={mapEnumOptionsToSelectOptions(HOME_OWNERSHIP_STATUS_OPTIONS)}
      disabled={disabled}
    />
  );
};

export default HomeOwnershipStatusField;
