import {
  Button,
  Stack,
  Typography,
  useCoralBreakpoints,
} from '@krakentech/coral';

import PaymentFrequencyTooltip from '@/components/billingAndPayment/PaymentPlanCard/components/PaymentFrequencyTooltip';
import BoxWithGreyBorder from '@/components/BoxWithGreyBorder';
import MutedText from '@/components/MutedText';
import { PAYMENT_FREQUENCY } from '@/consts/paymentFrequency';
import { usePaymentSchedulesContext } from '@/context';
import { useSyncFeatureFlag } from '@/hooks/utils/useFeatureFlags';
import { FeatureNames } from '@/types/features';
import { cardinalNumberToOrdinalNumber } from '@/utils/formatters/number';
import { getPaymentFrequency } from '@/utils/paymentSchedule';
import { INTERNAL_PATHS } from '@/utils/urls';

const PaymentFrequency = () => {
  const changePaymentScheduleEnabled = useSyncFeatureFlag(
    FeatureNames.ChangePaymentSchedule
  );
  const { isMaxMd } = useCoralBreakpoints();
  const {
    currentActivePaymentSchedule: {
      isVariablePaymentAmount,
      paymentFrequencyMultiplier,
      paymentFrequency,
      paymentDay,
    },
  } = usePaymentSchedulesContext();

  const getPaymentFrequencyText = () => {
    if (isVariablePaymentAmount) {
      return 'When you get your bill';
    } else {
      return `Every ${getPaymentFrequency(
        paymentFrequency,
        paymentFrequencyMultiplier
      )}${
        paymentFrequency === PAYMENT_FREQUENCY.MONTHLY
          ? ` on the ${cardinalNumberToOrdinalNumber(paymentDay!)}`
          : ''
      }`;
    }
  };

  return (
    <BoxWithGreyBorder>
      <Stack
        {...(isMaxMd && { direction: 'vertical' })}
        justifyContent={isMaxMd ? 'center' : 'space-between'}
        alignItems="center"
      >
        <Stack
          direction="vertical"
          gap="xs"
          alignItems="center"
          md={{
            alignItems: 'flex-start',
            direction: 'vertical',
            gap: 'xs',
          }}
        >
          <Stack gap="xs">
            <MutedText variant="h3">You pay:</MutedText>
            <PaymentFrequencyTooltip
              isVariablePaymentAmount={isVariablePaymentAmount!}
              paymentFrequency={paymentFrequency!}
              paymentFrequencyMultiplier={paymentFrequencyMultiplier!}
            />
          </Stack>
          <Typography>{getPaymentFrequencyText()}</Typography>
        </Stack>
        {changePaymentScheduleEnabled && (
          <Button
            variant="outlined"
            {...(isMaxMd && { fullWidth: true })}
            href={INTERNAL_PATHS.CHANGE_PAYMENT_SCHEDULE.path}
          >
            Change
          </Button>
        )}
      </Stack>
    </BoxWithGreyBorder>
  );
};

export default PaymentFrequency;
