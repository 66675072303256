import { useKrakenMutation } from '@/components/GraphQLClient';
import { MARKETING_PREFERENCE_OPTIONS } from '@/consts/marketingPreferenceOptions';
import { useCommsPreferencesContext } from '@/context';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';

import { useCommsAndMarketingPreferences } from '../useCommsAndMarketingPreferences';
import { updateCommsPreferencesSchema } from '../validations';

import UpdateCommsPreferences from './UpdateCommsPreferences.graphql';

const getHumanReadableFieldName = (fieldName: MARKETING_PREFERENCE_OPTIONS) => {
  if (fieldName === MARKETING_PREFERENCE_OPTIONS.SMS) {
    return 'SMS messaging';
  } else if (fieldName === MARKETING_PREFERENCE_OPTIONS.MARKETING) {
    return 'news, updates and information from us';
  } else {
    return 'information about plumbing & drainage cover';
  }
};

export const useUpdateCommsPreferences = () => {
  const { accountNumber } = useAccountNumberContext();
  const { refetch } = useCommsAndMarketingPreferences({ accountNumber });
  const { setMutationLoading, setMutationError, setMutationSuccess } =
    useCommsPreferencesContext();

  return useKrakenMutation({
    mutation: (_: any) => UpdateCommsPreferences,
    validateFn(response) {
      return updateCommsPreferencesSchema.validate(response);
    },
    onSuccess: async (
      { updateCommsPreferences: { commsPreferences } },
      { input }
    ) => {
      // Refetch the query to get this data so the new comms preferences are displayed on the page
      refetch();

      // Set the mutation loading state to false
      setMutationLoading(false);

      const fieldName = Object.keys(input)[0] as MARKETING_PREFERENCE_OPTIONS;

      const isOptedInToFieldName = commsPreferences[fieldName];

      const mutationSuccessMessage = `You have opted ${
        isOptedInToFieldName ? 'in to receive' : 'out of receiving'
      } ${getHumanReadableFieldName(fieldName)}`;

      // Display mutation success alert
      setMutationSuccess(mutationSuccessMessage);
    },
    onError: () => {
      // Set the mutation loading state to false
      setMutationLoading(false);

      // Display the mutation error
      setMutationError();
    },
  });
};
