import { ReactNode, createContext, useState, useContext } from 'react';
import { AlertSeverity } from '@krakentech/coral';

import { ANIMATED_ALERT_STATUS } from '@/consts/animatedAlertStatus';
import { MOBILE_FORM_DIALOG_TOGGLE_ORIGIN } from '@/consts/mobileFormDialogToggleOrigin';
import { AnimatedAlert } from '@/types/alerts';

import { CommsPreferencesContextReturnValue } from './index.types';

export const CommsPreferencesContext = createContext(
  {} as CommsPreferencesContextReturnValue
);

export const CommsPreferencesContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [billingAddressPostcode, setBillingAddressPostcode] = useState('');
  const [mutationLoading, setMutationLoading] = useState(false);
  const [mutationStatusAlerts, setMutationStatusAlerts] = useState<
    Array<AnimatedAlert>
  >([]);
  const [showMobileFormDialog, setShowMobileFormDialog] = useState(false);
  const [mobileFormDialogToggleOrigin, setMobileFormDialogToggleOrigin] =
    useState<MOBILE_FORM_DIALOG_TOGGLE_ORIGIN | null>(null);

  // The only state variable not saved to context. This is just for the purposes of assigning an alertIndex to a new mutationStatusAlert that is definitely unique
  const [nextMutationStatusAlertIndex, setNextMutationStatusAlertIndex] =
    useState(0);

  const setMutationAlert = (
    alertSeverity: AlertSeverity,
    alertMessage: string
  ) => {
    // If there are two mutation status alerts already on context, remove the first one. This is to make sure we don't have more than two displayed at any time
    const oldMutationStatusAlert =
      mutationStatusAlerts.length > 1
        ? [mutationStatusAlerts[1]]
        : mutationStatusAlerts;

    setMutationStatusAlerts([
      ...oldMutationStatusAlert,
      {
        alertStatus: ANIMATED_ALERT_STATUS.OPEN,
        alertSeverity,
        alertMessage,
        alertIndex: nextMutationStatusAlertIndex,
      },
    ]);

    // Increase the nextMutationStatusAlertIndex by 1, ready for the next one
    setNextMutationStatusAlertIndex(nextMutationStatusAlertIndex + 1);
  };

  const setMutationError = () => {
    setMutationAlert(
      'error',
      'Your communication preference was not updated. Please try again or come back later'
    );
  };

  const setMutationSuccess = (message: string) => {
    setMutationAlert('success', message);
  };

  return (
    <CommsPreferencesContext.Provider
      value={{
        email,
        setEmail,
        mobile,
        setMobile,
        billingAddressPostcode,
        setBillingAddressPostcode,
        mutationLoading,
        setMutationLoading,
        mutationStatusAlerts,
        setMutationStatusAlerts,
        setMutationSuccess,
        setMutationError,
        showMobileFormDialog,
        setShowMobileFormDialog,
        mobileFormDialogToggleOrigin,
        setMobileFormDialogToggleOrigin,
      }}
    >
      {children}
    </CommsPreferencesContext.Provider>
  );
};

export const useCommsPreferencesContext = () => {
  const context = useContext(CommsPreferencesContext);
  if (context === undefined) {
    throw new Error(
      'useCommsPreferencesContext must be used within a CommsPreferencesContextProvider'
    );
  }
  return context;
};
