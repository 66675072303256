import styled, { css } from 'styled-components';

export const StyledActionCard = styled.div<{
  addRowGap?: boolean;
}>`
  ${({ theme, addRowGap }) => css`
    > div {
      > div:hover {
        border: ${theme.overrides.card.root.borderWidth} solid
          ${theme.color.primary.dark};
      }

      a,
      button {
        padding: ${theme.spacing.lg} ${theme.spacing.mdLg};
        border: ${theme.overrides.card.root.borderWidth} solid transparent;

        &:hover,
        &:focus-visible {
          background-color: transparent;
        }
      }

      a {
        &:focus-visible {
          border: ${theme.overrides.card.root.borderWidth} solid
            ${theme.color.primary.dark};
        }

        div {
          row-gap: ${addRowGap ? theme.spacing.smMd : 0};
        }
      }

      button {
        &:hover,
        &:focus {
          border-color: transparent;
        }

        &:focus-visible {
          border: ${theme.overrides.card.root.borderWidth} solid
            ${theme.color.primary.dark};
        }

        div {
          row-gap: ${addRowGap ? theme.spacing.smMd : 0};
        }
      }

      svg {
        vertical-align: middle;
      }
    }
  `}
`;

export const StyledChevronRightIcon = styled.div<{ displayIcon?: boolean }>`
  ${({ displayIcon }) => css`
    display: ${displayIcon ? 'block' : 'none'};
  `}
`;
