import { useKrakenMutation } from '@/components/GraphQLClient';

import { useAuthWithPreSignedKey } from '../useAuthWithPreSignedKey';
import { verifyIdentitySchema } from '../validations';

import VerifyIdentity from './VerifyIdentity.graphql';

export const useVerifyIdentity = () => {
  const { mutate } = useAuthWithPreSignedKey();

  return useKrakenMutation({
    mutation: (_) => VerifyIdentity,
    validateFn(response) {
      return verifyIdentitySchema.validate(response);
    },
    onSuccess(data) {
      mutate({ input: { preSignedKey: data.verifyIdentity.token } });
    },
  });
};
