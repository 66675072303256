import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { Card, Link, Stack, Typography } from '@krakentech/coral';

import BoxWithGreyBorder from '@/components/BoxWithGreyBorder';
import PageError from '@/components/PageError';
import { Body1Skeleton, H4Skeleton } from '@/components/Skeletons';
import { usePaymentSchedulesContext } from '@/context';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';
import { useSyncFeatureFlag } from '@/hooks/utils/useFeatureFlags';
import { FeatureNames } from '@/types/features';

import { PaymentAmount, PaymentFrequency, PaymentMethod } from './components';

const PaymentPlanCard = () => {
  const { push } = useRouter();
  const changeBankDetailsEnabled = useSyncFeatureFlag(
    FeatureNames.ChangeBankDetails
  );
  const {
    currentActivePaymentSchedule: data,
    currentActivePaymentScheduleError: error,
    currentActivePaymentScheduleLoading: isLoading,
  } = usePaymentSchedulesContext();

  const { accountNumber } = useAccountNumberContext();

  useEffect(() => {
    if (!isLoading && !error && typeof data === 'undefined') {
      push('/404');
    }
  }, [isLoading, error, data, push, accountNumber]);

  if (isLoading) {
    return <LoadingState />;
  }

  if (error) {
    return <PageError />;
  }

  if (!data) {
    return null;
  }

  const { isVariablePaymentAmount } = data;

  return (
    <Card>
      <Stack gap="md" direction="vertical">
        <Typography variant="h2">Your plan</Typography>

        <PaymentFrequency />

        {!isVariablePaymentAmount && <PaymentAmount />}

        <PaymentMethod />

        {
          // TO DO: Check if changing payment frequency is also enabled
          !changeBankDetailsEnabled && (
            <Typography>
              If you want to manage your payment plan then please{' '}
              <Link href={process.env.NEXT_PUBLIC_HELP_LINK}>contact us</Link>{' '}
              and we&apos;ll be happy to help!
            </Typography>
          )
        }
      </Stack>
    </Card>
  );
};

const LoadingState = () => (
  <Card>
    <Stack gap="md" direction="vertical" alignItems="flex-start">
      <H4Skeleton />

      <BoxWithGreyBorder fullWidth>
        <Stack
          direction="vertical"
          gap="xs"
          alignItems="center"
          md={{
            direction: 'vertical',
            gap: 'xs',
            alignItems: 'flex-start',
          }}
        >
          <Body1Skeleton width={250} />
          <Body1Skeleton width={250} />
        </Stack>
      </BoxWithGreyBorder>

      <BoxWithGreyBorder fullWidth>
        <Stack
          direction="vertical"
          gap="xs"
          alignItems="center"
          md={{
            direction: 'vertical',
            gap: 'xs',
            alignItems: 'flex-start',
          }}
        >
          <Body1Skeleton width={250} />
          <Body1Skeleton width={250} />
        </Stack>
      </BoxWithGreyBorder>

      <BoxWithGreyBorder fullWidth>
        <Stack
          direction="vertical"
          gap="xs"
          alignItems="center"
          md={{
            direction: 'vertical',
            gap: 'xs',
            alignItems: 'flex-start',
          }}
        >
          <Body1Skeleton width={250} />
          <Body1Skeleton width={250} />
        </Stack>
      </BoxWithGreyBorder>
    </Stack>
  </Card>
);

export default PaymentPlanCard;
