export const formatCurrency = (amount: number) => {
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  }).format(amount / 100);
}; // 1000 => £10.00, 123 => £1.23

export const formatCurrencyWithoutPence = (amount: number) => {
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount / 100);
}; // 1000 => £10, 123 => £1

// You'll normally want to use formatCurrency instead of this. This is probably only really for use with the PriceTextField component, as the £ sign is displayed as a prefix at the beginning of this field and numbers with 4 digits and over have a comma separator
export const formatCurrencyWithoutPoundSignOrComma = (amount: number) => {
  return formatCurrency(amount).replace('£', '').replace(',', '');
}; // 1000 => 10.00, 123 => 1.23, 100000 => 1000.00

export const formatCurrencyWithoutMinusSymbol = (amount: number) => {
  const amountWithoutMinusSymbol = Math.abs(amount);

  return formatCurrency(amountWithoutMinusSymbol);
}; // 1000 => £10.00, -1000 => £10.00

export const formatCurrencyWithMinusIndicatorIfPositive = (amount: number) => {
  return amount > 0
    ? `-${formatCurrency(amount)}`
    : formatCurrencyWithoutMinusSymbol(amount);
}; // 1000 => -£10.00, -1000 => £10.00, 0 => £0.00

export const formatCurrencyWithCreditOrDebitIndicator = (
  amount: number,
  displayBrackets = true
) => {
  if (amount === 0) {
    return formatCurrencyWithoutMinusSymbol(amount);
  }

  const indicator = amount > 0 ? 'credit' : 'debit';

  return `${formatCurrencyWithoutMinusSymbol(amount)} ${displayBrackets ? `(${indicator})` : indicator}`;
}; // 1000 => "£10.00 (credit)", -1000 => "£10.00 (debit)", 0 => "£0.00"

export const poundsToPence = (amountInPounds: number) => {
  return amountInPounds * 100;
};

export const penceToPounds = (amountInPence: number) => {
  return amountInPence / 100;
};
