import { Card, Stack, Typography } from '@krakentech/coral';
import { Field } from 'formik';

import { COMMS_DELIVERY_PREFERENCES } from '@/consts/commsDeliveryPreferences';
import { useCommsPreferencesContext } from '@/context';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';
import { useUpdateCommsDeliveryPreference } from '@/hooks/accounts/useUpdateCommsDeliveryPreference';
import { useMovingOutStatus } from '@/hooks/moveHome/useMovingOutStatus';

import RadioCard from '../../RadioCard';

const CommsDeliveryPreferenceSection = () => {
  const { mutationLoading, setMutationLoading, email, billingAddressPostcode } =
    useCommsPreferencesContext();
  const { mutate } = useUpdateCommsDeliveryPreference();

  const { accountNumber } = useAccountNumberContext();

  const { hasMovedOut } = useMovingOutStatus();

  const submitUpdateCommsDeliveryPreference = (
    newPreference: COMMS_DELIVERY_PREFERENCES,
    accountNumber: string
  ) => {
    // Disable all the inputs
    setMutationLoading(true);

    mutate({
      input: {
        accountNumber,
        commsDeliveryPreference: newPreference,
      },
    });
  };

  return (
    <Card>
      <Stack direction="vertical" gap="md">
        <Stack direction="vertical" gap="xs">
          <Typography variant="h2">
            Communication delivery preference
          </Typography>
          <Typography variant="body2" color="dark">
            Choose how we communicate with you
          </Typography>
        </Stack>

        <Stack direction="vertical" gap="sm">
          <Field
            name="commsDeliveryPreference"
            label="Via email"
            explanation={`We'll send to ${email}. This is more efficient and reduces your carbon footprint`}
            component={RadioCard}
            value={COMMS_DELIVERY_PREFERENCES.EMAIL}
            onChange={() =>
              submitUpdateCommsDeliveryPreference(
                COMMS_DELIVERY_PREFERENCES.EMAIL,
                accountNumber
              )
            }
            disabled={hasMovedOut || mutationLoading}
          />
          <Field
            name="commsDeliveryPreference"
            label="By post"
            explanation={`We'll send to your billing address ${billingAddressPostcode}`}
            component={RadioCard}
            value={COMMS_DELIVERY_PREFERENCES.POSTAL_MAIL}
            onChange={() =>
              submitUpdateCommsDeliveryPreference(
                COMMS_DELIVERY_PREFERENCES.POSTAL_MAIL,
                accountNumber
              )
            }
            disabled={hasMovedOut || mutationLoading}
          />
        </Stack>
      </Stack>
    </Card>
  );
};

export default CommsDeliveryPreferenceSection;
