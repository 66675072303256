import { ReactNode, createContext, useState, useContext } from 'react';

import { ScopedTokenContextReturnValue } from './index.types';

export const ScopedTokenContext = createContext(
  {} as ScopedTokenContextReturnValue
);

export const ScopedTokenContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [scopedToken, setScopedToken] = useState('');

  return (
    <ScopedTokenContext.Provider
      value={{
        scopedToken,
        setScopedToken,
      }}
    >
      {children}
    </ScopedTokenContext.Provider>
  );
};

export const useScopedTokenContext = () => {
  const context = useContext(ScopedTokenContext);
  if (context === undefined) {
    throw new Error(
      'useScopedTokenContext must be used within a ScopedTokenContextProvider'
    );
  }
  return context;
};
