import { DIRECT_DEBIT_INSTRUCTION_STATUS } from '@/consts/directDebit';
import { DirectDebitInstruction } from '@/types/directDebit';

export const isValidDirectDebitInstruction = (
  directDebitInstruction?: Partial<DirectDebitInstruction>
): boolean => {
  return (
    !!directDebitInstruction &&
    (directDebitInstruction.status === DIRECT_DEBIT_INSTRUCTION_STATUS.ACTIVE ||
      directDebitInstruction.status ===
        DIRECT_DEBIT_INSTRUCTION_STATUS.PROVISIONAL)
  );
};

export const isFailedDirectDebitInstruction = (
  directDebitInstruction?: Partial<DirectDebitInstruction>
): boolean => {
  return (
    !!directDebitInstruction &&
    directDebitInstruction.status === DIRECT_DEBIT_INSTRUCTION_STATUS.FAILED
  );
};

export const getPaymentScheduleSummaryPrefix = (isFistMore: boolean) => {
  if (isFistMore) {
    return 'First payment:';
  } else {
    return 'Monthly payments:';
  }
};
