import { ReactNode } from 'react';
import { Tooltip, Typography } from '@krakentech/coral';

import { Body1Skeleton } from '../Skeletons';

import { StyledTable, StyledColumnHeading, StyledBodyTd } from './index.styled';

const Table = ({
  columns,
  data,
  isLoading = false,
  oddRowsShorter = false,
}: {
  columns: Array<{
    name: string;
    width: number;
    tooltip?: string;
  }>;
  data?: Array<Record<string, ReactNode | undefined>> | undefined;
  isLoading?: boolean;
  oddRowsShorter?: boolean;
}) => (
  <StyledTable oddRowsShorter={oddRowsShorter}>
    <thead>
      <tr>
        {columns.map(({ width, name, tooltip }, i) => {
          return (
            <StyledColumnHeading key={i} width={width}>
              <Typography textWrap="nowrap">
                {isLoading ? (
                  <Body1Skeleton width={116} />
                ) : (
                  <strong>
                    {name} {tooltip && <Tooltip title={tooltip} />}
                  </strong>
                )}
              </Typography>
            </StyledColumnHeading>
          );
        })}
      </tr>
    </thead>

    <tbody>
      {isLoading &&
        Array.from({ length: 2 }).map((_, i) => (
          <tr key={i}>
            {Array.from({ length: columns.length }).map((_, i) => (
              <StyledBodyTd key={i}>
                <Body1Skeleton width={116} />
              </StyledBodyTd>
            ))}
          </tr>
        ))}
      {data &&
        data.map((entry, i) => (
          <tr key={i}>
            {Object.values(entry).map((value, i) => {
              return <StyledBodyTd key={i}>{value}</StyledBodyTd>;
            })}
          </tr>
        ))}
    </tbody>
  </StyledTable>
);

export default Table;
