import { Grid } from '@krakentech/coral';
import { IconHome } from '@krakentech/icons';

import { ActionCard } from '@/components';
import { INTERNAL_PATHS } from '@/utils/urls';

const HomeMoveCard = () => {
  const actionCardHref = INTERNAL_PATHS.MOVE_HOME[0].path;

  return (
    <Grid.Item>
      <ActionCard
        href={actionCardHref}
        title="Move out of home"
        titleComponent="p"
        icon={<IconHome size={32} />}
      />
    </Grid.Item>
  );
};

export default HomeMoveCard;
