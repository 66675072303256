// Some util functions to override Coral styles, which are probably to be used in page components
import styled, { css } from 'styled-components';

// https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp
export const HideArrowsFromNumberInput = styled.div`
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const FormSubmitButtonOverride = styled.div`
  button[disabled] {
    cursor: not-allowed;
    pointer-events: auto;
  }
`;

export const RadioOverride = styled.div`
  ${({ theme }) => css`
    div[role='radiogroup'] {
      gap: ${theme.spacing.smMd};
    }

    label {
      margin-right: ${theme.spacing.smMd};
    }

    span {
      color: ${theme.color.text.main};
    }
  `}
`;

export const InlineRadioOverride = styled.div`
  ${({ theme }) => css`
    div[role='radiogroup'] {
      flex-direction: row;
      gap: ${theme.spacing.sm};
    }

    fieldset {
      display: flex;
    }
  `}
`;

export const SkipToContentOverride = styled.div`
  ${({ theme }) => css`
    a {
      color: ${theme.color.base.main};
    }
  `}
`;

export const StepperOverride = styled.div`
  width: 9.9rem;
  margin: 0 auto;
`;

export const SwitchOverride = styled.div`
  div {
    width: 100%;
  }

  label {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0;
  }

  span {
    text-align: left;
  }
`;

export const ToggleButtonOverride = styled.div`
  ${({ theme }) => css`
    button[aria-pressed='true'] {
      color: ${theme.color.base.main};
      background: ${theme.color.primary.main};
    }
  `}
`;
