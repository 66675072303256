import { octopusTheme } from '@krakentech/coral';

export const black = '#000';
export const white = '#fff';

export const textColors = {
  main: '#1E0D35',
  light: white,
  mid: '#A3A0AC',
  mid20: 'rgba(163, 160, 172, 0.2)',
  dark: '#696176',
};

export const baseColors = {
  main: '#FAFAFA',
  dark: white,
};

export const actionColors = {
  success: '#1E7B44',
  success10: 'rgba(30, 123, 68, 0.1)',
  success30: 'rgba(30, 123, 68, 0.3)',
  successLight: '#E4F8EC',
  error: '#CC0011',
  error10: 'rgba(204, 0, 17, 0.1)',
  error30: 'rgba(204, 0, 17, 0.3)',
  errorLight: '#FFE7E9',
  warning: '#996700',
  warning10: 'rgba(153, 103, 0, 0.1)',
  warning30: 'rgba(153, 103, 0, 0.3)',
  warningLight: '#FFF4DD',
  info: '#098CC4',
  info10: 'rgba(9, 140, 196, 0.1)',
  info30: 'rgba(9, 140, 196, 0.3)',
  infoLight: '#E5F6FE',
};

export const commonColors = {
  base: baseColors,
  text: textColors,
  action: actionColors,
};

export const alertBackgroundColors = {
  success: {
    light: {
      backgroundColor: actionColors.successLight,
    },
  },
  info: {
    light: {
      backgroundColor: actionColors.infoLight,
    },
  },
  warning: {
    light: {
      backgroundColor: actionColors.warningLight,
    },
  },
  error: {
    light: {
      backgroundColor: actionColors.errorLight,
    },
  },
};

export const datePickerStyles = {
  '.rdp-months': {
    boxShadow: '0 0.4rem 0.4rem 0 rgba(0, 0, 0, 0.25)',
    color: textColors.light,
  },
  '.rdp-tbody': {
    padding: `0 ${octopusTheme.spacing.xsSm} ${octopusTheme.spacing.xsSm}`,
  },
  '.rdp-head_row': {
    padding: `${octopusTheme.spacing.sm} ${octopusTheme.spacing.xsSm} 0`,
  },
  '.rdp-tbody, .rdp-head_row': {
    background: baseColors.dark,
  },
  '.rdp-nav button:hover, .rdp-caption button:hover, , .rdp-caption button:focus':
    {
      backgroundColor: 'transparent',
      color: white,
    },
};

export const stepLineBorderWidth = '0.3rem 0 0 0';

export const stepLabelColor = {
  'button > div:last-child ': {
    color: textColors.main,
  },
};

export const buttonBorderWidth = '0.2rem';

const focusOutlineWidth = '0.4rem';

export const buttonFocusOutline = (outlineColor: string) => ({
  outline: `${focusOutlineWidth} solid ${outlineColor}`,
  outlineOffset: '0', // Remove the gap between the outline and the button
});
