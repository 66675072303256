export const cookies = [
  {
    disabled: false,
    isCheckedByDefault: true,
    label: 'Analytics',
    id: 'analytics',
    tooltip:
      'Helps us understand how customers are using our site and helps us build better features.',
  },
];
