import { deleteCookie } from 'cookies-next';

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID as string;

type PageViewArgs = {
  /**
   * The title of the page.
   */
  title?: string;
  /**
   * The URL of the page.
   */
  url: string;
};

export const trackPageViewForGoogle = ({ title, url }: PageViewArgs) => {
  if (window && typeof window.gtag !== 'undefined') {
    window.gtag('config', GA_TRACKING_ID, {
      // Will fallback to window.document.title if a specific title isn't passed in.
      page_title: title || window.document.title,
      page_path: url,
    });
  }
};

type EventArgs = {
  /**
   *  The value that will appear as the event action in Google Analytics Event reports.
   */
  action: string;
  /**
   * The category of the event.
   */
  category?: string;
  /**
   * The label of the event.
   */
  label: string;
  /**
   * A non-negative integer that will appear as the event value.
   */
  value?: number;
};

export const event = ({
  action,
  category = 'general',
  label,
  value,
}: EventArgs) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value,
  });
};

// Copied from OEIT
type TrackingPermission = 'granted' | 'denied';
type GTagPermissions = {
  ad_storage: TrackingPermission;
  analytics_storage: TrackingPermission;
  ad_user_data: TrackingPermission;
  ad_personalization: TrackingPermission;
};

export const createConsentArgs = (
  hasGivenMarketingConsent: boolean,
  hasGivenAnalyticsConsent: boolean
): GTagPermissions => {
  const analyticsStorage = hasGivenAnalyticsConsent ? 'granted' : 'denied';
  const adStorage = hasGivenMarketingConsent ? 'granted' : 'denied';
  return {
    ad_storage: adStorage,
    analytics_storage: analyticsStorage,
    ad_user_data: 'denied',
    ad_personalization: 'denied',
  };
};

export const setGoogleAnalyticsConsent = (
  hasGivenMarketingConsent: boolean,
  hasGivenAnalyticsConsent: boolean
): void => {
  // check we're not on the server
  if (typeof window == 'undefined') {
    return;
  }
  // update the consent settings via GTM https://support.google.com/analytics/answer/9976101
  if (window && typeof window.gtag !== 'undefined') {
    window.gtag(
      'consent',
      'update',
      createConsentArgs(hasGivenMarketingConsent, hasGivenAnalyticsConsent)
    );
  }
};

// Function to manually delete GA cookies from the browser. This is for when a user withdraws consent, so they have GA cookies on the browser but want to stop analytics tracking. Whilst keeping these in the browser is technically GDPR compliant, it does look confusing and would result in complaints from customers who have withdrawn consent but still see GA cookies on their browser (even though they wouldn't be used for tracking)
// Locally, these cookies are being saved as being on the root domain, which is why deleting them locally is easy because, by default, deleteCookie only deletes cookies from the root domain.
// However, in preview and test, preview and prod, this is not the case. On test, these cookies are saved as being on the root domain with a . prefixed before it. On prod, these cookies are being saved as being on the root domain of the client's main website, with a . in front of it. E.g. if the production domain is myaccount.nautilus.com, these cookkies would be saved to .nautilus.com. So, we need to remove the production domain prefix from the domain before deleting the cookies.
// There are 2-3 GA cookies to delete. _ga and _gid are the main ones, but there is also _ga_<containerId> which is used for cross-domain tracking. The container ID comes from the GA tracking ID, only we need to remove "G-" from the beginning of it.
// Note that we shouldn't be loading these GA cookies on test, preview or locally anyway. They should be reserved for production to keep our data clean. We should only be loading the GA cookies on test, preview and locally if we are testing GA functionality. However, the functionality to delete cookies from these environments remains in place in case we need to use it in the future, or in case Google changes how it sets cookies in the future (let's be honest, they obviously don't care about GDPR compliance so they probably will)
export const manuallyDeleteGoogleAnalyticsCookies = (): void => {
  deleteCookie('_ga');
  deleteCookie('_gid');

  const domain = window.location.hostname;

  // On test and preview, GA cookies are set as being on the root domain, but with a . in front of it.
  const testDomain = `.${domain}`;
  const prodDomain =
    typeof process.env.NEXT_PUBLIC_PROD_DOMAIN_PREFIX === 'string'
      ? `${domain.split(process.env.NEXT_PUBLIC_PROD_DOMAIN_PREFIX)[1]}`
      : `.${domain}`;

  // Delete this for test domain
  deleteCookie('_ga', { path: '/', domain: testDomain });
  deleteCookie('_gid', { path: '/', domain: testDomain });

  // Delete this for prod domain
  deleteCookie('_ga', { path: '/', domain: prodDomain });
  deleteCookie('_gid', { path: '/', domain: prodDomain });

  const containerId = process.env.NEXT_PUBLIC_GA_TRACKING_ID?.split('-')[1];

  if (containerId) {
    // Delete cookie for local and test
    deleteCookie(`_ga_${containerId}`);
    deleteCookie(`_ga_${containerId}`, { path: '/', domain: testDomain });
    deleteCookie(`_ga_${containerId}`, { path: '/', domain: prodDomain });
  }
};

export const setUserIdForGoogle = (userId?: string | null) => {
  if (window && typeof window.gtag !== 'undefined') {
    window.gtag('set', {
      user_id: userId,
    });
  }
};
