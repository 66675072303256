import { StyledCircleIcon, StyledIcon } from './index.styled';
import { CircleIconProps } from './index.types';

const CircleIcon = ({ icon }: CircleIconProps) => {
  return (
    <StyledCircleIcon>
      <StyledIcon icon={icon} />
    </StyledCircleIcon>
  );
};

export default CircleIcon;
