import { useState, useEffect } from 'react';

import {
  PaginatedData,
  UsePaginationReturnValue,
  DataFromQuery,
} from './index.types';

export const usePagination = (
  dataFromQuery: DataFromQuery
): UsePaginationReturnValue => {
  const [paginatedData, setPaginatedData] = useState<PaginatedData | undefined>(
    undefined
  );
  const [numberOfDisplayedItems, setNumberOfDisplayedItems] = useState(0);
  const [totalNumberOfItems, setTotalNumberOfItems] = useState(0);

  useEffect(() => {
    if (dataFromQuery?.pages.length) {
      const edges: PaginatedData = [];
      dataFromQuery.pages.forEach((page) => {
        edges.push(...page.edges);
      });

      setPaginatedData(edges);
      setNumberOfDisplayedItems(edges.length);
      setTotalNumberOfItems(dataFromQuery.pages[0].totalCount);
    }
  }, [dataFromQuery]);

  return {
    paginatedData,
    numberOfDisplayedItems,
    totalNumberOfItems,
  };
};
