import {
  Button,
  Card,
  Grid,
  Stack,
  Typography,
  useCoralBreakpoints,
} from '@krakentech/coral';
import { useIsClient } from '@krakentech/utils';

type EnrichmentCardProps = {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
  buttonActionTitle?: string;
  closeCard: () => void;
  href?: string;
  buttonAction?: () => void;
};

const EnrichmentCard = ({
  icon,
  title,
  subtitle,
  buttonActionTitle = 'Add',
  closeCard,
  href,
  buttonAction,
}: EnrichmentCardProps) => {
  const { isMinMd } = useCoralBreakpoints();
  const isClient = useIsClient();

  const isMinMdBreakpoint = isClient && isMinMd;

  return (
    <Card
      padding="small"
      md={{
        padding: 'medium',
      }}
    >
      <Stack gap="sm" direction="vertical">
        <Grid
          templateColumns="repeat(1, 1fr)"
          alignItems="center"
          gap="sm"
          lg={{
            templateColumns: 'repeat(2, 1fr)',
            gap: 'xs',
          }}
        >
          <Stack
            alignItems="center"
            direction="vertical"
            lg={{ direction: 'horizontal' }}
          >
            {icon}
            <Stack
              alignItems="center"
              direction="vertical"
              gap="none"
              lg={{
                direction: 'vertical',
                alignItems: 'normal',
                gap: 'none',
              }}
            >
              <Typography
                variant="h2"
                {...(!isMinMdBreakpoint && { textAlign: 'center' })}
              >
                {title}
              </Typography>
              <Typography
                variant="body2"
                {...(!isMinMdBreakpoint && { textAlign: 'center' })}
              >
                {subtitle}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            justifyContent="center"
            gap="sm"
            direction="vertical"
            lg={{
              justifyContent: 'flex-end',
            }}
            md={{
              direction: 'horizontal',
              gap: 'md',
            }}
          >
            <Button href={href} onClick={buttonAction}>
              {buttonActionTitle} information
            </Button>
            <Button variant="outlined" onClick={closeCard}>
              Not now
            </Button>
          </Stack>
        </Grid>
      </Stack>
    </Card>
  );
};

export default EnrichmentCard;
