import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';

export const StyledCircleIcon = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: ${theme.spacing.xxl};
    height: ${theme.spacing.xxl};
    border-radius: 100%;
    background-color: ${theme.color.primary.main10};
  `}
`;

export const StyledIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    font-size: ${theme.typography.h3.baseStyles.fontSize};
    color: ${theme.color.primary.main};
  `}
`;
