import {
  Button,
  Container,
  Dialog,
  Stack,
  Typography,
  useCoralBreakpoints,
} from '@krakentech/coral';

type ConfirmEmailUpdateDialogProps = {
  open: boolean;
  currentEmail: string;
  updatedEmail: string;
  onCancel: () => void;
  onSubmit: () => void;
};

const ConfirmEmailUpdateDialog = ({
  open,
  currentEmail,
  updatedEmail,
  onCancel,
  onSubmit,
}: ConfirmEmailUpdateDialogProps) => {
  const { isMaxMd } = useCoralBreakpoints();
  return (
    <Dialog
      open={open}
      ariaLabelledBy={`Confirm-Email-ActionCard`}
      onClose={onCancel}
    >
      <Stack direction="vertical" gap="md">
        <Typography variant="h2" component="h1">
          Confirm email change
        </Typography>
        <Stack direction="vertical" gap="xsSm">
          <FormattedColonSeparatedSentence
            leftValue="Current email"
            rightValue={currentEmail}
          />
          <FormattedColonSeparatedSentence
            leftValue="New email"
            rightValue={updatedEmail || ''}
          />
        </Stack>
        <FormattedColonSeparatedSentence
          leftValue="Note"
          rightValue="You will be logged out and need to log back in using your new email address. Your password will stay the same. Any other account changes you have made will be saved."
        />
        <Stack {...(isMaxMd && { direction: 'vertical' })}>
          <Button fullWidth={true} onClick={onSubmit}>
            Confirm email change
          </Button>
          <Button variant="outlined" fullWidth={true} onClick={onCancel}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

function FormattedColonSeparatedSentence({
  leftValue,
  rightValue,
}: {
  leftValue: string;
  rightValue: string;
}) {
  return (
    <Container>
      <Typography variant="h3" component="span">
        {leftValue}:{' '}
      </Typography>
      <Typography component="span">{rightValue}</Typography>
    </Container>
  );
}

export default ConfirmEmailUpdateDialog;
