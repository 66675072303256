import {
  PaymentPlanInstalmentState,
  PaymentPlanInstalmentAction,
} from './index.types';

export const initialInstalment: PaymentPlanInstalmentState = {
  amount: undefined,
  date: undefined,
  paymentNumber: undefined,
};

export const paymentPlanInstalmentReducer = (
  state: PaymentPlanInstalmentState,
  action: PaymentPlanInstalmentAction
): PaymentPlanInstalmentState => {
  switch (action.type) {
    case 'SET_INSTALMENT':
      return {
        ...state,
        ...action.instalment,
      };
    default:
      return state;
  }
};
