import { useState } from 'react';
import { CoralThemeProvider } from '@krakentech/coral';
import { IconsThemeProvider } from '@krakentech/icons';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { leepTheme, nautilusTheme, pwlTheme, stwTheme } from '@/styles/themes';

import client from '../GraphQLClient';

import { AuthProvider } from './Auth';
import { GlobalMessageProvider } from './GlobalMessage';

type ProvidersProps = {
  children: React.ReactNode;
};

const Providers = ({ children }: ProvidersProps) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
          },
        },
      })
  );

  const themes = {
    leep: leepTheme,
    nautilus: nautilusTheme,
    pwl: pwlTheme,
    stw: stwTheme,
  };

  const theme = process.env.NEXT_PUBLIC_THEME as
    | 'leep'
    | 'nautilus'
    | 'pwl'
    | 'stw';
  const clientTheme = themes[theme];

  const defaultIconProps = {
    size: 40,
    color: clientTheme.overrides?.color?.primary?.main,
  };

  return (
    <QueryClientProvider client={queryClient}>
      <CoralThemeProvider config={clientTheme}>
        <IconsThemeProvider defaultProps={defaultIconProps}>
          <GlobalMessageProvider>
            <AuthProvider client={client}>{children}</AuthProvider>
          </GlobalMessageProvider>
        </IconsThemeProvider>
      </CoralThemeProvider>
    </QueryClientProvider>
  );
};

export default Providers;
