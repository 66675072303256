import { Card, Stack, Typography } from '@krakentech/coral';

import { MutedText } from '@/components';

import { PaymentInfoCardProps } from './index.types';

const PaymentInfoCard = ({
  header,
  amount,
  explanation,
  dataAttributes,
}: PaymentInfoCardProps) => {
  return (
    <Card {...(dataAttributes && { dataAttributes })}>
      <Stack direction="vertical" gap="smMd" alignItems="center">
        <Typography component="h2" variant="h2">
          {header}
        </Typography>

        <Stack direction="vertical" gap="xsSm" alignItems="center">
          <Typography component="div" variant="homepageTitle">
            {amount}
          </Typography>

          {explanation && (
            <MutedText variant="body1" textAlign="center">
              {explanation}
            </MutedText>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};

export default PaymentInfoCard;
