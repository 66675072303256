import { useEffect } from 'react';
import { FormikTextField } from '@krakentech/coral-formik';
import { useFormikContext } from 'formik';

import { MutedText } from '@/components';

import type { PhoneFieldTypeProps } from './index.types';

const PhoneNumberField = ({
  name,
  label,
  isError,
  errorMessage,
  telephoneHelperText = 'Number must start with +44 or 0',
  disabled = false,
}: PhoneFieldTypeProps) => {
  const { setFieldError } = useFormikContext();

  useEffect(() => {
    if (isError) {
      return setFieldError(name, errorMessage);
    }
  }, [isError, setFieldError]);

  return (
    <>
      {/* TODO: Replace with FormTextFieldWithDescription when available */}
      <FormikTextField name={name} label={label} disabled={disabled} />
      <MutedText variant="caption">{telephoneHelperText}</MutedText>
    </>
  );
};

export default PhoneNumberField;
