import { subDays, addMonths, format } from 'date-fns';

import {
  formatFullDateShortMonth,
  formatDayOfTheWeek,
} from './formatters/date';

export const getDateNDaysAgo = (numberOfDaysAgo: number) => {
  const rawDate = subDays(new Date(), numberOfDaysAgo);
  return formatFullDateShortMonth(rawDate);
};

// This function returns the string day of the week (e.g. 'Wednesday')
export const getDayOfTheWeekNDaysAgo = (numberOfDaysAgo: number) => {
  const rawDate = subDays(new Date(), numberOfDaysAgo);
  return formatDayOfTheWeek(rawDate);
};

export const makeNumberTwoDigits = (number: number) => {
  return String(number).padStart(2, '0');
};

// This function returns the next nth day of the month, where n is the dayOfMonth parameter. This only works if dayOfMonth is any number between 1 and 28, inclusive. Any higher than 28 and it will get weird.
export const getNextNthDayOfTheMonth = (
  dayOfMonth: number,
  dateToStartFrom: Date
) => {
  // Get the day, month and year of dateToStartFrom
  const currentDayOfMonth = parseInt(format(dateToStartFrom, 'd'));
  const currentMonth = parseInt(format(dateToStartFrom, 'M'));
  const currentYear = parseInt(format(dateToStartFrom, 'yyyy'));

  // Use the makeNumberTwoDigits function to ensure the month and day are always two digits, so they're padded with a 0 if necessary. Not doing this breaks the page on webkit browsers.
  return dayOfMonth < currentDayOfMonth
    ? addMonths(
        new Date(
          `${currentYear}-${makeNumberTwoDigits(
            currentMonth
          )}-${makeNumberTwoDigits(dayOfMonth)}`
        ),
        1
      )
    : new Date(
        `${currentYear}-${makeNumberTwoDigits(
          currentMonth
        )}-${makeNumberTwoDigits(dayOfMonth)}`
      );
};
