import { Dialog, Stack, Typography } from '@krakentech/coral';

const DirectDebitGuaranteeDialog = ({
  isOpen,
  closeDialog,
}: {
  isOpen: boolean;
  closeDialog: () => void;
}) => {
  return (
    <Dialog
      open={isOpen}
      ariaLabelledBy="directDebitGuarantee"
      onClose={closeDialog}
    >
      <Stack
        direction="vertical"
        gap="xs"
        sm={{
          gap: 'sm',
        }}
      >
        <ul>
          <li>
            <Typography>
              The Guarantee is offered by all banks and building societies that
              accept instructions to pay Direct Debits
            </Typography>
          </li>
          <li>
            <Typography>
              If there are any changes to the amount, date or frequency of your
              Direct Debit the organisation will notify you (normally 10 working
              days) in advance of your account being debited or as otherwise
              agreed. If you request the organisation to collect a payment,
              confirmation of the amount and date will be given to you at the
              time of the request
            </Typography>
          </li>
          <li>
            <Typography>
              If an error is made in the payment of your Direct Debit, by the
              organisation or your bank or building society, you are entitled to
              a full and immediate refund of the amount paid from your bank or
              building society If you receive a refund you are not entitled to,
              you must pay it back when the organisation asks you to
            </Typography>
          </li>
          <li>
            <Typography>
              You can cancel a Direct Debit at any time by simply contacting
              your bank or building society. Written confirmation may be
              required. Please also notify the organisation.
            </Typography>
          </li>
        </ul>
      </Stack>
    </Dialog>
  );
};

export default DirectDebitGuaranteeDialog;
