import NextImage from 'next/image';
import Link from 'next/link';
import { Stack, useCoralBreakpoints } from '@krakentech/coral';
import { Header as CoralHeader } from '@krakentech/coral-organisms';
import { useIsClient } from '@krakentech/utils';

import { useAuth } from '@/components/Providers/Auth';
import { INTERNAL_PATHS } from '@/utils/urls';

import LeepLogo from '../../public/images/logos/leep.png';
import NautilusLogo from '../../public/images/logos/nautilus.svg';
import PwlLogo from '../../public/images/logos/pwl.png';
import StwLogo from '../../public/images/logos/stw.png';
import LogoutButton from '../auth/LogoutButton';
import ContactUsButton from '../ContactUsButton';

import { StyledHeaderContainer, StyledLogoLink } from './index.styled';
import { LogoConfig } from './index.types';

const Header = () => {
  const { isAuthenticated } = useAuth();

  const routes = {
    dashboard: isAuthenticated
      ? INTERNAL_PATHS.HOME.path
      : INTERNAL_PATHS.LOGIN.path,
    contactUs: process.env.NEXT_PUBLIC_HELP_LINK,
  };

  const logos: Record<string, LogoConfig> = {
    nautilus: {
      src: NautilusLogo,
      desktop: {
        width: 240,
        height: 54,
      },
      mobile: {
        width: 124,
        height: 44,
      },
    },
    pwl: {
      src: PwlLogo,
      desktop: {
        width: 240,
        height: 54,
      },
      mobile: {
        width: 125,
        height: 28,
      },
    },
    leep: {
      src: LeepLogo,
      desktop: {
        width: 54,
        height: 54,
      },
      mobile: {
        width: 44,
        height: 44,
      },
    },
    stw: {
      src: StwLogo,
      desktop: {
        width: 90,
        height: 54,
      },
      mobile: {
        width: 75,
        height: 44,
      },
    },
  };

  const brand = process.env.NEXT_PUBLIC_THEME as string;
  const logoAlt = process.env.NEXT_PUBLIC_COMPANY_NAME ?? '';
  const { isMaxMd } = useCoralBreakpoints();
  const isClient = useIsClient();

  const isMaxMdBreakpoint = isClient && isMaxMd;

  return (
    <StyledHeaderContainer>
      <CoralHeader>
        <Stack justifyContent="space-between" alignItems="center" fullWidth>
          <Link href={routes.dashboard} passHref legacyBehavior>
            <StyledLogoLink>
              <Stack alignItems="center">
                <NextImage
                  priority
                  src={logos[brand].src}
                  alt={logoAlt}
                  width={
                    isMaxMdBreakpoint
                      ? logos[brand].mobile.width
                      : logos[brand].desktop.width
                  }
                  height={
                    isMaxMdBreakpoint
                      ? logos[brand].mobile.height
                      : logos[brand].desktop.height
                  }
                />
              </Stack>
            </StyledLogoLink>
          </Link>

          <Stack gap="sm">
            <ContactUsButton />
            {isAuthenticated && <LogoutButton />}
          </Stack>
        </Stack>
      </CoralHeader>
    </StyledHeaderContainer>
  );
};

export default Header;
