import { useEffect } from 'react';
import {
  Typography,
  Stack,
  Grid,
  useCoralBreakpoints,
} from '@krakentech/coral';
import { useFormikContext } from 'formik';

import PaymentScheduleCard from '@/components/billingAndPayment/PaymentScheduleCard';
import { Body1Skeleton } from '@/components/Skeletons';
import { PAYMENT_PLAN_BILLING_CONFIG } from '@/consts/directDebit';
import { useDirectDebitContext } from '@/context';
import { useBalance } from '@/hooks/accounts/useBalance';
import { useFirstPaymentPlanInstalment } from '@/hooks/billsAndPayments/useFirstPaymentPlanInstalment';
import { useLastPaymentPlanInstalment } from '@/hooks/billsAndPayments/useLastPaymentPlanInstalment';
import { PaymentFrequencyFormValues } from '@/types/directDebit';
import { getNextNthDayOfTheMonth } from '@/utils/date';
import {
  formatCurrency,
  formatCurrencyWithCreditOrDebitIndicator,
} from '@/utils/formatters/currency';
import { formatFullDateShortMonth } from '@/utils/formatters/date';
import { cardinalNumberToOrdinalNumber } from '@/utils/formatters/number';

import FixedScheduleError from '../FixedScheduleError';
import PaymentCardLoader from '../PaymentCardLoader';

const PaymentPlanContent = () => {
  const { values } = useFormikContext<PaymentFrequencyFormValues>();
  const {
    setFirstPossibleFixedSchedulePaymentDate,
    firstPossibleFixedSchedulePaymentDate,
    setPaymentPlanFirstInstalment,
    setPaymentPlanLastInstalment,
  } = useDirectDebitContext();
  const {
    isLoading: isLoadingFirstInstalment,
    isError: isErrorFirstInstalment,
    data: firstInstalment,
    refetch: refetchFirstInstalment,
  } = useFirstPaymentPlanInstalment({
    firstPreferredPaymentDate: values.paymentDay?.value
      ? getNextNthDayOfTheMonth(values.paymentDay?.value, new Date())
      : '',
  });
  const {
    isLoading: isLoadingLastInstalment,
    isError: isErrorLastInstalment,
    data: lastInstalment,
    refetch: refetchLastInstalment,
  } = useLastPaymentPlanInstalment({
    firstPreferredPaymentDate: values.paymentDay?.value
      ? getNextNthDayOfTheMonth(values.paymentDay?.value, new Date())
      : '',
  });
  const {
    isLoading: isLoadingBalance,
    isError: isErrorBalance,
    data: balance,
  } = useBalance();

  useEffect(() => {
    if (firstInstalment && values.paymentDay) {
      // Get what the user's next desired payment date is, based off their selected payment day. E.g. if today is January 1st 2024 and they want to pay on the 5th of each month, their nextDesiredPaymentDate is January 5th 2024
      const nextDesiredPaymentDate = getNextNthDayOfTheMonth(
        values.paymentDay.value,
        new Date(firstInstalment.date)
      );

      // Save this value to local state
      setFirstPossibleFixedSchedulePaymentDate(nextDesiredPaymentDate);
    }
  }, [
    firstInstalment,
    values.paymentDay,
    setFirstPossibleFixedSchedulePaymentDate,
  ]);

  // Save first instalment amount and date to context to be used in the payment plan summary on the next page
  useEffect(() => {
    if (firstInstalment && firstPossibleFixedSchedulePaymentDate) {
      setPaymentPlanFirstInstalment({
        type: 'SET_INSTALMENT',
        instalment: {
          amount: firstInstalment.amount,
          date: new Date(firstPossibleFixedSchedulePaymentDate),
          paymentNumber: firstInstalment.paymentNumber,
        },
      });
    }
  }, [
    firstInstalment,
    firstPossibleFixedSchedulePaymentDate,
    setPaymentPlanFirstInstalment,
  ]);

  // Save last instalment amount, date and paymentNumber to context to be used in the payment plan summary on the next page
  useEffect(() => {
    if (lastInstalment) {
      setPaymentPlanLastInstalment({
        type: 'SET_INSTALMENT',
        instalment: lastInstalment,
      });
    }
  }, [lastInstalment, setPaymentPlanLastInstalment]);

  if (isLoadingFirstInstalment || isLoadingLastInstalment || isLoadingBalance) {
    return <LoadingState />;
  }

  if (isErrorFirstInstalment || isErrorLastInstalment || isErrorBalance) {
    return (
      <FixedScheduleError
        refetch={() => {
          refetchFirstInstalment();
          refetchLastInstalment();
        }}
      />
    );
  }

  if (!firstPossibleFixedSchedulePaymentDate) {
    return null;
  }

  // If the last instalment is the first payment (meaning it has a paymentNumber of 1), then it's the same instalment as the first one. So we don't display it. The reason this happens is if the user wants to set up a payment plan at the end of the financial year, so we only have one payment left for them in the current financial year
  const notAtEndOfFinancialYear = lastInstalment?.paymentNumber !== 1;

  const numberOfDesktopColumns =
    values.paymentDay && notAtEndOfFinancialYear ? 2 : 1;

  const billingConfig = process.env.NEXT_PUBLIC_PAYMENT_PLAN_BILLING_CONFIG;

  const getPaymentSplitExplanationCopy = () => {
    if (notAtEndOfFinancialYear) {
      return `Your ${billingConfig === PAYMENT_PLAN_BILLING_CONFIG.FIRST_MORE ? 'first' : 'final'} payment may be ${billingConfig === PAYMENT_PLAN_BILLING_CONFIG.LAST_LESS ? 'lower' : 'higher'} to ensure payments are evenly split`;
    } else {
      return 'As you only have 1 instalment left, this will be your only payment.';
    }
  };

  // This probably won't happen but just in case
  if (!values.paymentDay) {
    return null;
  }

  return (
    <>
      <Typography>
        We take your account balance (
        {formatCurrencyWithCreditOrDebitIndicator(balance, false)}) and divide
        it equally into the remaining instalments until{' '}
        {formatFullDateShortMonth(lastInstalment.date)}.{' '}
        {getPaymentSplitExplanationCopy()}
      </Typography>

      <Grid
        templateColumns={`repeat(1, 1fr)`}
        gap="md"
        md={{
          templateColumns: `repeat(${numberOfDesktopColumns}, 1fr)`,
        }}
      >
        <Grid.Item>
          <PaymentScheduleCard
            title={
              billingConfig === PAYMENT_PLAN_BILLING_CONFIG.FIRST_MORE ||
              !notAtEndOfFinancialYear
                ? 'First payment'
                : 'Monthly payments'
            }
          >
            <Stack direction="vertical" alignItems="center">
              <Typography textAlign="center" variant="h1" component="p">
                {formatCurrency(firstInstalment.amount)}
              </Typography>
              <Typography textAlign="center">
                {billingConfig !== PAYMENT_PLAN_BILLING_CONFIG.FIRST_MORE
                  ? `will be collected every month on the ${cardinalNumberToOrdinalNumber(
                      values.paymentDay.value
                    )} from `
                  : 'will be collected on '}
                <strong>
                  {formatFullDateShortMonth(
                    firstPossibleFixedSchedulePaymentDate
                  )}
                </strong>
              </Typography>
            </Stack>
          </PaymentScheduleCard>
        </Grid.Item>

        {notAtEndOfFinancialYear && (
          <Grid.Item>
            <Stack fullHeight>
              {/* values.paymentDay will always be defined at this point, but naughty Typescript */}
              <PaymentScheduleCard
                title={
                  billingConfig === PAYMENT_PLAN_BILLING_CONFIG.FIRST_MORE
                    ? 'Monthly payments'
                    : 'Final payment'
                }
              >
                <Stack direction="vertical" alignItems="center">
                  <Typography textAlign="center" variant="h1" component="p">
                    {formatCurrency(lastInstalment.amount)}
                  </Typography>
                  <Typography textAlign="center">
                    {billingConfig === PAYMENT_PLAN_BILLING_CONFIG.FIRST_MORE
                      ? `will be collected every month on the ${cardinalNumberToOrdinalNumber(
                          values.paymentDay.value
                        )} until `
                      : 'your final payment will be collected on '}
                    <strong>
                      {formatFullDateShortMonth(lastInstalment.date)}
                    </strong>
                  </Typography>
                </Stack>
              </PaymentScheduleCard>
            </Stack>
          </Grid.Item>
        )}
      </Grid>
    </>
  );
};

const LoadingState = () => {
  const { isMaxMd } = useCoralBreakpoints();

  return (
    <>
      <Body1Skeleton width={isMaxMd ? undefined : 400} />

      <Grid
        templateColumns={`repeat(2, 1fr)`}
        gap="md"
        lg={{ templateColumns: 'repeat(1, 1fr)' }}
      >
        <Grid.Item>
          <PaymentCardLoader title="First payment" />
        </Grid.Item>
        <Grid.Item>
          <PaymentCardLoader title="Following payments" />
        </Grid.Item>
      </Grid>
    </>
  );
};

export default PaymentPlanContent;
