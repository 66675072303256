import { useKrakenQuery } from '@/components/GraphQLClient';
import { useAuth } from '@/components/Providers/Auth';

import { commsAndMarketingPreferencesSchema } from '../validations';

import CommsAndMarketingPreferences from './CommsAndMarketingPreferences.graphql';

export const useCommsAndMarketingPreferences = ({
  accountNumber,
}: {
  accountNumber?: string;
}) => {
  const { isAuthenticated } = useAuth();

  return useKrakenQuery({
    queryKey: ['commsAndMarketingPreferences'],
    query: CommsAndMarketingPreferences,
    select: (data: any) => data,
    variables: { accountNumber },
    validateFn(response) {
      return commsAndMarketingPreferencesSchema.validate(response);
    },
    enabled: Boolean(isAuthenticated && accountNumber),
    cacheTime: 0,
  });
};
