import { useKrakenInfiniteQuery } from '@/components/GraphQLClient';
import { useAuth } from '@/components/Providers/Auth';

import { paymentsSuccessSchema } from '../validations';

import Payments from './Payments.graphql';

export const usePayments = ({
  accountNumber,
  ledgerId,
  first = 20,
}: {
  accountNumber?: string;
  ledgerId?: number;
  first?: number;
}) => {
  const { isAuthenticated } = useAuth();

  return useKrakenInfiniteQuery({
    queryKey: ['payments'],
    query: Payments,
    variables: { accountNumber, ledgerId, first },
    // @ts-expect-error TODO: fix type error
    async validateFn(response) {
      const validatedDate = await paymentsSuccessSchema.validate(response);
      return validatedDate.account.transactions;
    },
    enabled: Boolean(isAuthenticated && accountNumber && ledgerId),
  });
};
