import { Card, Typography, Stack } from '@krakentech/coral';
import { FormikTextField } from '@krakentech/coral-formik';

import { MutedText, PhoneNumberField } from '@/components';

import type { ContactDetailsFormTypeProps } from './index.types';

const ContactDetailsForm = ({
  isLandlineError,
  isMobileError,
  readOnly,
}: ContactDetailsFormTypeProps) => {
  return (
    <Card>
      <fieldset disabled={readOnly} aria-disabled={readOnly}>
        <Stack direction="vertical" gap="smMd">
          <Typography variant="h2">Your contact information</Typography>
          <MutedText variant="body1">
            This will be used for your login and receiving messages and bills
          </MutedText>
          <Stack direction="vertical" gap="sm">
            <Typography variant="h3">Email</Typography>
            <FormikTextField
              name="email"
              type="email"
              label="Email address"
              disabled={readOnly}
            />
          </Stack>
          <Stack direction="vertical" gap="xs">
            <Typography variant="h3">Telephone number</Typography>
            <MutedText variant="body1">
              Please enter at least one number in the following fields so we can
              send water supply updates, information about our services and
              respond to your enquiries, when necessary.
            </MutedText>
          </Stack>
          <Stack direction="vertical" gap="xs">
            <PhoneNumberField
              name="mobile"
              label="Mobile number"
              errorMessage="Please enter a valid mobile number"
              isError={isMobileError}
              disabled={readOnly}
            />
            <PhoneNumberField
              name="landline"
              label="Landline number"
              errorMessage="Please enter a valid landline number"
              isError={isLandlineError}
              disabled={readOnly}
            />
          </Stack>
        </Stack>
      </fieldset>
    </Card>
  );
};

export default ContactDetailsForm;
