import styled, { css } from 'styled-components';

export const StyledErrorLink = styled.span`
  ${({ theme }) => css`
    a {
      color: ${theme.color.text.main};

      &:hover {
        color: ${theme.color.action.error};
      }
    }
  `}
`;
