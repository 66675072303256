import { ReactElement, ReactNode, useEffect } from 'react';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { datadogRum } from '@datadog/browser-rum';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { GlobalCss, ToastContainer } from '@krakentech/coral';
import { storyblokInit, apiPlugin } from '@storyblok/react';
import { DehydratedState, Hydrate } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Analytics as VercelAnalytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { NextPage } from 'next';
import { appWithTranslation } from 'next-i18next';
import { clarity } from 'react-microsoft-clarity';

import { PageLayout, Analytics, Sentry } from '@/components';
import Providers from '@/components/Providers';
import { CustomGlobalStyles } from '@/styles/CustomGlobalStyles.styled';
import { getSavedUserPreference, hasAnalyticsConsent } from '@/utils/cookies';
import { getEnvironment } from '@/utils/environment';

config.autoAddCss = false;

storyblokInit({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_PREVIEW_ACCESS_TOKEN,
  use: [apiPlugin],
});

type PageProps = {
  dehydratedState?: DehydratedState;
};

export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<
  P,
  IP
> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout<P> = AppProps<P> & {
  Component: NextPageWithLayout<P>;
};

let isDatadogInitialized = false;
let isClarityInitialized = false;

function App({ Component, pageProps }: AppPropsWithLayout<PageProps>) {
  const router = useRouter();
  const environment = getEnvironment();

  useEffect(() => {
    const userPreference = getSavedUserPreference();

    if (
      environment !== 'development' &&
      userPreference &&
      hasAnalyticsConsent(userPreference)
    ) {
      const clarityTestProjectID =
        process.env.NEXT_PUBLIC_MS_CLARITY_TEST_PROJECT_ID;
      const datadogApplicationID =
        process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID;
      const datadogClientToken = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN;
      const theme = process.env.NEXT_PUBLIC_THEME;

      if (clarityTestProjectID && !isClarityInitialized) {
        // Initialize MS Clarity in test
        clarity.init(clarityTestProjectID);
        isClarityInitialized = true;
      }

      if (
        datadogApplicationID &&
        datadogClientToken &&
        theme &&
        !isDatadogInitialized
      ) {
        // Initialize Datadog RUM
        datadogRum.init({
          // version: '1.0.0',
          applicationId: datadogApplicationID,
          clientToken: datadogClientToken,
          site: 'datadoghq.eu',
          service: `${theme}`, // Use service to split stats by client in DD
          env: environment === 'preview' ? 'test' : environment, // Tags the environment in Datadog, e.g. production, or (preview & test) as test.
          sessionSampleRate: 100, // Percentage of RUM sessions to track
          sessionReplaySampleRate: 0, // Percentage of sessions to record for replay (0 to disable)
          trackUserInteractions: true, // Collects user interactions/clicks
          enablePrivacyForActionName: true, // Anonymize the names of user actions eg clicks, taps etc https://docs.datadoghq.com/real_user_monitoring/session_replay/browser/privacy_options/#mask-action-names
          trackResources: true,
          trackLongTasks: true,
          defaultPrivacyLevel: 'mask-user-input', // Masks user input from session replay, currently disabled via sessionReplaySampleRate: 0
          enableExperimentalFeatures: ['feature_flags'], // This allows viewing FF values in DD
        });
        isDatadogInitialized = true;
      }
    }
  }, []);

  const getLayout = Component.getLayout || ((page: ReactElement) => page);

  return (
    <>
      <Providers>
        <Analytics />
        <Sentry />
        <ToastContainer duration={6000} />
        <GlobalCss />
        <CustomGlobalStyles />
        <PageLayout>
          <Hydrate state={pageProps.dehydratedState}>
            {getLayout(<Component {...pageProps} />)}
            <VercelAnalytics />
            <SpeedInsights route={router.pathname} />
          </Hydrate>
          <ReactQueryDevtools initialIsOpen={false} />
        </PageLayout>
      </Providers>
    </>
  );
}

export default appWithTranslation(App);
