import { useState, useMemo } from 'react';
import {
  Typography,
  Stack,
  Checkbox,
  Tooltip,
  Grid,
  Button,
  useCoralBreakpoints,
} from '@krakentech/coral';
import { useCookiePreferencesContext } from '@krakentech/coral-organisms';

import { CookiePreferenceType } from '@/utils/cookies';

import { cookies } from '../../content';

const CookieSelection = ({
  onUpdatePreference,
}: {
  onUpdatePreference: (newPreference: CookiePreferenceType) => void;
}) => {
  const cookiesCheckedDefaults = useMemo(() => {
    return cookies.reduce((accumulator: any, cookie) => {
      accumulator[cookie.id] = cookie.isCheckedByDefault;
      return accumulator;
    }, {});
  }, []);

  const [cookiesChecked, setCookiesChecked] = useState(cookiesCheckedDefaults);
  const { isMaxLg } = useCoralBreakpoints();
  const { setDisplayMode } = useCookiePreferencesContext();

  const onSelectCookies = () => {
    if (cookiesChecked.analytics) {
      onUpdatePreference('analytics');
    } else {
      onUpdatePreference('essential');
    }

    setDisplayMode('closed');
  };

  return (
    <Grid
      templateColumns="repeat(1, 1fr)"
      alignItems="center"
      gap="md"
      lg={{ templateColumns: 'repeat(3, 1fr)' }}
      xl={{ templateColumns: 'repeat(5, 1fr)' }}
    >
      <Grid.Item colSpan={1} lg={{ colSpan: 2 }} xl={{ colSpan: 4 }}>
        <Stack direction="vertical" gap="sm">
          <Typography variant="h4" component="h2">
            Choose your cookies 🍪
          </Typography>

          <Typography>
            Some necessary cookies are always saved - our site would break
            without them. Amongst other things, they keep our servers running
            when we&apos;re really popular and protect you from hackers.
          </Typography>

          <Stack>
            {cookies.map(
              ({ id, label, disabled, tooltip, isCheckedByDefault }) => {
                return (
                  <Stack key={id} alignItems="baseline" gap="sm">
                    <Checkbox
                      name={id}
                      label={label}
                      onChange={({ target: { checked } }) =>
                        setCookiesChecked({
                          ...cookiesChecked,
                          [id]: checked,
                        })
                      }
                      checked={isCheckedByDefault}
                      disabled={disabled}
                    />
                    <Tooltip title={tooltip} placement="top" />
                  </Stack>
                );
              }
            )}
          </Stack>
        </Stack>
      </Grid.Item>

      <Grid.Item colSpan={1}>
        <Stack justifyContent="center">
          <Button fullWidth={isMaxLg} onClick={onSelectCookies}>
            Looks good
          </Button>
        </Stack>
      </Grid.Item>
    </Grid>
  );
};

export default CookieSelection;
