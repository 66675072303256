import { Button, Dialog, Link, Stack, Typography } from '@krakentech/coral';

import { formatCurrencyWithoutPence } from '@/utils/formatters/currency';
import { INTERNAL_PATHS } from '@/utils/urls';

import { DebitTooHighDialogTypeProps } from './index.types';

const DebitTooHighDialog = ({
  isOpen,
  closeDialog,
  dialogTitle,
  maxDebitAmount,
  actionBlocked,
  ariaLabelAction,
}: DebitTooHighDialogTypeProps) => {
  return (
    <Dialog
      open={isOpen}
      ariaLabelledBy={`${ariaLabelAction}ActionCard`}
      onClose={closeDialog}
    >
      <Stack direction="vertical" gap="lg">
        <Stack direction="vertical" gap="xsSm">
          <Typography variant="h2" component="h1">
            {dialogTitle}
          </Typography>
          <Typography>
            As your balance is in debit of more than{' '}
            {formatCurrencyWithoutPence(maxDebitAmount)}, you can&apos;t{' '}
            {actionBlocked}. To reduce your balance, please{' '}
            <Link href={INTERNAL_PATHS.MAKE_ONLINE_PAYMENT[0].path}>
              make an online payment
            </Link>{' '}
            or{' '}
            <Link href={INTERNAL_PATHS.BILLS.path}>check your latest bill</Link>{' '}
            for more payment options.
          </Typography>
          <Typography>
            Alternatively, please{' '}
            <Link
              href={process.env.NEXT_PUBLIC_HELP_LINK as string}
              target="_blank"
            >
              get in touch
            </Link>{' '}
            so we can help you complete this journey.
          </Typography>
        </Stack>
        <Button onClick={closeDialog}>Close</Button>
      </Stack>
    </Dialog>
  );
};

export default DebitTooHighDialog;
