import styled, { css } from 'styled-components';

import { baseColors } from '../../styles/themes/common';

export const StyledTable = styled.table<{ oddRowsShorter: boolean }>`
  ${({ theme, oddRowsShorter }) => css`
    border-collapse: collapse;
    width: 100%;

    tbody {
      tr:nth-child(odd) {
        background-color: ${baseColors.main};

        ${oddRowsShorter &&
        css`
          td {
            padding: ${theme.spacing.xxs} ${theme.spacing.mdLg};

            @media (min-width: ${theme.breakpoints.md}px) {
              padding: ${theme.spacing.xxs} ${theme.spacing.lg};
            }
          }
        `}
      }
    }
  `}
`;

export const StyledColumnHeading = styled.th<{ width: number }>`
  ${({ theme, width }) => css`
    padding: 0 ${theme.spacing.mdLg} ${theme.spacing.xs};
    text-align: left;
    color: ${theme.color.text.dark};

    ${width &&
    css`
      width: ${width}%;
    `}
  `}
`;

export const StyledBodyTd = styled.td`
  ${({ theme }) => css`
    padding: ${theme.spacing.smMd} ${theme.spacing.mdLg};
    vertical-align: top;
  `}
`;
