import {
  Card,
  Grid,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useCoralBreakpoints,
} from '@krakentech/coral';
import { IconQuestion } from '@krakentech/icons';

import { Body2Skeleton, H2Skeleton } from '@/components';
import { PaymentInfoCard } from '@/components';
import { useBalance } from '@/hooks/accounts/useBalance';
import { useCurrentActivePaymentSchedule } from '@/hooks/billsAndPayments/useCurrentActivePaymentSchedule';
import { useHasCurrentDirectDebitInstruction } from '@/hooks/billsAndPayments/useHasCurrentDirectDebitInstruction';
import { useMovingOutStatus } from '@/hooks/moveHome/useMovingOutStatus';
import { useFeatureFlag } from '@/hooks/utils/useFeatureFlags';
import { FeatureNames } from '@/types/features';
import {
  isValidDirectDebitInstruction,
  isFailedDirectDebitInstruction,
} from '@/utils/directDebit';
import { formatCurrencyWithoutMinusSymbol } from '@/utils/formatters/currency';
import { getPaymentFrequency } from '@/utils/paymentSchedule';

import PageError from '../../PageError';

const BalanceCard = () => {
  const {
    data: balance,
    isLoading: isLoadingBalance,
    error: balanceError,
  } = useBalance();
  const {
    data: currentPaymentScheduleData,
    isLoading: isLoadingCurrentPaymentSchedule,
    error: currentPaymentScheduleError,
  } = useCurrentActivePaymentSchedule();
  const {
    isLoading: isLoadingHasCurrentDirectDebitInstruction,
    error: hasCurrentDirectDebitInstructionError,
    data: currentDirectDebitInstruction,
  } = useHasCurrentDirectDebitInstruction();
  const {
    isMovingOut,
    hasMovedOut,
    isMovingOutStatusLoading,
    isMovingOutStatusError,
  } = useMovingOutStatus();

  const showDirectDebit = useFeatureFlag(FeatureNames.DirectDebit);

  if (
    isLoadingBalance ||
    isLoadingCurrentPaymentSchedule ||
    isLoadingHasCurrentDirectDebitInstruction ||
    isMovingOutStatusLoading
  ) {
    return <LoadingState />;
  }

  if (
    balanceError ||
    currentPaymentScheduleError ||
    hasCurrentDirectDebitInstructionError ||
    isMovingOutStatusError
  ) {
    return <PageError />;
  }

  const balanceInDebit = balance < 0;

  const getPaymentScheduleCopy = () => {
    // If there is no payment schedule returned, then we don't have enough data to display anything. This shouldn't happen on prod but can happen on test
    if (!currentPaymentScheduleData) {
      return;
    }

    const {
      node: {
        isVariablePaymentAmount,
        paymentFrequency,
        paymentFrequencyMultiplier,
      },
    } = currentPaymentScheduleData;

    const hasValidDirectDebitInstruction = isValidDirectDebitInstruction(
      currentDirectDebitInstruction?.node
    );
    const hasFailedDirectDebitInstruction = isFailedDirectDebitInstruction(
      currentDirectDebitInstruction?.node
    );

    const isInMovingProcess = isMovingOut || hasMovedOut;

    if (isVariablePaymentAmount) {
      if (hasValidDirectDebitInstruction) {
        if (balanceInDebit) {
          return 'Your balance is collected in full via Direct Debit after your bill has been issued';
        } else {
          return 'Your balance is in good shape - no payment needed';
        }
      } else {
        if (balanceInDebit && isInMovingProcess) {
          return 'Make a payment to clear your balance';
        } else if (isInMovingProcess) {
          return 'Your account is in good shape - no payment needed';
        } else if (balanceInDebit) {
          // If the user's most recent DDI failed, they won't be able to create a new DDI via the consumer site, so telling them to set one up when they can't right now is pretty weird
          return `Please ${!hasFailedDirectDebitInstruction ? 'set up a Direct Debit or ' : ''}make a payment to clear your balance`;
        } else {
          return 'You pay when you get your bill';
        }
      }
    } else {
      return `You're paying into your account every ${getPaymentFrequency(
        paymentFrequency,
        paymentFrequencyMultiplier
      )}${hasValidDirectDebitInstruction ? ' via Direct Debit' : ''}`;
    }
  };

  const BalanceCardHeader = () => (
    <Stack component="span">
      <Typography component="span" variant="h2">
        Account Balance
      </Typography>

      <Tooltip
        title="If your balance has 'debit' displayed, this is how much you owe us. If 'credit' is displayed, this amount will go towards your next bill"
        theme="dark"
        zIndex={1}
      >
        <IconQuestion size={20} />
      </Tooltip>
    </Stack>
  );

  const BalanceCardAmount = () => (
    <Stack
      gap="xs"
      alignItems="baseline"
      justifyContent="center"
      component="span"
    >
      <Typography variant="homepageTitle" component="span">
        {formatCurrencyWithoutMinusSymbol(balance)}
      </Typography>

      {balance > 0 && (
        <Typography variant="body2" color="success" component="span">
          (credit)
        </Typography>
      )}

      {balanceInDebit && (
        <Typography variant="body2" color="error" component="span">
          (debit)
        </Typography>
      )}
    </Stack>
  );

  return (
    <PaymentInfoCard
      header={<BalanceCardHeader />}
      amount={<BalanceCardAmount />}
      {...(showDirectDebit && { explanation: getPaymentScheduleCopy() })}
    />
  );
};

const LoadingState = () => {
  const { isMaxMd } = useCoralBreakpoints();

  return (
    <Grid.Item>
      <Card fullHeight padding="large">
        <Stack direction="vertical" gap="smMd" alignItems="center">
          <H2Skeleton width={isMaxMd ? 200 : 250} />

          <Skeleton
            variant="rounded"
            width={isMaxMd ? undefined : 250}
            height={60}
          />

          <Body2Skeleton width={isMaxMd ? undefined : 250} />
        </Stack>
      </Card>
    </Grid.Item>
  );
};

export default BalanceCard;
