import { PropsWithChildren, createContext, useContext, useState } from 'react';

import { YourAccountFormValues } from '@/components/forms/YourAccountForm/index.types';
import { SeparatedDate } from '@/types/date';

export type AccountUserName = {
  givenName: string;
  familyName: string;
};

export type BlackholeUser = AccountUserName & {
  id: string;
};

export enum USER_REGISTRATION_JOURNEY_KEY_OPTIONS {
  FORGOTTEN_EMAIL = 'FORGOTTEN_EMAIL',
  REGISTER_FOR_ONLINE_ACCOUNT = 'REGISTER_FOR_ONLINE_ACCOUNT',
}

export type UserRegistrationInitialValues = {
  initialAccountNumber?: string;
  initialPostcode?: string;
  initialSurname?: string;
  initialDateOfBirth?: SeparatedDate;
  initialMobileNumber?: string;
  initialScopedToken?: string;
  initialSelectedAccountUserName?: AccountUserName;
  initialSelectedUserId?: string;
  initialAccountUserEmailAddress?: string | null;
  initialUserRegistrationSuccessful?: boolean;
  initialHasMobileNumber?: boolean;
  initialHasDateOfBirth?: boolean;
  initialBlackholeUsers?: Array<BlackholeUser>;
};

export type UserRegistrationContextReturnValue = YourAccountFormValues & {
  setAccountFields: ({
    accountNumber,
    postcode,
    surname,
  }: YourAccountFormValues) => void;
  mobileNumber: string;
  setMobileNumber: (mobileNumber: string) => void;
  dateOfBirth: SeparatedDate;
  setDateOfBirth: (dateOfBirth: SeparatedDate) => void;
  scopedToken: string;
  setScopedToken: (token: string) => void;
  selectedAccountUserName: AccountUserName;
  setSelectedAccountUserName: (accountUser: AccountUserName) => void;
  selectedUserId: string;
  setSelectedUserId: (userId: string) => void;
  accountUserEmailAddress?: string | null;
  setAccountUserEmailAddress: (email?: string | null) => void;
  userRegistrationSuccessful: boolean;
  setUserRegistrationSuccessful: (success: boolean) => void;
  hasMobileNumber: boolean;
  setHasMobileNumber: (hasMobileNumber: boolean) => void;
  hasDateOfBirth: boolean;
  setHasDateOfBirth: (hasDateOfBirth: boolean) => void;
  blackholeAccountUsers: Array<BlackholeUser>;
  setBlackholeAccountUsers: (
    blackholeAccountUsers: Array<BlackholeUser>
  ) => void;
};

export const UserRegistrationContext = createContext(
  {} as UserRegistrationContextReturnValue
);

export const UserRegistrationContextProvider = ({
  children,
  initialAccountNumber = '',
  initialPostcode = '',
  initialSurname = '',
  initialDateOfBirth = { day: '', month: '', year: '' },
  initialMobileNumber = '',
  initialScopedToken = '',
  initialSelectedAccountUserName = { givenName: '', familyName: '' },
  initialSelectedUserId = '',
  initialAccountUserEmailAddress = undefined,
  initialUserRegistrationSuccessful = false,
  initialHasMobileNumber = false,
  initialHasDateOfBirth = false,
  initialBlackholeUsers = [],
}: PropsWithChildren & UserRegistrationInitialValues) => {
  const [accountNumber, setAccountNumber] = useState(initialAccountNumber);
  const [postcode, setPostcode] = useState(initialPostcode);
  const [surname, setSurname] = useState(initialSurname);
  const [dateOfBirth, setDateOfBirth] = useState(initialDateOfBirth);
  const [mobileNumber, setMobileNumber] = useState(initialMobileNumber);
  const [scopedToken, setScopedToken] = useState(initialScopedToken);
  const [selectedAccountUserName, setSelectedAccountUserName] =
    useState<AccountUserName>(initialSelectedAccountUserName);
  const [selectedUserId, setSelectedUserId] = useState(initialSelectedUserId);
  const [accountUserEmailAddress, setAccountUserEmailAddress] = useState(
    initialAccountUserEmailAddress
  );
  const [userRegistrationSuccessful, setUserRegistrationSuccessful] = useState(
    initialUserRegistrationSuccessful
  );
  const [hasMobileNumber, setHasMobileNumber] = useState<boolean>(
    initialHasMobileNumber
  );
  const [hasDateOfBirth, setHasDateOfBirth] = useState<boolean>(
    initialHasDateOfBirth
  );
  const [blackholeAccountUsers, setBlackholeAccountUsers] = useState<
    Array<BlackholeUser>
  >(initialBlackholeUsers);

  const setAccountFields = ({
    accountNumber,
    postcode,
    surname,
  }: YourAccountFormValues) => {
    setAccountNumber(accountNumber);
    setPostcode(postcode);
    setSurname(surname);
  };

  return (
    <UserRegistrationContext.Provider
      value={{
        accountNumber,
        postcode,
        surname,
        setAccountFields,
        dateOfBirth,
        setDateOfBirth,
        mobileNumber,
        setMobileNumber,
        scopedToken,
        setScopedToken,
        selectedAccountUserName,
        setSelectedAccountUserName,
        selectedUserId,
        setSelectedUserId,
        accountUserEmailAddress,
        setAccountUserEmailAddress,
        userRegistrationSuccessful,
        setUserRegistrationSuccessful,
        hasMobileNumber,
        setHasMobileNumber,
        hasDateOfBirth,
        setHasDateOfBirth,
        blackholeAccountUsers,
        setBlackholeAccountUsers,
      }}
    >
      {children}
    </UserRegistrationContext.Provider>
  );
};

export const useUserRegistrationContext = () => {
  const context = useContext(UserRegistrationContext);
  if (context === undefined) {
    throw new Error(
      'useUserRegistrationContext must be used within a UserRegistrationContextProvider'
    );
  }
  return context;
};
