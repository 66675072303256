import { useKrakenQuery } from '@/components/GraphQLClient';
import { useAuth } from '@/components/Providers/Auth';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';
import { makeDateKrakenFriendly } from '@/utils/formatters/date';

import { paymentPlanInstalmentSchema } from '../validations';

import LastPaymentPlanInstalment from './LastPaymentPlanInstalment.graphql';

export const useLastPaymentPlanInstalment = ({
  firstPreferredPaymentDate,
}: {
  firstPreferredPaymentDate: Date | string;
}) => {
  const { isAuthenticated } = useAuth();

  const { accountNumber } = useAccountNumberContext();

  // If the firstPreferredPaymentDate is a date, it needs to be converted to a Kraken-friendly date string
  const preferredPaymentStartDate =
    typeof firstPreferredPaymentDate === 'string'
      ? firstPreferredPaymentDate
      : makeDateKrakenFriendly(firstPreferredPaymentDate);

  return useKrakenQuery({
    queryKey: ['lastPaymentPlanInstalment', preferredPaymentStartDate],
    query: LastPaymentPlanInstalment,
    select: (data: any) =>
      data.proposedDirectDebitPaymentSchedule.paymentAmounts.edges[0].node,
    variables: {
      accountNumber,
      firstPreferredPaymentDate: preferredPaymentStartDate,
    },
    validateFn(response) {
      return paymentPlanInstalmentSchema.validate(response);
    },
    enabled: Boolean(
      isAuthenticated &&
        accountNumber &&
        typeof firstPreferredPaymentDate !== 'string' &&
        preferredPaymentStartDate
    ),
  });
};
