import { Card, Stack, Typography } from '@krakentech/coral';
import { Form, Formik, useFormikContext } from 'formik';
import { object, string } from 'yup';

import {
  FormContainer,
  FormTextFieldWithDescription,
  FormSubmitButton,
  EmailAlreadyRegisteredAlert,
  NotEnoughInfoAlert,
  IdentifyAndVerifyGenericErrorAlert,
} from '@/components';
import {
  accountNumberValidationMessage,
  postcodeRegex,
  postcodeValidationMessage,
} from '@/consts/validation';
import {
  USER_REGISTRATION_JOURNEY_KEY_OPTIONS,
  useUserRegistrationContext,
} from '@/context/UserRegistrationContext';
import { useAccountIdentification } from '@/hooks/auth/useAccountIdentification';
import { useUserRegistrationStatus } from '@/hooks/auth/useUserRegistrationStatus';
import { useVerifyIdentity } from '@/hooks/auth/useVerifyIdentity';
import { userRegistrationJourneyConfig } from '@/utils/identifyAndVerify';

import { YourAccountFormProps } from './index.types';

const AccountForm = ({
  formBtnIsDisabled,
  journeyKey,
}: {
  formBtnIsDisabled: boolean;
  journeyKey: USER_REGISTRATION_JOURNEY_KEY_OPTIONS;
}) => {
  const { setFieldValue } = useFormikContext();

  const { surname, scopedToken } = useUserRegistrationContext();
  const {
    data: accountIdentificationData,
    isFetching: accountDataFetching,
    isError: accountDataError,
    isSuccess: accountIdentificationSuccess,
  } = useAccountIdentification({
    familyName: surname,
    scopedToken,
  });
  const {
    data: userRegistrationStatus,
    isFetching: userRegistrationStatusFetching,
    isError: userRegistrationStatusError,
  } = useUserRegistrationStatus({
    scopedToken:
      userRegistrationJourneyConfig[journeyKey].blockForRegisteredEmails &&
      accountIdentificationSuccess
        ? scopedToken
        : '',
  });
  const { isError: isVerifyIdentityError } = useVerifyIdentity();

  const convertFieldValueToUpperCase = ({
    fieldName,
    fieldValue,
  }: {
    fieldName: string;
    fieldValue: string;
  }) => setFieldValue(fieldName, fieldValue.toUpperCase().trim());

  const notEnoughInfoToProceed =
    accountIdentificationData &&
    !accountIdentificationData.blackholeEmailAccountUser.hasDateOfBirth &&
    !accountIdentificationData.blackholeEmailAccountUser.hasMobileNumber;

  const canShowNotEnoughInfoAlert =
    userRegistrationJourneyConfig[journeyKey].showDetailedNotEnoughInfoAlert;

  return (
    <Form>
      <FormContainer>
        <Stack gap="lg" direction="vertical">
          <Card>
            <Stack direction="vertical" gap="smMd">
              <Typography variant="h2">Enter your account details</Typography>
              <FormTextFieldWithDescription
                fieldLabel="Account Number"
                fieldName="accountNumber"
                description="Includes 10 characters and can be found on any bills or letters from us"
                onChange={(event) =>
                  convertFieldValueToUpperCase({
                    fieldName: 'accountNumber',
                    fieldValue: event.target.value,
                  })
                }
              />
              <FormTextFieldWithDescription
                fieldLabel="Postcode"
                fieldName="postcode"
                description="Enter the postcode of the property you are responsible for"
                onChange={(event) =>
                  convertFieldValueToUpperCase({
                    fieldName: 'postcode',
                    fieldValue: event.target.value,
                  })
                }
              />
              <FormTextFieldWithDescription
                fieldLabel="Surname"
                fieldName="surname"
                description="Enter your surname as it appears on the bill"
              />
            </Stack>
          </Card>

          {isVerifyIdentityError ||
            accountDataError ||
            userRegistrationStatusError ||
            (notEnoughInfoToProceed && !canShowNotEnoughInfoAlert && (
              <IdentifyAndVerifyGenericErrorAlert />
            ))}

          {accountIdentificationData &&
            userRegistrationJourneyConfig[journeyKey]
              .blockForRegisteredEmails &&
            userRegistrationStatus?.email && (
              <EmailAlreadyRegisteredAlert
                email={userRegistrationStatus.email}
              />
            )}

          {canShowNotEnoughInfoAlert && notEnoughInfoToProceed && (
            <NotEnoughInfoAlert />
          )}

          <FormSubmitButton
            disabled={formBtnIsDisabled}
            loading={accountDataFetching || userRegistrationStatusFetching}
          >
            Continue
          </FormSubmitButton>
        </Stack>
      </FormContainer>
    </Form>
  );
};

const YourAccountForm = ({
  accountNumber,
  postcode,
  surname,
  onSubmit,
  journeyKey,
}: YourAccountFormProps) => (
  <Formik
    initialValues={{ accountNumber, postcode, surname }}
    validationSchema={object().shape({
      accountNumber: string()
        .min(10, accountNumberValidationMessage)
        .required(accountNumberValidationMessage),
      postcode: string()
        .matches(postcodeRegex, postcodeValidationMessage)
        .required(postcodeValidationMessage),
      surname: string().required('Please enter your surname'),
    })}
    onSubmit={onSubmit}
  >
    {({ values: { accountNumber, postcode, surname } }) => {
      return (
        <AccountForm
          formBtnIsDisabled={!accountNumber || !postcode || !surname}
          journeyKey={journeyKey}
        />
      );
    }}
  </Formik>
);

export default YourAccountForm;
