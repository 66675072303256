import { Stack } from '@krakentech/coral';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { Alert, DateField, FormSubmitButton } from '@/components';
import { SeparatedDate } from '@/types/date';
import { validateDateOfBirth as dateOfBirth } from '@/utils/validators/yup';

type DateOfBirthFormProps = {
  initialDateOfBirthValue?: SeparatedDate;
  isLoading?: boolean;
  errorMessageOnSubmit?: string;
  onSubmit: (value: { dateOfBirth: SeparatedDate }) => void;
};

const DateOfBirthForm = ({
  initialDateOfBirthValue = { day: '', month: '', year: '' },
  isLoading = false,
  errorMessageOnSubmit = '',
  onSubmit,
}: DateOfBirthFormProps) => (
  <Formik
    onSubmit={onSubmit}
    initialValues={{
      dateOfBirth: {
        day: initialDateOfBirthValue.day,
        month: initialDateOfBirthValue.month,
        year: initialDateOfBirthValue.year,
      },
    }}
    validationSchema={Yup.object().shape({
      dateOfBirth,
    })}
  >
    {({ values: { dateOfBirth }, dirty }) => (
      <Form>
        <Stack direction="vertical" gap="lg">
          <DateField fieldArrayName="dateOfBirth" title="" />
          {errorMessageOnSubmit && (
            <Alert severity="error">{errorMessageOnSubmit}</Alert>
          )}
          <FormSubmitButton
            loading={isLoading}
            disabled={
              !dirty &&
              (!dateOfBirth.day || !dateOfBirth.month || !dateOfBirth.year)
            }
          >
            Continue
          </FormSubmitButton>
        </Stack>
      </Form>
    )}
  </Formik>
);

export default DateOfBirthForm;
