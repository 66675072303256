import { PropsWithChildren, createContext, useState, useContext } from 'react';

import { AccountNumberContextReturnValue } from './index.types';

export const AccountNumberContext = createContext(
  {} as AccountNumberContextReturnValue
);

export const AccountNumberContextProvider = ({
  children,
}: PropsWithChildren) => {
  const [accountNumber, setAccountNumber] = useState('');

  return (
    <AccountNumberContext.Provider
      value={{
        accountNumber,
        setAccountNumber,
      }}
    >
      {children}
    </AccountNumberContext.Provider>
  );
};

export const useAccountNumberContext = () => {
  const context = useContext(AccountNumberContext);
  if (context === undefined) {
    throw new Error(
      'useAccountNumberContext must be used within a AccountNumberContexProvider'
    );
  }
  return context;
};
