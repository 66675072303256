import { useRouter } from 'next/router';
import { Stack, Typography, Button, Link, Grid } from '@krakentech/coral';
import { useCookiePreferencesContext } from '@krakentech/coral-organisms';

import { CookiePreferenceType } from '@/utils/cookies';

const CookieBanner = ({
  onUpdatePreference,
}: {
  onUpdatePreference: (newPreference: CookiePreferenceType) => void;
}) => {
  const { reload } = useRouter();
  const { setDisplayMode } = useCookiePreferencesContext();

  return (
    <Grid
      templateColumns="repeat(1, 1fr)"
      alignItems="center"
      gap="md"
      lg={{ templateColumns: 'repeat(2, 1fr)' }}
      xl={{ templateColumns: 'repeat(3, 1fr)' }}
    >
      <Grid.Item colSpan={1} xl={{ colSpan: 2 }}>
        <Typography>
          🍪 <strong>We use cookies to provide a great experience.</strong> If
          you&apos;re happy with this, continue browsing.{' '}
          <Link href={process.env.NEXT_PUBLIC_COOKIE_POLICY} target="_blank">
            Read more
          </Link>
          .
        </Typography>
      </Grid.Item>

      <Grid.Item colSpan={1}>
        <Stack
          direction="vertical"
          md={{
            direction: 'horizontal',
          }}
        >
          <Button
            variant="outlined"
            fullWidth
            onClick={() => setDisplayMode('selection')}
          >
            Fine tune
          </Button>
          <Button
            variant="outlined"
            fullWidth
            onClick={() => {
              onUpdatePreference('essential');
              setDisplayMode('closed');
            }}
          >
            Reject all
          </Button>
          <Button
            fullWidth
            onClick={() => {
              onUpdatePreference('everything');
              setDisplayMode('closed');

              /*
                Not sure if this is ideal, but without cookies initially set it blocks the adobe script
                from being loaded which prevent anything being tracked prior to a full refresh.
                Seems to be the only way to do it at the moment
              */
              reload();
            }}
          >
            Accept all
          </Button>
        </Stack>
      </Grid.Item>
    </Grid>
  );
};

export default CookieBanner;
