import { PropsWithChildren } from 'react';
import { Button, Stack, useCoralTheme } from '@krakentech/coral';
import { IconWarning } from '@krakentech/icons';

const TryAgainError = ({
  allowUserToRetryQuery,
  retryQuery,
  children,
}: PropsWithChildren<{
  allowUserToRetryQuery: boolean;
  retryQuery: () => void;
}>) => {
  const {
    color: {
      action: { warning },
    },
  } = useCoralTheme();

  return (
    <Stack direction="vertical" alignItems="center" gap="md">
      <IconWarning color={warning} />

      {children}

      {allowUserToRetryQuery && (
        <Button variant="text" onClick={retryQuery}>
          Try again
        </Button>
      )}
    </Stack>
  );
};

export default TryAgainError;
