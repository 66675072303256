export const METER_READING_SOURCES = {
  CUSTOMER: 'CUSTOMER',
  OPS: 'OPS',
  METER_READER: 'METER_READER',
  ESTIMATE: 'ESTIMATE',
  SMART: 'SMART',
};

export const METER_READING_SOURCE_LABELS = {
  CUSTOMER: 'Your reading',
  OPS: 'Your reading',
  METER_READER: 'Our reading',
  ESTIMATE: 'Estimate',
  SMART: 'Smart', // TODO: use correct label for smart meter readings when we have clients with smart meters
};
