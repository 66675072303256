import { PropsWithChildren } from 'react';
import {
  Alert as CoralAlert,
  AlertSeverity,
  useCoralTheme,
} from '@krakentech/coral';
import {
  IconCross,
  IconCircleExclamationFilled,
  IconCircleInformationFilled,
  IconCircleQuestionFilled,
  IconCircleCheckmarkFilled,
  IconTriangleExclamationFilled,
} from '@krakentech/icons';

const Alert = ({
  severity,
  removeIcon = false,
  onClose,
  children,
}: PropsWithChildren<{
  severity: AlertSeverity;
  removeIcon?: boolean;
  onClose?: () => void;
}>) => {
  const {
    color: { action },
  } = useCoralTheme();

  return (
    <CoralAlert
      severity={severity}
      {...(onClose && { onClose })}
      icon={removeIcon ? false : <SeverityIcon severity={severity} />}
      {...(onClose && {
        closeIcon: <IconCross color={action[severity]} size={16} />,
      })}
      iconAlignment="top"
    >
      {children}
    </CoralAlert>
  );
};

function SeverityIcon({ severity }: { severity: AlertSeverity }) {
  const {
    color: { action },
  } = useCoralTheme();

  const colorAndSize = {
    color: action[severity],
    size: 20,
  };

  switch (severity) {
    case 'info':
      return <IconCircleInformationFilled {...colorAndSize} />;
    case 'success':
      return <IconCircleCheckmarkFilled {...colorAndSize} />;
    case 'warning':
      return <IconTriangleExclamationFilled {...colorAndSize} />;
    case 'error':
      return <IconCircleExclamationFilled {...colorAndSize} />;
    default:
      return <IconCircleQuestionFilled {...colorAndSize} />;
  }
}

export default Alert;
