import { Grid, Stack } from '@krakentech/coral';

import MutedText from '@/components/MutedText';

import { FormFieldWithButtonProps } from './index.types';

const FormFieldWithButton = ({
  input,
  button,
  mutedText,
}: FormFieldWithButtonProps) => (
  <Stack direction="vertical" gap="xs">
    <Grid
      gap="sm"
      templateColumns="3fr 1fr"
      md={{ templateColumns: '2fr 1fr' }}
    >
      <Grid.Item>{input}</Grid.Item>
      <Grid.Item>{button}</Grid.Item>
    </Grid>
    {mutedText && <MutedText variant="caption">{mutedText}</MutedText>}
  </Stack>
);

export default FormFieldWithButton;
