import { useCallback, useEffect, useState } from 'react';
import { UseQueryResult } from '@tanstack/react-query';

import {
  AddressOption,
  getAddressList,
} from '@/components/AddressFinder/getAddressList';
import {
  FoundAddress,
  FoundBillingAddress,
  UseAddressLookupParams,
} from '@/pages/move-home/types';

export type FetchAddressDataReturn = {
  hasFetched: boolean;
  fetchingAddressData: boolean;
  fetchFailed: boolean;
  fetchData: () => Promise<void>;
  resetFetch: () => void;
  fetchedData: FoundAddress[] | FoundBillingAddress[] | undefined;
};
/**
 * Hook to create a more sensible API around data fetching of addresses.
 * Resets the data fetched when a new fetch is request, to simplify the logic in
 * the consuming components.
 */
export function useFetchAddressData(
  query: ({
    postcode,
  }: UseAddressLookupParams) => UseQueryResult<
    FoundAddress[] | FoundBillingAddress[]
  >,
  postcodeValue: string
): FetchAddressDataReturn {
  const [hasFetched, setHasFetched] = useState(false);
  const [fetchedData, setFetchedData] = useState<
    FoundAddress[] | FoundBillingAddress[] | undefined
  >(undefined);
  const {
    data: addressData,
    refetch: refetchAddressData,
    isFetching: fetchingAddressData,
    isError,
  } = query({ postcode: postcodeValue });

  useEffect(() => {
    if (!fetchingAddressData) setFetchedData(addressData);
  }, [addressData, fetchingAddressData]);

  const fetchFailed = hasFetched && isError;

  const fetchData = async () => {
    setFetchedData(undefined);
    setHasFetched(true);
    await refetchAddressData();
  };

  const resetFetch = useCallback(() => {
    setHasFetched(false);
    setFetchedData(undefined);
  }, []);

  return {
    hasFetched,
    fetchingAddressData,
    fetchFailed,
    fetchData,
    fetchedData,
    resetFetch,
  };
}

export type GetAddressOptionsReturn = {
  fetch: () => Promise<void>;
  options: AddressOption[] | undefined;
  reset: () => void;
  fetching: boolean;
  failed: boolean;
};

type Options = AddressOption[] | undefined;

export function useGetAddressOptions(
  query: ({
    postcode,
  }: UseAddressLookupParams) => UseQueryResult<
    FoundAddress[] | FoundBillingAddress[]
  >,
  postcode: string
): GetAddressOptionsReturn {
  const [options, setOptions] = useState<Options>(undefined);

  const { data, isFetching, isError, refetch, remove } = query({ postcode });

  useEffect(() => {
    if (!isFetching) setOptions(getAddressList(data));
  }, [data, isFetching]);

  const fetch = async () => {
    setOptions(undefined);
    await refetch();
  };

  const reset = useCallback(() => {
    setOptions(undefined);
    remove();
  }, [remove]);

  return {
    options,
    fetch,
    reset,
    fetching: isFetching,
    failed: !isFetching && isError,
  };
}
