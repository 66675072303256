import { useState } from 'react';
import {
  Button,
  Stack,
  Loader,
  Container,
  Typography,
} from '@krakentech/coral';

import { StyledLoadingLabelContainer } from '../ButtonLoadingLabel/index.styled';

const Paginator = ({
  buttonLabel,
  numberOfItemsDisplayed,
  totalNumberOfItems,
  onButtonClick,
  hasNextPage,
}: {
  buttonLabel: string;
  numberOfItemsDisplayed: number;
  totalNumberOfItems: number;
  onButtonClick: () => void;
  hasNextPage?: boolean;
}) => {
  const [buttonLoading, setButtonLoading] = useState(false);

  return (
    <Container marginX="auto" marginY="md" paddingX="md">
      <Stack direction="vertical" alignItems="center">
        {hasNextPage && (
          <Button
            variant="outlined"
            loading={buttonLoading}
            onClick={async () => {
              setButtonLoading(true);
              await onButtonClick();
              setButtonLoading(false);
            }}
            disabled={buttonLoading}
            loadingLabel={
              <StyledLoadingLabelContainer>
                <Stack alignItems="center" gap="sm">
                  {buttonLabel} <Loader size={18} />
                </Stack>
              </StyledLoadingLabelContainer>
            }
          >
            {buttonLabel}
          </Button>
        )}

        <Typography variant="body2">
          Showing {numberOfItemsDisplayed} of {totalNumberOfItems}
        </Typography>
      </Stack>
    </Container>
  );
};

export default Paginator;
