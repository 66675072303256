import { number, object, string } from 'yup';

export type ObtainKrakenTokenResponse = {
  obtainKrakenToken: {
    __typename?: string;
    refreshToken?: string;
    refreshExpiresIn?: number;
    token?: string;
    payload: object;
  };
};

export const obtainKrakenTokenSuccessSchema = object({
  obtainKrakenToken: object({
    __typename: string().optional(),
    payload: object().required(),
    refreshToken: string().optional(),
    refreshExpiresIn: number().optional(),
    token: string().required(),
  }),
});

export const validateResponseSchema = async (request: unknown) =>
  await obtainKrakenTokenSuccessSchema.validate(request);
