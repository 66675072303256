import { Typography, Stack } from '@krakentech/coral';

import { Alert } from '@/components';
import { Body1Skeleton, H2Skeleton } from '@/components/Skeletons';
import { useBalance } from '@/hooks/accounts/useBalance';

const VariableScheduleContent = () => {
  const { data: balance, isLoading, error } = useBalance();

  if (isLoading) {
    return <LoadingState />;
  }

  return (
    <>
      <Typography>
        Please wait until your next bill which will explain your charges.
        We&apos;ll collect your balance in full a few days after it has been
        issued
      </Typography>

      {balance < 0 && !error && (
        <Alert severity="info">
          <Typography>
            <strong>Top tip: </strong>Clear your debit balance before your next
            bill to reduce charges. Pay from the homepage or check your latest
            bill for payment options.
          </Typography>
        </Alert>
      )}
    </>
  );
};

const LoadingState = () => (
  <>
    <H2Skeleton />

    <Stack direction="vertical">
      <Body1Skeleton />
      <Body1Skeleton />
    </Stack>
  </>
);

export default VariableScheduleContent;
