import styled, { css } from 'styled-components';

export const StyledRadioCard = styled.div<{
  isChecked: boolean;
  disabled: boolean;
}>`
  ${({ theme, isChecked, disabled }) => css`
    position: relative;
    display: flex;
    align-items: baseline;
    border: ${theme.spacing.xxs} solid ${theme.color.surface.light};
    border-radius: ${theme.borderRadius};
    overflow: auto;

    &:hover {
      border-color: ${theme.color.primary.dark};
    }

    ${disabled &&
    css`
      color: ${theme.color.text.dark};
      border-color: ${theme.color.surface.light};

      &:hover {
        border-color: ${theme.color.surface.light};
      }
    `}

    ${isChecked &&
    css`
      border-color: ${theme.color.primary.main};
    `}

        ${isChecked &&
    disabled &&
    css`
      border-color: ${theme.color.primary.light};
    `}
  `}
`;

export const StyledRadioCardInput = styled.input`
  ${({ theme, disabled }) => css`
    position: absolute;
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    appearance: none;
    outline: none;
    width: 100%;
    height: 100%;
    margin: 0;
    z-index: 2;
    -webkit-tap-highlight-color: transparent;
    background: transparent;

    &:focus-visible {
      outline-width: ${theme.borderWidth};
      outline-style: solid;
      outline-color: ${theme.color.primary.dark};
      outline-offset: ${theme.spacing.xxs};
    }
  `}
`;

export const StyledRadioCardContent = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.xsSm};
  `}
`;

export const StyledRadioPresentation = styled.span<{
  isChecked: boolean;
  disabled: boolean;
}>`
  ${({ theme, isChecked, disabled }) => css`
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    height: ${theme.spacing.xsSm};
    width: ${theme.spacing.xsSm};
    margin-top: 0.8rem;
    border: 0.1rem solid ${theme.color.text.main};
    border-radius: 100%;

    ${disabled &&
    css`
      border-color: ${theme.color.surface.light};
    `}

    ${isChecked &&
    css`
      border-color: ${theme.color.primary.main};

      &:after {
        width: 0.7rem;
        height: 0.7rem;
        background: ${theme.color.primary.main};
        content: '';
        z-index: 0;
        border-radius: 100%;
      }
    `}

        ${isChecked &&
    disabled &&
    css`
      border-color: ${theme.color.primary.light};

      &:after {
        background: ${theme.color.primary.light};
      }
    `}
  `}
`;
