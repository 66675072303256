/**
 * Intended for use inside a useEffect as server-rendered applications do not immediately have access to the window
 */
export const getLocalStorageItem = (key: string): string | null | undefined => {
  return typeof window !== 'undefined' ? window.localStorage[key] : null;
};

export enum LOCAL_STORAGE_ITEMS {
  // used to set the user's selected cookie preference, e.g. 'everything' or 'analytics', etc.
  COOKIE_PREFERENCE = 'cookiePreference',
}
