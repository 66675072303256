import { Select } from '@/components';
import type { FieldTypeProps } from '@/components/accountManagement/personalDetails/fields/index.types';
import { mapEnumOptionsToSelectOptions } from '@/components/Select';
import { EMPLOYMENT_STATUS_OPTIONS } from '@/consts/updatePersonalDetails';

const EmploymentStatusField = ({
  name = 'employmentStatus',
  label = 'Employment status (optional)',
  disabled = false,
}: FieldTypeProps) => {
  return (
    <Select
      name={name}
      label={label}
      values={mapEnumOptionsToSelectOptions(EMPLOYMENT_STATUS_OPTIONS)}
      disabled={disabled}
    />
  );
};

export default EmploymentStatusField;
