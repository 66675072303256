import { useHasNotMovedOut } from '@/hooks/moveHome/useHasNotMovedOut';
import { useWillNotMoveOutInFuture } from '@/hooks/moveHome/useWillNotMoveOutInFuture';

export const useMovingOutStatus = () => {
  const {
    data: hasNotMovedOut,
    isLoading: hasNotMovedOutLoading,
    isError: hasNotMovedOutError,
  } = useHasNotMovedOut();

  const {
    data: willNotMoveOutInTheFuture,
    isLoading: willNotMoveOutLoading,
    isError: willNotMoveOutError,
  } = useWillNotMoveOutInFuture();

  const isMovingOutStatusLoading =
    hasNotMovedOutLoading || willNotMoveOutLoading;
  const isMovingOutStatusError = hasNotMovedOutError || willNotMoveOutError;

  const isMovingOut =
    !isMovingOutStatusLoading &&
    !isMovingOutStatusError &&
    !willNotMoveOutInTheFuture;

  const hasMovedOut =
    !isMovingOutStatusLoading && !isMovingOutStatusError && !hasNotMovedOut;

  return {
    isMovingOut,
    hasMovedOut,
    isMovingOutStatusLoading,
    isMovingOutStatusError,
  };
};
