import { format } from 'date-fns';

// NOTE: this won't work for numbers higher than 31 but given that this will probably only be used for dates, it should be fine
export const cardinalNumberToOrdinalNumber = (number: number) => {
  // if number is single digit, add a 0 to the front of it
  // this was added to prevent a Playwright bug in Safari browser - see here https://github.com/octoenergy/water-consumer-site/pull/276/commits/8868b6a7ac833ceba3ba0410d89768fd666f0587#r1403482034
  const formattedNumber = number > 0 && number < 10 ? `0${number}` : number;

  // Stick number in a date and return just the day of the month. Yes, really, this is the only way to do this
  return format(new Date(`2023-01-${formattedNumber}`), 'do');
};
