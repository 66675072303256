import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { INTERNAL_PATHS } from '@/utils/urls';

export function useNavigationLinks() {
  const [activeTab, setActiveTab] = useState('');
  const { pathname } = useRouter();

  const routes = {
    overview: INTERNAL_PATHS.HOME.path,
    bills: INTERNAL_PATHS.BILLS.path,
    usage: INTERNAL_PATHS.USAGE.path,
    settings: INTERNAL_PATHS.SETTINGS.path,
  };

  useEffect(() => {
    if (pathname.includes(INTERNAL_PATHS.BILLS.name)) {
      setActiveTab(INTERNAL_PATHS.BILLS.name);
    } else if (pathname.includes(INTERNAL_PATHS.USAGE.name)) {
      setActiveTab(INTERNAL_PATHS.USAGE.name);
    } else if (pathname.includes(INTERNAL_PATHS.SETTINGS.name)) {
      setActiveTab(INTERNAL_PATHS.SETTINGS.name);
    } else {
      setActiveTab(INTERNAL_PATHS.HOME.name);
    }
  }, [pathname]);

  return { activeTab, routes };
}
