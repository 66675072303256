import { FC, ReactNode } from 'react';
import Head from 'next/head';
import { useCoralTheme } from '@krakentech/coral';

const CustomHead: FC<{
  title?: string;
  children?: ReactNode;
}> = ({ title, children }) => {
  const { color } = useCoralTheme();

  const companyName = process.env.NEXT_PUBLIC_COMPANY_NAME;
  const titleWithSuffix = title
    ? `${title} - ${companyName}`
    : `${companyName}`;

  return (
    <Head>
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=5"
      />
      <meta name="theme-color" content={color.primary.main}></meta>

      {children}

      <title>{titleWithSuffix}</title>
    </Head>
  );
};

export default CustomHead;
