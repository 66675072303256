export type PageInJourney = {
  name: string;
  path: string;
  title: string;
};
export type PagesInJourney = Array<PageInJourney>;

export type NewAddressType = {
  value: string;
  label: string;
  propertyId?: string;
};

export type NewAddressSubmitValues = {
  address: NewAddressType | undefined;
  moveOutsideAddress: NewAddressType | undefined;
  moveInDate: Date | undefined;
  postcode: string;
};

export type UseAddressLookupParams = {
  postcode: string;
};

export type FoundAddress = {
  node: {
    address: string;
    propertyId?: string | null;
    activeMeterCount?: number | null;
  };
};

export type FoundBillingAddress = {
  node: string;
};

export const isFoundBillingAddress = (
  address: FoundAddress | FoundBillingAddress
): address is FoundBillingAddress => {
  return typeof address.node === 'string';
};

export const isServiceableAddress = (
  address: NewAddressType | undefined
): boolean => {
  if (!address) return false;

  return address.propertyId !== undefined;
};
