import { PropsWithChildren } from 'react';
import { Container, Stack, StepConfig, Stepper } from '@krakentech/coral';

import ChildPageLayout from '@/components/layouts/ChildPageLayout';

const StepperLayout = ({
  heading,
  totalSteps,
  activeStep,
  stepConfigs,
  onStepClicked,
  children,
}: PropsWithChildren<{
  heading: string;
  totalSteps: number;
  activeStep: number;
  stepConfigs: StepConfig[];
  onStepClicked: (step: number) => void;
}>) => (
  <ChildPageLayout pageHeading={heading}>
    <Stack gap="lg" md={{ gap: 'md' }} direction="vertical">
      <Container md={{ maxWidth: 450 }} marginX="auto" width="100%">
        <Stepper
          totalSteps={totalSteps}
          activeStep={activeStep}
          disableUncompleted
          stepConfigs={stepConfigs}
          onStepClicked={(step) => onStepClicked(step)}
        />
      </Container>
      {children}
    </Stack>
  </ChildPageLayout>
);

export default StepperLayout;
