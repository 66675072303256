import { Card, Stack, Typography, Button } from '@krakentech/coral';

import { LoginActionCardProps } from './index.types';

const LoginActionCard = ({
  header,
  buttonText,
  buttonHref,
}: LoginActionCardProps) => {
  return (
    <Card>
      <Stack direction="vertical">
        <Typography variant="h2" textAlign="center">
          {header}
        </Typography>
        <Button href={buttonHref} variant="outlined">
          {buttonText}
        </Button>
      </Stack>
    </Card>
  );
};

export default LoginActionCard;
