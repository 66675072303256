import { Banner } from '@krakentech/coral';

import { useViewerEmail } from '@/hooks/accounts/useViewerEmail';

const MasqueradeBanner = () => {
  const { data: viewerEmail, isSuccess: isViewerEmailSuccess } =
    useViewerEmail();

  if (isViewerEmailSuccess) {
    return <Banner>You are now masquerading as {viewerEmail}</Banner>;
  } else {
    return null;
  }
};

export default MasqueradeBanner;
