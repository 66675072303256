import { useKrakenQuery } from '@/components/GraphQLClient';
import { useAuth } from '@/components/Providers/Auth';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';

import { fullNameAndSupplyAddressSuccessSchema } from '../validations';

import FullNameAndSupplyAddress from './FullNameAndSupplyAddress.graphql';

export const useFullNameAndSupplyAddress = () => {
  const { isAuthenticated } = useAuth();

  const { accountNumber } = useAccountNumberContext();

  return useKrakenQuery({
    queryKey: ['fullNameAndSupplyAddress'],
    query: FullNameAndSupplyAddress,
    validateFn(response) {
      return fullNameAndSupplyAddressSuccessSchema.validate(response);
    },
    enabled: Boolean(isAuthenticated && accountNumber),
    variables: { accountNumber, activeFrom: new Date() },
  });
};
