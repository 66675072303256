import { Stack } from '@krakentech/coral';
import { PaymentElement } from '@stripe/react-stripe-js';

const StripePaymentForm = () => {
  return (
    <Stack direction="vertical" gap="lg">
      <PaymentElement />
    </Stack>
  );
};

export default StripePaymentForm;
