type Ledger = {
  id: string;
  ledgerType: string;
};

export const getCorrectLedgerId = (ledgers: Array<Ledger>) => {
  if (!ledgers || !ledgers.length) {
    return null;
  }

  const matchingLedger = ledgers.find((ledger) => ledger.ledgerType !== 'MAIN');

  return matchingLedger ? parseInt(matchingLedger.id) : null;
};
