import styled from 'styled-components';

export const StyledQueuedAlertOuterContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
`;

export const StyledQueuedAlertInnerContainer = styled.div`
  position: fixed;
  width: 100%;

  section {
    margin-bottom: 0;
  }
`;
