import { useEffect, useState } from 'react';
import { CookiePreferences as CoralCookiePreferences } from '@krakentech/coral-organisms';
import { hasCookie } from 'cookies-next';

import {
  CookiePreferenceType,
  getSavedUserPreference,
  hasAnalyticsConsent,
  hasMarketingConsent,
  updateCookiePreference,
  COOKIES,
  updateConsent,
} from '@/utils/cookies';

import { CookieBanner, CookieSelection } from './components';
import { StyledCookiePreferencesContainer } from './index.styled';

const getInitialPreferences = () => {
  const savedPreference = getSavedUserPreference();
  const initialMarketingState = savedPreference
    ? hasMarketingConsent(savedPreference)
    : false;
  const initialAnalyticsState = savedPreference
    ? hasAnalyticsConsent(savedPreference)
    : false;
  return {
    initialMarketingState,
    initialAnalyticsState,
  };
};

const CookiePreferences = () => {
  // start with banner hidden so we don't show a flash of content
  const [showBanner, setShowBanner] = useState(false);
  // We want to make sure the initial preferences for a user are sent to GA if they do not interact with the banner
  // e.g if they are not shown it because they have already set a preference
  const [hasSetInitialPreferences, setHasSetInitialPreferences] =
    useState(false);

  const onUpdatePreference = (preference: CookiePreferenceType) => {
    updateCookiePreference(preference);
  };

  // show the banner if we don't have a cookie set
  useEffect(() => {
    const mandatoryCookiesNotSet =
      !hasCookie(COOKIES.DISMISS_COOKIE_BANNER) ||
      !hasCookie(COOKIES.COOKIE_PREFERENCE);

    setShowBanner(mandatoryCookiesNotSet);
  }, [setShowBanner]);

  // we should send this if they have a cookie and we have not set their initial preferences
  useEffect(() => {
    if (!hasSetInitialPreferences && hasCookie(COOKIES.DISMISS_COOKIE_BANNER)) {
      const initialPreferences = getInitialPreferences();
      updateConsent(
        initialPreferences.initialMarketingState,
        initialPreferences.initialAnalyticsState
      );
      setHasSetInitialPreferences(true);
    }

    if (!hasSetInitialPreferences) {
      const initialPreferences = {
        initialMarketingState: false,
        initialAnalyticsState: false,
      };
      updateConsent(
        initialPreferences.initialMarketingState,
        initialPreferences.initialAnalyticsState
      );
      setHasSetInitialPreferences(true);
    }
  }, [hasSetInitialPreferences]);

  const initialDisplayMode = 'banner';

  return showBanner ? (
    <StyledCookiePreferencesContainer>
      <CoralCookiePreferences initialDisplayMode={initialDisplayMode}>
        <CoralCookiePreferences.CookiePreferencesChild displayName="banner">
          <CookieBanner onUpdatePreference={onUpdatePreference} />
        </CoralCookiePreferences.CookiePreferencesChild>

        <CoralCookiePreferences.CookiePreferencesChild displayName="selection">
          <CookieSelection onUpdatePreference={onUpdatePreference} />
        </CoralCookiePreferences.CookiePreferencesChild>
      </CoralCookiePreferences>
    </StyledCookiePreferencesContainer>
  ) : (
    <></>
  );
};

export default CookiePreferences;
