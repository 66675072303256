import { useKrakenQuery } from '@/components/GraphQLClient';
import { useAuth } from '@/components/Providers/Auth';

import { latestReadingSchema } from '../validations';

import LatestReading from './LatestReading.graphql';

export const useLatestReading = ({
  accountNumber,
}: {
  accountNumber: string | undefined;
}) => {
  const { isAuthenticated } = useAuth();

  return useKrakenQuery({
    queryKey: ['latestReading'],
    query: LatestReading,
    select: (data: any) => data.account,
    validateFn(response) {
      return latestReadingSchema.validate(response);
    },
    enabled: Boolean(isAuthenticated && accountNumber),
    variables: { accountNumber, activeFrom: new Date() },
  });
};
