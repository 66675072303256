import Link from 'next/link';
import { Button, Stack, Typography } from '@krakentech/coral';

import { SIGN_UP_STATUS } from '@/consts/nonEmailSignUpStatus';
import { useRequestPasswordReset } from '@/hooks/auth/useRequestPasswordReset';
import { INTERNAL_PATHS } from '@/utils/urls';

import ButtonLoadingLabel from '../ButtonLoadingLabel';
import PageError from '../PageError';

const SignUpStatusCtaAndContactUs = ({
  status,
  email,
}: {
  status: string;
  email: string;
}) => {
  const {
    mutate,
    isLoading: isLoadingRequestPasswordReset,
    error: errorRequestPasswordReset,
  } = useRequestPasswordReset({
    redirectAfterRequestPasswordReset: true,
  });

  if (errorRequestPasswordReset) {
    return <PageError />;
  }

  const { COMPLETE, IN_PROGRESS, NOT_STARTED } = SIGN_UP_STATUS;

  const getSignUpStatusCta = (status: string) => {
    if (status === NOT_STARTED) {
      return (
        <Link href={INTERNAL_PATHS.SIGN_UP.VERIFY_IDENTITY.path}>
          <Button size="medium" fullWidth>
            Start
          </Button>
        </Link>
      );
    } else if (status === IN_PROGRESS) {
      return (
        <Button
          onClick={() => mutate({ input: { email } })}
          loading={isLoadingRequestPasswordReset}
          disabled={isLoadingRequestPasswordReset}
          loadingLabel={<ButtonLoadingLabel>Resend email</ButtonLoadingLabel>}
          size="medium"
          fullWidth
        >
          Resend email
        </Button>
      );
    } else if (status === COMPLETE) {
      return (
        <Button href={INTERNAL_PATHS.LOGIN.path} size="medium" fullWidth>
          Go to log in
        </Button>
      );
    }
  };
  return (
    <Stack direction="vertical" gap="sm" xs={{ gap: 'md' }}>
      {getSignUpStatusCta(status)}
      <Typography textAlign="center">
        Don&apos;t see your name?{' '}
        <Link
          // For Typescript, this env var should never be undefined
          href={process.env.NEXT_PUBLIC_HELP_LINK || ''}
        >
          Contact us
        </Link>
      </Typography>
    </Stack>
  );
};

export default SignUpStatusCtaAndContactUs;
