import { Card, Stack, Typography } from '@krakentech/coral';
import { useFormikContext } from 'formik';

import { H2Skeleton } from '@/components/Skeletons';
import { PAYMENT_FREQUENCY_FIELD_OPTIONS } from '@/consts/paymentFrequency';
import { useHasActiveWaterMeter } from '@/hooks/usage/useHasActiveWaterMeter';
import { PaymentFrequencyFormValues } from '@/types/directDebit';

import {
  FixedMonthlyScheduleContent,
  PaymentPlanContent,
  VariableScheduleContent,
} from './components';
import FixedScheduleError from './components/FixedScheduleError';
import PaymentCardLoader from './components/PaymentCardLoader';

const EstimatedPaymentsCard = () => {
  const {
    data: activeWaterMeterCount,
    isLoading,
    isError,
    refetch,
  } = useHasActiveWaterMeter();
  const { values } = useFormikContext<PaymentFrequencyFormValues>();

  if (isLoading) {
    return <LoadingState />;
  }

  if (isError) {
    return <FixedScheduleError refetch={refetch} />;
  }

  return (
    <Card>
      <Stack direction="vertical" gap="md">
        <Typography variant="h2">Your payments</Typography>

        {values.paymentFrequency ===
        PAYMENT_FREQUENCY_FIELD_OPTIONS.VARIABLE ? (
          <VariableScheduleContent />
        ) : (
          <>
            {activeWaterMeterCount ? (
              <FixedMonthlyScheduleContent />
            ) : (
              <PaymentPlanContent />
            )}
          </>
        )}
      </Stack>
    </Card>
  );
};

const LoadingState = () => (
  <Card>
    <Stack direction="vertical" gap="md">
      <H2Skeleton />

      <PaymentCardLoader title="Next payment" />
    </Stack>
  </Card>
);

export default EstimatedPaymentsCard;
