import { Link } from '@krakentech/coral';

import { StyledErrorLink } from './index.styled';
import { ErrorLinkProps } from './index.types';

const ErrorLink = ({ href, target = '_self', children }: ErrorLinkProps) => (
  <StyledErrorLink>
    <Link href={href} target={target}>
      {children}
    </Link>
  </StyledErrorLink>
);

export default ErrorLink;
