export const accountNumberValidationMessage =
  'Please enter an account number that has 10 characters';
export const postcodeValidationMessage = 'Please enter a valid UK postcode';
export const surnameValidationMessage = 'Please enter your surname';

export const postcodeRegex = /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i;

export const validEmailAddressValidationMessage =
  'Please enter a valid email address';
export const requiredEmailAddressValidationMessage =
  'Please enter an email address';
export const requiredNewPasswordValidationMessage =
  'Please enter a new password';
export const requiredNewPasswordConfirmValidationMessage =
  'Please confirm your new password';
export const passwordConfirmDoesNotMatchValidationMessage =
  'This does not match your password';
