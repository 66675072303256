
import { useKrakenQuery } from '@/components/GraphQLClient';
import { useAuth } from '@/components/Providers/Auth';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';

import { hasCurrentDirectDebitInstructionSchema } from '../validations';

import HasCurrentDirectDebitInstruction from './HasCurrentDirectDebitInstruction.graphql';

export const useHasCurrentDirectDebitInstruction = () => {
  const { isAuthenticated } = useAuth();

  const { accountNumber } = useAccountNumberContext();

  return useKrakenQuery({
    queryKey: ['hasCurrentDirectDebitInstruction'],
    query: HasCurrentDirectDebitInstruction,
    select: (data: any) => data.account.directDebitInstructions.edges[0],
    variables: { accountNumber },
    validateFn(response) {
      return hasCurrentDirectDebitInstructionSchema.validate(response);
    },
    enabled: Boolean(isAuthenticated && accountNumber),
    cacheTime: 0,
  });
};
