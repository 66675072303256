import { Button, Stack, Typography } from '@krakentech/coral';

import { StyledReviewDetail } from './index.styled';
import { ReviewDetailProps } from './index.types';

const ReviewDetail = ({
  handleOnClick,
  title,
  details,
  buttonLabel = 'Change',
  endIcon,
}: ReviewDetailProps) => {
  return (
    <StyledReviewDetail>
      <Stack
        gap="sm"
        alignItems="start"
        direction="vertical"
        md={{
          alignItems: 'center',
          direction: 'horizontal',
          gap: 'smMd',
        }}
      >
        <Stack.Item flex={1}>
          <Stack gap="xs" direction="vertical">
            <Typography variant="body2" color="dark">
              {title}
            </Typography>
            {details && <Typography>{details}</Typography>}
          </Stack>
        </Stack.Item>
        {handleOnClick && (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleOnClick}
          >
            {buttonLabel}
          </Button>
        )}
        {endIcon && <Stack.Item>{endIcon}</Stack.Item>}
      </Stack>
    </StyledReviewDetail>
  );
};

export default ReviewDetail;
