import { Button, Dialog, Link, Stack, Typography } from '@krakentech/coral';

import ButtonLoadingLabel from '@/components/ButtonLoadingLabel';
import { INTERNAL_PATHS } from '@/utils/urls';

type OrderPrintedBillDialogProps = {
  dialogOpen: boolean;
  onOrderPrintedCopy: () => void;
  onClose: () => void;
  billType: string;
  id: string;
  isLoading: boolean;
};

const OrderPrintedBillDialog = ({
  dialogOpen,
  onOrderPrintedCopy,
  onClose,
  billType,
  id,
  isLoading,
}: OrderPrintedBillDialogProps) => {
  const href = INTERNAL_PATHS.SINGLE_BILL_DOWNLOAD.createPath({
    billType,
    id,
  });

  return (
    <Dialog
      ariaLabelledBy="order-paper-bill-dialog"
      open={dialogOpen}
      onClose={onClose}
      maxWidth="xs"
    >
      <Stack direction="vertical" gap="md">
        <Typography variant="h1" textWrap="unset">
          Are you sure you want to order a paper copy?
        </Typography>
        <Typography>
          Save time and paper -
          <Link href={href} target="_blank">
            view and download your PDF bill.
          </Link>
          This is exactly the same version as the one you would receive in the
          post.
        </Typography>
        <Typography>
          If you would still like a paper copy, please note this usually takes
          around 5 working days to arrive.
        </Typography>
        <Stack
          direction="vertical-reverse"
          fullWidth
          md={{ direction: 'horizontal' }}
        >
          <Button fullWidth onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button
            fullWidth
            onClick={onOrderPrintedCopy}
            loading={isLoading}
            disabled={isLoading}
            loadingLabel={<ButtonLoadingLabel>Ordering</ButtonLoadingLabel>}
          >
            Order paper copy
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default OrderPrintedBillDialog;
