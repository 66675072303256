import { useKrakenQuery } from '@/components/GraphQLClient';
import { useAuth } from '@/components/Providers/Auth';

import {
  TAccountNumberList,
  accountNumberListSuccessSchema,
} from '../validations';

import AccountNumberList from './AccountNumberList.graphql';

export const useAccountNumberList = (shouldFetch = true) => {
  const { isAuthenticated } = useAuth();

  return useKrakenQuery({
    queryKey: ['accountNumberList'],
    query: AccountNumberList,
    select: (data: TAccountNumberList) => data.viewer.accounts,
    validateFn(response) {
      return accountNumberListSuccessSchema.validate(response);
    },
    enabled: Boolean(isAuthenticated && shouldFetch),
  });
};
