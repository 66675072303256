import {
  Button,
  Stack,
  Typography,
  useCoralBreakpoints,
} from '@krakentech/coral';

import { INTERNAL_PATHS } from '@/utils/urls';

const SetUpDirectDebitCta = () => {
  const { isMinMd } = useCoralBreakpoints();

  return (
    <Stack direction="vertical" gap="md">
      <Typography textAlign={isMinMd ? 'left' : 'center'}>
        Change to Direct Debit to make your payments easier to manage - it only
        takes a minute!
      </Typography>
      <Button size="medium" href={INTERNAL_PATHS.SET_UP_DIRECT_DEBIT[0].path}>
        Start
      </Button>
    </Stack>
  );
};

export default SetUpDirectDebitCta;
