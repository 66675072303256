import { useKrakenQuery } from '@/components/GraphQLClient';

import { userRegistrationStatusSchema } from '../validations';

import UserRegistrationStatus from './UserRegistrationStatus.graphql';

export const useUserRegistrationStatus = ({
  scopedToken,
}: {
  scopedToken?: string;
}) =>
  useKrakenQuery({
    queryKey: ['UserRegistrationStatus'],
    query: UserRegistrationStatus,
    validateFn(response) {
      return userRegistrationStatusSchema.validate(response);
    },
    enabled: Boolean(scopedToken),
    requestHeaders: scopedToken
      ? {
          Authorization: scopedToken,
        }
      : undefined,
    select: (data) => data.blackholeEmailAccountUser,
    cacheTime: 0,
  });
