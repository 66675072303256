import { Stack, Typography } from '@krakentech/coral';

import { Select } from '@/components';
import type { FieldTypeProps } from '@/components/accountManagement/personalDetails/fields/index.types';
import { mapEnumOptionsToSelectOptions } from '@/components/Select';
import {
  STANDARD_TITLE_OPTIONS,
  EXTENDED_TITLE_OPTIONS,
} from '@/consts/updatePersonalDetails';

const TitleField = ({
  name = 'title',
  label = 'Title (optional)',
  disabled = false,
}: FieldTypeProps) => {
  const TITLE_OPTIONS =
    // This is a temporary solution to allow the extended title options to be displayed on ST
    process.env.NEXT_PUBLIC_THEME === 'stw'
      ? EXTENDED_TITLE_OPTIONS
      : STANDARD_TITLE_OPTIONS;

  return (
    <Stack direction="vertical" gap="sm">
      <Typography variant="h3">Title</Typography>
      <Select
        name={name}
        label={label}
        values={mapEnumOptionsToSelectOptions(TITLE_OPTIONS)}
        disabled={disabled}
      />
    </Stack>
  );
};

export default TitleField;
