import { useKrakenQuery } from '@/components/GraphQLClient';
import { useAuth } from '@/components/Providers/Auth';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';

import { balanceSuccessSchema } from '../validations';

import Balance from './Balance.graphql';

export const useBalance = () => {
  const { accountNumber } = useAccountNumberContext();

  const { isAuthenticated } = useAuth();

  return useKrakenQuery({
    queryKey: ['balance'],
    query: Balance,
    select: (data: any) => data.account.balance,
    validateFn(response) {
      return balanceSuccessSchema.validate(response);
    },
    enabled: Boolean(isAuthenticated && accountNumber),
    variables: { accountNumber },
    // TODO: don't have this at 0 all the time, to reduce the number of requests on the /direct-debit/payment-frequency page
    cacheTime: 0,
  });
};
