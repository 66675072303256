import { useCoralTheme } from '@krakentech/coral';

const PoundSignActive = () => {
  const { color } = useCoralTheme();

  return (
    <svg
      width="16"
      height="21"
      viewBox="0 0 16 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.52561 6C4.52561 3.9375 6.16624 2.25 8.22874 2.25C8.60374 2.25 9.02561 2.34375 9.40061 2.48438L13.1506 3.70312C13.76 3.89062 14.3694 3.60938 14.5569 3C14.7912 2.39062 14.4631 1.78125 13.8537 1.59375L10.1037 0.328125C9.49436 0.140625 8.83811 0 8.22874 0C4.94749 0 2.27561 2.67188 2.27561 6V9H1.15061C0.494362 9 0.0256124 9.51562 0.0256124 10.125C0.0256124 10.7812 0.494362 11.25 1.15061 11.25H2.27561V14.25C2.27561 15.0469 2.04124 15.8438 1.66624 16.5938L0.119362 19.3594C-0.0681376 19.6875 -0.0212626 20.1094 0.166237 20.4844C0.353737 20.8125 0.728737 21 1.15061 21H13.9006C14.51 21 15.0256 20.5312 15.0256 19.875C15.0256 19.2656 14.51 18.75 13.9006 18.75H3.02561L3.63499 17.6719C4.19749 16.5938 4.52561 15.4219 4.52561 14.25V11.25H10.9006C11.51 11.25 12.0256 10.7812 12.0256 10.125C12.0256 9.51562 11.51 9 10.9006 9H4.52561V6Z"
        fill={color.tertiary.main}
      />
    </svg>
  );
};

export default PoundSignActive;
