import { useRouter } from 'next/router';
import { Loader, Typography } from '@krakentech/coral';

import { useAccountNumberList } from '@/hooks/accounts/useAccountNumberList';

import PageError from '../PageError';

// This type only allows for 'createRedirectPath' function or 'path', it can be replaced with 'path' only when the accountNumber is removed from all page URL's
type AccountSelectorProps =
  | {
      createRedirectPath?: ({
        accountNumber,
      }: {
        accountNumber: string;
      }) => string;
      path?: never;
    }
  | { createRedirectPath?: never; path?: string };

const AccountSelector = ({
  createRedirectPath,
  path,
}: AccountSelectorProps) => {
  const router = useRouter();
  const { data, isError } = useAccountNumberList();

  if (isError) {
    return <PageError />;
  }

  // Check that data is not undefined, and that there is at least one account number to link or redirect to
  if (data) {
    if (data.length > 1) {
      // TODO: build this part of the page when there is the need for it/when we have the designs
      return <Typography>Multiple accounts</Typography>;
    } else {
      if (createRedirectPath) {
        router.push(createRedirectPath({ accountNumber: data[0].number }));
        return null;
      } else if (path) {
        router.push(path);
        return null;
      }
    }
  }

  return <Loader type="linear" variant="indeterminate" />;
};

export default AccountSelector;
