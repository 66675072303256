import appConfig from '@/config';

/**
 * A mapping of environment to the associated api endpoint from the project config. Use the API_ENDPOINT variable to access the endpoint for the current environment
 */
export const API_ENDPOINTS = {
  development: appConfig.apiEndpointDev,
  preview: appConfig.apiEndpointPreview,
  production: appConfig.apiEndpointProd,
  test: appConfig.apiEndpointTest,
};

/**
 * A flag for whether or not the application is running in development mode.
 */
export const isDevelopment = process.env.NODE_ENV === 'development';

/**
 * A flag for whether or not the application is running in test mode.
 */
export const isTest = process.env.NODE_ENV === 'test';

/**
 * A flag for whether or not the application is running in a Vercel preview deployment.
 */
export const isPreviewDeployment =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview';

/**
 * A flag for whether or not the application is running in a Vercel production deployment.
 */
export const isProductionDeployment =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';

/**
 * A flag for whether or not the application is running in a Vercel preview or production deployment.
 */
export const isVercelDeployment = isPreviewDeployment || isProductionDeployment;

/**
 * Get the current environment the application is runnning in.
 */
export const getEnvironment = () => {
  if (isDevelopment) {
    return 'development';
  } else if (isPreviewDeployment) {
    return 'preview';
  } else if (isTest) {
    return 'test';
  } else {
    return 'production';
  }
};

/**
 * The Vercel deployment URL e.g. project-blueprint.vercel.app or an empty string if running locally.
 */
export const VERCEL_DEPLOYMENT_URL = process.env.NEXT_PUBLIC_VERCEL_URL || '';

/**
 * The API origin from the project config for the current environment
 */
export const API_ENDPOINT = API_ENDPOINTS[getEnvironment()];

/**
 * The current hostname e.g. project-blueprint.vercel.app or localhost:3000
 */
export const HOSTNAME = isVercelDeployment
  ? VERCEL_DEPLOYMENT_URL
  : appConfig.localhost;

/**
 * The current origin e.g. https://project-blueprint.vercel.app or http://localhost:3000
 */
export const ORIGIN = isVercelDeployment
  ? `https://${HOSTNAME}`
  : `http://${HOSTNAME}`;

export const IS_STW = process.env.NEXT_PUBLIC_THEME === 'stw';
export const isStw = () => IS_STW;

export const isPwl = () => process.env.NEXT_PUBLIC_THEME === 'pwl';
