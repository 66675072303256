import { array, object, string, date, number } from 'yup';

export const occupancyPeriodSchema = object({
  account: object({
    properties: array(
      object({
        occupancyPeriods: array(
          object({
            effectiveFrom: date(),
            effectiveTo: date().nullable(),
          })
        ),
      })
    ),
  }),
});

export const submitMoveHomeSchema = object({
  moveOut: object({
    account: object({
      id: string().required(),
    }),
  }),
});

export const addressLookupSchema = object({
  addressLookup: object({
    edges: array(
      object({
        node: object({
          address: string().required(),
          propertyId: string().nullable(),
        }),
      })
    ).required(),
  }),
});

export const serviceablePropertiesLookupSchema = object({
  serviceableProperties: object({
    edges: array(
      object({
        node: object({
          address: string().required(),
          propertyId: string().nullable(),
          activeMeterCount: number().nullable(),
        }),
      })
    ).required(),
  }),
});

export const billingAddressLookupSchema = object({
  billingAddresses: object({
    edges: array(
      object({
        node: string().required(),
      })
    ).required(),
  }),
});

export const submitCreateNewAccountSchema = object({
  moveInCreateCustomer: object({
    accountNumber: string().required(),
  }),
});
