import { ReactNode, createContext, useState, useContext } from 'react';

import { NonEmailSignUpContextReturnValue } from './index.types';

export const NonEmailSignUpContext = createContext(
  {} as NonEmailSignUpContextReturnValue
);

export const NonEmailSignUpContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [userId, setUserId] = useState('');
  const [email, setEmail] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState<string | Date>('');
  const [mobileNumber, setMobileNumber] = useState('');

  return (
    <NonEmailSignUpContext.Provider
      value={{
        userId,
        setUserId,
        email,
        setEmail,
        dateOfBirth,
        setDateOfBirth,
        mobileNumber,
        setMobileNumber,
      }}
    >
      {children}
    </NonEmailSignUpContext.Provider>
  );
};

export const useNonEmailSignUpContext = () => {
  const context = useContext(NonEmailSignUpContext);
  if (context === undefined) {
    throw new Error(
      'useNonEmailSignUpContext must be used within a NonEmailSignUpContextProvider'
    );
  }
  return context;
};
