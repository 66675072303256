import { useKrakenQuery } from '@/components/GraphQLClient';
import { useAuth } from '@/components/Providers/Auth';

import { meterDetailsSchema } from '../validations';

import MeterDetails from './MeterDetails.graphql';

export const useMeterDetails = ({
  accountNumber,
}: {
  accountNumber: string | undefined;
}) => {
  const { isAuthenticated } = useAuth();

  return useKrakenQuery({
    queryKey: ['meterDetails'],
    query: MeterDetails,
    select: (data: any) => data.account,
    validateFn(response) {
      return meterDetailsSchema.validate(response);
    },
    enabled: Boolean(isAuthenticated && accountNumber),
    variables: { accountNumber, activeFrom: new Date() },
    cacheTime: 0,
  });
};
