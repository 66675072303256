import { Typography, Stack, Card, Radio } from '@krakentech/coral';

import { InlineRadioOverride } from '@/styles/Overrides.styled';

import { RadioToggleCardProps } from './index.types';

const RadioToggleCard = ({
  handleOnRadioChange,
  value,
  title,
  onLabel = 'Yes',
  offLabel = 'No',
  name,
  children,
}: RadioToggleCardProps) => (
  <Card>
    <Stack direction="vertical" gap="smMd">
      <Typography variant="h2">{title}</Typography>
      <InlineRadioOverride>
        <Radio
          onChange={(_, radioValue) => handleOnRadioChange(radioValue)}
          name={name}
          value={value}
        >
          <Radio.Option value="true">{onLabel}</Radio.Option>
          <Radio.Option value="false">{offLabel}</Radio.Option>
        </Radio>
      </InlineRadioOverride>
      {children}
    </Stack>
  </Card>
);

export default RadioToggleCard;
