import { Dialog, Stack, Typography, Button } from '@krakentech/coral';

import { UnorderedList } from '@/components';

import { MeterReadingIsHeldDialogProps } from './index.types';

const MeterReadingIsHeldDialog = ({
  reading,
  isOpen,
  onClose,
  loading,
  submitForm,
}: MeterReadingIsHeldDialogProps) => {
  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      ariaLabelledBy="submitReadingToggle"
    >
      <Stack direction="vertical" gap="lg">
        <Stack direction="vertical" gap="xsSm">
          <Typography variant="h1">{`Are you sure you want to submit ${reading}?`}</Typography>
          <Typography>
            Make sure you&apos;ve only included the black numbers or those
            before the decimal. You may proceed if you&apos;re sure you have
            read the meter correctly or you can try entering another reading.
          </Typography>
          <UnorderedList
            listItems={[
              'Read is higher or lower than expected',
              'A previous reading is under review',
            ]}
          />
          <Typography>
            This ensures an accurate billing process - we may contact you if
            needed.
          </Typography>
        </Stack>
        <Stack justifyContent="flex-end">
          <Button
            onClick={onClose}
            size="medium"
            type="button"
            variant="outlined"
            color="primary"
            fullWidth
          >
            Change reading
          </Button>

          <Button
            size="medium"
            color="primary"
            onClick={() => {
              submitForm();
            }}
            loading={loading}
            disabled={loading}
            fullWidth
          >
            Submit
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default MeterReadingIsHeldDialog;
